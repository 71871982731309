import * as React from "react";

import { Redirect } from "react-router-dom";
import { Logger } from "@sparkademy/app-common/services/logger-service";

export class ErrorBoundary extends React.Component<{}, { hasError: boolean }> {
  constructor(props: any) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error: any, _errorInfo: any) {
    Logger.error(error);
  }

  render() {
    if (this.state.hasError) {
      return <Redirect to="/error" />;
    }
    return this.props.children;
  }
}
