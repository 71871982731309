/** @jsx jsx */
import { jsx, Box, SxStyleProp } from "theme-ui";
import * as React from "react";

export const SuitcaseIcon: React.FC<{ sxOverride?: SxStyleProp; color: string }> = ({
  sxOverride,
  color,
}) => (
  <Box sx={{ mr: 3, display: "flex", ...sxOverride }}>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3 18V14H10V14.875H14V14H21V18C21 19.6569 19.6569 21 18 21H6C4.34315 21 3 19.6569 3 18Z"
        stroke={color}
        strokeWidth="1.2"
      />
      <path
        d="M3 9V13H10V12.125H14V13H21V9C21 7.34315 19.6569 6 18 6H6C4.34315 6 3 7.34315 3 9Z"
        stroke={color}
        strokeWidth="1.2"
      />
      <path
        d="M9 6V5C9 3.89543 9.89543 3 11 3H13C14.1046 3 15 3.89543 15 5V6"
        stroke={color}
        strokeWidth="1.2"
      />
    </svg>
  </Box>
);
