import * as React from "react";
import { themeNew } from "@sparkademy/app-common/materials/theme";
import { useParams, Redirect } from "react-router-dom";
import { useSessionContext } from "@sparkademy/app-common/contexts/session-context";
import { Layout, LayoutSideMenu } from "../components/Layout";
import { ContainerSmall } from "@sparkademy/app-common/elements/Container";
import { ReactComponent as Download } from "@sparkademy/app-common/materials/icons/download.svg";
import { ReactComponent as Linkedin } from "@sparkademy/app-common/materials/icons/linkedin.svg";
import { Heading, Text, Box, Button as BaseButton, ThemeProvider } from "theme-ui";
import { ButtonGhostIconAnchor } from "@sparkademy/app-common/elements/ButtonGhostIcon";
import { useContentContext } from "@sparkademy/app-common/contexts/content-context";
import { Loader } from "@sparkademy/app-common/components/Loader";
import {
  addCertificateToLinkedIn as addCertificateToLinkedInAPI,
  getCertificateUrlForLevel,
} from "../services/http-api-service";

import { uiCopy } from "../data/ui-copy";
import { TrackingService } from "../services/tracking-service";
import certificateExample from "../data/certificate-example.png";
import { Narrative } from "@sparkademy/app-common/elements/Narrative";

export const CertificateDownload: React.FC = props => {
  const { currentUser } = useSessionContext();

  const { levels } = useContentContext();
  let { levelSlug } = useParams();
  const levelInfo = levels.find(l => l.id === levelSlug);

  const [loading, setIsLoading] = React.useState(false);

  const addCertificateToLinkedIn = async () => {
    if (!(levelInfo && currentUser)) {
      return;
    }

    setIsLoading(true);
    try {
      TrackingService.addCertificateToLinkedInClick(currentUser.data.id!, levelInfo.name);
      const { url: addToLinkedInUrl } = await addCertificateToLinkedInAPI(
        levelInfo.id,
        currentUser
      );
      window.open(addToLinkedInUrl);
    } catch (err) {
      console.error(err);
    }
    setIsLoading(false);
  };

  // const shareCertificateOnLinkedIn = async () => {
  //   if (!(level && currentUser)) {
  //     return
  //   }

  //   setIsLoading(true)
  //   try {
  //     TrackingService.shareCertificateOnLinkedInClick(currentUser.data.id!, level.name)
  //     const { url: shareOnLinkedInUrl } = await api.shareCertificateOnLinkedIn(level, currentUser)
  //     window.open(shareOnLinkedInUrl, '_blank', 'width=600,height=800')
  //   } catch (err) {
  //     console.error(err)
  //   }
  //   setIsLoading(false)
  // }

  if (!currentUser) {
    return null;
  }

  if (loading || levels.length === 0) {
    return (
      <Layout>
        <Loader />
      </Layout>
    );
  }

  if (!levelInfo) {
    return <Redirect to="/" />;
  }

  return (
    <ThemeProvider theme={themeNew}>
      <LayoutSideMenu
        sx={{
          bg: "new.primary.white",
        }}
      >
        <Box
          sx={{
            background: theme => `
          linear-gradient(
            165.14deg,
            ${theme.colors.new.primary.white} -264%,
            rgba(255, 255, 255, 0) 104.92%
          ), ${theme.colors.new.primary.purple}
          `,
          }}
        >
          <ContainerSmall
            sx={{
              color: "new.primary.white",
              height: 130,
              py: 7,
              display: "flex",
              alignItems: "flex-end",
            }}
          >
            <Heading
              as="span"
              sx={{
                fontSize: 4,
                fontWeight: "bold",
              }}
            >
              {uiCopy.CERTIFICATE_DOWNLOAD_TITLE(currentUser)}
            </Heading>
          </ContainerSmall>
        </Box>
        <ContainerSmall
          sx={{
            py: 14,
            maxWidth: "100ch",
          }}
        >
          <Heading
            as="h1"
            sx={{
              fontSize: 3,
              fontWeight: "bold",
            }}
          >
            {uiCopy.CERTIFICATE_DOWNLOAD_INTRO_TITLE}
          </Heading>
          <Box sx={{ mt: 9 }} />
          <Narrative>{uiCopy.CERTIFICATE_DOWNLOAD_INTRO_BODY()}</Narrative>
          <Box sx={{ mt: 12 }} />
          <section>
            <Heading
              as="h2"
              sx={{
                fontSize: 2,
                fontWeight: "bold",
              }}
            >
              {uiCopy.CERTIFICATE_DOWNLOAD_CERTIFICATE_TITLE}
            </Heading>
            <Box sx={{ mt: 7 }} />
            <Text
              as="p"
              sx={{
                fontSize: 1,
                lineHeight: 1.4,
              }}
            >
              {uiCopy.CERTIFICATE_DOWNLOAD_CERTIFICATE_BODY}
            </Text>
            <Box sx={{ mt: 10 }} />
            <Box
              sx={{
                bg: "new.secondary.lightGrey",
                p: 5,
                display: "flex",
              }}
            >
              <Box
                sx={{
                  width: 340,
                  flex: "0 0 auto",
                  img: {
                    display: "block",
                    maxWidth: "100%",
                  },
                }}
              >
                <img src={certificateExample} alt="Example certificate" />
              </Box>
              <Box sx={{ mr: 9 }} />
              <Box
                sx={{
                  flex: "1 1 auto",
                  py: 5,
                }}
              >
                <Text
                  as="p"
                  sx={{
                    fontSize: 0,
                    lineHeight: 1.4,
                  }}
                >
                  {uiCopy.CERTIFICATE_DOWNLOAD_CERTIFICATE_BOX_BODY}
                </Text>
                <Box sx={{ mt: 8 }} />
                <Box
                  sx={{
                    display: "flex",
                    ml: -5,
                  }}
                >
                  <ButtonGhostIconAnchor
                    href={getCertificateUrlForLevel(levelInfo.id, currentUser)}
                    icon={Download}
                    id="Certificate-Download-Anchor"
                    target="_blank"
                    onClick={() =>
                      TrackingService.downloadCertificateClick(currentUser.data.id!, levelInfo.name)
                    }
                    sx={{
                      fontSize: 0,
                    }}
                  >
                    {uiCopy.CERTIFICATE_DOWNLOAD_CERTIFICATE_BOX_DOWNLOAD}
                  </ButtonGhostIconAnchor>
                </Box>
                <Box sx={{ mt: 8 }} />
                <Text
                  as="p"
                  sx={{
                    fontSize: 0,
                    lineHeight: 1.4,
                  }}
                >
                  {uiCopy.CERTIFICATE_DOWNLOAD_CERTIFICATE_BOX_LINKEDIN}
                </Text>

                <Box sx={{ mt: 8 }} />
                <BaseButton
                  onClick={addCertificateToLinkedIn}
                  sx={{
                    bg: "#0A6EB3",
                    color: "#FFF",
                    borderRadius: 5,
                    fontWeight: "bold",
                    fontFamily: "body",
                    p: 0,
                    cursor: "pointer",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      minHeight: 44,
                    }}
                  >
                    <Box
                      sx={{
                        borderRight: `1px solid #0B5287`,
                        px: 2,
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <Linkedin width={32} height={32} />
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        px: 4,
                      }}
                    >
                      {uiCopy.CERTIFICATE_DOWNLOAD_CERTIFICATE_BOX_LINKEDIN_ADD}
                    </Box>
                  </Box>
                </BaseButton>
              </Box>
            </Box>
          </section>
        </ContainerSmall>
      </LayoutSideMenu>
    </ThemeProvider>
  );
};

//   "CERTIFICATE_DOWNLOAD_CERTIFICATE_BOX_BODY": "Download your certificate as a pdf.",
//   "CERTIFICATE_DOWNLOAD_CERTIFICATE_BOX_DOWNLOAD": "Download",
//   "CERTIFICATE_DOWNLOAD_CERTIFICATE_BOX_LINKEDIN": "Post this Certificate of Completion to your LinkedIn profile and share this achievement with your professional network.",
//   "CERTIFICATE_DOWNLOAD_CERTIFICATE_BOX_LINKEDIN_ADD": "Add to profile",
