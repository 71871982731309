// @ts-ignore
import { mdx } from "mdx.macro";
import * as React from "react";
// import CoreSyllabusDiagram from './core-syllabus-diagram.png'

export type StaticContent = {
  title: string;
  h1?: string;
  slug: string;
  content?: React.FC;
  userIdFilter?: string;
  hideFromFooter?: boolean;
  accordions: Array<{
    content: React.FC;
    accordion: Array<{
      title: string;
      content: React.FC;
    }>;
  }>;
  contentAfter?: React.FC;
  narrativeStyleNames?: string[];
};

export const pages: StaticContent[] = [
  {
    title: "Core Certification Syllabus",
    slug: "core-syllabus",
    hideFromFooter: true,
    content: mdx`
The Sparkademy Certification program has the objective to identify, define and teach the skill-set and competencies required for an internal innovation enabler, ultimately reducing the need for external consultants and allowing corporates to foster their own style of innovation culture.

The Core Certification curriculum consists of a well-rounded and highly curated set of four learning modules, which are mandatory for the program participants to receive certification. Every module is composed of a purposefully selected online course on a relevant topic, and an evaluation that includes a series of exercises to test and apply the knowledge acquired through the online course. The following modules are part of the Core curriculum:

<div class='arrow-list-after' />

- **Module 1:** User Discovery & Needfinding
- **Module 2:** Problem-solving and Idea Evaluation
- **Module 3:** Prototyping, Testing and Experimentation
- **Module 4:** Team Dynamics and Leadership

<h2 class="visually-hidden">Module Structure</h2>

Each module consists of the following elements:

<img src="/core-syllabus-diagram.png" alt="Module structure diagram" width="500" />

### Learning Experience

- **Online Course:** The Sparkademy online courses include the best state-of-the-art learning content. They are designed to meet the needs of all different learning styles, through videos, readings, animations, and ungraded mini-exercises. For each topic, our lecturers and the Sparkademy team brought together their teaching and consulting experience developed over the past years.
- **Method Cards:** To help participants incorporate their learnings into practice, Sparkademy created method cards that summarize the essential methods and tools participants get exposed to during their learning journey. We encourage participants to refer to these cards whenever they apply the learnings in their daily workflow and use them as a reference for their future projects.

### Assessment Elements

Both are mandatory to pass a module.

- **Practical Assignment:** The purpose of the practical assignment is to evaluate the skills that participants are developing overtime and test their ability to apply them in real life challenges in their work environment.
- **Knowledge Check:** The knowledge check evaluates the extent to which participants understood the content of the online course and are able to apply it in a new setting. Participants can go back to the online course and their own notes. Please note that this is not purely a memory test, but it requires reflection and critical thinking.



<hr />

## Module 1: <br /> User Discovery and Needfinding

Discovery is a preliminary phase in the innovation process that involves researching the problem space, framing the problem(s) to be solved, and gathering enough evidence and direction on what to do next. This module helps an internal innovation enabler understand the user’s needs and context and find deep insights rather than quick answers. At the beginning of an innovation project, ‘discovery-style’ exploratory research ensures that products and features genuinely meet user needs.

**At the end of this module, participants will understand (concepts):**

- The differences between qualitative and quantitative research in terms of their underlying goals and purpose
- How User Needfinding helps businesses to develop and innovate more successfully
- How observation, participation, and interviews complement each other
- Know the differences as well as the advantages and disadvantages of interviews and surveys as research methods

**At the end of this module, participants can apply methods to:**

- Identify and map out assumptions related to a project challenge
- Identify the stakeholders of a challenge
- Apply the rules and best practices to creating the right interview setting, establishing rapport with interviewees, and behaving during an interview

**At the end of this module, participants will have acquired abilities to:**

- Formulate an overarching research question
- Determine extreme-users, non-users, workarounds, misuses, and analog situations in a real-life scenario
- Build an interview guideline for a real-life challenge
- Synthesize learnings from qualitative data
- Identify patterns and clusters in the users’ needs and behavior
- Generate insights from qualitative data
- Reflect on past interviews and improve their interviewing abilities
- Evaluate in which work-related challenges User Needfinding is useful

<hr />

## Module 2: <br /> Problem-Solving and Idea Evaluation

Organizational innovation is not only about generating creative business ideas. It is also about reviewing ideas to identify those which are most likely to become successful innovations. It can sometimes be challenging to step back and reflect in an environment that is fast-paced or requires assimilating large amounts of information. This module will equip enablers with a selection of behaviors and techniques to augment their innate creativity, communicate engaging new ideas, approach problems from fresh angles, and produce novel solutions.

**At the end of this module, participants will understand (concepts):**

- Describe the added value of creativity in the context of a company
- Understand the concept of convergent and divergent thinking
- Outline the essential aspects and best practices to creative thinking and brainstorm sessions

**At the end of this module, participants can apply methods to:**

- Demonstrate the difference between divergent and convergent thinking
- Apply frameworks to categorize data collected during user research
- Set the stage to enable creative thinking
- Cluster and select ideas
- Create a shared understanding of the selected ideas and apply feedback frameworks to refine them

**At the end of this module, participants will have acquired abilities to:**

- Develop personas based on the common attributes identified during the data analysis
- Generate a customer journey
- Define Problems statements that inspire and set boundaries for the rest of the development process
- Build the right and open mindset for brainstorming and ideation
- Choose the right brainstorming method when facilitating and ideation session
- Select the ‘right’ ideas for a set-based concurrent engineering

<hr />

## Module 3: <br /> Prototyping, Testing & Experimentation

Prototyping brings concepts to execution without wasting time, money, and energy in building products and services that no one wants. It is a time-tested method for generating innovative solutions by keeping humans at the center of the design process and gathering feedback early and often. Making one’s ideas tangible, even before these appear ready to be shared, is the fastest and most reliable way to gather feedback early on about what works and what doesn’t.

**At the end of this module, participants will understand (concepts):**

- How prototypes help to learn more about ideas and how to test assumptions
- How early scrappy prototypes can help to test and to select among many possible product variations
- Why emotions in prototypes are important

**At the end of this module, participants can apply methods to:**

- Apply different methods on prototyping physical and digital products and services
- Engage users and their team in experimentation with a prototype
- Design prototypes to reveal an emotional response
- Communicate ideas in a compelling way
- Prototype Business models of their ideas

**At the end of this module, participants will have acquired abilities to:**

- Clearly define and test a hypothesis as well as develop optimal metrics and success criteria
- Select and apply different prototyping methods to validate or invalidate the hypothesis
- Evaluate the evidence collected to define the next steps
- Be comfortable with putting unfinished work in front of others

<hr />

## Module 4: <br /> Team Dynamics and Leadership

Innovation is an emergent group phenomenon, meaning it arises from the communication dynamics of the entire team. The atmosphere internal innovation enabler create should reward creativity and provide constructive feedback as it will make team members feel comfortable taking chances. When difficult problems arise, a supportive climate can elicit outside-the-box thinking that leads to novel solutions. The key to creating an innovative atmosphere is to ensure the team remains positive and supportive.

**At the end of this module, participants will understand (concepts):**

- Teamwork as an emergent property of a team that can be developed and improved
- The differences between innovation teams and other business groups
- The value of positive communication in a team
- The meaning of trust in different cultures, contexts and for different people

**At the end of this module, participants can apply methods to:**

- Assess team performance in different dimensions
- Set up routines for constructive feedback and communication
- Break down relationships into different objectives and preconditions for trust-building
- Analyze different functions of leadership

**At the end of this module, participants will have acquired abilities to:**

- Judge strengths and weaknesses in their own team
- Identify and evaluate explicit and implicit dimensions of diversity
- Identify conflicts in terms of content, phases, or dimensions
- Develop strategies for constructive solving of conflicts
- Evaluate what type of trust is suitable for different kinds of relationships
- Develop strategies for increasing accountability and control in a team
- Evaluate what type of role division and what type of leadership is suitable in different situations
- Develop strategies for more reflective decision making in a team
- Develop KPIs suitable for different team purposes

`,
    accordions: [],
    narrativeStyleNames: ["arrow-h3", "arrow-list-after"],
  },
];
