import { atom } from "jotai";

export const sideMenuIsShowingAtom = atom<boolean>(false);
export const shouldShowLoaderAtom = atom<boolean>(false);

export const errorToastMessageAtom = atom<string>("");
export const showErrorToastAtom = atom(null, (_, set, msg: string) => {
  set(errorToastMessageAtom, msg);
  setTimeout(() => set(errorToastMessageAtom, ""), 3000);
});

export const methodCardModalVisibleAtom = atom<string>("");
