import * as React from "react";
import {
  AlertDialogLabel,
  AlertDialogDescription,
  AlertDialogOverlay,
  AlertDialogContent,
} from "@sparkademy/app-common/elements/Alert";
import { Box, Text } from "theme-ui";
import { Button } from "@sparkademy/app-common/elements/Button";
import { ReactComponent as IllustrationConfetti } from "@sparkademy/app-common/materials/illustrations/confetti.svg";

export const SubmitDoneDialog: React.FC<{ onClose: () => void }> = ({ onClose }) => {
  const cancelRef = React.useRef<HTMLButtonElement>();

  return (
    <AlertDialogOverlay
      id="SubmitDoneDialog"
      // @ts-ignore
      leastDestructiveRef={cancelRef}
    >
      <AlertDialogContent>
        <Box sx={{ maxWidth: "600px" }}>
          <AlertDialogLabel></AlertDialogLabel>
          <AlertDialogDescription>
            <Box
              sx={{
                margin: "auto",
                width: 170,
                height: 170,
                svg: {
                  width: "100%",
                  height: "auto",
                },
              }}
            >
              <IllustrationConfetti />
            </Box>
            <Text
              as="p"
              sx={{
                textAlign: "center",
                fontWeight: 700,
                fontSize: "25px",
                mb: 10,
                mt: 5,
              }}
            >
              Your exercise has been submitted.
            </Text>
            <Text as="p" sx={{ textAlign: "center", fontWeight: 700, fontSize: "25px" }}>
              We will provide you with feedback in a few days, make sure to check back in soon.
            </Text>
          </AlertDialogDescription>
        </Box>
        <Box sx={{ mt: 11 }} />
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Button type="submit" onClick={() => onClose()}>
            OK
          </Button>
        </Box>
      </AlertDialogContent>
    </AlertDialogOverlay>
  );
};
