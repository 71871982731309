import * as React from "react";
import { Box, Text, Link } from "theme-ui";
import { ExerciseTeam } from "@sparkademy/app-common/models/assignment";
import { TeamDisplayBox } from "@sparkademy/app-common/components/assignment/TeamDisplayBox";
export const TeamFormationPanel: React.FC<{
  team: ExerciseTeam;
  currentUserIsLeader: boolean;
  wasSubmitted: boolean;
}> = ({ team, currentUserIsLeader, wasSubmitted }) => {
  const leader = team.members.find(m => m.is_leader);
  const others = team.members.filter(m => !m.is_leader);

  return (
    <Box
      sx={{
        maxWidth: "728px",
        borderRadius: "5px",
        p: "32px",
        bg: "new.secondary.lightGrey",
      }}
    >
      <Text as="p" sx={{ fontWeight: 700, fontSize: "16px" }}>
        {wasSubmitted
          ? "Your assignment has been submitted."
          : `Your team is selected and ${
              currentUserIsLeader ? "you" : "your Team Leader"
            } will be in charge of submitting at the end.`}
      </Text>

      <Box
        sx={{
          py: "24px",
          my: "24px",
          borderTop: "1px solid #BFBFBF",
          borderBottom: "1px solid #BFBFBF",
        }}
      >
        {!wasSubmitted && (
          <Text as="p" sx={{ fontSize: "16px", mb: "24px" }}>
            Each member can edit and save the content, which will overwrite previously saved work.
            Please ensure all members are in agreement when submitting as this cannot be undone.
          </Text>
        )}

        <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
          <TeamDisplayBox
            label="Team Leader"
            text={`${leader?.first_name} ${leader?.last_name} ${
              currentUserIsLeader ? "(You)" : ""
            }`}
          />
          <Box sx={{ mt: "16px" }} />
          <TeamDisplayBox
            label="Team Members"
            text={
              others.length > 0
                ? others.map(m => `${m.first_name} ${m.last_name}`).join(", ")
                : "[None]"
            }
          />
        </Box>
      </Box>

      {wasSubmitted ? (
        <Text as="p" sx={{ fontSize: "16px" }}>
          Problems or mistakes? &nbsp;
          <Link href="mailto:team@sparkademy.com" sx={{ textDecoration: "none" }}>
            Contact us
          </Link>
          &nbsp; to make any changes to your team or submission.
        </Text>
      ) : (
        <Text as="p" sx={{ fontSize: "16px" }}>
          <Link href="mailto:team@sparkademy.com" sx={{ textDecoration: "none" }}>
            Contact us
          </Link>
          &nbsp; for any team changes or in the case that{" "}
          {currentUserIsLeader ? "you're" : "your Team Leader is"} unable to submit.
        </Text>
      )}
    </Box>
  );
};
