import * as React from "react";
import VisuallyHidden from "@reach/visually-hidden";
import { Box, Button as BaseButton, Heading, Text } from "theme-ui";
import { DialogOverlay, DialogContent } from "@sparkademy/app-common/elements/Dialog";
import { ReactComponent as Close } from "@sparkademy/app-common/materials/icons/close.svg";
import { ReactComponent as Download } from "@sparkademy/app-common/materials/icons/download.svg";
import { ReactComponent as Check } from "@sparkademy/app-common/materials/icons/new/check.svg";
import { Module } from "@sparkademy/app-common/models/module";
import {
  AssignmentType,
  UploadedFile,
  AssignmentTypeLabelMapping,
} from "@sparkademy/app-common/models/assignment";
import { Narrative } from "@sparkademy/app-common/elements/Narrative";
import { FilesUpload } from "@sparkademy/app-common/components/assignment/FilesUpload";
import { UploadedFileItem } from "@sparkademy/app-common/components/assignment/UploadedFileItem";
import { listAssignmentUploads } from "../services/http-api-service";
import { useSessionContext } from "@sparkademy/app-common/contexts/session-context";
import { ButtonGhostIconAnchor } from "@sparkademy/app-common/elements/ButtonGhostIcon";
import { Button } from "@sparkademy/app-common/elements/Button";
import { dateFormatter } from "@sparkademy/app-common/utils/date-formatters";

export const UploadAssignmentsModal: React.FC<{
  module: Module;
  isOpen: boolean;
  assignmentType: AssignmentType;
  onClose: () => void;
}> = ({ module, isOpen, assignmentType, onClose }) => {
  const [uploadedFiles, setUploadedFiles] = React.useState<UploadedFile[]>([]);
  const isSubmitted = module.homework.status === "pending-evaluation";
  const { currentUser } = useSessionContext();

  React.useEffect(() => {
    listAssignmentUploads(module.id, assignmentType, currentUser!).then(
      res => res.files.length > 0 && setUploadedFiles(res.files)
    );
  }, [assignmentType, currentUser, module.id]);

  return (
    <DialogOverlay id="upload-assignments-modal" isOpen={isOpen} onDismiss={onClose}>
      <DialogContent
        sx={{
          margin: 0,
          display: "flex",
          flexDirection: "column",
          overflow: "hidden",
          outline: "none",
          mx: [4, 4, 0],
          bg: "white",
          width: ["100%", "894px"],
          borderRadius: "10px",
        }}
      >
        <Box sx={{ bg: "primary", display: "flex", py: 5, px: 8 }}>
          <Heading
            as="h4"
            sx={{
              color: "white",
              fontSize: 2,
              fontWeight: "bold",
              alignSelf: "center",
            }}
          >
            {AssignmentTypeLabelMapping[assignmentType]}
          </Heading>
          <Box
            sx={{
              marginLeft: "auto",
            }}
          >
            <BaseButton
              sx={{
                all: "unset",
                fontFamily: "inherit",
                color: "white",
                cursor: "pointer",
                bg: "transparent",
                ":hover": {
                  color: "grays.light",
                  // @ts-ignore
                  WebkitTextFillColor: theme => theme.colors.grays.light,
                },
              }}
              onClick={onClose}
            >
              <VisuallyHidden>Close</VisuallyHidden>
              <Close width={32} height={32} aria-hidden="true" focusable="false" />
            </BaseButton>
          </Box>
        </Box>

        {isSubmitted === false && (
          <Box sx={{ p: 8 }}>
            <Text as="p" sx={{ fontSize: 0 }}>
              After downloading the workbook, complete the assignments as you progress through the
              module. When finished, upload your workbook and other files below by{" "}
              <b>{dateFormatter.format(module.homework.end)}</b>. You may edit the files below as
              many times as you need before the deadline.
            </Text>

            <Box
              sx={{
                py: 8,
                borderBottom: "1px solid",
                borderBottomColor: "new.secondary.grey",
              }}
            >
              <ButtonGhostIconAnchor
                icon={Download}
                iconPosition="right"
                sx={{ pl: 0 }}
                href={module.workbookUrl}
                target="_blank"
              >
                Download Workbook
              </ButtonGhostIconAnchor>
            </Box>

            <Box>
              <Narrative
                sx={{
                  p: { fontSize: 0 },
                  h4: { my: 8 },
                }}
              >
                <Heading
                  as="h4"
                  sx={{
                    fontSize: 2,
                    fontWeight: "bold",
                    alignSelf: "center",
                  }}
                >
                  Upload Your Files
                </Heading>
                <Text as="p">
                  Upload your files below. <b>You may only submit PDF, JPG, JPEG, or PNG</b>
                </Text>
                <Text as="p">
                  Once you are ready to submit, click on the submit button. Make sure to double
                  check your files before you submit, as{" "}
                  <b>you cannot edit your files after you submit</b>.
                </Text>
              </Narrative>

              <Box sx={{ py: 8 }}>
                <FilesUpload disabled={true} accept=".jpg,.jpeg,.png,.pdf" />
              </Box>

              <Box
                sx={{
                  p: 4,
                  bg: "new.secondary.lightGrey",
                  borderRadius: "5px",
                  minHeight: "40px",
                }}
              >
                {uploadedFiles.length > 0 &&
                  uploadedFiles.map(uf => (
                    <UploadedFileItem key={uf.id} file={uf} onItemRemove={() => null} />
                  ))}
              </Box>

              <Box sx={{ mt: 8, display: "flex" }}>
                {!isSubmitted && uploadedFiles.length > 0 && (
                  <Box sx={{ fontSize: 1, display: "flex", alignItems: "center" }}>
                    <Text sx={{ mr: 4 }}>Ready to submit</Text>
                  </Box>
                )}

                <Button sx={{ ml: "auto" }} disabled>
                  Submit
                </Button>
              </Box>
            </Box>
          </Box>
        )}

        {isSubmitted && (
          <Box sx={{ p: 8 }}>
            <Narrative
              sx={{
                p: { fontSize: 0 },
                h4: { mb: 8 },
                mb: 8,
              }}
            >
              <Heading
                as="h4"
                sx={{
                  fontSize: 2,
                  fontWeight: "bold",
                  alignSelf: "center",
                }}
              >
                Your Submission
              </Heading>
              <Text as="p">Congratulations! You have completed the Practical Assignment.</Text>
              <Text as="p">You may come back here and access your files anytime.</Text>
            </Narrative>

            <Box>
              <Box
                sx={{
                  p: 4,
                  bg: "new.secondary.lightGrey",
                  borderRadius: "5px",
                  minHeight: "40px",
                }}
              >
                {uploadedFiles.length > 0 &&
                  uploadedFiles.map(uf => <UploadedFileItem key={uf.id} file={uf} />)}
              </Box>

              <Box sx={{ mt: 8, display: "flex" }}>
                <Box
                  sx={{
                    fontSize: 1,
                    display: "flex",
                    alignItems: "center",
                    marginLeft: "auto",
                  }}
                >
                  <Text sx={{ color: "brand.green", mr: 4 }}>Submitted</Text>
                  <Check />
                </Box>
              </Box>
            </Box>
          </Box>
        )}
      </DialogContent>
    </DialogOverlay>
  );
};
