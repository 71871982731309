// eslint-disable-next-line
import * as React from "react";
// @ts-ignore
import { mdx } from "mdx.macro";
import { Tool } from "../../models/tool";

// Businness Modeling assets
import businessModelInnovationFrontImage from "../method-kit/abm/front/business-model-innovation.svg";
import businessModelCanvasFrontImage from "../method-kit/abm/front/business-model-canvas.svg";
import businessModelFrameworksFrontImage from "../method-kit/abm/front/business-model-frameworks.svg";
import businessModelHealthCheckFrontImage from "../method-kit/abm/front/business-model-health-check.svg";
import businessModelNavigatorFrontImage from "../method-kit/abm/front/business-model-navigator.svg";
import businessModelZooFrontImage from "../method-kit/abm/front/business-model-zoo-tm.svg";
import leanStartupForCorporatesFrontImage from "../method-kit/abm/front/lean-startup-for-corporates.svg";
import managing2plusBmsFrontImage from "../method-kit/abm/front/managing-2plus-bms.svg";
import managingPlatformBmsFrontImage from "../method-kit/abm/front/managing-platform-bms.svg";
import overcomeCorporateChallengesFrontImage from "../method-kit/abm/front/overcome-corporate-challenges.svg";

import businessModelCanvasBackImage from "../method-kit/abm/back/business-model-canvas.svg";
import businessModelFrameworksBackImage from "../method-kit/abm/back/business-model-frameworks.svg";
import businessModelHealthCheckBackImage from "../method-kit/abm/back/business-model-health-check.svg";
// eslint-disable-next-line
import businessModelInnovationBackImage from "../method-kit/abm/back/business-model-innovation.svg";
import businessModelNavigatorBackImage from "../method-kit/abm/back/business-model-navigator.svg";
// eslint-disable-next-line
import businessModelZoo1BackImage from "../method-kit/abm/back/business-model-zoo-1.svg";
// eslint-disable-next-line
import businessModelZoo2BackImage from "../method-kit/abm/back/business-model-zoo-2.svg";
// eslint-disable-next-line
import businessModelZoo3BackImage from "../method-kit/abm/back/business-model-zoo-3.svg";
// eslint-disable-next-line
import businessModelZoo4BackImage from "../method-kit/abm/back/business-model-zoo-4.svg";
import leanStartupForCorporatesBackImage from "../method-kit/abm/back/lean-startup-for-corporates.svg";
import managing2plusBmsBackImage from "../method-kit/abm/back/managing-2plus-bms.svg";
import managingPlatformBmsBackImage from "../method-kit/abm/back/managing-platform-bms.svg";
import overcomeCorporateChallengesBackImage from "../method-kit/abm/back/overcome-corporate-challenges.svg";

// @ts-ignore
import businessHealthCheckTemplate from "../method-kit/abm/business-health-check.pdf";
// @ts-ignore
import businessModelCanvasTemplate from "../method-kit/abm/business-model-canvas.pdf";
// @ts-ignore
import businessModelNavigatorTemplate from "../method-kit/abm/business-model-navigator.pdf";
// @ts-ignore
import businessModelZooTemplate from "../method-kit/abm/business-model-zoo.pdf";

export const tools: Tool[] = [
  {
    moduleId: "a_businessmodel",
    name: "Business Model Innovation",
    slug: "abm-business-model-innovation",
    resources: [],
    content: mdx`
### Why is Business Model Innovation important?

Business models are the underlying secret to success for many innovative companies. Practicing business model innovation allows new and established businesses to act before competitors in a certain industry and:

- Take advantage of new technologies
- Adjust to shifting markets
- Respond to changes in customer’s needs and expectation

### What is Business Model Innovation?

A business model is:

1. "A conceptual tool that expresses the business logic of a specific firm".
2. "The method by which a firm organizes and captures value."

**Business Model Design**: This is the very first instance of a business model that usually happens in a startup. Your business model design is the first time you are deciding what to do inside your organization.

**Business Model Reconfiguration**: Reconfiguration refers to any firm’s second or third or fourth or tenth business model. You are taking your current business model, and you are adding something or modifying it in some way or another.

**Business Model Innovation**: This would be the first business model of its kind in its industry. Innovation can happen with Business Model Design or Reconfiguration.

<img src={businessModelInnovationBackImage} alt="Image" />

### How can you apply this?

Business model innovation can happen in companies at any stage of their development; however, structures need to be put in place to foster business model innovation.

##### 0. Self-Assess

Assess your organization and your goals. Are you looking to create a new business model to capture value from a product or service you have developed? Are you an established company looking to articulate your business model for the first time? Are you hoping to change your existing business model to stay ahead of new trends and technologies?

##### 1. Choose An Appropriate Framework

Consider different Business Model Frameworks:

1. **Business Model Zoo™** provides the highest level of insight to categorize your business model into one of four major categories.
2. **Business Model Navigator** can provide more detailed inspiration for a business model.
3. **Business Model Canvas** is the most detailed toolkit to determine the elements of your Business Model and communicate it.

See the [Business Model Frameworks](/method-kit/abm-business-model-framework) Method Card to learn how to choose a framework appropriate for your business.

##### 2. Articulate Your Business Model

1. Using the Business Model Canvas or the Lean Canvas, write down all the elements of your business model that you have designed or changed from an existing one.
2. Put your business model into a format through which you can communicate it to your team and stakeholders. This could be in the form of a poster, pitch deck, report, or even more creative formats.

##### 3. Test Your Business Model

1. Before you continue, start experimenting more to reduce risk. Develop prototypes and run tests that help you to verify that your Business Model will be accepted and hopefully profitable. Look at each of the components of the Business Model Canvas. Which assumptions did you make?
2. Out of those underlying assumptions, extract the most relevant one and develop a testing procedure or prototype to verify it, just as you learned in Module 3.

##### 4. Implement Your Business Model

1. After testing your business model, reflect on what you learned. Do you need to test something else? Do you need to go back and change something in the business model you designed? Or are you ready to implement or scale up because the uncertainty and, therefore, the risk of failing is low or acceptable?
2. Go back and have another look at your minimal viable product. This MVP is the minimum set of features in a product that will compel a customer to buy the product.
3. Implementing a business model successfully is not a linear path but requires iterations and changes over and over again. Be ready to go back and make changes multiple times.

### What’s Up Next?

Dive deeper into choosing a framework and explore your options in the [Business Model Frameworks](/method-kit/abm-business-model-framework) Method Card.
    `,
    benefits: [],
    thumbImage: businessModelInnovationFrontImage,
  },
  {
    moduleId: "a_businessmodel",
    name: "Business Model Framework",
    slug: "abm-business-model-framework",
    resources: ["paper, pens and markers"],
    content: mdx`
### Why should you use this Business Model Framework?

- Gives insight into what you can do inside your organization to tackle a specific problem
- Reduces complexity and improves communication when developing a business model
- Provide scaffolding to direct innovation practices
- Give inspiration for where to begin and a process to follow through to get to a solution for your business
- Both startups and established companies can take advantage of frameworks to foster business model innovation

### What is a framework?

A Framework is a set of assumptions, concepts, values and practices that constitutes a way of view in reality. In innovation, frameworks can be a tool to provide further insight into a problem and help reach a solution.

### How can you apply Business Model Frameworks?

This is an overview of how you can explore and use some of the business model frameworks discussed in this module.

##### 1. Learn about your options.

1. Refer to the Module 5 methods cards on the [Business Model Zoo™](/method-kit/abm-business-model-zoo), [Business Model Navigator](/method-kit/abm-business-model-navigator), and the [Business Model Canvas](/method-kit/abm-business-model-canvas) frameworks to understand how each framework can be used or refer to the descriptions below.
    - **The Business Model Canvas** is useful for visualizing all elements of an existing or potential business model. It can be effective for startups looking to develop their business model for the first time. The Business Model Canvas can also be an effective way to articulate the business model of an established company and reach consensus with your team.
    - **The Business Model Zoo™** can provide you with high level insights on your existing business model. It can be helpful if you are a startup trying to learn more about how to develop a successful business model or an established company looking to better understand the potential strengths and weaknesses of your business model.
    - **The Business Model Navigator** can provide a wealth of inspiration as well as ideas to challenge your existing assumptions. This can be helpful for a startup beginning to learn about what types of business models they could use for their venture as well as an established company to introduce new business model ideas that can spur innovation.
2. Learn more in depth about business model frameworks by reading books like Business Model Generation and Business Model Innovation Strategy.

##### 2. Define your starting point and goals.

1. Evaluate your starting point by performing a [Business Health Check](/method-kit/abm-business-model-health-check) to understand what stage of business model development you are in and what aspects of your business model need improvement.
2. Use the results of a Business Health Check to define with your team what your goal is at the moment.

##### 3. Choose a framework.

1. Business Model Canvas 
2. Business Model Zoo™ 
3. Business Model Navigator

##### 4.Understand how to use the framework.

Consider how you will use these frameworks in your Business Model Innovation process.

### What’s Up Next?

Perform a [Business Model Health Check](/method-kit/abm-business-model-health-check) or dive into one of these frameworks.
`,
    benefits: [],
    backImage: businessModelFrameworksBackImage,
    thumbImage: businessModelFrameworksFrontImage,
  },
  {
    moduleId: "a_businessmodel",
    name: "Business Model Zoo™",
    slug: "abm-business-model-zoo",
    resources: [
      "Business Model Zoo Website",
      "Business Model Zoo Worksheet (printed or editable version)",
    ],
    content: mdx`
### Why should you use the Business Model Zoo™?

- Helps you understand the four elemental “ideal types” of business models
- Inspires with hundreds of examples of real business models
- Can improve your ability to recognize the differences in business models and thus design a business model that best fits your company’s unique needs
- Build a better understanding of your competitors and the dynamics across business models in your industry

### What are the four categories in the Business Model Zoo™?

[The product model](https://www.businessmodelzoo.com/business-models/product-model): The company develops a product or standardized service and sells it to customers. The value proposition is transactional: to provide a product or standardized service that customers will buy.

<img src={businessModelZoo1BackImage} alt="Image" />

[The solutions model](https://www.businessmodelzoo.com/business-models/solutions-model): The company engages with a customer about a problem the customer faces, and provides an integrated solution. The value proposition is relational: to tailor solutions to each customer.

<img src={businessModelZoo2BackImage} alt="Image" />

[The matchmaking model](https://www.businessmodelzoo.com/business-models/matchmaking-model): The company joins buyers and sellers in its online or physical marketplace. The value proposition is transactional: to facilitate exchange.

<img src={businessModelZoo3BackImage} alt="Image" />

[The multisided model](https://www.businessmodelzoo.com/business-models/multi-sided-model): The company provides different products or services to different customer groups. The value proposition is multi-sided: one customer group gets additional benefits from the other group’s transactions.

<img src={businessModelZoo4BackImage} alt="Image" />

### How to use the Business Model Zoo™?

Print out the Business Model Zoo™ worksheet. The worksheet will walk you through the following questions and give space for you to write down your ideas and brainstorm with your team.

##### 1. Define Your business model category.

Complete the short assessment on the [Business Model Zoo™ website](https://businessmodelzoo.fabrikstaging.com) to understand what category your business model falls into. Having this information will enable you to analyze your business model in new ways.

##### 2. Evaluate your business model.

1. First, read the description of your given business model. Does the description of your business model category make sense for your business?
     - No: you might have a hybrid business model that combines elements of multiple categories. Explore the webpages for the other categories to understand which other ones fit.
2. If you’ve identified a category that fits your business, take notes on the requirements for success. Answer for yourself: “is your business already doing this?” 
     - No: write down ideas for how your business can satisfy these requirements.
3. Next, take notes on opportunities and rewards in your category. Answer for yourself: “is your business taking advantage of these?” 
     - No: write down ideas for how you can.
4.  Takes notes on the risks and threats. Answer for yourself: “is your business mitigating these?” 
     - No: write down some ideas for how to address these potential roadblocks.

##### 3. Get inspired by other businesses.

1. Explore the Exemplars for your business model category.
2. Write down any that are similar to your business or that you would like to model your business after.
3. Write down if there are any key differences or elements of the example that stick out to you.
4. Next explore the Exemplars for your industry.
5. Write down the most common category represented in your industry.
6. Write down the less common categories.

### What’s up next?

Now that you have your business model category defined, you can use another framework like the Business Model Canvas to identify the elements of your business model.
`,
    benefits: [],
    thumbImage: businessModelZooFrontImage,
    template: businessModelZooTemplate,
  },
  {
    moduleId: "a_businessmodel",
    name: "Business Model Navigator",
    slug: "abm-business-model-navigator",
    resources: [
      "Business Model Canvas / pitch / poster",
      "Business Model Navigator Website",
      "55 notecards or with the names of business models",
      "poster-size sheets of paper",
    ],
    content: mdx`
### Why should you use this Business Model Navigator?

- Can be used understand the drivers of business model success and how they can be applied to your business model
- Examples can serve as inspiration in your own business model innovation process
- By looking to these patterns, you can find a business model from a different industry or company that you can apply to your own business in an innovative way
- Helps you understand your competitors and the leading innovators in your industry.

### What is the Business Model Navigator?

The Business Model Navigator is a typology of 55 business models derived by researchers Oliver Gassmann and Karolin Frankenberger from four key elements: the customer, the customer value proposition, the main business activities, and the financial model. The Business Model Navigator includes descriptions of 55 types of business models as well as case studies of successful companies who have utilized the model.

You can find the descriptions of all 55 business models on [the business model navigator website](https://businessmodelnavigator.com).

### How to use the Business Model Navigator?

The Business Model Navigator can be used as part of a business model innovation process. This is a team exercise that helps you explore and incorporate the Business Model Navigator in designing or reconfiguring your business model can be in a collaborative workshop with your team.

##### 1. Articulate Your Current Business Model Idea

If you have not already, articulate your business model to your team. On a large piece of paper, projector, or central screen, write down the current customer base, value proposition, and method for capturing value. If you have already filled in a Business Model Canvas, this model could also be used.

##### 2. Discuss Failure Modes with Your Team

1.  Talk with your team about the existing business model.
2.  Brainstorm with your team why it may fail and write down these reasons below the articulated business model. Talking about reasons your business model could fail helps you overcome mental blocks and see your business model from an objective standpoint.

##### 3. Business Model Research

1.  Break your team into pairs or trios and give them each a large piece of paper or section of a virtual white board.
2.  Split the 55 business model patterns evenly between the number of subgroups you have given them their models on notecards or sticky notes.
3.  Tell each team to spend 10 minutes investigating the patterns they were assigned on the Business Model Navigator website and choose at least three they would like to focus on.

##### 4. Ideation Breakouts

In a 30-minute breakout, tell each team to discuss and take notes on how each business model they chose could be applied to your business or a particular problem.

##### 5. Sharing and Takeaways

Each team returns and shares their favorite ideas with the larger group. One person documents each group’s work and takes note of the favorite ideas.

### What’s up next?

Having used the Business Model Navigator to generate a range of new solutions for your business model, you can begin or re-do a Business Model Canvas or identify aspects of your business model to prototype and test.
`,
    benefits: [],
    backImage: businessModelNavigatorBackImage,
    thumbImage: businessModelNavigatorFrontImage,
    template: businessModelNavigatorTemplate,
  },
  {
    moduleId: "a_businessmodel",
    name: "Business Model Canvas",
    slug: "abm-business-model-canvas",
    resources: [
      "Business Model Template",
      "print out in A1/24 x 33 inches or online version",
      "Post-its",
      "markers",
    ],
    content: mdx`
### Why should you use the Business Model Canvas?

Each business model consists of different components that need to come together to make the business work successfully. By describing the components in the Business Model Canvas, you can visualize the whole business model and are able to analyze and communicate it.

- A way to lay out the design of a new business model
- A way to clarify and communicate an existing business model
- Builds an understanding of how different parts of your business model are related
- Reveals elements in need of change or further development

<a href="https://sparkademy.directus.app/assets/f70e40b0-4aa2-4b5c-9d67-243a39c6e5cd" target="_blank">Check out our filled out template for AirBnB</a>

### What are the elements of the Business Model Canvas?

The value proposition and customers are the most essential component because if you don't have a value proposition for paying customers and/or end-users, no one will pay for your product.

**Value Proposition**: What value your business offer customers? There are different kinds of value propositions your business can have:

- Functional value
- Problem solving
- Time saving

**Customer Segments**: Who are the customers you are trying to serve and what are their characteristics? How is your customer base segmented? For instance, you might describe one of these segments:

- Mass Market
- Niche Market
- Segmented
- Diversified
- Multi-sided Market

These are the ways that you interact with your customers and delivery value to them.

**Delivery Channels**: What are the methods you use to reach your customers? Do you need a sales force? Do you need a social media presence? Do you need a website? Do you need a physical retail outlet?

**Relationships**: How do you interact or connect with the customer?
There are several types of relationships you can have with your customers:

- Personal assistance
- Self-service
- Communities
- Co-creation

These are the main operations and assets that make your value proposition a reality and make your business model successful.

**Key Resources**: What are your special connections, capabilities, skills, intellectual or physical assets like patents or manufacturing facilities?

**Key partners**: With whom do you need to work with to deliver value to your customers? Look around in your ecosystem and understand who your suppliers, your complementors, and other ecosystem participants are. What value do they bring to your business model? And what kinds of partnerships do you have with these people?

**Key Activities**: What do you need to do to create, deliver, and capture value? Do you need to design and produce products to create value? if you're a consulting company or a health care company, you may be more Problem-Solving oriented, and finding the solutions is the value-adding activity. If you're an intermediary, you may need to expand your network and manage risk.

The financial structure is how you determine how the money comes in and goes out of your company.

**Financial Structure**: What are your revenue streams and projected costs? What are the costs of creating and delivering value to your customers? What are the costs of maintain relationships with you customers? There are many different ways to generate revenue from one-time transactions to subscriptions or licensing.

### How can you apply this method?

Startups and established companies might approach filling out a business model canvas with different goals, but they can both follow a similar process. The following steps are an example of how you can use the business model canvas to formulate your business structure. Each step should take 10 minutes in order to complete the canvas within 1 hour and 10 minutes.

##### 0. Prepare for the workshop

Invite the team involved in the business creation. A group can provide much more insights and ideas than an individual. Make sure you print the canvas large enough or prepare an online template that participants can work on.

##### 1. Name Your Purpose

1. Spend the first 10 minutes with your team talking about your overarching purpose or mission.
2. Put the purpose statement at the top of the business model canvas or on the wall where it is visible to everyone which can help inform other elements of the business model, and most importantly, help you evaluate whether or not your business is meeting your team’s goals throughout the workshop.

##### 2. Identify Your Customer

1. On one sticky note each, write down your customers or desired customers and list their characteristics, needs, and goals. You can do this by creating Customer Personas synthesized from your Needfinding process.
2. Group these sticky notes into customer segments and add a segment type sticky note to label each group.

##### 3. Connect Your Value Proposition

1. To ensure that the customers and value proposition connect, start by writing a sticky note for each customer segment to indicate how the customer’s needs are currently being addressed (or how they’re not).
2. Then write your value proposition on another sticky note that should address what is unaddressed in current solutions or improve them.

##### 4. Name Your Delivery Channels and Customer Relationships

1. On sticky notes, write down the channels through which you will reach your customer base to get the word out or deliver your service to them.
2. Next, write down the relationship(s) your customers will have with your company. Below each relationship, list the ways in which the customer will interact with your company.
3. If you are stuck on these points, refer to the Business Model Zoo™ or Business Model Navigator to understand what the relationship is between your business and its customers.

##### 5. Identify Key Resources, Activities, and Partners

1. On sticky notes, list the key resources needed to execute your business plan.
2. Group your resources by what you currently have and what you will need for your business to work.
3. Next, list the key activities you must perform to create, deliver, and capture value.
4. Finally, list who you need to work with to create and deliver your value.

##### 6. Cost Structures and Revenue Streams

1. On sticky notes, write down all of the potential costs for your business to run key activities and deliver value to customers.
2. Next, list all the possible revenue streams. Consider which makes the most sense for your company in delivering your value proposition.

##### 7. Link the Boxes

Elements of the Business Model Canvas may be interdependent, and all of the elements should connect back to your purpose, value proposition, and customers.

1. Identify all of the stakeholders included in your Business Model Canvas, and list them off to the side of your canvas. Going down the list, write a note indicating if they give and/or receive value.
2. Flag stakeholders that only TAKE value. This could be a parasite in your business model. Discuss ways that they could give value back, and if not, consider eliminating them from the business model.
3. Flag stakeholders that only RECEIVE value. These stakeholders might end of drained and flee. Discuss ways that the business could offer them value in return.
4. Finally, go through each of the boxes on the canvas, and ask your team whether the elements you’ve written on sticky notes contribute to the value proposition and/or purpose. If not, move the sticky notes to an area below your canvas to be reconsidered or revised.

### What’s Up Next?

Now that you have a business model down on paper, it’s time to test and validate it.
`,
    benefits: [],
    backImage: businessModelCanvasBackImage,
    thumbImage: businessModelCanvasFrontImage,
    template: businessModelCanvasTemplate,
  },
  {
    moduleId: "a_businessmodel",
    name: "Lean Startup For Corporates",
    slug: "abm-lean-startup-for-corporates",
    resources: ["Customer insights from needfinding Business model canvas"],
    content: mdx`
### Why should validate your business model?

- Testing and validating your business model ensure your business is offering something that people actually want
- Using a build-measure-learn cycle decreases the uncertainty through testing and slowly increase investment over time
- This approach can be used to leverage innovation in large companies with some important caveats.

### What is the Lean Startup approach?

The Lean Startup approach is based on a build-measure-learn cycle where a business can prototype and test their business model cheaply and with limited resources. The approach includes three major steps: design, test, and implement.

While the Lean approach can be applied to many types of business, there are important [challenges you must address for established corporations](/method-kit/abm-overcome-corporate-challenges).

### How can validate your business model?

##### 1. Design the business model

1. Customer discovery: utilize the tools of human-centered design to discover your target customer’s needs and validate your assumptions.
2. Continue going back to your customers to test your prototypes to make sure they are developing in the right directing and you are catching issues early.
3. Design a minimum viable product (MVP).
4. Design your business model, decide how to capture value.

##### 2. Develop and run tests to verify your business model

1. Extract the most important assumptions for the business’s success from the busines model
2. Design and run testing procedures as cheaply as possible for each of these assumptions in your business model. This could include surveying customers about how much they would be willing to pay for your product, affordable marketing strategies, or getting quotes from manufacturers or developers.

##### 3. Learn and implement

1. Distill your insights in one document using quotes and visualizations. Reflect on the feedback you heard, did it validate your assumption or undermine it?
2. For assumptions that were undermined or still uncertain after getting feedback, go back to your business model prototype and try testing different solutions.
3. If you hit a wall or a problem too difficult, using other tools and frameworks like the Business Model Navigator or the Business Model Zoo™ could provide inspiration.
4. If the risk of uncertainty seems low, you can begin preparations for scaling your business. As a final step, you can go back to your MVP and refine it until it is ready to go to market.
`,
    benefits: [],
    backImage: leanStartupForCorporatesBackImage,
    thumbImage: leanStartupForCorporatesFrontImage,
  },
  {
    moduleId: "a_businessmodel",
    name: "Overcome Corporate Challenges",
    slug: "abm-overcome-corporate-challenges",
    resources: [],
    content: mdx`
### Why should you consider corporate challenges?

It’s a mistake to believe that big companies can behave like large startups. Unlike a startup that is designing their first business model, an established company has already designed and scaled one or multiple business models. This difference between searching for a new business model and executing an existing model has many implications for why large companies cannot — and should not — simply mimic the behavior of startups. The large company is focused on executing the business model which can make the process of business model innovation more difficult than in a smaller, less established company. As a result, business model reconfiguration is rarely successful unless steps are taken to address unique corporate challenges.

### What are corporate challenges?

There are several challenges for established companies in business model innovation:

**Incentive Structures**: managers have greater interest in ensuring an existing model succeeds rather than encouraging the development of a new innovative business model.

**Minimum viable product**: existing manufacturing and quality-controlled processes can make prototyping new products difficult.

**Customer discovery**: organizations with a dedicated sales team may regard this as dangerous both to the company's sales in the current quarter or year and to their own sales commissions.

**Procurement**: in a large company, the procurement function is measured on its ability to cut costs, improve delivery times, and manage key suppliers. This may be contracting the essence of quick and iterative learning, where new suppliers are needed and decision processes should be fast.

### How can you address corporate challenges?

##### 1. Create a dedicated innovation team

An innovation team should have insights into different parts of the company. Most importantly, the innovation team should have unique incentives that are not tied to an established business model. This will encourage your team to experiment and push forward new business models. There should also be unique communication channels for an innovation team so that they can prototype and run tests quickly without needing to go to the top of the company for approval.

##### 2. Treat the MVP as an artifact for learning

An MVP may not reach the same standards as established company quality-controlled processes require; however, it is an essential part of low-cost testing for a new business model. Brainstorm possible avenues for development outside of the typical manufacturing pipeline. These could include contracting to outside manufacturers or developing an MVP under a different company name.

##### 3. Explore low stakes avenues for testing and implementation

New business models cannot be verified without testing and implementing different elements of it. Brainstorm company resources that could be utilized for testing. You may need to work to resolve the conflict between the sales force who might feel threatened by new products being tested with possible or existing customers.

##### 4. Identify potential conflict areas between new and old business models

Before pursuing business model reconfiguration or adding another business model to your company, understand where possible conflict might arise between the existing business model and the new business model. Work proactively to address these conflicts to reduce risk for stakeholders.

### What’s Up Next?

Understand what unique challenges your organization may face by performing a [Business Health Check](/method-kit/abm-business-model-health-check). Learn about solutions for [Managing 2+ Business Models](/method-kit/abm-managing-dual-business-models) and Business Model Reconfiguration.
`,
    benefits: [],
    backImage: overcomeCorporateChallengesBackImage,
    thumbImage: overcomeCorporateChallengesFrontImage,
  },
  {
    moduleId: "a_businessmodel",
    name: "Business Model Health Check",
    slug: "abm-business-model-health-check",
    resources: [
      "Printed Business Model Canvas",
      "printed Business Health Check questionnaire",
      "pens or markers",
    ],
    content: mdx`
### Why should you conduct a business model health check?

A Business Health Check is essential to kickstarting and maintaining business model innovation in established companies. Performing a Health Check on your company will help you not only understand opportunities for growth, but also help you stay ahead of disruptions, and identify the right time to reconfigure your business model before growth declines.

### What is a Business Health Check?

A Business Health Check is a series of questions to ask of your company to understand where there are weaknesses and potential for improvement and innovation.

### How can you perform a Business Model Health Check?

Download and print copies of the Business Health Check Questionnaire for each member of your team. Together with your team, go through the questions and fill in your answers to understand where opportunities for improvement lie in your company.

##### 1. Communication:

1. Questions to answer:
     - Have you communicated internally about what your value proposition is?
     - Are your stakeholders and your team in agreement about the value proposition?
     - Have you completed a Business Model Canvas or Lean Canvas and shared it with your team?

2. Did you answer to any of the questions with 1, 2, or three (disagree to highly disagree)? Define actions you can take to come to a consensus on what your business model is.

##### 2. Inconsistencies:

Using the Business Model Canvas or Lean Canvas, evaluate whether or not the different elements of your model support or contradict one another.

1. Questions to answer:
     - Are your key activities lined up with your key resources?
     - Are the customer channels truly fitting the customers?
     - Do you capture all the value you create, which is fairly rare, or at least enough of it?
     - Are different elements of the business model reinforcing each other and are the value creation and capture aligned?

2. Define actions to work against inconsistencies you detected.
     - How could you change your business model? Or how could you as a team come up with alternatives?

##### 3. Industry maturity and competition:

1. Questions to answer:
     - Where are you in this cycle? If you wait for declining sales to take action, it is already too late.

2. Is your business in the maturity or decline phase? Be prepared and start with new business model research before your industry sector becomes too mature and saturated. Define action you and your team are going to take, in order to come up with the new business model.

##### 4. Technological discontinuities:

Monitor for potentially disruptive technologies and incorporate and adapt before new technologies take over.

1. Questions to answer
     - What technologies is your business models relying on?
     - What are technologies competing with them?
     - What emerging technologies are able to fulfill the same value proposition, even if not profitable yet?

2. Define your path forward. For each technology, decide whether you want to monitor them passively or integrate capabilities to adopt them. What are your next actions in each case?

### What’s up next?

A business model health check is never completely finished, but it should be a continuous topic for your team to return to. Schedule the actions you decided for and implement them into your task list. Repeat the health check regularly.
`,
    benefits: [],
    backImage: businessModelHealthCheckBackImage,
    thumbImage: businessModelHealthCheckFrontImage,
    template: businessHealthCheckTemplate,
  },
  {
    moduleId: "a_businessmodel",
    name: "Managing Platform BMs",
    slug: "abm-multi-sided-platforms",
    resources: ["Business Model Canvas", "market analysis", "A4 paper and pens"],
    content: mdx`
### Why should you consider strategies to manage a multi-sided platform?

The multi-sided platform business model has become increasingly popular and dominant in the digital economy. While there is potential for success with a multi-sided platform business model, there are several barriers that are difficult to overcome without a sound strategy.

### What is a Multi-Sided Platform Business Model?

In a multi-sided platform (MSP), you have two or more customer segments that both interact with the platform or platform owner. At the same time, direct or indirect interaction or transactions represent a flow of value between the different parties. MSPs rely on network effects on both sides of the platform that create unique conditions for success.

An advantage of multiple sides can be increased revenue streams and larger network effects, however there are tradeoff to consider as well. More sides require greater initial startup costs and ongoing management that may not be feasible for your organization taking into account your key resources and activities.

### What is a Network Effect?

To identify and map your team's assumptions, you can follow the following steps:

##### 1. Get started

Platform business models typically benefit from network effects. This means that the platform's attractiveness to a user depends on how many others are already on the platform, or, if you have multi-sided platforms, one side requires as many users as possible of the other side already being on the platform. E.g.: Uber is attractive to passengers only if there are enough drivers and vice versa.

But if both sides of a multi-sided platform require each other to be attractive, which one do you invest in first, and how do you bring them on board? Some options are:

- create stand-alone value for one side
- subsidize one or multiple sides

The solution depends on the specific situation, but it often involves temporarily losing money.

### How can you successfully adopt these strategies?

The following points are not a step by step process, but showcase different strategies and considerations to make your multisided business model thrive.

##### 1. Business Model Canvas: Define your sides

When filling out your Business Model or Lean Canvas, you must define several customer bases, one for each side to make your MSP successful. The Business Model should also include a value propositions, channels and relationships for each customer group.

##### 2. Market Analysis: Aim for economies of scale

Costs decrease as the number of users on each side of the platform increases, so it is essential to find markets and customer bases that can scale. In performing a market analysis, look for large addressable markets not just on one side but all sides of you MSP.

##### 3. Understand the chicken/egg proposition

The chicken/egg proposition means that no one will join one side without the other side in place. There are several key implications for a business model like increased costs of gaining access to multiple customer bases at once. This can mean increased costs for marketing, platform maintenance, and sales. Especially in launching a platform, choosing only two sides to start with and scale can help reduce start-up costs.

##### 4. Understand the important strategy questions

The basic idea behind a platform business model is that you set up and orchestrate different sides of your market. You are not just doing a service and selling it to one party, but you are trying to create a network effect where one side of the market reinforces the other side of the market.

The most difficult MSP design decisions are those that involve features putting the interests of different sides of the MSP at odds with each other or with those of the MSP. Such features create strategic trade-offs for the MSP because they generate positive value for some participant groups or for the MSP itself, but negative value for other participant groups.

##### 5. With Your Team: Anticipate market failures

With you team, brainstorm possible market failures that would prevent the ecosystem from functioning properly and how they can be mitigated. Some common failures are low-quality suppliers or sellers driving out high-quality ones or too much competition on one side of the platform reduces incentives to maintain and develop high quality services
For each market failure that you identified in your brainstorm, write down at least three strategies to mitigates risks for failure.

##### 6. Test and Implement

Using the methods of Business Model Innovation and the Lean methodology, prototype, test, and implement aspects of your business model for both sides of your platform before investing in them.

### What’s up next?

Understand what unique challenges your organization may face by performing a [Business Health Check](/method-kit/abm-business-model-health-check), take [Corporate Challenges](/method-kit/abm-overcome-corporate-challenges) into account and develop a strategy to [handle several business models at the same time](/method-kit/abm-managing-dual-business-models).
`,
    benefits: [],
    backImage: managingPlatformBmsBackImage,
    thumbImage: managingPlatformBmsFrontImage,
  },
  {
    moduleId: "a_businessmodel",
    name: "Managing 2+ BMs",
    slug: "abm-managing-dual-business-models",
    resources: [],
    content: mdx`
### Why should you consider strategies to manage multiple business models?

Managing two or more business models simultaneously can be important when you’re reconfiguring your business model and, instead of replacing the old business model, you decide to add another one and have two business models working in parallel.

### What are strategies for managing multiple business models?

The strategies presented here are based on Markides and Charitou: Competing with dual business models (2004).

**Separation strategy** can be used when there are major conflicts between business models. You spin your new business model off and incubate it outside your own company.

**Phased separation strategy** takes place when there are only minor conflicts, so the new business model can start in-house and spins off at some point to address a different market.

**Integration strategy** comes into play when you have low conflict and have high strategic relatedness. You may be serving a similar market, but maybe you have different delivery channels or other minor conflicts.

**Phased integration strategy** happens when there are major conflicts but you have higher strategic relatedness, so you can start with a spin-off that you then bring back in later once it is established.

### How can you successfully adopt these strategies?

##### Step 1: Determine the ideal strategy

Compare the ideal business models for each venture.

##### 1. How serious are the conflicts between the two businesses?

1. Questions to answer: How different are the value propositions? Are they targeting the same customer segments? Do they need the same resources? Will the incentive structures for stakeholders in each business model conflict?
2. If there are major conflict between the two business models, like narrow value propositions or incentives that conflict, meaning that the success of stakeholders in one business would hurt the success of the other, you should pursue a separation strategy or phased integration strategy.

##### 2. How strategically similar is the new market to the existing business?

1. Questions to answer: Can the resources and competencies of the existing business be translated to the new market? If I am to enter the new market space, can I do it with my existing business model or do I need a new business model? Do I look at the new customers as simply another segment that can be served with my existing business model or as a completely different market that requires its dedicated value-chain activities?
2. If there are many similarities between the markets or the competencies needed to enter a new market, consider an integration strategy to take advantage of the company’s existing resources. If there are also major conflicts between the business models, considered a phased integration strategy.

##### Step 2: Set the conditions to successfully implement the strategy

##### For the separation strategy:

1. Give operational and financial autonomy to their units but still maintain close watch over the strategy, and encourage cooperation between the unit and the patent through common incentives and reward systems.
2. Allow the unit to develop their own cultures and budgetary systems.
3. Allow the unit to have its own CEO who is transferred from inside the organization, rather than hiring an outsider.

##### For the integration strategy:

1. Treat the new business model as a wonderful new opportunity to grow the business, rather than see it as a threat.
2. Exploit the strength of the traditional business model to find ways to differentiate yourselves rather than imitating the strategies of your attackers.
3. Approach the task proactively instead of reacting to a problem.
4. Take extreme care not to suffocate the new business with the existing policies of the firm.

### What’s up next?

Understand what unique challenges your organization may face by performing a [Business Health Check](/method-kit/abm-business-model-health-check).
`,
    benefits: [],
    backImage: managing2plusBmsBackImage,
    thumbImage: managing2plusBmsFrontImage,
  },
];
