import * as React from "react";
import { Container } from "@sparkademy/app-common/elements/Container";
import { Box, Heading } from "theme-ui";
import { Narrative } from "@sparkademy/app-common/elements/Narrative";
import { ReactComponent as Confetti } from "@sparkademy/app-common/materials/illustrations/confetti.svg";
import { ButtonLinkProgress } from "@sparkademy/app-common/elements/Button";
import { ButtonOutlineLink } from "@sparkademy/app-common/elements/ButtonOutline";
import { ReactComponent as Check } from "@sparkademy/app-common/materials/icons/new/check.svg";
import { useParams } from "react-router-dom";
import { courseAtom } from "../stores/course";
import { LayoutSideMenu } from "../components/Layout";
import { CourseSideMenu } from "../components/Course/CourseSideMenu";
import { useAtom } from "jotai";

export const LessonBlockComplete: React.FC<{}> = () => {
  const { lessonId, blockId } = useParams<{
    lessonId: string;
    blockId: string;
  }>();
  const [course] = useAtom(courseAtom);
  const lesson = course?.lessons.find(l => l.id === lessonId);

  if (!course || !lesson) {
    return null;
  }

  const courseLink = "/course/" + course.id;

  const currentBlock = lesson.blocks.find(b => b.id === blockId);
  const blockIndex = lesson.blocks.findIndex(block => block.id === blockId);
  const isLastBlock = lesson.blocks.length - 1 === blockIndex;
  const nextBlock = lesson.blocks[blockIndex + 1];

  const nextLessonBlockLink = `/course/${course.id}/lesson/${lessonId}/block/${nextBlock.id}/unit/${nextBlock.units[0].id}`;

  return (
    <LayoutSideMenu
      sx={{ backgroundColor: "white" }}
      sideMenuOverride={
        <CourseSideMenu lesson={lesson} blockId={blockId!} unitId={nextBlock.units[0].id} />
      }
    >
      <Container
        sx={{
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          backgroundColor: "white",
        }}
      >
        <Box
          sx={{
            width: 170,
            height: 170,
            svg: {
              width: "100%",
              height: "auto",
            },
            mb: "21px",
            mt: ["25px", "50px"],
          }}
        >
          <Confetti />
        </Box>
        <Heading
          as="h1"
          sx={{
            fontSize: [2, 3],
            fontWeight: "bold",
            textAlign: "center",
          }}
        >
          Congratulations!
        </Heading>
        <Narrative sx={{ mt: "24px" }}>
          <p sx={{ fontSize: "20px", lineHeight: "28px" }}>
            You have finished <b>{currentBlock!.title}</b>.
            <br />
            {!isLastBlock ? (
              <>
                Next: <b>{nextBlock.title}</b> ({nextBlock.timeCommitment} min)
              </>
            ) : (
              <>This is the last block!</>
            )}
          </p>
        </Narrative>
        {!isLastBlock ? (
          <ButtonLinkProgress
            sx={{
              mt: ["32px", "57px"],
              mb: "16px",
              width: "348px",
              display: "flex",
              justifyContent: "center",
              backgroundColor: "#A593EF",
            }}
            to={nextLessonBlockLink}
            length={5}
          >
            <Box sx={{ zIndex: 1 }}>Next</Box>
          </ButtonLinkProgress>
        ) : null}
        <ButtonOutlineLink sx={{ width: "348px", mx: "auto", mb: "24px" }} to={courseLink}>
          Back to Course Dashboard
        </ButtonOutlineLink>

        <Box sx={{ display: "flex", color: "brand.green", fontSize: "20px", lineHeight: "28px" }}>
          Progress saved
          <Box sx={{ ml: "4px", svg: { width: "16px", height: "16px" } }}>
            <Check />
          </Box>
        </Box>
      </Container>
    </LayoutSideMenu>
  );
};
