import * as React from "react";

interface Props {
  title: string;
  embedId: string;
  width: string;
  height: string;
}

export const YoutubePlayer: React.FC<Props> = props => {
  return (
    <div
      sx={{
        overflow: "hidden",
        paddingBottom: "56.25%",
        position: "relative",
        height: 0,
      }}
    >
      <iframe
        width="853"
        height="480"
        src={`https://www.youtube.com/embed/${props.embedId}`}
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        title="Embedded youtube"
        sx={{
          left: 0,
          top: 0,
          height: "100%",
          width: "100%",
          position: "absolute",
        }}
      />
    </div>
  );
};
