import { postWithRetry } from "@sparkademy/app-common/utils/httpRetry";

const API_TOKEN = "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";

type ChatCompletionResponse = {
  model: string;
  usage: {
    prompt_tokens: number;
    completion_tokens: number;
    total_tokens: number;
  };
  choices: [{ message: { role: string; content: string } }];
};

export class OpenAiService {
  static async createChatCompletion(content: string): Promise<ChatCompletionResponse> {
    const payload = {
      "model": "gpt-4",
      "temperature": 0.2,
      "messages": [{ role: "user", content }],
    };

    const res = await postWithRetry("https://api.openai.com/v1/chat/completions", payload, 5, {
      headers: {
        "Authorization": `Bearer ${API_TOKEN}`,
      },
      timeout: false,
    });

    return (await res.json()) as Promise<ChatCompletionResponse>;
  }
}
