import * as React from "react";
import { Heading, Box, Text } from "theme-ui";
import { ReactComponent as Document } from "@sparkademy/app-common/materials/icons/new/document-white.svg";
import { ReactComponent as Book } from "@sparkademy/app-common/materials/icons/new/book.svg";

import { useSessionContext } from "@sparkademy/app-common/contexts/session-context";
import { Narrative } from "@sparkademy/app-common/elements/Narrative";
// import { ErrorCard } from "./ErrorCard";

import { ReactComponent as GearGroup } from "@sparkademy/app-common/materials/icons/new/gear-group.svg";
import { WorkshopSessionList } from "./WorkshopSessionList";
import { IconDescriptionList, IconDescriptionListItem } from "../IconDescriptionList";
import { HTMLContentWrapper } from "../Course/HTMLContentWrapper";
import { toTitleCase } from "../../utils/string-formatters";

type Props = {
  workshop: {
    id: number;
    title: string;
    description: string;
    requirements: string[];
    timeCommitment: number;
    skills: string[];
    companyId: string | undefined;
    sessions: {
      id: string;
      schedules: {
        startAt: Date;
        endAt: Date;
      }[];
    }[];
  };
};

export const CardWorkshop: React.FC<Props> = ({ workshop }) => {
  const { currentUser } = useSessionContext();

  if (!currentUser) {
    return null;
  }

  return (
    <React.Fragment>
      <Box
        as="article"
        sx={{
          bg: "new.primary.white",
          borderRadius: 10,
          p: 8,
          my: 8,
        }}
      >
        <Box
          as="header"
          sx={{
            display: "flex",
            alignItems: "center",
            mb: 8,
          }}
        >
          <GearGroup />
          <Box sx={{ ml: 4 }} />
          <Heading
            as="h2"
            sx={{
              fontSize: 3,
              fontWeight: "bold",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            {workshop.title}
          </Heading>
        </Box>
        <Box sx={{ mt: 3 }} />

        <Box
          sx={{
            fontSize: 1,
            maxWidth: "65ch",
          }}
        >
          <Narrative>
            <HTMLContentWrapper html={workshop.description}></HTMLContentWrapper>
          </Narrative>
        </Box>

        <Box
          sx={{
            mt: 8,
            display: "flex",
          }}
        >
          <IconDescriptionList>
            <IconDescriptionListItem
              titlesx={{ fontWeight: 700 }}
              Icon={Document}
              title="Pre-requisites"
            >
              <Box sx={{ color: "new.secondary.violet", fontWeight: 400, mt: 2 }}>
                {workshop.requirements.map((requirement, index) => {
                  return <Text key={index}>{toTitleCase(requirement)}</Text>;
                })}
              </Box>
            </IconDescriptionListItem>

            <IconDescriptionListItem titlesx={{ fontWeight: 700 }} Icon={Book} title="Key Skills">
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                  // maxHeight: "100px",
                  maxWidth: "400px",
                  mt: 2,
                }}
              >
                {workshop.skills.map((skill, idx) => {
                  return (
                    <Text
                      key={idx}
                      sx={{
                        color: "new.secondary.violet",
                        border: "1px solid",
                        borderColor: "new.secondary.violet",
                        borderRadius: "3px",
                        // marginLeft: "16px",
                        padding: "8px",
                        fontSize: "16px",
                        textAlign: "center",
                        mb: 2,
                        mr: 2,
                        width: "178px",
                        // height: "32px",
                      }}
                    >
                      {skill}
                    </Text>
                  );
                })}
              </Box>
            </IconDescriptionListItem>
          </IconDescriptionList>
        </Box>

        <Heading sx={{ mt: 7 }}>Upcoming Sessions</Heading>

        <WorkshopSessionList workshopId={workshop.id} sessions={workshop.sessions} />
      </Box>
    </React.Fragment>
  );
};
