import Cookies from "js-cookie";

export function setCookie(name: string, value: string, days: number) {
  let expires;
  if (days) {
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = date;
  }

  const isLocalhost = document.location.host.split(".").length === 1;
  const isSecure = document.location.protocol === "https:";

  Cookies.set(name, value || "", {
    expires,
    path: "/",
    secure: isSecure,
    sameSite: "Lax",
    domain: isLocalhost ? "" : ".sparkademy.com",
  });
}
