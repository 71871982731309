import * as React from "react";
import { Box } from "theme-ui";
import {
  Question,
  ExerciseSection,
  ExerciseStatusType,
} from "@sparkademy/app-common/models/assignment";
import { MarkdownContent } from "@sparkademy/app-common/components/assignment/MarkdownContent";
import { QuestionInputText } from "./QuestionInputText";
import { QuestionInputFile } from "./QuestionInputFile";
import { FeedbackInputText } from "./FeedbackInputText";
import { useAssignmentAnswersContext } from "../../contexts/assignment-answers-context";
import { useAssignmentContext } from "../../contexts/assignment-context";
import { useChatGPTAtom } from "../../stores/grading";
import { useAtom } from "jotai";

export const ExercisePart: React.FC<{ section: ExerciseSection }> = ({ section }) => {
  const { savedFeedback } = useAssignmentAnswersContext();
  const sectionFeedback = savedFeedback[section.id];
  const [exerciseNumber, _] = section.id.split(".");

  return (
    <Box>
      <Box sx={{ display: "flex" }}>
        <Box sx={{ width: "50px", fontWeight: "bold" }}>{section.id}</Box>
        <Box sx={{ maxWidth: "700px", alignSelf: "center" }}>
          <MarkdownContent content={section.content} />
        </Box>
      </Box>

      {section.questions.map(q => (
        <PartQuestion key={q.id} question={q} section={section} />
      ))}

      {sectionFeedback?.map((sf, idx) => (
        <FeedbackInputText
          key={idx}
          evaluationItem={section}
          feedbackIndex={idx}
          exerciseId={`ex${exerciseNumber}`}
          text={sf}
        />
      ))}
    </Box>
  );
};

const PartQuestion: React.FC<{ question: Question; section: ExerciseSection }> = ({
  question,
  section,
}) => {
  const { savedFeedback } = useAssignmentAnswersContext();
  const [useChatGPT] = useAtom(useChatGPTAtom);
  const questionFeedback = savedFeedback[question.id];

  const [exerciseNumber, _, questionLetter] = question.id.split(".");

  const { assignmentStatus } = useAssignmentContext();
  const exerciseStatus = assignmentStatus.find(as => as.exercise_id === `ex${exerciseNumber}`);
  const wasGraded = exerciseStatus?.status === ExerciseStatusType.GRADED;

  if (useChatGPT) {
    setGPTQuery(question, section);
  }

  return (
    <Box sx={{ my: "48px" }}>
      {question.title && (
        <Box sx={{ display: "flex", mb: "32px" }}>
          <Box sx={{ width: "50px", fontWeight: "bold" }}>{`${questionLetter}.`}</Box>
          <Box sx={{ maxWidth: "700px", alignSelf: "center" }}>{question.title}</Box>
        </Box>
      )}

      {question.type === "text" && <QuestionInputText question={question} />}

      {question.type === "file" && <QuestionInputFile question={question} />}

      {!wasGraded &&
        questionFeedback?.map((qf, idx) => (
          <FeedbackInputText
            key={idx}
            evaluationItem={question}
            feedbackIndex={idx}
            exerciseId={`ex${exerciseNumber}`}
            text={qf}
          />
        ))}

      {wasGraded && questionFeedback?.length > 0 && (
        <FeedbackInputText
          evaluationItem={question}
          feedbackIndex={0}
          exerciseId={`ex${exerciseNumber}`}
          text={questionFeedback?.join("\n\n")}
        />
      )}
    </Box>
  );
};

function setGPTQuery(question: Question, section: ExerciseSection) {
  if (question.gpt_query) return;

  const instructions = `I want you to act as a coach and expert on design thinking and user centric innovation.
  I will give you a question that was asked to a participant of a hands-on training program on design thinking; the participants's answer to this question and a feedback that was prepared for this participant to help him improve his answer and learn more.
  You will rewrite the feedback in a way that it is more coherent but still very close to the original feedback, given the context of the question and the participants's answer. You should not start your answer with something like 'This is my rewritten feedback:'. You must submit only the feedback itself.\n\n`;

  const aText = `Learner's answer: "{{${question.id}_answer}}"\n`;
  const qFeedback = `Feedback prepared for the learner: "{{${question.id}_feedback}}"`;
  const sFeedback = `Feedback prepared for the learner: "{{${section.id}_feedback}}"`;

  let qText = "";
  if (section.questions.length === 1) {
    qText = `Question asked to the learner: "${section.content}"\n`;
    if (!section.gpt_query) {
      section.gpt_query = instructions + qText + aText + sFeedback;
    }
  } else {
    qText = `Question asked to the learner: "${question.title}"\n`;
  }

  question.gpt_query = instructions + qText + aText + qFeedback;
}
