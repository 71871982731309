export const pronounOpts = [
  { value: "", label: "Select an option..." },
  { value: "he/him", label: "He/Him" },
  { value: "she/her", label: "She/Her" },
  { value: "they/them", label: "They/Them" },
  { value: "other", label: "Other" },
];

export const roleOpts = [
  { value: "", label: "Select an option..." },
  { value: "business_development", label: "Business Development" },
  { value: "channel_engagement", label: "Channel Engagement" },
  { value: "consumer_experience", label: "Consumer Experience" },
  { value: "continuous_improvement", label: "Continuous Improvement" },
  { value: "customer_support", label: "Customer Support/Success" },
  { value: "data_analytics", label: "Data & Analytics" },
  { value: "digital", label: "Digital" },
  { value: "finance", label: "Finance" },
  { value: "hr", label: "HR" },
  { value: "innovation", label: "Innovation" },
  { value: "marketing", label: "Marketing" },
  { value: "operations", label: "Operations" },
  { value: "organizational_development", label: "Organizational Development" },
  { value: "people", label: "People" },
  { value: "procurement", label: "Procurement" },
  { value: "production_manufacturing", label: "Production/Manufacturing" },
  { value: "project_management", label: "Project Management" },
  { value: "quality", label: "Quality" },
  { value: "r&d", label: "R&D" },
  { value: "retail", label: "Retail" },
  { value: "sales", label: "Sales" },
  { value: "strategy", label: "Strategy" },
  { value: "sustainability", label: "Sustainability" },
  { value: "technology", label: "Technology" },
  { value: "transformation", label: "Transformation" },
  { value: "strategic_role", label: "Strategic Role" },
  { value: "other", label: "Other" },
];

export const responsibilityOpts = [
  { value: "", label: "Select an option..." },
  { value: "analyst", label: "Analyst" },
  { value: "consultant", label: "Consultant" },
  { value: "coordinator", label: "Coordinator" },
  { value: "director", label: "Director" },
  { value: "executive", label: "Executive" },
  { value: "global", label: "Global" },
  { value: "head_of", label: "Head Of" },
  { value: "lead", label: "Lead" },
  { value: "manager", label: "Manager" },
  { value: "operator", label: "Operator" },
  { value: "project_manager", label: "Project Manager" },
  { value: "scientist", label: "Scientist" },
  { value: "specialist", label: "Specialist" },
  { value: "vp", label: "VP" },
  { value: "other", label: "Other" },
];

export const occupationOpts = [
  { value: "", label: "Select an option..." },
  { value: "accountant", label: "Accountant" },
  { value: "actor/entertainer", label: "Actor/Entertainer" },
  { value: "architect", label: "Architect" },
  { value: "athlete", label: "Athlete" },
  { value: "banker/financial_advisor", label: "Banker/Financial Advisor" },
  { value: "business_owner/entrepreneur", label: "Business Owner/Entrepreneur" },
  { value: "chef", label: "Chef" },
  { value: "civil_servant", label: "Civil Servant" },
  { value: "cleaner", label: "Cleaner" },
  { value: "clerical_worker/receptionist", label: "Clerical Worker/Receptionist" },
  { value: "construction_worker", label: "Construction Worker" },
  { value: "consultant", label: "Consultant" },
  { value: "designer", label: "Designer (Other than Graphic)" },
  { value: "doctor", label: "Doctor" },
  { value: "driver", label: "Driver" },
  { value: "editor/writer", label: "Editor/Writer" },
  { value: "skilled_tradesperson", label: "Electrician, Plumber, Carpenter" },
  { value: "engineer", label: "Engineer" },
  { value: "farmer/agricultural_worker", label: "Farmer/Agricultural Worker" },
  { value: "firefighter", label: "Firefighter" },
  { value: "freelancer", label: "Freelancer" },
  { value: "graphic_designer", label: "Graphic Designer" },
  { value: "healthcare_professional", label: "Healthcare Professional" },
  { value: "hr_professional", label: "HR Professional" },
  { value: "it_professional", label: "IT Professional" },
  { value: "journalist", label: "Journalist" },
  { value: "lawyer", label: "Lawyer" },
  { value: "lecturer/teacher", label: "Lecturer/Teacher" },
  { value: "manager/administrator", label: "Manager/Administrator" },
  { value: "marketer/public_relations", label: "Marketer/Public Relations" },
  { value: "mechanic", label: "Mechanic" },
  { value: "military_personnel", label: "Military Personnel" },
  { value: "nurse", label: "Nurse" },
  { value: "paramedic", label: "Paramedic" },
  { value: "pharmacist", label: "Pharmacist" },
  { value: "police_officer", label: "Police Officer" },
  { value: "programmer", label: "Programmer/Software Developer" },
  { value: "professor/researcher", label: "Professor/Researcher" },
  { value: "real_estate_agent", label: "Real Estate Agent" },
  { value: "retail_worker", label: "Retail Worker" },
  { value: "sales_representative", label: "Sales Representative" },
  { value: "scientist", label: "Scientist" },
  { value: "secretary", label: "Secretary" },
  { value: "social_worker", label: "Social Worker" },
  { value: "student", label: "Student" },
  { value: "technician", label: "Technician" },
  { value: "translator/interpreter", label: "Translator/Interpreter" },
  { value: "unemployed", label: "Unemployed" },
  { value: "waiter/waitress", label: "Waiter/Waitress" },
  { value: "other", label: "Other" },
];

export const countryOpts = [
  { value: "", label: "Select an option..." },
  { value: "algeria", label: "Algeria" },
  { value: "argentina", label: "Argentina" },
  { value: "armenia", label: "Armenia" },
  { value: "australia", label: "Australia" },
  { value: "austria", label: "Austria" },
  { value: "belgium", label: "Belgium" },
  { value: "bolivia", label: "Bolivia" },
  { value: "brazil", label: "Brazil" },
  { value: "canada", label: "Canada" },
  { value: "chile", label: "Chile" },
  { value: "china", label: "China" },
  { value: "colombia", label: "Colombia" },
  { value: "costa rica", label: "Costa Rica" },
  { value: "croatia", label: "Croatia" },
  { value: "cyprus", label: "Cyprus" },
  { value: "czech_republic", label: "Czech Republic" },
  { value: "denmark", label: "Denmark" },
  { value: "egypt", label: "Egypt" },
  { value: "finland", label: "Finland" },
  { value: "france", label: "France" },
  { value: "georgia", label: "Georgia" },
  { value: "germany", label: "Germany" },
  { value: "ghana", label: "Ghana" },
  { value: "greece", label: "Greece" },
  { value: "honduras", label: "Honduras" },
  { value: "hungary", label: "Hungary" },
  { value: "india", label: "India" },
  { value: "indonesia", label: "Indonesia" },
  { value: "iran", label: "Iran" },
  { value: "iraq", label: "Iraq" },
  { value: "ireland", label: "Ireland" },
  { value: "israel", label: "Israel" },
  { value: "italy", label: "Italy" },
  { value: "japan", label: "Japan" },
  { value: "kenya", label: "Kenya" },
  { value: "malaysia", label: "Malaysia" },
  { value: "mexico", label: "Mexico" },
  { value: "morocco", label: "Morocco" },
  { value: "netherlands", label: "Netherlands" },
  { value: "new_zealand", label: "New Zealand" },
  { value: "nigeria", label: "Nigeria" },
  { value: "norway", label: "Norway" },
  { value: "pakistan", label: "Pakistan" },
  { value: "peru", label: "Peru" },
  { value: "philippines", label: "Philippines" },
  { value: "poland", label: "Poland" },
  { value: "portugal", label: "Portugal" },
  { value: "romania", label: "Romania" },
  { value: "russia", label: "Russia" },
  { value: "saudi_arabia", label: "Saudi Arabia" },
  { value: "senegal", label: "Senegal" },
  { value: "singapore", label: "Singapore" },
  { value: "south_africa", label: "South Africa" },
  { value: "south_korea", label: "South Korea" },
  { value: "spain", label: "Spain" },
  { value: "sweden", label: "Sweden" },
  { value: "switzerland", label: "Switzerland" },
  { value: "thailand", label: "Thailand" },
  { value: "turkey", label: "Turkey" },
  { value: "uae", label: "United Arab Emirates" },
  { value: "ukraine", label: "Ukraine" },
  { value: "united_kingdom", label: "United Kingdom" },
  { value: "united_states", label: "United States" },
  { value: "uruguay", label: "Uruguay" },
  { value: "vietnam", label: "Vietnam" },
  { value: "other", label: "Other" },
];

export const referralSourceOpts = [
  { value: "", label: "Select an option..." },
  { value: "colleagues", label: "Heard about it from colleagues" },
  { value: "company_community", label: "Through a company internal community" },
  { value: "l&d", label: "Heard about it from Learning and Development program" },
  { value: "manager", label: "My manager recommended it to me" },
  {
    value: "previous_training",
    label: "Through a previous training (e.g., Innovation Masterclass)",
  },
  { value: "all_above", label: "All the options above" },
  { value: "other", label: "Other" },
];

export const motivationOpts = [
  { value: "", label: "Select an option..." },
  { value: "superiors_requirement", label: "Requirement from your superiors" },
  { value: "personal_interest", label: "Personal curiosity and interest about the topic" },
  { value: "future_of_work", label: "Belief these skills are relevant for the future of work" },
  {
    value: "professional_improvement",
    label: "Belief these skills will help perform better professionally",
  },
  { value: "other", label: "Other" },
];
