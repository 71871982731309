import * as React from "react";
import { Box } from "theme-ui";
import { LessonSectionBlock } from "./LessonSectionBlock";
import { Lesson } from "@sparkademy/app-common/models/course";

export const sectionLabelHeight = 54;
export const sectionLabelHalfHeight = sectionLabelHeight / 2;

export const LessonSection: React.FC<{ courseId: string; lesson: Lesson }> = ({
  courseId,
  lesson,
}) => {
  return (
    <Box
      sx={{
        ":not(:first-of-type)": { mt: `${60 + sectionLabelHalfHeight}px` },
        ":first-of-type": { mt: 0 },
      }}
    >
      <Box
        sx={{
          mt: "48px",
          borderTop: "1px solid #BFBFBF",
          width: "100%",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            px: "16px",
            width: ["240px", "340px"],
            mt: `-${sectionLabelHalfHeight}px`,
            bg: "white",
          }}
        >
          <Box
            className="lesson-section"
            sx={{
              height: `${sectionLabelHeight}px`,
              lineHeight: `${sectionLabelHeight}px`,
              textAlign: "center",
              bg: "new.bg",
              fontWeight: 700,
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              overflow: ["hidden", "visible"],
              px: ["10px", "initial"],
            }}
          >
            {lesson.title}
          </Box>
        </Box>
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: ["column", "row"],
          flexWrap: "wrap",
          flex: ["auto", "0 0 33.333333%"],
          justifyContent:
            lesson.blocks.length > 3 ? ["center", "left"] : ["center", "space-around"],
        }}
      >
        {lesson.blocks.map(b => (
          <LessonSectionBlock key={b.id} courseId={courseId} lessonId={lesson.id} block={b} />
        ))}
      </Box>
    </Box>
  );
};
