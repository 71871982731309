/** @jsx jsx */
import { jsx, Heading } from "theme-ui";

import * as React from "react";
import { useSessionContext } from "@sparkademy/app-common/contexts/session-context";
import { List, ListItem } from "@sparkademy/app-common/elements/List";
import { ModuleInfo } from "@sparkademy/app-common/models/module";
import { CardCourseEnrollment } from "../components/CardModule";
import { LayoutSideMenu } from "../components/Layout";
import { ContainerSmall } from "@sparkademy/app-common/elements/Container";
import { useAvailableCoursesData } from "../hooks/useAvailableCoursesData";
import { Loader } from "@sparkademy/app-common/components/Loader";
import { useCourseRunEnrollments } from "../hooks/useBackendData";
import { useContentContext } from "@sparkademy/app-common/contexts/content-context";

type CourseEnrollment = {
  course_run_id: number;
  id: number;
  user_id: string;
};
export const AvailableCourses: React.FC = () => {
  const { currentUser } = useSessionContext();
  const { courseRuns, loading } = useAvailableCoursesData(currentUser?.company!);
  const { data: enrollments } = useCourseRunEnrollments(currentUser!);
  const { modules } = useContentContext();

  const availableCoursesList: ModuleInfo[] = modules
    .filter((module: ModuleInfo) => module.name.includes("Sprint Design"))
    .filter((module: ModuleInfo) =>
      courseRuns.find(course_run => course_run.course.module === module.id)
    );

  if (loading) {
    return (
      <LayoutSideMenu>
        <Loader></Loader>
      </LayoutSideMenu>
    );
  }

  return (
    <LayoutSideMenu sx={{ bg: "new.bg" }}>
      <ContainerSmall
        sx={{
          py: 10,
          px: 10,
        }}
      >
        <Heading
          as="h1"
          sx={{
            fontSize: 4,
            fontWeight: "bold",
            mt: 5,
            mb: 10,
          }}
        >
          Available Courses
        </Heading>

        <List id="courses">
          {availableCoursesList.map((module, i) => {
            const courseRunArray = courseRuns.filter(mod => mod.course.module === module.id);
            const courseRun = courseRunArray.find(
              courseRun =>
                Array.isArray(enrollments) &&
                enrollments.some(
                  (enrollment: CourseEnrollment) => courseRun.id === enrollment.course_run_id
                )
            );

            return (
              <ListItem key={i}>
                <CardCourseEnrollment
                  courseRun={courseRun}
                  sx={{ mt: 10 }}
                  module={module}
                  courseRuns={courseRunArray}
                />
              </ListItem>
            );
          })}
        </List>
      </ContainerSmall>
    </LayoutSideMenu>
  );
};
