import { addHours } from "../utils/date";
import {
  Course,
  HTMLContent,
  KnowledgeCheckContent,
  KnowledgeCheckQuestionGroup,
  QuizContent,
  VideoContent,
  ContentType,
  KnowledgeCheckQuestion,
} from "./course";
import { SkillWorkshopSession } from "./workshops";

type DirectusBlockUnit = {
  id: string;
  title: string;
  time_commitment: number;
};

type DirectusLessonBlock = {
  id: string;
  title: string;
  icon: { id: string };
  icon_small: { id: string };
  units: { block_units_id?: DirectusBlockUnit }[];
};

type DirectusLesson = {
  id: string;
  title: string;
  blocks: { lesson_blocks_id?: DirectusLessonBlock }[];
};

export type DirectusCourseResponse = {
  courses_by_id: {
    id: string;
    title: string;
    module: string;
    description: string;
    lessons: { lessons_id: DirectusLesson }[];
  };
};

type DirectusContentTypesUnion =
  | {
      id: string;
      collection: "html_content";
      item: DirectusHTMLContent;
    }
  | {
      id: string;
      collection: "video_content";
      item: DirectusVideoContent;
    }
  | {
      id: string;
      collection: "quiz_content";
      item: DirectusQuizContent;
    }
  | {
      id: string;
      collection: "knowledge_check_content";
      item: DirectusKnowledgeCheckContent;
    };

type DirectusHTMLContent = {
  id: string;
  title: string;
  content: string;
};

type DirectusVideoContent = {
  id: string;
  title: string;
  source: string;
  youtube_id: string;
  cloudflare_id: string;
};

type DirectusQuizContent = {
  id: string;
  graded: boolean;
  content: string;
  status: string;
  questions: {
    id: string;
    text: string;
    options: {
      text: string;
      weight: number;
      explanation: string;
    }[];
    explanation: string;
  }[];
};

type DirectusKnowledgeCheckQuestion = {
  id: string;
  text: string;
  options: {
    text: string;
    weight: number;
    explanation: string;
  }[];
};

type DirectusKnowledgeCheckQuestionGroup = {
  id: string;
  question_group_text: string;
  questions: DirectusKnowledgeCheckQuestion[];
};

type DirectusKnowledgeCheckContent = {
  id: string;
  content: string;
  page_number: number;
  items: (
    | {
        id: string;
        collection: "knowledge_check_question";
        item: DirectusKnowledgeCheckQuestion;
      }
    | {
        id: string;
        collection: "knowledge_check_question_group";
        item: DirectusKnowledgeCheckQuestionGroup;
      }
  )[];
};

export type DirectusBlockUnitResponse = {
  block_units_by_id: {
    id: string;
    title: string;
    contents: DirectusContentTypesUnion[];
  };
};

type DirectusSkill = {
  name: string;
  short_name: string;
};

export type DirectusSkillWorkshopSession = {
  id: string;
  title: string;
  preparation_summary: string;
  instructions: string;
  skill_workshop_id: {
    id: string;
    title: string;
  };
  schedules: DirectusSkillWorkshopSessionSchedule[];
};

type DirectusSkillWorkshopSessionSchedule = {
  description: string;
  start_at: string;
  duration: number;
  material: { id: string } | null;
  resources: DirectusSkillWorkshopResource[];
};

type DirectusSkillWorkshopResource = {
  id: string;
  type: string;
  link_text: string;
  link_url: string;
};

export type DirectusSkillWorkshop = {
  id: number;
  title: string;
  description: string;
  requirements: string[];
  time_commitment: number; // expressed in hours
  hidden: boolean;
  skills: {
    Skills_id: DirectusSkill;
  }[];
  company: { id: string } | null;
  sessions: DirectusSkillWorkshopSession[];
};

export type DirectusCourseRunSchedule = {
  id: string;
  start_on: string;
  end_on: string;
  max_enrollments: number;
};

export type DirecutsCourseRunResponse = {
  course_runs: {
    id: string;
    course: {
      id: string;
      title: string;
      module: string;
    };
    schedule: DirectusCourseRunSchedule;
    company: {
      id: string;
      name: string;
    } | null; // TODO: remove null
  }[];
};

export const parseCourseResponse = (input: DirectusCourseResponse): Course => {
  const result = {
    id: input.courses_by_id.id,
    title: input.courses_by_id.title,
    description: input.courses_by_id.description,
    module: input.courses_by_id.module,
    lessons: input.courses_by_id.lessons.map(lesson => ({
      id: lesson.lessons_id.id,
      title: lesson.lessons_id.title,
      courseId: input.courses_by_id.id,
      blocks: lesson.lessons_id.blocks.map(block => ({
        id: block.lesson_blocks_id?.id || "",
        title: block.lesson_blocks_id?.title || "[MISSING]",
        lessonId: lesson.lessons_id.id,
        icon: block.lesson_blocks_id?.icon.id || "",
        iconSmall: block.lesson_blocks_id?.icon_small.id || "",
        timeCommitment:
          block.lesson_blocks_id?.units.reduce(
            (acc, unit) => acc + (unit.block_units_id?.time_commitment ?? 0),
            0
          ) || 0,
        units:
          block.lesson_blocks_id?.units.map(unit => ({
            id: unit.block_units_id?.id || "",
            title: unit.block_units_id?.title || "[MISSING]",
            blockId: block.lesson_blocks_id!.id,
            timeCommitment: unit.block_units_id?.time_commitment || 0,
          })) || [],
      })),
    })),
  };

  return result;
};

export const parseBlockUnitResponse = (input: DirectusBlockUnitResponse): ContentType[] => {
  if (!input.block_units_by_id) {
    return [];
  }

  return input.block_units_by_id.contents
    .map(c => buildContentFromType(c))
    .filter(c => c !== undefined) as ContentType[];
};

const parseKnowledgeCheckQuestion = (
  question: DirectusKnowledgeCheckQuestion,
  groupId?: string
) => {
  return {
    id: question.id,
    groupId,
    type: "question",
    text: question.text,
    options: question.options.map(option => ({
      text: option.text,
      weight: option.weight,
      explanation: option.explanation,
    })),
  };
};

const buildContentFromType = (content: DirectusContentTypesUnion): ContentType | undefined => {
  if (!content.item) {
    return undefined;
  }

  switch (content.collection) {
    case "html_content": {
      const res: HTMLContent = {
        id: content.item.id,
        title: content.item.title,
        type: "html",
        content: content.item.content,
      };
      return res;
    }

    case "video_content": {
      const res: VideoContent = {
        id: content.item.id,
        title: content.item.title,
        type: "video",
        cloudflareId: content.item.cloudflare_id || content.item.source,
        youtubeId: content.item.youtube_id,
      };
      return res;
    }

    case "quiz_content": {
      const res: QuizContent = {
        id: content.item.id,
        title: "",
        type: "quiz",
        graded: content.item.graded,
        content: content.item.content,
        status: content.item.status,
        questions: content.item.questions.map(question => ({
          id: question.id,
          text: question.text,
          options: question.options.map(option => ({
            text: option.text,
            weight: option.weight,
            explanation: option.explanation,
          })),
          explanation: question.explanation,
        })),
      };
      return res;
    }

    case "knowledge_check_content": {
      const knowledgeCheck = content.item;
      const res: KnowledgeCheckContent = {
        id: knowledgeCheck.id,
        title: `Page ${knowledgeCheck.page_number}`,
        type: "knowledge_check",
        content: knowledgeCheck.content,
        pageNumber: knowledgeCheck.page_number,
        items: knowledgeCheck.items.map(assoc => {
          if (assoc.collection === "knowledge_check_question_group") {
            return {
              id: assoc.item.id,
              type: "group",
              questionGroupText: assoc.item.question_group_text,
              questions: assoc.item.questions.map(q =>
                parseKnowledgeCheckQuestion(q, assoc.item.id)
              ),
            } as KnowledgeCheckQuestionGroup;
          }
          // assoc.collection === "knowledge_check_question
          return parseKnowledgeCheckQuestion(assoc.item) as KnowledgeCheckQuestion;
        }),
      };
      return res;
    }
  }
};
