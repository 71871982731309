/** @jsx jsx */
import { jsx, Box } from "theme-ui";
import * as React from "react";
import { Course, HTMLContent } from "@sparkademy/app-common/models/course";
import { HTMLContentWrapper } from "./HTMLContentWrapper";
import { useSessionContext } from "@sparkademy/app-common/contexts/session-context";
import { User } from "@sparkademy/app-common/models/user";
import { useAtom } from "jotai";
import { courseAtom } from "../../stores/course";
import { CDN_ASSETS_URL } from "../../data/directus";
import { useUpdateAtom } from "jotai/utils";
import { methodCardModalVisibleAtom } from "@sparkademy/app-common/stores/ui";
import { MethodCardModal } from "@sparkademy/app-common/components/MethodCardModal";

export const BlockUnitHTMLContent: React.FC<{
  item: HTMLContent;
}> = ({ item }) => {
  const { currentUser } = useSessionContext();
  const [course] = useAtom(courseAtom);
  const openMethodCardModal = useUpdateAtom(methodCardModalVisibleAtom);

  let processedHtml = replaceImageSource(item.content);
  processedHtml = replaceEmbeddedUserVars(processedHtml, currentUser, course);

  // change links to method cards to open a modal instead of navigating away
  React.useEffect(() => {
    const elements = document.querySelectorAll("a");
    elements.forEach(anchor => {
      // ignore links that aren't to method cards such as youtube videos
      if (!anchor.href.match(/\/method-kit\//)) {
        return;
      }

      anchor.addEventListener("click", e => {
        console.log(`clicked on ${anchor.href}`);
        const slug = anchor.href.split("/").pop();
        if (!slug) return;
        openMethodCardModal(slug);
        e.preventDefault();
      });
    });
  }, [processedHtml]);

  return (
    <Box>
      <HTMLContentWrapper html={processedHtml} />
      <MethodCardModal />
    </Box>
  );
};

function replaceEmbeddedUserVars(html: string, user: User | null, course: Course | null) {
  if (!user || !html) return html;

  return html
    .replace(/\$\{user_email\}/g, user.data.email)
    .replace(/\$\{user_cohort\}/g, user.data.cohort_id)
    .replace(/\$\{course_title\}/g, course?.title || "[BLANK]");
}

function replaceImageSource(html: string) {
  // replace the image source with the CDN URL so we cache CMS assets
  // cloudfront is returning errors for some images
  // return html.replace(
  //   /src=\"https\:\/\/sparkademy.directus.app\/assets/g,
  //   `src="${CDN_ASSETS_URL}`
  // );
  return html;
}
