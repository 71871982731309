import * as React from "react";
import { Link } from "react-router-dom";
import { Box, Heading, Image, AspectRatio, Text } from "theme-ui";
import { Tool } from "@sparkademy/app-common/models/tool";
import { Module, ModuleInfo } from "@sparkademy/app-common/models/module";
import { CardClickable, SHADOW_SIZE } from "@sparkademy/app-common/elements/CardClickable";
import { getMethodKitColorForLevel } from "@sparkademy/app-common/utils/theme";
import { ReactComponent as Lock } from "@sparkademy/app-common/materials/icons/lock.svg";

import { timeAgoFormatter } from "@sparkademy/app-common/utils/date-formatters";
import TimeAgo from "react-timeago";
import { dateStateAtom, showDateAtom } from "../stores/current-date";
import { uiCopy } from "../data/ui-copy";
import { useAtom } from "jotai";

type Props = {
  tool: Tool;
  module: Module;
  moduleInfo: ModuleInfo;
  isPublic: boolean;
};

export const MethodCardThumb: React.FC<Props> = ({ tool, module, moduleInfo, isPublic }) => {
  const [date] = useAtom(dateStateAtom);
  const [showDate] = useAtom(showDateAtom);
  if (!module.isActive && !module.isFailed) {
    return (
      <Box
        sx={{
          position: "relative",
          borderWidth: 3,
          borderStyle: "solid",
          borderRadius: 2,
          boxShadow: theme => `
            0px 0px 0 0 ${theme.colors.black}
        `,
        }}
      >
        <Heading
          as="h3"
          sx={{
            fontSize: [0, tool.name.length > 20 ? ".9rem" : 0],
            px: 3,
            py: 3,
            bg: "white",
          }}
        >
          {tool.name}
        </Heading>
        <AspectRatio
          ratio={16 / 9}
          sx={{
            p: 3,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Image
            src={tool.thumbImage}
            sx={{
              width: "100%",
              height: "auto",
              maxHeight: "355px",
            }}
          />
        </AspectRatio>
        <Box
          sx={{
            position: "absolute",
            top: "-3px",
            left: "-3px",
            right: "-3px",
            bottom: "-3px",
            bg: "rgba(0, 0, 0, .65)",
            color: "white",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              mb: 3,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: 32,
              height: 32,
              svg: {
                width: "auto",
                height: "100%",
              },
            }}
          >
            <Lock />
          </Box>

          <Text
            id="opens-in-msg"
            as="span"
            sx={{
              fontSize: 0,
            }}
          >
            {uiCopy.TOOLS_CLOSED(
              <TimeAgo
                date={module.materials.start}
                formatter={timeAgoFormatter}
                now={() => (showDate ? date.getTime() : Date.now())}
              />
            )}
          </Text>
        </Box>
      </Box>
    );
  }
  return (
    <CardClickable
      level={moduleInfo.level}
      completed
      sx={{
        textDecoration: "none",
        borderColor: getMethodKitColorForLevel(moduleInfo.level),
        borderWidth: 3,
        borderStyle: "solid",
        borderRadius: 2,
        boxShadow: theme => `
          0px 0px 0 0 ${theme.colors.black}
        `,
        width: "100%",
        a: {
          textDecoration: "none",
          color: "text",
          fontSize: 0,
        },
        ":hover": {
          boxShadow: theme => `
            ${SHADOW_SIZE}px ${SHADOW_SIZE}px 0 0 ${theme.colors.black}
          `,
          transform: "translateY(-10px)",
        },
        ":focus-within": {
          boxShadow: theme => `
            ${SHADOW_SIZE}px ${SHADOW_SIZE}px 0 0 ${theme.colors.black}
          `,
          transform: "translateY(-10px)",
        },
        "& a:focus-visible": {
          outline: "none",
        },
        "& a:before": {
          display: "none",
        },
      }}
    >
      <Link
        id={tool.slug}
        to={isPublic ? `/method-kit/public/${tool.slug}` : `/method-kit/${tool.slug}`}
      >
        <Heading
          as="h3"
          sx={{
            fontSize: [0, tool.name.length > 20 ? ".9rem" : 0],
            fontWeight: "bold",
            px: 3,
            py: 2,
            bg: "white",
          }}
        >
          {tool.name}
        </Heading>
        <Image
          src={tool.thumbImage}
          alt={`illustration of ${tool.name}`}
          sx={{
            maxWidth: "100%",
            height: "auto",
          }}
        />
      </Link>
    </CardClickable>
  );
};
