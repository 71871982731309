import { Loader } from "@sparkademy/app-common/components/Loader";
import { ContainerSmall } from "@sparkademy/app-common/elements/Container";
import { List, ListItem } from "@sparkademy/app-common/elements/List";
import { Level } from "@sparkademy/app-common/models/level";
import React from "react";
import { Box, Heading, Link } from "theme-ui";
import { CardLevel } from "../components/CardLevel";
import { Layout, LayoutSideMenu } from "../components/Layout";
import { useUserData } from "../hooks/useUserData";

export const Programs: React.FC = () => {
  const { levels } = useUserData();
  const pastLevels = levels.filter(level => ["completed", "failed"].includes(level.status!));
  const upcomingLevels = levels.filter(level => ["closed", "locked"].includes(level.status!));
  const activeLevels = levels.filter(level => ["open"].includes(level.status!));
  if (levels.length === 0) {
    return (
      <Layout>
        <Loader />
      </Layout>
    );
  }
  return (
    <LayoutSideMenu sx={{ bg: "new.bg" }}>
      <ContainerSmall
        sx={{
          py: 10,
          px: 10,
        }}
      >
        <ProgramsList programArray={activeLevels} label="Active Programs" notFoundMessage />
        <ProgramsList programArray={upcomingLevels} label="Upcoming Programs" />
        <ProgramsList programArray={pastLevels} label="Completed Programs" />
      </ContainerSmall>
    </LayoutSideMenu>
  );
};

const ProgramsList: React.FC<{ programArray: Level[]; label: string; notFoundMessage?: boolean }> =
  ({ programArray, label, notFoundMessage }) => {
    return programArray.length > 0 ? (
      <Box id={label.split(" ").join("-")}>
        <Heading
          as="h1"
          sx={{
            fontSize: [2, 3],
            fontWeight: "bold",
            mb: 10,
          }}
        >
          {label}
        </Heading>
        <List id="level-list">
          {programArray.map(level => {
            return (
              <ListItem
                key={level.id}
                sx={{
                  mb: 9,
                }}
              >
                <CardLevel level={level} />
              </ListItem>
            );
          })}
        </List>
      </Box>
    ) : notFoundMessage ? (
      <Box id={label.split(" ").join("-")}>
        <Heading
          as="h1"
          sx={{
            fontSize: [2, 3],
            fontWeight: "bold",
            my: 10,
          }}
        >
          {label}
        </Heading>
        <Box as="p" sx={{ fontSize: [1, 2], mb: 10 }}>
          There are no {label} at this time. Please check back later, or{" "}
          <Link href="mailto:team@sparkademy.com" sx={{ textDecoration: "none" }} to={undefined}>
            Contact Us
          </Link>
        </Box>
      </Box>
    ) : null;
  };
