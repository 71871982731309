import React, { Fragment, useEffect } from "react";
import { Layout } from "../components/Layout";
import { useCourse } from "../hooks/useBackendData";
import { courseAtom, blockUnitCompletionMapAtom } from "../stores/course";
import { parseCourseResponse } from "@sparkademy/app-common/models/directus";
import { Loader } from "@sparkademy/app-common/components/Loader";
import { useParams, Redirect } from "react-router-dom";
import { getBlockUnitCompletions } from "../services/http-api-service";
import { useSessionContext } from "@sparkademy/app-common/contexts/session-context";
import { useAtom } from "jotai";
import { useUpdateAtom } from "jotai/utils";

export const CourseContainer: React.FC = props => {
  const { currentUser } = useSessionContext();
  const { courseId } = useParams<{ courseId: string }>();
  const [course, setCourse] = useAtom(courseAtom);
  const setBlockUnitCompletions = useUpdateAtom(blockUnitCompletionMapAtom);

  const { loading, error, data } = useCourse(courseId, currentUser!);

  useEffect(() => {
    if (courseId && currentUser) {
      getBlockUnitCompletions(courseId, currentUser).then(response => {
        setBlockUnitCompletions(response);
      });
    }
  }, [courseId, currentUser, setBlockUnitCompletions]);

  useEffect(() => {
    if (data && data["courses_by_id"] !== null) {
      setCourse(parseCourseResponse(data));
    }
  }, [data, setCourse]);

  if (error) {
    console.log(error);
    return <Redirect to="/error" />;
  }

  if (loading || !course) {
    return (
      <Layout sx={{ bg: "new.primary.white" }}>
        <Loader />
      </Layout>
    );
  }

  return <Fragment>{props.children}</Fragment>;
};
