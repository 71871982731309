import * as React from "react";
import { Box } from "theme-ui";
import { Exercise, ExerciseStatusType } from "@sparkademy/app-common/models/assignment";
import { useAssignmentContext } from "@sparkademy/app-common/contexts/assignment-context";
import { useAssignmentAnswersContext } from "@sparkademy/app-common/contexts/assignment-answers-context";

export const ExerciseSelector: React.FC<{
  exercises: Exercise[];
  currentExerciseIndex: number;
  onExerciseChanged: (exerciseIndex: number) => void;
}> = ({ exercises, currentExerciseIndex, onExerciseChanged }) => {
  const [activeSelector, setActiveSelector] = React.useState<number>(currentExerciseIndex);
  const { setDirtyState } = useAssignmentAnswersContext();

  const onSelectorChanged = (index: number) => {
    if (index !== currentExerciseIndex) {
      setActiveSelector(index);
      onExerciseChanged(index);
      setDirtyState(false);
    }
  };

  const getExerciseShortTitle = (title: string) => {
    // title would look like: "Exercise 1: Stakeholder and Assumptions mapping"
    const titleParts = title.split(":");
    if (titleParts.length > 0) {
      return titleParts[0];
    }
    return title;
  };

  return (
    <Box sx={{ display: "flex", my: "48px" }}>
      {exercises.map((e, idx) => (
        <Box
          key={e.id}
          className={e.id}
          onClick={() => onSelectorChanged(idx)}
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            alignItems: "center",
            border: "2px solid",
            borderColor: activeSelector === idx ? "primary" : "new.secondary.grey",
            borderRadius: "10px",
            width: "120px",
            height: "80px",
            mr: "10px",
            py: "15px",
            cursor: "pointer",
          }}
        >
          <Box sx={{ fontWeight: 700, fontSize: "14px" }}>{getExerciseShortTitle(e.title)}</Box>

          <ExerciseStatusLabel exercise={e} />
        </Box>
      ))}
    </Box>
  );
};

const ExerciseStatusLabel: React.FC<{
  exercise: Exercise;
}> = ({ exercise }) => {
  const { assignmentStatus } = useAssignmentContext();
  const exerciseStatus = assignmentStatus.find(as => as.exercise_id === exercise.id);

  let statusDisplay = "Not Submitted";
  let statusColor = "new.secondary.grey";

  switch (exerciseStatus?.status) {
    case ExerciseStatusType.IN_PROGRESS:
      statusDisplay = "In Progress";
      statusColor = "new.secondary.grey";
      break;
    case ExerciseStatusType.SUBMITTED:
      statusDisplay = "Submitted";
      statusColor = "brand.green";
      break;
    case ExerciseStatusType.GRADED:
      statusDisplay = "Graded";
      statusColor = "primary";
      break;
  }

  return (
    <Box className={exerciseStatus?.status} sx={{ color: statusColor, fontSize: "14px" }}>
      {statusDisplay}
    </Box>
  );
};
