/** @jsx jsx */
import { jsx, Box, SxStyleProp } from "theme-ui";
import * as React from "react";

export const CircleCheckIcon: React.FC<{ checked: boolean }> = ({ checked }) => (
  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M24 48C37.2548 48 48 37.2548 48 24C48 10.7452 37.2548 0 24 0C10.7452 0 0 10.7452 0 24C0 37.2548 10.7452 48 24 48Z"
      fill={checked ? "#8BF2CF" : "#EDEDF4"}
    />
    <rect
      x="13.2031"
      y="28.5586"
      width="4.8"
      height="13.7058"
      transform="rotate(-60 13.2031 28.5586)"
      fill="white"
    />
    <rect
      x="32.9141"
      y="12.8008"
      width="4.8"
      height="23.4971"
      transform="rotate(30 32.9141 12.8008)"
      fill="white"
    />
    <rect
      x="11.6016"
      y="28.5586"
      width="4.8"
      height="13.7058"
      transform="rotate(-60 11.6016 28.5586)"
      fill={checked ? "#4B27E0" : "white"}
    />
    <rect
      x="31.3125"
      y="12.8008"
      width="4.8"
      height="23.4971"
      transform="rotate(30 31.3125 12.8008)"
      fill={checked ? "#4B27E0" : "white"}
    />
  </svg>
);
