import * as React from "react";
import { Question } from "../models/empathy";
import { Text, Label, Box, Image } from "theme-ui";
import { Narrative } from "@sparkademy/app-common/elements/Narrative";
import { Radio } from "@sparkademy/app-common/elements/Radio";

export const QuestionFieldset: React.FC<{
  question: Question;
  name: string;
  onChange: (idx: number, checked: boolean) => void;
  checkedIndex: number | null;
}> = ({ question, name, onChange, checkedIndex }) => (
  <React.Fragment>
    <fieldset key={question.text}>
      <Text
        as="span"
        sx={{
          fontSize: [0, 1],
          lineHeight: 1.4,
        }}
      >
        {question.text}
      </Text>
      <Box sx={{ mt: 6 }} />

      <Box sx={{ display: "flex", alignItems: "center" }}>
        {question.mediaUrl ? (
          <Box
            sx={{
              mr: [3, 15],
              flex: "1 1 50%",
            }}
          >
            <Image src={question.mediaUrl} alt={``} />
          </Box>
        ) : null}
        <Box sx={{ flex: "1 1 50%" }}>
          {question.options.map(({ id, text }, index) => (
            <Narrative key={id}>
              <Label
                sx={{
                  fontSize: [0, 1],
                  lineHeight: 1.4,
                }}
              >
                <Box sx={{ minWidth: 25 }}>
                  <Radio
                    sx={{
                      flex: "0 0 auto",
                      bg: "transparent",
                      width: 25,
                      height: 25,
                      m: 0,
                    }}
                    name={name}
                    value="1"
                    checked={checkedIndex === index}
                    onChange={event => onChange(index, event.target.checked)}
                  />
                </Box>
                <Box sx={{ ml: 5, display: "inline" }} />
                <Box sx={{ display: "inline", cursor: "pointer" }}>{text}</Box>
              </Label>
              <Box sx={{ mt: 4 }} />
            </Narrative>
          ))}
        </Box>
      </Box>
    </fieldset>
  </React.Fragment>
);
