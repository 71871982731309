/** @jsx jsx */
import { jsx, Box } from "theme-ui";
import * as React from "react";

export const HTMLContentWrapper: React.FC<{
  html: string;
}> = ({ html }) => {
  return (
    <Box
      sx={{
        maxWidth: "894px",
        lineHeight: "140%",
        "img": {
          maxWidth: "min(894px,100%)",
          width: "auto",
          height: "auto",
          py: "32px",
        },
        "p > strong": {
          lineHeight: "32px",
        },
        "ul, ol": {
          pl: ["32px", "40px"],
        },
        ul: {
          pb: "8px",
          margin: 0,
          listStylePosition: "outside",
        },
        // specific padding for Safari since custom list item markers are not yet supported 👇
        "@media not all and (min-resolution:.001dpcm)": {
          "@supports (-webkit-appearance:none)": {
            ul: { ml: "18px" },
          },
        },
        "ul li::marker": {
          content: '">"',
          fontWeight: "bold",
        },
        "ul li > p": { display: "inline" },
        "ul li ul": { ml: "32px" },
        li: {
          mt: "24px",
          pl: "16px",
          lineHeight: 1.2,
        },
        "li > a": {
          display: "inline",
        },
        a: {
          color: "brand.blue",
          textDecoration: "none",
        },
        "a:hover, a:focus-visible": {
          color: "brand.blueLight",
        },
        "p em": {
          fontStyle: "italic",
        },
        "table": {
          //course/10/lesson/2/block/10/unit/12
          //See this link and remove this rule to see why float should be initial/none
          //table with float would make the container 0 height.
          float: "initial !important",
        },
      }}
    >
      <div dangerouslySetInnerHTML={{ __html: html }} />
    </Box>
  );
};
