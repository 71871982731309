import * as React from "react";
import { Box } from "theme-ui";
import ReactMarkdown from "react-markdown";

export const MarkdownContent: React.FC<{ content: string }> = ({ content }) => {
  return (
    <Box
      sx={{
        p: { my: 0 },
        "p:not(:first-of-type)": {
          mt: "32px",
          fontSize: 0,
          lineHeight: 1.4,
        },
        ul: {
          margin: 0,
          pl: "8px",
          listStylePosition: "outside",
        },
        // specific padding for Safari since custom list item markers are not yet supported 👇
        "@media not all and (min-resolution:.001dpcm)": {
          "@supports (-webkit-appearance:none)": {
            ul: { pl: "18px" },
          },
        },
        "ul li::marker": {
          content: '">"',
          fontWeight: "bold",
        },
        "ul li > p": { display: "inline" },
        "ul li ul": { ml: "32px" },
        ol: {
          margin: 0,
          ml: "32px",
          padding: 0,
          listStyleType: "lower-alpha",
        },
        "ol li::marker": {
          fontWeight: "bold",
        },
        li: {
          mt: "16px",
          pl: "16px",
          fontSize: 0,
          lineHeight: 1.2,
          maxWidth: "65ch",
        },
        a: {
          color: "brand.blue",
          textDecoration: "none",
        },
        "a:hover, a:focus-visible": {
          color: "brand.blueLight",
        },
        img: {
          maxWidth: "100%",
        },
      }}
    >
      <ReactMarkdown children={content} linkTarget="_blank" />
    </Box>
  );
};
