/** @jsx jsx */
import * as React from "react";
import { EmpathyItemAnswer, EmpathyItem } from "../../models/empathy";
import { jsx, Text, Box, Heading } from "theme-ui";
import { Button } from "@sparkademy/app-common/elements/Button";
import { Narrative } from "@sparkademy/app-common/elements/Narrative";
import { Loader } from "@sparkademy/app-common/components/Loader";
import { QuestionFieldset } from "../QuestionFieldset";
import useEventListener from "@use-it/event-listener";

type Props = {
  sectionId: number;
  sectionName: string;
  introTitle: string;
  introBody: () => React.ReactNode;
  cohort: string;

  load: () => Promise<EmpathyItem[]>;
  save: (answers: EmpathyItemAnswer[]) => Promise<{ id: string }>;
  onEnd: Function;
};

function putAtIndex<T>(arr: Array<T>, item: T, index: number): Array<T> {
  return [...arr.slice(0, index), item, ...arr.slice(index + 1)];
}

function loadImage(url: string) {
  const image = new Image();
  image.src = url;
}

export const EmpathyController: React.FC<Props> = ({
  sectionId,
  sectionName,
  introTitle,
  introBody,
  load,
  save,
  onEnd,
  cohort,
}) => {
  const [lastPickStart, setLastPickStart] = React.useState(new Date());

  const [loading, setLoading] = React.useState(true);
  const [error, setError] = React.useState(false);
  const [items, setItems] = React.useState<EmpathyItem[]>([]);
  const [answers, setAnswers] = React.useState<EmpathyItemAnswer[]>([]);
  const [activeItemIdx, setActiveItemIdx] = React.useState(-1);
  const [choices, setChoices] = React.useState<Array<Array<number | null>>>([]);

  React.useEffect(() => {
    // This is used to show a confirmation prompt for the user before navigating away
    const handler = (e: Event) => {
      e.preventDefault();
      e.returnValue = true;
    };

    window.addEventListener("beforeunload", handler);

    return () => window.removeEventListener("beforeunload", handler);
  }, []);

  React.useEffect(() => {
    async function _load() {
      try {
        let response = await load();
        if (response.length) {
          setChoices(response.map(item => item.questions.map(q => null)));
          setItems(response);
          setLoading(false);

          // Preload first item image, if any
          const firstQuestion = response[0].questions[0];
          if (firstQuestion.mediaUrl) {
            loadImage(firstQuestion.mediaUrl);
          }
        }
      } catch (ex) {
        setLoading(false);
        setError(true);
      }
    }
    _load();
  }, [load, cohort, sectionName]);

  React.useEffect(() => {
    // Preload image for the next question, if any
    const nextItem = items[activeItemIdx + 1];
    if (nextItem) {
      const nextQuestion = nextItem.questions[0];
      if (nextQuestion.mediaUrl) {
        loadImage(nextQuestion.mediaUrl);
      }
    }
  }, [activeItemIdx, items]);

  async function submit(answers: EmpathyItemAnswer[]) {
    try {
      setLoading(true);

      const result = await save(answers);
      if (window.localStorage) {
        window.localStorage.setItem("uid", result.id);
      }

      setLoading(false);
      onEnd();
    } catch (ex) {
      console.error(ex);
      setLoading(false);
      setError(true);
    }
  }

  async function nextQuestion() {
    // Move to the first question in case we're still in the intro
    if (activeItemIdx === -1) {
      setActiveItemIdx(0);
      return;
    }

    const now = new Date();
    const updatedAnswers = answers.concat([
      {
        sectionItemId: items[activeItemIdx].id,
        answers: choices[activeItemIdx].map((choiceIndex, questionIndex) => ({
          questionId: items[activeItemIdx].questions[questionIndex].id,
          optionId: items[activeItemIdx].questions[questionIndex].options[choiceIndex as number].id,
        })),
        timeTakenMs: now.getTime() - lastPickStart.getTime(),
      },
    ]);

    setAnswers(updatedAnswers);
    setActiveItemIdx(activeItemIdx + 1);
    setLastPickStart(new Date());

    if (activeItemIdx + 1 === items.length) {
      await submit(updatedAnswers);
    }
  }

  const startTest = () => {
    setLastPickStart(new Date());
    nextQuestion();
  };

  useEventListener("keydown", (event: KeyboardEvent) => {
    // Advance intro with enter key
    if (activeItemIdx === -1 && event.keyCode === 13) {
      startTest();
    }
  });

  if (activeItemIdx === -1) {
    return (
      <React.Fragment>
        <Heading
          as="h1"
          sx={{
            fontSize: [2, 3],
            fontWeight: "bold",
          }}
        >
          {introTitle}
        </Heading>
        <Box sx={{ mt: 4 }} />
        <Narrative>{introBody()}</Narrative>
        <Box sx={{ mt: 8 }} />

        <Button onClick={startTest}>Next</Button>
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      {error ? (
        "Something went wrong"
      ) : loading ? (
        <Loader />
      ) : items.length > activeItemIdx ? (
        <Box
          sx={{
            fieldset: {
              border: "0",
              padding: "0.01em 0 0 0",
              margin: "0",
              minWidth: "0",
            },
          }}
        >
          <Text
            as="p"
            sx={{
              fontWeight: "bold",
              fontSize: 1,
            }}
          >
            {`Question ${activeItemIdx + 1} of ${items.length}`}
          </Text>
          <Box sx={{ mt: 3 }} />
          {items[activeItemIdx].content ? (
            <React.Fragment>
              <Narrative
                dangerouslySetInnerHTML={{
                  __html: items[activeItemIdx].content as string,
                }}
              />
              <Box sx={{ mt: 8 }} />
            </React.Fragment>
          ) : null}
          <form
            onSubmit={event => {
              event.preventDefault();
              nextQuestion();
            }}
          >
            {items[activeItemIdx].questions.map((question, questionIndex) => (
              <React.Fragment key={question.id}>
                <Box sx={{ mt: 10 }} />
                <QuestionFieldset
                  question={question}
                  name={`question-${questionIndex}`}
                  onChange={(index, checked) => {
                    setChoices(
                      putAtIndex(
                        choices,
                        putAtIndex(choices[activeItemIdx], checked ? index : null, questionIndex),
                        activeItemIdx
                      )
                    );
                  }}
                  checkedIndex={choices[activeItemIdx][questionIndex]}
                />
              </React.Fragment>
            ))}

            <Box sx={{ mt: 10 }} />
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <Button
                type="submit"
                disabled={choices[activeItemIdx].filter(i => i === null).length !== 0}
              >
                {activeItemIdx + 1 === items.length ? "Submit" : "Next"}
              </Button>
            </Box>
          </form>
        </Box>
      ) : null}
    </React.Fragment>
  );
};
