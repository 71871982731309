import * as React from "react";
import { Box, Link, Button as BaseButton } from "theme-ui";
import VisuallyHidden from "@reach/visually-hidden";
import { ReactComponent as Close } from "@sparkademy/app-common/materials/icons/close.svg";
import { ProductTour } from "../ProductTour";
import { ButtonGhost } from "@sparkademy/app-common/elements/ButtonGhost";
import { ForwardIcon } from "../CustomIcons/ForwardIcon";
import { TrackingService } from "../../services/tracking-service";
import { SkillWorkshopSession } from "@sparkademy/app-common/models/workshops";

const steps = [
  {
    target: "article",
    content: (
      <React.Fragment>
        <h3>👋 Welcome to your Sparkademy Workshop!</h3>
        <p>
          This is a new learning format! Please read all the workshop instructions carefully. 🚀
        </p>
      </React.Fragment>
    ),
    disableBeacon: true,
  },
  {
    target: "#prep-card",
    content: (
      <React.Fragment>
        <p>
          <b>Before</b> the workshop, complete your preparation assignment here.
        </p>
      </React.Fragment>
    ),
    disableBeacon: true,
  },
  {
    target: "#meeting-link-container",
    content: (
      <React.Fragment>
        <p>
          <b>At the start time</b> of the workshop, the meeting link to join will appear here.
        </p>
      </React.Fragment>
    ),
    disableBeacon: true,
  },
  {
    target: "#guided-learning-container",
    content: (
      <React.Fragment>
        <p>
          Instead of a live facilitator, your team will receive instructions for each activity in
          the guided learning.
        </p>
      </React.Fragment>
    ),
    disableBeacon: true,
  },
  {
    target: "#collab-boards-container",
    content: (
      <React.Fragment>
        <p>
          You and your team will complete the workshop activities on the Mural board you will find
          here.
        </p>
      </React.Fragment>
    ),
    disableBeacon: true,
  },
  {
    target: "#workshop-session-card",
    content: (
      <React.Fragment>
        <p>
          <a href="https://youtu.be/eZvU411kRpc" target="_blank">
            Check out a video walkthrough.
          </a>
          <br />
          <br />
          We hope you are excited for this unique collaborative experience!
        </p>
      </React.Fragment>
    ),
    disableBeacon: true,
  },
];

export const DISMISS_TUTORIAL_TRACKING_KEY = "workshop_tutorial_dismissed";

export const WorkshopTutorialBanner: React.FC<{ session: SkillWorkshopSession }> = ({
  session,
}) => {
  const [bannerVisible, setBannerVisible] = React.useState(true);
  const [startProductTour, setStartProductTour] = React.useState(false);

  const startTutorial = () => {
    // send tracking event
    TrackingService.WorkshopTutorialStarted(
      session.workshop.id,
      session.workshop.title,
      session.id,
      session.title
    );
    setStartProductTour(true);
    dismissBanner();
  };

  const dismissBanner = () => {
    setBannerVisible(false);
    sessionStorage.setItem(DISMISS_TUTORIAL_TRACKING_KEY, "true");
  };

  return (
    <React.Fragment>
      <ProductTour
        name="workshop"
        steps={steps}
        run={startProductTour}
        trackTourCompletion={false}
      />

      {bannerVisible && (
        <Box
          id="workshop-tutorial-banner"
          sx={{
            bg: "primary",
            fontSize: 2,
            fontWeight: 700,
            py: 4,
            position: "fixed",
            width: ["100%", , , "calc(100% - 303px)"],
            right: 0,
            bottom: 0,
            color: "white",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box>
            First workshop? Here's how to get started.
            <ButtonGhost
              onClick={startTutorial}
              sx={{
                pl: "10px",
                color: "white",
                fontSize: "inherit",
                fontWeight: "inherit",
                textDecoration: "underline",
              }}
            >
              View tutorial
              <ForwardIcon color="white" sxOverride={{ ml: "5px" }} />
            </ButtonGhost>
          </Box>

          <Box
            sx={{
              position: "absolute",
              right: 2,
              top: 0,
              p: 3,
            }}
          >
            <BaseButton
              sx={{
                all: "unset",
                fontFamily: "inherit",
                color: "white",
                cursor: "pointer",
                bg: "transparent",
                ":hover": {
                  color: "grays.light",
                  // @ts-ignore
                  WebkitTextFillColor: theme => theme.colors.grays.light,
                },
              }}
              onClick={dismissBanner}
            >
              <VisuallyHidden>Close</VisuallyHidden>
              <Close width={25} height={25} aria-hidden="true" focusable="false" />
            </BaseButton>
          </Box>
        </Box>
      )}
    </React.Fragment>
  );
};
