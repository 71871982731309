import * as React from "react";

import { ReactComponent as CircleCheck } from "@sparkademy/app-common/materials/icons/CircleCheck.svg";
import "react-step-progress-bar/styles.css";

// @ts-ignore
import { ProgressBar as PB, Step } from "react-step-progress-bar";

export const ProgressBar: React.FC<{ level: number; numLevels: number; style?: Object }> = ({
  level,
  numLevels,
  style,
}) => {
  const currentStep: React.CSSProperties = {
    color: "#EDEDF4",
    width: "32px",
    height: "32px",
    fontSize: " 16px",
    backgroundColor: "#3C3C3B",
    fontWeight: "bold",
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };

  const waitingStep: React.CSSProperties = {
    width: "32px",
    height: "32px",
    fontSize: " 16px",
    fontWeight: "bold",
    backgroundColor: "#ededf4",
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "4px solid black",
  };

  const steps = (max: number) => {
    const final: Step[] = [];
    for (let i = 1; i <= max; i++) {
      final.push(
        <Step key={i}>
          {() => (level > i ? <CircleCheck /> : level === i ? currentIcon(i) : waitingIcon(i))}
        </Step>
      );
    }
    return final;
  };

  const currentIcon = (index: number) => <p style={currentStep}> {index} </p>;

  const waitingIcon = (index: number) => <p style={waitingStep}> {index} </p>;

  return (
    <PB
      percent={((level - 1) / (numLevels - 1)) * 100}
      style={
        style
          ? style
          : {
              margin: "0 auto 250px",
            }
      }
      height="2px"
      filledBackground="#17E5A0"
    >
      {steps(numLevels)}
    </PB>
  );
};
