/** @jsx jsx */
import { jsx, Box, SxStyleProp } from "theme-ui";
import * as React from "react";

export const ForwardIcon: React.FC<{ sxOverride?: SxStyleProp; color: string }> = ({
  sxOverride,
  color,
}) => (
  <Box sx={{ mr: 3, display: "flex", ...sxOverride }}>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 11H17.17L13.59 7.41L15 6L21 12L15 18L13.59 16.59L17.17 13H3V11Z"
        fill={color}
      />
      <mask id="mask0_6850_30161" maskUnits="userSpaceOnUse" x="3" y="6" width="18" height="12">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3 11H17.17L13.59 7.41L15 6L21 12L15 18L13.59 16.59L17.17 13H3V11Z"
          fill={color}
        />
      </mask>
      <g mask="url(#mask0_6850_30161)">
        <path d="M24 0H0V24H24V0Z" fill={color} />
      </g>
    </svg>
  </Box>
);
