/** @jsx jsx */
import { jsx } from "theme-ui";
import * as React from "react";
import { ReactComponent as Logo } from "@sparkademy/app-common/materials/logos/logo-sideways.svg";
import { Link } from "react-router-dom";
import { Container } from "@sparkademy/app-common/elements/Container";
import { TrackingService } from "../services/tracking-service";

export const Header: React.FC<{ logoIsClickable: boolean }> = ({ logoIsClickable }) => {
  return (
    <header
      sx={{
        boxShadow: "0px 4px 5px rgba(0, 0, 0, 0.05)",
        bg: "new.primary.white",
        zIndex: 1,
      }}
    >
      <Container
        sx={{
          minHeight: 96,
          display: "flex",
          alignItems: "center",
          justifyContent: ["center", "center", "space-between"],
        }}
      >
        {logoIsClickable ? (
          <Link
            to="/"
            aria-label="homepage"
            onClick={() => {
              TrackingService.headerLogoClick();
            }}
            sx={{
              display: "block",
              svg: {
                display: "block",
              },
            }}
          >
            <Logo height={21} aria-hidden="true" focusable="false" />
          </Link>
        ) : (
          <Logo height={21} aria-hidden="true" focusable="false" />
        )}
      </Container>
    </header>
  );
};
