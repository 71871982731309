import { gql } from "@apollo/client";
import { HasuraSkillWorkshopSessionTeamResponse } from "@sparkademy/app-common/models/hasura";
import { SkillWorkshopSessionTeam } from "@sparkademy/app-common/models/workshops";
import { useHasuraQuery } from "./useHasuraQuery";

export function useWorkshopSessionTeamData(sessionId: number, userId: string) {
  const { loading, error, data } = useHasuraQuery(
    gql`
      query ($user_id: uuid!, $session_id: Int!) {
        skill_workshop_session_teams(
          where: {
            skill_workshop_session_team_users: { user_id: { _eq: $user_id } }
            session_id: { _eq: $session_id }
          }
        ) {
          id
          skill_workshop_resources {
            id
            type
            link_text
            link_url
          }
        }
      }
    `,
    {
      session_id: sessionId,
      user_id: userId,
    }
  );

  return {
    team: data ? parseDirectusResponse(data) : null,
    loading,
    error,
  };
}

function parseDirectusResponse(
  data: HasuraSkillWorkshopSessionTeamResponse
): SkillWorkshopSessionTeam | null {
  if (data.skill_workshop_session_teams.length > 0) {
    const team = data.skill_workshop_session_teams[0];
    return {
      id: team.id,
      resources: team.skill_workshop_resources.map(resource => ({
        id: resource.id,
        type: resource.type,
        linkText: resource.link_text,
        linkUrl: resource.link_url,
      })),
    };
  }

  return null;
}
