/** @jsx jsx */
import { jsx, Box, SxStyleProp } from "theme-ui";
import * as React from "react";

export const HomeIcon: React.FC<{ sxOverride?: SxStyleProp; color: string }> = ({ sxOverride, color }) => (
  <Box sx={{ mr: 3, display: "flex", ...sxOverride }}>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M22 22L2 22" stroke={color} strokeWidth="1.5" strokeLinecap="round" />
      <path
        d="M2.5519 9.39858C2.21974 9.64606 2.1511 10.1159 2.39858 10.4481C2.64606 10.7803 3.11595 10.8489 3.4481 10.6014L2.5519 9.39858ZM10.3133 4.55103L10.7614 5.15245V5.15245L10.3133 4.55103ZM20.5519 10.6014C20.8841 10.8489 21.3539 10.7803 21.6014 10.4481C21.8489 10.1159 21.7803 9.64606 21.4481 9.39858L20.5519 10.6014ZM13.6867 4.55103L13.2386 5.15245V5.15245L13.6867 4.55103ZM3.4481 10.6014L10.7614 5.15245L9.86522 3.94961L2.5519 9.39858L3.4481 10.6014ZM21.4481 9.39858L14.1348 3.94961L13.2386 5.15245L20.5519 10.6014L21.4481 9.39858ZM10.7614 5.15245C11.4816 4.61585 12.5184 4.61585 13.2386 5.15245L14.1348 3.94961C12.8828 3.0168 11.1172 3.0168 9.86522 3.94961L10.7614 5.15245Z"
        fill={color}
      />
      <path
        d="M15.25 6.18182C15.25 6.59603 15.5858 6.93182 16 6.93182C16.4142 6.93182 16.75 6.59603 16.75 6.18182H15.25ZM19.25 9C19.25 9.41421 19.5858 9.75 20 9.75C20.4142 9.75 20.75 9.41421 20.75 9H19.25ZM16.5714 6.75H19.4286V5.25H16.5714V6.75ZM19.25 6.18182V9H20.75V6.18182H19.25ZM19.4286 6.75C19.4759 6.75 19.5176 6.7531 19.551 6.75759C19.5674 6.75981 19.5806 6.76219 19.5904 6.7643C19.5953 6.76534 19.599 6.76625 19.6016 6.76693C19.6042 6.76761 19.6054 6.76799 19.6052 6.76795C19.6051 6.76791 19.6032 6.7673 19.5997 6.76596C19.5963 6.76462 19.5905 6.76228 19.5828 6.75868C19.5693 6.75229 19.5403 6.73752 19.5042 6.71039C19.4521 6.67122 19.25 6.50034 19.25 6.18182H20.75C20.75 5.81308 20.5159 5.59438 20.406 5.5117C20.2833 5.4194 20.1533 5.36824 20.06 5.33856C19.8648 5.27645 19.6417 5.25 19.4286 5.25V6.75ZM16.75 6.18182C16.75 6.50035 16.5479 6.67122 16.4958 6.71039C16.4597 6.73752 16.4307 6.75229 16.4172 6.75868C16.4095 6.76228 16.4037 6.76462 16.4003 6.76596C16.3968 6.76731 16.3949 6.76791 16.3948 6.76795C16.3946 6.76799 16.3958 6.76761 16.3984 6.76693C16.401 6.76625 16.4047 6.76534 16.4096 6.7643C16.4194 6.76219 16.4326 6.75981 16.449 6.75759C16.4824 6.7531 16.5241 6.75 16.5714 6.75V5.25C16.3583 5.25 16.1352 5.27645 15.94 5.33856C15.8467 5.36824 15.7167 5.4194 15.594 5.5117C15.4841 5.59438 15.25 5.81308 15.25 6.18182H16.75Z"
        fill={color}
      />
      <path d="M3 22V10" stroke={color} strokeWidth="1.5" strokeLinecap="round" />
      <path
        d="M20.25 22C20.25 22.4142 20.5858 22.75 21 22.75C21.4142 22.75 21.75 22.4142 21.75 22H20.25ZM21.75 10C21.75 9.58579 21.4142 9.25 21 9.25C20.5858 9.25 20.25 9.58579 20.25 10H21.75ZM21.75 22V10H20.25V22H21.75Z"
        fill={color}
      />
      <path
        d="M15 22V17C15 15.5858 15 14.8787 14.5607 14.4393C14.1213 14 13.4142 14 12 14C10.5858 14 9.87868 14 9.43934 14.4393C9 14.8787 9 15.5858 9 17V22"
        stroke={color}
        strokeWidth="1.5"
      />
    </svg>
  </Box>
);
