import * as React from "react";
import { Box, Text } from "theme-ui";

export const TeamDisplayBox: React.FC<{ label: string; text: string }> = ({ label, text }) => (
  <Box
    className="team-display-box"
    sx={{
      display: "flex",
      p: "16px",
      border: "0.5px solid #BFBFBF",
      flexDirection: "column",
      alignItems: "center",
      width: "395px",
      borderRadius: "5px",
    }}
  >
    <Text sx={{ fontWeight: 700, mb: "8px" }}>{label}</Text>
    <Text>{text}</Text>
  </Box>
);
