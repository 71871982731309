import GAME_ROUNDS_DATA, { GameData } from "../data/gambling/rounds";

export const TIME_OUT_MS = 3000;

export type FourSquares =
  | {
      type: "start";
    }
  | {
      type: "collect_extra_info";
      state: FourSquaresState;
    }
  | {
      type: "intro";
      step: number;
      state: FourSquaresState;
    }
  | {
      type: "picking";
      state: FourSquaresState;
    }
  | {
      type: "jitter-between-rounds";
      state: FourSquaresState;
    }
  | {
      type: "picked-wait";
      choice: number;
      state: FourSquaresState;
    }
  | {
      type: "picked";
      choice: number;
      state: FourSquaresState;
    }
  | {
      type: "timeout";
      state: FourSquaresState;
    }
  | {
      type: "round-before";
      previousRoundPoints?: number;
      nextRound: number;
      state: FourSquaresState;
    }
  | {
      type: "finished";
      state: FourSquaresState;
      total: number;
      step: number;
    };

export type FourSquaresTrialPayload = {
  best: number;
  time: number;
  choice: number;
  payoffs: [number, number, number, number];
  delay_ms: number;
};

export type FourSquaresRoundPayload = {
  index: number;
  total: number;
  trials: FourSquaresTrialPayload[];
  payoffs: [number, number, number, number];
  timed_out: number;
  max_trials: number;
  optimal_total: number;
  total_delay_ms: number;
};

export type FourSquaresState = {
  current: number;
  finished: boolean;
  rounds: Array<{
    maxTrials: number;
    payoffs: [number, number, number, number];
    timeOuts: number;
    total: number;
    optimalTotal: number;
    totalDelayMs: number;
    trials: any[];
  }>;
};

export class FourSquaresGame {
  rounds: FourSquaresGameRound[];
  roundIndex: number;
  gameData: GameData;

  constructor(trials: number[], gameData?: GameData) {
    this.roundIndex = 0;
    this.rounds = [];
    this.gameData = gameData || GAME_ROUNDS_DATA;

    for (let i = 0; i < trials.length; i++) {
      const maxTrials = trials[i];
      this.rounds.push(new FourSquaresGameRound(this.gameData, i, maxTrials));
    }
  }

  get round(): FourSquaresGameRound | null {
    if (this.roundIndex >= this.rounds.length) {
      return null;
    }
    return this.rounds[this.roundIndex];
  }

  next() {
    if (this.round?.finished) {
      this.roundIndex += 1;
    } else {
      this.round?.update();
    }
  }

  choose(delayMs: number, slot: number | null): boolean {
    if (!this.round) {
      return false;
    }

    this.round.choose(delayMs, slot);
    this.next();
    return true;
  }
}

export class FourSquaresGameRound {
  index: number;
  trials: any[];
  maxTrials: number;
  timeOuts: number; // count of timed-out choices
  totalDelayMs: number; // total ms deciding (includes timed-out)
  total: number; // total points won
  optimalTotal: number; // optimal total points won
  payoffs: [number, number, number, number];
  gameData: GameData;

  constructor(gameData: GameData, index: number = 0, maxTrials: number = 0) {
    this.index = index;
    this.trials = [];
    this.maxTrials = maxTrials;
    this.timeOuts = 0;
    this.totalDelayMs = 0;
    this.total = 0;
    this.optimalTotal = 0;
    this.payoffs = [0, 0, 0, 0];
    this.gameData = gameData;
    this.update();
  }

  get finished(): boolean {
    return this.trials.length >= this.maxTrials;
  }

  get highestPayoff(): number {
    let highest = 0;

    for (let i = 0; i < this.payoffs.length; i++) {
      const val = this.payoffs[i];
      if (val > highest) {
        highest = val;
      }
    }
    return highest;
  }

  update() {
    if (this.index >= this.gameData.length) {
      throw new Error("more rounds specified than configured in game data");
    }

    const values = this.gameData[this.index];
    if (values && this.maxTrials && this.maxTrials > values.length) {
      throw new Error(
        `maxTrials=${this.maxTrials} for round ${this.index} is larger than configured in game data`
      );
    }

    this.payoffs = values[this.trials.length];
  }

  choose(delayMs: number, slot: number | null) {
    delayMs = delayMs || TIME_OUT_MS;

    const best = this.highestPayoff;
    this.optimalTotal += best;
    this.totalDelayMs += delayMs;

    if (slot !== null) {
      this.total += this.payoffs[slot];
    } else {
      this.timeOuts += 1;
    }

    this.trials.push({
      payoffs: this.payoffs,
      choice: slot,
      time: new Date().getTime() / 1000,
      best,
      delayMs,
    });
  }
}
