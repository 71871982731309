/** @jsx jsx */
import React from "react";
import { jsx, Box } from "theme-ui";
import { Document, Page, pdfjs } from "react-pdf";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import { toTitleCase } from "../utils/string-formatters";
import { Loader } from "@sparkademy/app-common/components/Loader";
import { ButtonAnchor } from "@sparkademy/app-common/elements/Button";
import { useContentContext } from "@sparkademy/app-common/contexts/content-context";
import { Layout } from "../components/Layout";
import { LevelInfoBlock, ModuleInfoBlock } from "../components/CertificateInfoBlock";
import { ModuleInfo } from "@sparkademy/app-common/models/module";
import { LevelInfo } from "@sparkademy/app-common/models/level";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const ContentBox: React.FC = ({ children }) => (
  <div
    sx={{
      margin: "auto",
      width: [window.innerWidth, 800],
      mb: 10,
      pr: [5, 0],
      pl: [5, 0],
    }}
  >
    {children}
  </div>
);

export const Certificate: React.FC = () => {
  const [loading, setLoading] = React.useState(true);
  const { userId, levelOrModuleId } = useParams<{ userId: string; levelOrModuleId: string }>();
  const isLevelCert = ["core", "advanced", "master"].includes(levelOrModuleId);

  const baseUrl = "//sparkademy-program-certificates.s3.eu-central-1.amazonaws.com";
  const fileUrl = `${baseUrl}/${userId}-${levelOrModuleId}-completed.pdf`;
  const pageWidth = window.innerWidth < 800 ? window.innerWidth : 800;
  const { levels, modules } = useContentContext();

  if (levels.length === 0 || modules.length === 0) {
    return (
      <Layout>
        <Loader />
      </Layout>
    );
  }

  let levelInfo: LevelInfo | undefined;
  let moduleInfo: ModuleInfo | undefined;
  let certTitle = "Blank Title";

  if (isLevelCert) {
    const levelDisplayName = toTitleCase(levelOrModuleId);
    certTitle = `${levelDisplayName} Catalyst Certification Program`;
    levelInfo = levels.find(l => l.id === levelOrModuleId);
  } else {
    moduleInfo = modules.find(m => m.id === levelOrModuleId);
    if (moduleInfo) {
      levelInfo = levels.find(l => l.id === moduleInfo?.level);
      certTitle = moduleInfo.name;
    }
  }

  return (
    <div>
      <Helmet>
        <title>{certTitle}</title>
        <meta property="og:title" content={certTitle} />
      </Helmet>

      {loading && (
        <Box
          sx={{
            width: "100vw",
            height: "100vh",
            position: "absolute",
            zIndex: 9,
            bg: "background",
          }}
        >
          <Loader />
        </Box>
      )}

      <div sx={{ margin: "auto", width: [window.innerWidth, 800], mb: 10 }}>
        <Document
          file={fileUrl}
          onLoadSuccess={() => setLoading(false)}
          onLoadError={() => setLoading(false)}
        >
          <Page pageNumber={1} width={pageWidth} />
        </Document>
      </div>

      <ContentBox>
        <h2>About Sparkademy</h2>
        <p>
          Sparkademy is the digital-first institution bringing personalized and scalable learning to
          the corporate world. At Sparkademy, large organisations benefit from the latest
          developments in neuroscience and education research to elevate their employees’ skills and
          futureproof their business.
        </p>
        <ButtonAnchor
          href={"https://www.sparkademy.com/"}
          target="_blank"
          rel="noopened noreferrer"
        >
          Learn More
        </ButtonAnchor>
      </ContentBox>

      {isLevelCert ? (
        <LevelInfoBlock levelInfo={levelInfo} />
      ) : (
        <ModuleInfoBlock moduleInfo={moduleInfo} />
      )}

      <ContentBox>
        <ButtonAnchor href={`/${levelInfo?.id}-syllabus`} target="_blank" rel="noopened noreferrer">
          See Syllabus
        </ButtonAnchor>
      </ContentBox>
    </div>
  );
};
