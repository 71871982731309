import * as React from "react";
import { User } from "../models/user";
import { Redirect, Route, RouteProps, useLocation, useHistory } from "react-router-dom";

export interface ProtectedRouteProps extends RouteProps {
  isAuthenticated: boolean;
  loaded: boolean;
  authenticationPath: string;
  redirectPathOnAuthentication: string;
  currentUser?: User;
}

export const ProtectedRoute: React.FC<ProtectedRouteProps> = props => {
  const currentLocation = useLocation();
  const history = useHistory();

  React.useEffect(() => {
    history.listen(() => {
      window.Intercom &&
        window.Intercom("update", { last_request_at: new Date().getTime() / 1000 });
    });
  }, [history]);

  if (!props.loaded) {
    return null;
  }

  if (props.isAuthenticated && props.currentUser) {
    return (
      <React.Fragment>
        <Route {...props} />
      </React.Fragment>
    );
  }

  return (
    <Redirect
      to={{
        pathname: props.authenticationPath,
        search:
          currentLocation.pathname !== "/"
            ? `?next=${encodeURIComponent(currentLocation.pathname + currentLocation.search)}`
            : "",
      }}
    />
  );
};
