// eslint-disable-next-line
import * as React from "react";
// @ts-ignore
import { mdx } from "mdx.macro";
import { Tool } from "../../models/tool";
// eslint-disable-next-line
import { CloudflareEmbedPlayer } from "../../components/CloudflareEmbedPlayer";

// m1 assets
import m1EmpathizeThumbImage from "../method-kit/m1/front/01-empathize.png";
import m1EmpathizeBackImage from "../method-kit/m1/back/01-empathize.svg";
import m1EmpathyMapThumbImage from "../method-kit/m1/front/02-empathy-map.png";
import m1EmpathyMapBackImage from "../method-kit/m1/back/02-empathy-map.svg";
import m1StakeholderMapThumbImage from "../method-kit/m1/front/18-stakeholder-map.png";
import m1StakeholderMapImage1 from "../method-kit/m1/back/18-stakeholder-map-1.svg";
// eslint-disable-next-line
import m1StakeholderMapImage2 from "../method-kit/m1/back/18-stakeholder-map-2.svg";
import m1AssumptionMappingThumbImage from "../method-kit/m1/front/03-assumption-maping.png";
// eslint-disable-next-line
import m1AssumptionMappingBackImage from "../method-kit/m1/back/03-assumption-maping.svg";
import m1ObserveThumbImage from "../method-kit/m1/front/04-observe.png";
import m1ObserveBackImage from "../method-kit/m1/back/04-observe.svg";
import m1IdentifyNonCustomersThumbImage from "../method-kit/m1/front/05-identify-non-customers.png";
import m1IdentifyNonCustomersBackImage from "../method-kit/m1/back/05-identify-non-customers.svg";
import m1ExtremeUsersThumbImage from "../method-kit/m1/front/06-extreme-users.png";
import m1ExtremeUsersBackImage from "../method-kit/m1/back/06-extreme-users.svg";
import m1ParticipateThumbImage from "../method-kit/m1/front/07-participate.png";
import m1ParticipateBackImage from "../method-kit/m1/back/07-participate.svg";
import m1AnalogueSituationsThumbImage from "../method-kit/m1/front/08-analogue-situations.png";
import m1AnalogueSituationsBackImage from "../method-kit/m1/back/08-analogue-situations.svg";
import m1FramingQuestionsThumbImage from "../method-kit/m1/front/09-framing-questions.png";
import m1FramingQuestionsBackImage from "../method-kit/m1/back/09-framing-questions.svg";
import m1InterviewPrinciplesThumbImage from "../method-kit/m1/front/11-interview-principles.png";
import m1InterviewPrinciplesBackImage from "../method-kit/m1/back/11-interview-principles.svg";
import m1ConductAnInterviewThumbImage from "../method-kit/m1/front/12-conduct-an-interview.png";
import m1ConductAnInterviewBackImage from "../method-kit/m1/back/12-conduct-an-interview.svg";
import m1UnpackingPrinciplesThumbImage from "../method-kit/m1/front/13-unpacking-principles.png";
import m1UnpackingPrinciplesBackImage from "../method-kit/m1/back/13-unpacking-principles.svg";
import m1LearningNotesThumbImage from "../method-kit/m1/front/14-learning-notes.png";
import m1LearningNotesBackImage from "../method-kit/m1/back/14-learning-notes.svg";
import m1UnpackingThumbImage from "../method-kit/m1/front/15-unpacking.png";
import m1UnpackingBackImage from "../method-kit/m1/back/15-unpacking.svg";
import m1ClusteringThumbImage from "../method-kit/m1/front/16-clustering.png";
import m1ClusteringBackImage from "../method-kit/m1/back/16-clustering.svg";
import m1CraftingInsightsThumbImage from "../method-kit/m1/front/17-crafting-insights.png";
import m1CraftingInsightsBackImage from "../method-kit/m1/back/17-crafting-insights.svg";
import m1PrepareAnInterviewImage from "../method-kit/m1/back/19-prepare-an-interview.svg";
// @ts-ignore
import m1AssumptionMappingTemplate from "../method-kit/m1/assumption-mapping-template.pptx";
// @ts-ignore
import m1EmpathyMapTemplate from "../method-kit/m1/empathy-map-template.pdf";
// @ts-ignore
import m1ObserveTemplate from "../method-kit/m1/observe-template.pdf";
//  @ts-ignore
import m1StakeholderMapTemplate from "../method-kit/m1/stakeholder-map-template.pptx";
//  @ts-ignore
import m1PrepareAnInterviewGuideline from "../method-kit/m1/prepare-an-interview-guideline.pdf";
//  @ts-ignore
import m1ConductAnInterviewTemplate from "../method-kit/m1/conduct-an-interview-template.pdf";

export const tools: Tool[] = [
  {
    phase: "empathize",
    moduleId: "m1",
    name: "Empathize",
    slug: "m1-empathize",
    resources: ["paper", "marker"],
    content: mdx`
Empathizing with your user enables you to see your innovation process from a new perspective. It puts the users at the core and uncovers their latent needs and motivations to understand their behavior.

Emphatic research is not interested in facts (such as the percentage change in sales). On the contrary, it focuses on the underlying motivations and thoughts (why someone buys a chocolate bar instead of an apple).

Observation, participation, and interviewing help us gain a deeper appreciation and understanding of the people's emotional and physical needs, and how they see things and interact with their environment. Based on what people say, do, think, and feel, you will gain a profound picture of your user aspirations and needs.


  `,
    benefits: [
      'Uncovers the "Why" behind actions and behaviors',
      "Helps to design a product or service meeting the users desires",
    ],
    thumbImage: m1EmpathizeThumbImage,
    backImage: m1EmpathizeBackImage,
  },
  {
    phase: "empathize",
    moduleId: "m1",
    name: "Empathy Map",
    slug: "m1-empathy-map",
    resources: ["template or blank paper", "marker"],
    content: mdx`
The empathy map is a framework that helps you structure the knowledge you gather during qualitative research through observation, participation, and interviews.

It is split into four different quadrants, two of which are based on observable data points and two are based on inferred data points. As you can directly observe and measure what people say and do, your job with qualitative research is to infer what they think and feel.

To do so,  create informed assumptions about what they think and feel based on your interpretation of the observed data. When working with assumptions, keep in mind that they are inferred until validated or invalidated.
  `,
    benefits: [
      "Gain a new perspective",
      "Uncover the users' needs and preferences that the user may not be aware of themselves",
      "Helps to understand the verbal response of the user more efficiently",
      "Enables us to make informed assumption about what people think and feel",
    ],
    thumbImage: m1EmpathyMapThumbImage,
    backImage: m1EmpathyMapBackImage,
    template: m1EmpathyMapTemplate,
  },
  {
    phase: "empathize",
    moduleId: "m1",
    name: "Stakeholder Map",
    slug: "m1-stakeholder-map",
    resources: ["whiteboard", "Post-Its", "markers"],
    content: mdx`
The Stakeholder Map is a technique used to identify relevant individuals or groups and sort them according to your project's key criteria. This enables you to define action steps on how to interact with each of the stakeholders.

### Why should you use the Stakeholder Map?

- Captures a holistic picture of the main actors involved
- Focus on satisfying the most important stakeholders
- Improves decision-making process
- Helps risk assessment
- Structures existing knowledge

### What are stakeholders, and why is the stakeholder map relevant?

Stakeholders are all the interested parties in a project. They can have decision power over the project or be influenced by and use the outcomes. In the picture on the top, you can see some examples.

Because they are affected, stakeholders will likely have individual desires and expectations regarding your project. For you, this means managing potentially opposing opinions and priorities but minimize unnecessary involvement. The stakeholder map will help you assess how their interests should be prioritized and addressed to make better-informed strategies and decisions.

### How can you identify and map relevant stakeholders for your project?

Follow these next steps to assess your project's stakeholders and evaluate which ones to focus on.

##### 1. Identify your stakeholders

Use the following categories to identify your stakeholders. The image at the top of this method card can serve as an example.

1. **Internal:** immediately influential and directly involved with the project
2. **Connected:** those who have an economic or contractual relationship
3. **External:** Stakeholders outside of your organization that are interested or affected by your decisions' consequences and outcomes

Don't worry if there is some overlap between those groups. The goal of this first step is just to help you identify your stakeholders.

It might also be that you already have a Stakeholder Map in a different format ready for your project. Ensure that you also include customers and users and move on to the next step using those stakeholders.

##### 2. Interest and power level

Rate the level of interest and power your stakeholders have for all of your identified stakeholders. To decide on those two levels, you can try to answer the following questions:

1. How much will my project affect the stakeholder? - (Interest)
2. How much can this stakeholder affect my project? - (Power)

##### 3. Stakeholder Mapping

Map those stakeholders according to their level of interest and power regarding your project in a two-by-two matrix.

<img src={m1StakeholderMapImage2} alt="Stakeholder Mapping Image" />

##### 4. Develop an action plan

Use this matrix to decide how to involve each stakeholder and assess how their interests should be prioritized.

Stakeholders on the far top-right of the matrix should be strongly involved and collaborated with closely. On the other extreme, those in the bottom-left should be involved using minimal effort, for example, by being notified of significant events and milestones.

Use those insights to develop an action plan, for example scheduling a monthly meeting, defining which stakeholders to involve in which decisions, how to involve people with high interest and low power, etc.
`,
    benefits: [],
    thumbImage: m1StakeholderMapThumbImage,
    backImage: m1StakeholderMapImage1,
    template: m1StakeholderMapTemplate,
  },
  {
    phase: "empathize",
    moduleId: "m1",
    name: "Assumption Mapping",
    slug: "m1-assumption-mapping",
    resources: ["whiteboard", "Post-Its", "markers"],
    content: mdx`
### Why should you use Assumption Mapping?

- Reveals the discrepancy between what is true and what you think is true
- Identifies the factors that have to be validated and invalidated for your project to work
- Provides a framework to focus on the most relevant assumptions in your further research
- It helps understand and align the perspectives of all the members of your team

### What is an assumption?

Assumptions are all relevant factors connected to your project that are considered certain or true without a doubt. It is important to identify all those at the start of an innovation project. Challenging assumptions is key for successful projects, and they must be validated throughout the process by (in-depth user) research. The validated or invalidated assumptions then serve as a foundation to generate new ideas and to innovate.

Assumptions can be about the problem space, about the customer or user, or something else related to your project. To check whether your assumptions are phrased well, consider going through this checklist:

- The assumption is a full sentence.
- The assumption is clear and concise, it is understandable for another team member or a random reader.
- The assumption has a level of uncertainty. Facts are no assumptions!
- The assumption describes a relationship, e.g. between a user and what he likes or between an action and an object
- The assumption is relevant to your project.

It is often recommended to think about the desirability, feasibility, and viability of your project. In an early stage project and for user discovery, the focus should clearly be on the desirability. Questions you can ask to come up with assumptions are for example:

- Who is the target customer or user?
- What problem do our customers want to solve?
- How do our customers solve this problem today?
- Why can’t our customers solve this problem today?
- What is the outcome our customers want to achieve?
- Why will our customers stop using their current solution?

Watch the video below to see an example how Assumption Mapping can work in a project!

<CloudflareEmbedPlayer videoId="ec79e7a81948ab2812f0bd85f1c44493" />

### How can you map and identify assumptions?

To identify and map your team's assumptions, you can follow the following steps:

##### 1. Get started

Invite your team members for a short activity of assumption mapping. Teams of 5-7 people work great. At the beginning of the activity, repeat the definition and characteristics of an assumption.

##### 2. Define relevant themes for your challenge.

These themes should always include desirability, but can additionally be Socio-environmental impact, Financing, Distribution, Branding, Functionality, Emotional aspects, Quality, etc.

##### 3. Identify your assumptions

Give each team member post its and a pen. Then, take 5 min in which every team member should identify and write down as many assumptions as possible for each theme. Your goal should be to come up at least with 10 assumptions per person, more is always great.

##### 4. Share your assumptions

Take 10 minutes to share all assumptions that emerged with the whole team.

##### 5. Map your resulting assumptions

in a 2x2 matrix with the following dimensions: How easy/hard it is to validate them and how relevant these assumptions are to your project.

<img src={m1AssumptionMappingBackImage} alt="Assumption Mapping Image" />

### What’s up next?

**Validate your relevant assumptions.**

Relevant assumptions that are easy to find out are on the top left of your matrix. They can be validated by some literature analysis, a simple online survey, checking legal or technical guidelines and requirements or simply asking an expert who knows more about the facts behind your assumption.

Assumptions that are relevant and hard to find out can be found on the top right of your matrix. They will probably need a more complex approach.
You can [observe](/method-kit/m1-observe), [participate](/method-kit/m1-participate) with, or [interview users](/method-kit/m1-prepare-an-interview), industry experts, or other people related to your project. But keep in mind, only users can reveal to you what they really want or need!

So you might want to have a look on who your users types are!
`,
    benefits: [],
    thumbImage: m1AssumptionMappingThumbImage,
    template: m1AssumptionMappingTemplate,
  },
  {
    phase: "empathize",
    moduleId: "m1",
    name: "Observe",
    slug: "m1-observe",
    resources: ["notepad", "pen or pencil", "camera"],
    content: mdx`
Observations are an efficient method of carrying out user research. The key advantage is that you can observe what people actually do, rather than what they say they do. Thus, It involves studying the spontaneous behavior of individuals. Various methods of observation are used, but in every case, researchers observe real users, employees, customers, and anyone who will either be using the solution at the end or that might provide inspiration in the design process.

To observe like a pro, keep a beginner’s mindset. Find out what people care about and note down unexpected behavior, look for patterns and body language. Gather a substantial amount of data and develop the best possible understanding of the problems, which underlie the development or the enhancement of your service or product. These insights are helpful as a preparation for interviews where you can follow up on what you observed.
`,
    benefits: [
      "Supports user engagement in existing situation",
      "Reveals surprising insight",
      "Inexpensive to carry out",
    ],
    thumbImage: m1ObserveThumbImage,
    backImage: m1ObserveBackImage,
    template: m1ObserveTemplate,
  },
  {
    phase: "empathize",
    moduleId: "m1",
    name: "Identify Non-Customers",
    slug: "m1-identify-non-customers",
    resources: ["whiteboard/paper", "makers"],
    content: mdx`
An alternative approach to competing for existing customers is to grow your industry by identifying a demand that exists beyond your industry to the so-called non-customers. These are buyers that do not buy into your industry, product, or service yet, but could be convinced in the future.

Non-customers should be identified and categorized as follows:

1. “Soon-to-be”, non-customers: Not yet active in your industry but can be reached easily.
2. “Refusing”, non-customers: These customers purposefully opt out of certain industries.
3. “Unexplored”, non-customers: Customers that are part of overlook opportunities.

Identifying different non-customer tiers, their behaviors, and drivers, allows you to develop a strategy on how to get them interested in what you have to offer.
`,
    benefits: [
      "Generates a better understanding of current non-customers",
      "Helps identify new opportunity spaces to bring non-customers on board",
    ],
    thumbImage: m1IdentifyNonCustomersThumbImage,
    backImage: m1IdentifyNonCustomersBackImage,
  },
  {
    phase: "empathize",
    moduleId: "m1",
    name: "Extreme Users",
    slug: "m1-extreme-users",
    resources: ["research topic", "target audience"],
    content: mdx`
Designers engage with people to understand their needs and gain insights about their lives. They also draw inspiration from their workarounds and frameworks, as they are more notable. Looking at extreme users will therefore often lead to surprising, inspirational, and actionable insights that help you to pull out meaningful needs that may not emerge when engaging with the middle of the bell curve. However, these needs are often also needs of the wider population.

To identify your extreme users, you can look at your challenge through different lenses, for example:

- Demographics (age, gender or ethnicity)
- Behaviors (experts vs. newbies or out of the ordinary)
- Attitude toward tech (early adopter vs laggard)

By looking through those lenses, you can stretch the edges of it to imagine and identify specific groups of people. Those are your extreme users.
    `,
    benefits: [
      "Identify pain-points and workarounds more easily than with an average user",
      "Sparks inspiration and encourages wild ideas for the solution",
      "Exposing you to use cases, hacks, and design opportunities that you’d never have imagined",
    ],
    thumbImage: m1ExtremeUsersThumbImage,
    backImage: m1ExtremeUsersBackImage,
  },
  {
    phase: "empathize",
    moduleId: "m1",
    name: "Participate",
    slug: "m1-participate",
    resources: [
      "notepad",
      "pen or pencil",
      "camera",
      "material/props dependent on project (optional)",
    ],
    content: mdx`
The best way for qualitative researchers to understand a group, a subculture, a setting, or a way of life is to immerse into that world. Performing the same tasks the users would usually do, helps to gain a new, in-depth understanding of their experience and the environment the users operate in.

Changing your perspective and stepping into their situation helps to identify the most pressing pain points and opportunity areas. You can design a participation experience by limiting yourself and taking away any aspect of the users’ experience or ability that you might take for granted.

When following a participation approach, strive to influence the research participants around you as little as possible while being as close as needed. They often consciously or unconsciously behave differently when they feel part of a research environment – even worse when they are photographed.
    `,
    benefits: [
      "Helps to understand how users engage in existing situations",
      "Builds empathy",
      "Challenges assumptions",
      "Broadens the individual's perspective",
      "Reveals the unexpected",
    ],
    thumbImage: m1ParticipateThumbImage,
    backImage: m1ParticipateBackImage,
  },
  {
    phase: "empathize",
    moduleId: "m1",
    name: "Analogue Situations",
    slug: "m1-analogue-situations",
    resources: ["Fitting analogies from various contexts", "whiteboard", "Post-It’s", "markers"],
    content: mdx`
Analog situations are examples that are situated beyond the scope of your own industry. It is not about learning from competitors that are doing something similar, but about finding similarities in completely different contexts to get new inspiration. A hospital team, for example, could learn how to improve collective performance under pressure by looking at Formula 1 race teams. Even historic events or stories can be used as analogies, often in the form of a “How can we be the …(e.g., Uber) of our industry? “. In its core, finding analogies allows you to transfer insights from another original setting to your situation and learn from it.

**Tip:** When looking for an analogous situation, try to identify elements of your product or service that you can isolate. Then, search for cross-industry learning by thinking solely about experiencing this element outside the context of your own challenge.
    `,
    benefits: [
      "It establishes a more creative thinking behaviour",
      "Identifies best practices and ideas that might be applied to own situation",
      "Provides new insights beyond the scope of your industry",
    ],
    thumbImage: m1AnalogueSituationsThumbImage,
    backImage: m1AnalogueSituationsBackImage,
  },
  {
    phase: "empathize",
    moduleId: "m1",
    name: "Framing Great Questions",
    slug: "m1-framing-questions",
    resources: ["notepad", "pen or pencil"],
    content: mdx`
### Why should you use this method?

- Helps setting up the right set of questions to guide you through your interview
- Helps you connect with your interviewee and evoke deep personal stories
- Help you to uncover hidden values, motivations and contradictions of your interviewee
- Gain a deep understanding of your interviewee

### What are the characteristics of a great question?

Because the way we frame a question has huge effects on the interviewed party, framing well is key when you want to uncover your users' motivations based on what they say. The objective of doing an interview is to gain a deep understanding of people's needs and aspirations.

You can frame a good question by following this checklist:

- The question is simple (=your interviewee can answer it)
- Only asking one question at a time
- Aiming for a long answer
- The question does not bias or influence the interviewee to answer in a certain way
- You avoid brainstorming for solutions
- You do not ask about the interviewee's surroundings
  - “What did  your co-worker say to you about this topic? “ instead of “What does your co-worker think about this topic?”
  - “What do you expect from the product?” instead of “What qualities do people in your field expect from the product?”
- Use a tone and words that fit the context and your relation to the interviewed party

As a good rule of thumb: The weaker the relationship, the more polite and formal you should be.

Stick to these suggestions above and your interviews will generate even better insights and results! Don’t be afraid to ask embarrassing questions as long as you frame your questions properly and ask them at the right time.

### How can you frame questions?

During an interview, you can ask your user different types of questions. In particular, when you are looking to reveal more about an answer given by your interviewee, you might want to choose one kind of question over another. The five kinds of interview questions below can help you uncover your users' needs and behaviors.

In an interview, aim for a mix of different questions.

- **Indirect questions**: Are a politer way to ask for an answer and often include "If".
  - If you would be in this situation, how would you …?

- **Probing and specifying questions**: Encourage deeper thoughts about a specific topic.
  - How did you react to this?

- **Follow-up questions**: Get the interviewee to elaborate on his/her answer.
  - Could you elaborate on that?
  - You mentioned, … can you tell me more about that?
  - What happened then?
  - Can you describe what it felt like?
  - Can you give an example?
  - Is there anything else you would like to share about__?

- **Interpreting questions**: Validate your interpretation of an earlier statement/ answer given by the interviewee.
  - Did I understand correctly that you mean X with your statement?

- **Direct questions**: Are phrased as directly related to the topic of the research. They are best to be left at the end of the interview, to avoid influencing the direction of the conversation.
  - Are you happy with the tool? What do you think of the colors?

### Avoid common mistakes 

When you frame your questions and create an interview guide, take care you avoid common mistakes:

- Don’t include too many closed-ended and short-answer questions. If you use them, solely aim to include them during the warm-up of the interview. 
- Avoid questions that ask basic information, the information you could have easily found out through other means, like an online search. 
- Avoid asking irrelevant questions that don’t contribute to the overarching questions.
- Ask for specific and concrete examples, instead of mere summaries.
- Keep a neutral and non-biased tone of voice, so the participant does not feel less competent, stupid, or not valued. 
- Avoid leading questions that include an assumption that might not be true, or which influences the participants' answer.

### What’s up next?

Use this tips and tricks to formulate an [interview guideline](/method-kit/m1-prepare-an-interview) and [conduct a successful interview](/method-kit/m1-conduct-an-interview).
    `,
    benefits: [],
    thumbImage: m1FramingQuestionsThumbImage,
    backImage: m1FramingQuestionsBackImage,
  },
  {
    phase: "empathize",
    moduleId: "m1",
    name: "Prepare An Interview",
    slug: "m1-prepare-an-interview",
    resources: ["Example Guideline"],
    template: m1PrepareAnInterviewGuideline,
    content: mdx`
### Why should you use this method?

- Preparation is essential to get the most insights out of your interviews
- Overarching questions help to keep your goal in mind during an interview
- Prepare questions that make sense for you and your interviewee
- Gain a deeper understanding of your interviewee
- A guideline helps you cover all relevant topics and guides you through the interview

### What is an interview guideline?

An interview guideline is a set of questions and additional preparation which will guide you through an interview later on.

One of the most effective types of interviews are semi-structured interviews. These have a certain structure and logic, which makes it easier for you to get the answers you need. However, it is also flexible enough to allow your interview to flow naturally and give room to new and unexplored ideas.

This is why we recommend you to structure your interview guideline as a semi-structured interview. This means that you should prepare all your core questions and potential follow-up questions in advance. During the interview you will then have the possibility to use these questions to keep the conversation where you want it to be. At the same time, you can also decide spontaneously to shift the conversation towards an unexpected, but interesting, topic.

The basis for all of your prepared questions should be your overarching question.

### What is an overarching research question?

For each interview, define one or a maximum of two overarching questions. Those questions represent your research goal and allow you to stay on track during the interview. This overarching research question should not be too broad, nor too narrow.

You can use a How-might-we framework to formulate a question that includes a user and a potential need to be solved. If no need is known yet, your overarching research question should still include a specific user group and an open question to be answered.

Be prepared to rewrite and further specify this research question after you gathered insights!

### How can you prepare an interview guideline?

The following steps will guide you through the creation of an interview guideline.
You can also find an example of an <a href={m1PrepareAnInterviewGuideline} target="_blank">interview guideline for download at the top of the page</a>.

##### 0. Before you start

Did you define the user you are going to interview? You should also consider to [interview non-customers](/method-kit/m1-identify-non-customers), [extreme users](/method-kit/m1-extreme-users), or people in [analog situations](/method-kit/m1-analogue-situations) as they can give you interesting insights as well.

##### 1. Define one or two overarching questions

This question will serve as guideline for your whole interview. It allows you to stay on track and always remember the ultimate goal of your interview.

##### 2. Write an introduction script

The script helps you to create a smooth start. It ensures that you are sharing all relevant information about your research and the interview itself. Here is a checklist for a good introduction:

- Introduce yourself
- The purpose of the interview and overview of the topics
- Expected duration of the interview
- Voluntary nature of the interview: participants can quit at any time.
- Affirm that there are no right or wrong answers
- Confidentiality statement: How will their answers be used?
- Permission to make an audio recording of the interview, if possible

##### 3. The conversation starter

The conversation starter is the way to create a comfortable and trustful atmosphere and build rapport with the participant. Start with questions that ask for basic background or information.  They should be:

- Simple to answer
- Something the participant likes to talk about or can easily relate to
- Build trust by offering something of yourself as well 

##### 4. The core questions

Create 5-10 open-ended questions that are closely/strongly related or relevant to your overarching question. It allows the participants to open up and share stories.

- Ask questions that dig deep and explore emotions and needs
- Frame the questions in a simple way, avoid bias, and aim for long answers
- Cluster the questions according to themes

Have a look at the method card [“Phrase Great Questions”](/method-kit/m1-framing-questions) if you need more guidance.

##### 5. The follow-up questions

Create 2 – 3 follow-up questions per core question to evoke deeper understanding and elicit more detailed and elaborate responses to your core questions. However, if your core question is well formulated, the participant will cover the majority of the follow-up questions on their own and you won’t have to ask them.

- The question should support you in doing a follow-up on information you didn’t anticipate beforehand 
- The questions are aimed to fill holes and uncover additional interesting information

##### 6. Conclusion and wrap up

Prepare a wrap-up for the interview where you thank the participant and inform them about the next steps. Most importantly, ask if there are still open questions or if the participant has other things to tell you. 
Stay focused, as surprising content can still come up after you have rounded off your interview with the participant

### What’s up next?

Now it’s time to get started with your interview.

Continue with the method card “Conduct an Interview” to learn some best practices, the different phases during an interview, and how to document an interview.

Of course, don’t forget to print your interview guideline and take it with you to your interviews!
    `,
    benefits: [],
    thumbImage: m1PrepareAnInterviewImage,
    backImage: m1PrepareAnInterviewImage,
  },
  {
    phase: "empathize",
    moduleId: "m1",
    name: "Interview Principles",
    slug: "m1-interview-principles",
    resources: ["whiteboard/paper", "markers"],
    content: mdx`
**20:80**<br />Even if the interviewee pauses before answering, do not help him/her by suggesting an answer. They should always cover at least 80 percent of the conversation. Use the remaining 20 percent to ask questions and make them feel at ease.

**Utilize silence**<br />Do not be afraid of silence, if you allow there to be silence, a person will have time to reflect on what they have just said and explain something deeper. Let the interviewee fill the silence!

**Look for inconsistencies & non-verbal clues**<br />Very often what people say, do, think, and feel are very different. Gracefully probe these contradictions and pay attention to body language and emotions.

**Stay on the same path**<br />Respond to what your interviewee offers and follow up to go deeper. Use simple queries to get him/ her to reveal more details, "Oh, why do you say that?"

**Ask "why?" 5 times**<br />Even if you think you already know the answer, probe for the deeper meaning. Behave like a curious child; insist on asking “why” until you reach a deeper level of understanding and identify the real underlying needs.
`,
    benefits: [
      "Generates a better understanding of current non-customers",
      "Helps identify new opportunity spaces to bring non-customers on board",
    ],
    thumbImage: m1InterviewPrinciplesThumbImage,
    backImage: m1InterviewPrinciplesBackImage,
  },
  {
    phase: "empathize",
    moduleId: "m1",
    name: "Conduct An Interview",
    slug: "m1-conduct-an-interview",
    resources: [
      "Printed Interview Guideline",
      "User, Extreme User or Non-User",
      "Pen and Paper",
      "Recorder",
    ],
    content: mdx`
### Why should you use this method?

- Focuses on experiences told from the interviewee points of view
- Helps with building empathy by gaining a new perspective
- Uncovers users’ needs and behaviors
- Reveals the unexpected
- Allows for open-ended responses from participants for more in-depth information.
- Provides an opportunity so that interviewers can learn answers to questions and the reasons behind the answers.
- Helps identify new opportunity spaces to bring customers on board

### What is a semi-structured interview?

Semi-structured interviews are conducted conversationally with one interviewee/respondent at a time. It consist of a mixture between few closed-ended question at the beginning and a majority of open-ended questions. Those are accompanied by follow-up questions and why or how questions.

During the interview, additional questions can be asked spontaneously, following up with reactions and comments of the interviewee. This combination allows for the objective comparison of candidates, while also providing an opportunity to spontaneously explore topics relevant to that particular candidate.

The interview should not extend the maximum length of one hour to minimize fatigue.

### How do we document semi-structured interviews?

Documenting already starts while conducting your interview. Always conduct your interviews in pairs, thus one person can focus on asking the questions and following the conversation, while at the same time your colleague can take notes. 

- Use paper and pencil
  - Devices such as notebooks create a physical barrier. 
- Key points, short phrases and quotes
  - Write down your observations as key points, short phrases and quotes. Quotes are meaningful and allow us to create better insights.
- Non-verbal clues
  - Note down all the non-verbal clues you observe. How did the person feel while answering a certain question or telling a story?
- Improvised questions
  - As we don't stick to our interview guideline and adapt on the spot, write down the additional questions you asked. This helps you to make sense of the answers afterwards. 

There is no right or wrong how you can document interviews, therefore develop your own style which works best for you.

You can also download a <a href={m1ConductAnInterviewTemplate} target="_blank">template and example</a> for a qualitative interview transcript on top of the page.

### How to conduct an interview? What are the “Acts of Interviewing”?

To get high-quality data from interviews with your users, it’s advisable to follow a certain structure throughout your interaction to make the interviewee feel comfortable. We also call those different phases the acts of interviewing.

##### 0. Before you start

Did you carefully prepare your interview? Have a look at the Method Card [“Prepare an Interview”](/method-kit/m1-prepare-an-interview) and write your Interview Guideline.

##### 1. Introduce yourself and build rapport

Start with presenting yourself and your project in a relatable way. Build trust by offering something of yourself, and be affirmed that there are no right or wrong answers.

##### 2. Evoke stories

Evoke specific stories to learn more about your interviewee's behaviors, their needs & pains, thoughts & feelings.

##### 3. Explore emotions and needs

Dig deep to uncover what really matters to the people you interview, for example by applying the ‘5 Why's’ technique.

##### 4. Follow-up and thank-you

Wrap up the interview and thank the interviewee. Inform about next steps and ask if there are any open questions.

**Tip**: Much insightful information often emerges after the interview is over.

##### 5. Document and de-brief

After the interview take a moment to debrief with your partner. As what you heard might be different of what you partner heard, thus this can lead to very interesting discussions and new observations.

Remember: An interview that is not documented has never happened. 

### What’s up next?

[Unpack your interviews](/method-kit/m1-unpacking), write [learning notes](/method-kit/m1-learning-notes) and [craft insights](/method-kit/m1-crafting-insights) based on your findings.
`,
    benefits: [],
    thumbImage: m1ConductAnInterviewThumbImage,
    backImage: m1ConductAnInterviewBackImage,
    template: m1ConductAnInterviewTemplate,
  },
  {
    phase: "empathize",
    moduleId: "m1",
    name: "Unpacking Principles",
    slug: "m1-unpacking-principles",
    resources: ["whiteboard/paper", "markers"],
    content: mdx`
**Narrow down**<br />Recollect the key aspects from the observations, interviews, and notes that arose during your research. Concentrate only on what is relevant for your challenge.

**Identify patterns**<br />Step back and identify the behavioral trends that developed through your research. Detect relationships between stakeholders or throughout systems to clarify connections and tensions.

**Focus on stories**<br />Do not only concentrate on pure facts. Stories are memorized because of the emotional connection people make with them. Usually, people's underlying needs are best revealed through their emotions, extreme behaviors, and life experiences.

**Infer meaning**<br />Organize your information in a way that is consistent with the challenge you are trying to solve. It is time to be subjective: interpret what you saw and infer from what you observed.

**Define target**<br />Organize and filter your information to build a precise, focused point of view. Make your target user a real human: give him/her a name, write a description, and select the most pressing needs. This is who will be at the center from now onward in your Design Thinking process.
    `,
    benefits: [],
    thumbImage: m1UnpackingPrinciplesThumbImage,
    backImage: m1UnpackingPrinciplesBackImage,
  },
  {
    phase: "empathize",
    moduleId: "m1",
    name: "Learning Notes",
    slug: "m1-learning-notes",
    resources: ["whiteboard", "Post-Its", "markers", "research data"],
    content: mdx`
Learning notes are a useful tool for understanding the main takeaways of an interview, immersion, or observation. By writing down participant quotes, observations, questions, and/or factual statements within a short time-frame after the end of the interview, you enlarge your learning. Creating learning is a two-step process:

1. Go through your interviews and look for everything that is non-obvious, surprised you, reveals contradictions, or was a repeated theme.
2. Create a post-it with your finding and add an anonymous participant code

As a result, you are able to collaboratively capture research data in a clear and efficient way.

Tips for creating the learning notes are:
- Make each note understandable on its own
- Be clear and concise
- Rule of thumb is, one note per minute of an interview
    `,
    benefits: [
      "Interpretation of your research data in a comprehensive way",
      "Quick manner to visualize your main findings",
      "Collaborative method for aligning on your main takeaways",
    ],
    thumbImage: m1LearningNotesThumbImage,
    backImage: m1LearningNotesBackImage,
  },
  {
    phase: "empathize",
    moduleId: "m1",
    name: "Unpacking",
    slug: "m1-unpacking",
    resources: ["whiteboard", "Post-Its", "markers"],
    content: mdx`
Having gathered a large number of notes, photos, impressions, and quotes, it is time to deduce the data. This downloading of your findings can be done individually or as a team using various frameworks.

When a team uses a distinct unpacking method (such as; We met.., We were surprised to notice.., We wonder if this means...), sharing, clustering, and interpreting the data becomes a more fluid process.

Each member unpacks the findings of their interviews by following the structure of this framework. It is critical to pay close attention to each teammates' stories, learnings, and impressions. This is a rich and powerful way to share what has been heard.
`,
    benefits: [
      "Makes individual learning part of the collective group's knowledge",
      "Enables systematic analysis on big data sets",
      "Supports transition between research analysis",
    ],
    thumbImage: m1UnpackingThumbImage,
    backImage: m1UnpackingBackImage,
  },
  {
    phase: "empathize",
    moduleId: "m1",
    name: "Clustering",
    slug: "m1-clustering",
    resources: ["template or whiteboard", "markers"],
    content: mdx`
Cluster Analysis or Clustering is the process of grouping observations or ideas of similar kinds into smaller groups within the larger population. By identifying similarities with respect to specific concepts or dimensions, this method helps you to make sense of your data. Organize the available data into meaningful structures and identifies hidden patterns. Try to be visual and organize your ideas into clusters. For each cluster write a good summary note considering the following aspects:

- Use a different color sticky note for your summaries
- Should be interesting and a trade-off between abstraction and precision, but still capture the essence of the learning notes within it
    `,
    benefits: [
      "Facilitates comparison",
      "Helps segmentation of big data sets",
      "Helps to discover new patterns",
      "Reveal trends",
    ],
    thumbImage: m1ClusteringThumbImage,
    backImage: m1ClusteringBackImage,
  },
  {
    phase: "empathize",
    moduleId: "m1",
    name: "Crafting Insights",
    slug: "m1-crafting-insights",
    resources: [
      "whiteboard",
      "Post-Its",
      "markers",
      "your team",
      "notes and unpacked data from interviews",
      "observation, and/or participation",
    ],
    content: mdx`
### Why should you use this method?

- Uncover new discoveries that inspire and are relevant to your challenge
- Make sense of the data from your own perspective
- Share your research outcomes

### What is an insight?

##### **Insight = Observation + your Interpretation**

Insights are a succinct expression of what you have learned from your field research activities. They always offer a new perspective, even if they are not new discoveries. They are inspiring and relevant to your challenge and go beyond merely summarizing verbal information.

##### Good insights are:

- Informative
  - Come from your research (=you didn’t know it before)
  - Non-obvious, surprising

- Inspiring
  - Contain a revealing interpretation of the research
  - Do not include a solution

- Memorable
  - One-sentence statements
  - Easy to read and understand

Creating an insight means synthesizing your findings by analyzing your data and adding your interpretation to it. When interpreting data, it is important to stick to a neutral point of view and always consider the context in which the data appears. However, your insight will always be subjective and contain a new assumption regarding your challenge. Therefore, insights should be supported and validated through additional research.

Watch this short video to see how a team crafted insights from their interviews using the framework suggested in the template!

<CloudflareEmbedPlayer videoId="a66c3af9efbdf9ff22b911288db69f6f" />


### How can you craft inspiring insights?

There are various ways to frame your research results into inspiring insights that can be shared with the team or other stakeholders. The steps presented here are only one way to get there and are a mixture of unpacking learnings and formulating insights together.
Check out the template and example for download to assist you in crafting insights!

##### 1. Invite your team

Unpacking interviews, sharing learnings, and formulating insights is best done with your team. Everyone has a different perspective and might interpret your results differently.

##### 2. Collect your facts

***I was surprised to hear/observe …***

Everyone takes 10 minutes to note down on Post-its:
What did you hear or observe? What type of user/interviewee did it come from? Try to be specific to make your statements relatable for other team members by writing down quotes and specific situations.

If you already unpacked and clustered your learnings, you can also share things that several users had in common. However, don’t forget to mention the user they came from!

##### 3. Share your facts

In the following 5 minutes, each team member shares their facts with the team.

##### 4. Add your interpretation

***I wonder if that means …?***

Now it is time to add your personal interpretation of the facts. You can either write down your interpretations individually and discuss them afterward, or share and discuss them at the same time.
Don’t hold back with contradicting interpretations!

##### 5. Formulate your insights

Rephrase your interpretations into informative, inspiring, and memorable insights. Don’t worry if you are not happy with your first try. Get different ideas down first and then, with the team, agree on the best formulation.

### What’s up next?

Insights are great to inspire the team moving on and to be shared with stakeholders outside the team.

Test and validate your insights again, using different research options. If validated, use the insights to formulate an inspiring Problem Statement, which is the basis for developing a solution to your challenge (Module 2).
    `,
    benefits: [],
    thumbImage: m1CraftingInsightsThumbImage,
    backImage: m1CraftingInsightsBackImage,
  },
];
