import * as React from "react";
import { Box, Text, Button as BaseButton } from "theme-ui";
import { Radio } from "@sparkademy/app-common/elements/Radio";
import { Checkbox } from "@sparkademy/app-common/elements/Checkbox";

interface CourseRunSelectProps<T> {
  items: T[];
  getIsSelected: (item: T) => boolean;
  onSelectItem: (item: T) => void;
  itemRenderer: (item: T) => string;
  isMultiple?: boolean;
}

export const ScrollableSelectList =
  <T,>(): React.FC<CourseRunSelectProps<T>> =>
  ({
    items,
    getIsSelected,
    onSelectItem,
    itemRenderer,
    isMultiple = false,
  }: CourseRunSelectProps<T>) => {
    return (
      <Box
        sx={{
          width: "100%",
          borderTop: "1px solid #BFBFBF",
          borderBottom: "1px solid #BFBFBF",
          maxHeight: "204px",
          overflowY: "auto",
          "::-webkit-scrollbar": {
            width: "8px",
          },
          "::-webkit-scrollbar-thumb": {
            backgroundColor: "primary",
            borderRadius: "10px",
          },
          scrollbarColor: "primary",
          scrollbarWidth: "8px",
        }}
      >
        {items.map((item, idx) => (
          <Box
            key={idx}
            sx={{
              py: "9px",
              borderBottom: idx < items.length - 1 && "0.5px solid #BFBFBF",
            }}
          >
            <Box
              className="select-row"
              sx={{
                display: "flex",
                justifyContent: "space-between",
                height: "22px",
                cursor: "pointer",
                alignItems: "center",
              }}
              id="select-row"
              onClick={() => onSelectItem(item)}
            >
              {itemRenderer(item)}
              {isMultiple ? (
                <Checkbox
                  id="item-checkbox"
                  type="checkbox"
                  checked={getIsSelected(item)}
                  wrapperSx={{
                    mt: "5px",
                  }}
                />
              ) : (
                <Radio
                  id="item-radio"
                  type="checkbox"
                  checked={getIsSelected(item)}
                  wrapperSx={{
                    ml: 5,
                  }}
                  sx={{
                    height: "16px",
                  }}
                />
              )}
            </Box>
          </Box>
        ))}
      </Box>
    );
  };
