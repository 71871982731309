import * as React from "react";
import { Box, Heading } from "theme-ui";
import { Container } from "@sparkademy/app-common/elements/Container";
import { Button } from "@sparkademy/app-common/elements/Button";
import { ReactComponent as IllustrationIdea } from "@sparkademy/app-common/materials/illustrations/idea.svg";
import { ReactComponent as IllustrationPerson } from "@sparkademy/app-common/materials/illustrations/person.svg";
import { ReactComponent as IllustrationActivity } from "@sparkademy/app-common/materials/illustrations/activity.svg";
import { ReactComponent as IllustrationGears } from "@sparkademy/app-common/materials/illustrations/gears.svg";
import { FourSquares } from "../../models/four-squares";
import { Narrative } from "@sparkademy/app-common/elements/Narrative";
import useEventListener from "@use-it/event-listener";

export const GameFourSquaresIntro: React.FC<{
  gameState: FourSquares;
  updateGame: (choice: number | null) => void;
}> = ({ gameState, updateGame }) => {
  const buttonRef = React.useRef<HTMLButtonElement | null>(null);

  useEventListener("keydown", (event: KeyboardEvent) => {
    // @ts-ignore
    if (event.keyCode === 13) {
      updateGame(null);
    }
  });

  if (gameState.type !== "intro") {
    return null;
  }

  return (
    <Container
      sx={{
        textAlign: "center",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      {gameState.step === 1 ? (
        <Box
          sx={{
            width: 170,
            height: 170,
            svg: {
              width: "100%",
              height: "auto",
            },
          }}
        >
          <IllustrationIdea />
        </Box>
      ) : gameState.step === 2 ? (
        <Box
          sx={{
            width: 170,
            height: 170,
            svg: {
              width: "100%",
              height: "auto",
            },
          }}
        >
          <IllustrationActivity />
        </Box>
      ) : gameState.step === 3 ? (
        <Box
          sx={{
            width: 170,
            height: 170,
            svg: {
              width: "100%",
              height: "auto",
            },
          }}
        >
          <IllustrationGears />
        </Box>
      ) : gameState.step === 4 ? (
        <Box
          sx={{
            width: 170,
            height: 170,
            svg: {
              width: "100%",
              height: "auto",
            },
          }}
        >
          <IllustrationPerson />
        </Box>
      ) : null}
      <Heading
        as="h1"
        sx={{
          fontSize: [2, 3],
          fontWeight: "bold",
          textAlign: "center",
          mb: 10,
        }}
      >
        {gameState.step === 1
          ? "How we identify your innovator type"
          : gameState.step === 2
          ? "What’s required from you"
          : gameState.step === 3
          ? "How it works"
          : gameState.step === 4
          ? "Before you start"
          : null}
      </Heading>
      <Narrative>
        {gameState.step === 1 ? (
          "Spark Check uses scientifically backed tests to identify which type of innovator you’re most similar to."
        ) : gameState.step === 2 ? (
          "Please make sure that you will not be interrupted during the next 20 minutes, that you are in a quiet space and that you are free from distractions (like your mobile phone)."
        ) : gameState.step === 3 ? (
          <p>
            <b>Your goal</b> in the first part is to collect a maximum number of points.
            <br />
            You can collect points by clicking on one of four squares.
            <br />
            Each square is tied to different points, and the points vary from click to click.
            <br />
            You only have <b>1.5 seconds</b> to click on a square, then the next try starts. If you
            take too long to click, you will receive zero points for that try.
          </p>
        ) : gameState.step === 4 ? (
          <React.Fragment>
            <p>
              Participant results will only be used to identify the people with the most ideal
              profile to participate in the Innovation Catalyst Program. They do not reflect your
              motivation or experience related to innovation or creativity.
            </p>
            <p>Your points results will not be communicated to your company.</p>
          </React.Fragment>
        ) : null}
      </Narrative>

      <Box
        sx={{
          my: 10,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {new Array(4).fill(0).map((e, i) => (
          <Box
            key={`box-${i}`}
            sx={{
              width: "10px",
              height: "10px",
              borderRadius: "50%",
              bg: i === gameState.step ? "new.primary.purple" : "new.secondary.grey",
              mx: 2,
            }}
          />
        ))}
      </Box>

      <Button
        ref={buttonRef}
        onClick={() => {
          updateGame(null);
          buttonRef.current?.focus();
        }}
      >
        Next
      </Button>
    </Container>
  );
};
