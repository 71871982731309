import ky from "ky";

const SUBMISSION_URL = "https://api.hsforms.com/submissions";
const MARKETING_INFORMATION_SUBSCRIPTION_ID = 11667468;

export class HubspotService {
  static submitSparkCheckConsent(email: string, termsConsent: boolean, marketingConsent: boolean) {
    const PORTAL_ID = 9344831;
    const FORM_ID = "84bfb795-d3aa-4805-9eea-bc6f494bd086";

    if (
      process.env.NODE_ENV === "development" ||
      window.E2E_TESTING ||
      window.IMPERSONATE_SESSION
    ) {
      return;
    }

    const json = {
      fields: [
        {
          name: "email",
          value: email,
        },
      ],
      context: {
        pageUri: `https://${window.location.host}/assessment-part1?cohort=demo`,
        pageName: "Spark Check Lite",
      },
      legalConsentOptions: {
        consent: {
          consentToProcess: termsConsent,
          text: "I agree to allow Sparkademy to store and process my personal data.",
          communications: [
            {
              value: marketingConsent,
              subscriptionTypeId: MARKETING_INFORMATION_SUBSCRIPTION_ID,
              text: "I agree to receive marketing communications from Sparkademy.",
            },
          ],
        },
      },
    };

    ky.post(`${SUBMISSION_URL}/v3/integration/submit/${PORTAL_ID}/${FORM_ID}`, {
      json,
    });
  }
}
