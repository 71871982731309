import * as React from "react";
import { Box, Text, ThemeProvider } from "theme-ui";
import { themeNew } from "@sparkademy/app-common/materials/theme";
import { ButtonOutline } from "@sparkademy/app-common/elements/ButtonOutline";
import { ButtonGhost } from "@sparkademy/app-common/elements/ButtonGhost";
import { ReactComponent as Checklist } from "@sparkademy/app-common/materials/icons/checklist.svg";
import { Narrative } from "@sparkademy/app-common/elements/Narrative-v2";

export const TeamGuidelinesPanel: React.FC<{
  selectTeamCallback: () => void;
}> = ({ selectTeamCallback }) => (
  <ThemeProvider theme={themeNew}>
    <Box
      sx={{
        minWidth: 275,
        backgroundColor: "#F5F6F8",
        p: "32px",
        borderRadius: "5px",
        fontSize: "16px",
      }}
    >
      <Narrative sx={{ fontSize: 0 }} olistType="decimal" markerColor="primary">
        <Text
          sx={{
            pb: 5,
            color: "primary",
            borderBottom: "1px solid",
            borderColor: "new.secondary.grey",
            mb: 32,
            fontSize: "16px",
          }}
        >
          <b>This is a team exercise. Please read the guidelines below.</b>
        </Text>
        <Box sx={{ display: "flex", alignItems: "center", ml: "16px" }}>
          <Checklist height={16} aria-hidden="true" focusable="false" />
          <Text sx={{ ml: "10px" }}>
            <b>Team Guidelines:</b>
          </Text>
        </Box>
        <ol>
          <li>
            Find your team members using the{" "}
            <a href="https://community.sparkademy.com/users/sign_in?post_login_redirect=https%3A%2F%2Fcommunity.sparkademy.com">
              Community
            </a>{" "}
            and confirm them to begin the exercise.
          </li>
          <li>
            The Team Leader selects the team members and is responsible for submitting the work.
          </li>
          <li>
            Saving work <b>will overwrite previously saved content</b> from your team members.{" "}
          </li>
          <li>
            Please ensure all members are in agreement when submitting. This cannot be undone.
          </li>
        </ol>
        <Box
          sx={{
            pb: "32px",
            mt: "60px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderBottom: "1px solid",
            borderColor: "new.secondary.grey",
          }}
        >
          <ButtonOutline onClick={selectTeamCallback}>Select my team</ButtonOutline>
        </Box>
        <Text sx={{ mt: "24px" }}>
          Can't find a team or want to make changes?{" "}
          <a href="mailto:team@sparkademy.com">Contact us.</a>
        </Text>
      </Narrative>
    </Box>
  </ThemeProvider>
);

export default TeamGuidelinesPanel;
