import * as React from "react";
import {
  AlertDialogLabel,
  AlertDialogDescription,
  AlertDialogOverlay,
  AlertDialogContent,
} from "@sparkademy/app-common/elements/Alert";
import { Box, Text, Button as BaseButton } from "theme-ui";
import { Button } from "@sparkademy/app-common/elements/Button";
import { ReactComponent as Close } from "@sparkademy/app-common/materials/icons/close.svg";
import VisuallyHidden from "@reach/visually-hidden";
import { ReactComponent as IllustrationTeamWarning } from "@sparkademy/app-common/materials/illustrations/team-warning.svg";

export const TeamSaveErrorDialog: React.FC<{ onClose: () => void }> = ({ onClose }) => {
  const cancelRef = React.useRef<HTMLButtonElement>();

  return (
    <AlertDialogOverlay
      // @ts-ignore
      leastDestructiveRef={cancelRef}
    >
      <AlertDialogContent>
        <AlertDialogLabel
          style={{ height: "32px", width: "100%", position: "sticky", top: "32px" }}
        >
          <BaseButton
            sx={{
              all: "unset",
              fontFamily: "inherit",
              float: "right",
              color: "primary",
              cursor: "pointer",
              bg: "transparent",
            }}
            onClick={onClose}
          >
            <VisuallyHidden>Close</VisuallyHidden>
            <Close width={32} height={32} aria-hidden="true" focusable="false" />
          </BaseButton>
        </AlertDialogLabel>

        <Box sx={{ maxWidth: "500px" }}>
          <AlertDialogLabel></AlertDialogLabel>
          <AlertDialogDescription>
            <Box
              sx={{
                margin: "auto",
                mb: "20px",
                width: 100,
                height: 100,
                svg: {
                  width: "100%",
                  height: "auto",
                },
              }}
            >
              <IllustrationTeamWarning />
            </Box>
            <Text as="p" sx={{ textAlign: "center", fontWeight: 700, fontSize: "20px" }}>
              Unable to save
            </Text>
            <Text as="p" sx={{ textAlign: "center", fontSize: "16px", mt: "20px" }}>
              It was not possible to save your progress, copy your answers and try again later.
            </Text>
          </AlertDialogDescription>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            mt: "32px",
            mb: "16px",
          }}
        >
          <Button type="submit" onClick={onClose}>
            Close
          </Button>
        </Box>
      </AlertDialogContent>
    </AlertDialogOverlay>
  );
};
