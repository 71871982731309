/** @jsx jsx */
import { jsx, Box } from "theme-ui";
import * as React from "react";
import {
  CourseVideoSettings,
  VideoContent,
  VideoSource,
} from "@sparkademy/app-common/models/course";
import { CloudFlarePlayer } from "../CloudFlarePlayer";
import { YoutubePlayer } from "../YoutubePlayer";
import { useResponsiveValue } from "@theme-ui/match-media";
import { useSessionContext } from "@sparkademy/app-common/contexts/session-context";

const videoSettings: CourseVideoSettings[] = [
  // test token user
  { userId: "ea0477b1-e4c8-40d3-9b36-b9d733a33ae5", source: "youtube" },

  { userId: "d833e83b-ac30-4291-956a-56c72d540a7c", source: "youtube" },
  { userId: "29b1a8ba-43aa-4490-9b70-6d6465f36904", source: "youtube" },
  { userId: "3e1bba40-d29b-40eb-b738-c681e038d635", source: "youtube" },
  { userId: "0be2034e-2f3e-425a-86dd-cbf2d75e1261", source: "youtube" },
  { userId: "fb3c1051-3655-4687-a921-3227184b44e0", source: "youtube" },
  { userId: "fa0556d0-435c-4e44-91c1-b20e18e924d9", source: "youtube" },
];

const getVideoSource = (cohortId: string, userId: string): VideoSource => {
  // try to find a video setting for the user
  let videoSetting = videoSettings.find(vs => vs.userId === userId);
  if (videoSetting) {
    return videoSetting.source;
  }
  // also try a more broad setting for the cohort
  videoSetting = videoSettings.find(vs => vs.cohortId === cohortId);
  // if no setting is found, default to cloudflare
  return videoSetting ? videoSetting.source : "cloudflare";
};

export const BlockUnitVideoContent: React.FC<{
  item: VideoContent;
}> = ({ item }) => {
  const { currentUser } = useSessionContext();
  let videoSource = getVideoSource(currentUser!.data.cohort_id, currentUser!.data.id);

  const size = useResponsiveValue([
    { width: "384px", height: "216px" },
    { width: "768px", height: "432px" },
  ]);

  console.log("video source is", videoSource);
  console.log("youtubeId is", item.youtubeId);
  console.log("cloudflareId is", item.cloudflareId);

  return (
    <Box sx={{ my: "48px" }} className="player-wrapper">
      {videoSource === "youtube" && item.youtubeId ? (
        <YoutubePlayer
          title={item.title}
          embedId={item.youtubeId}
          width={size.width}
          height={size.height}
        />
      ) : (
        <CloudFlarePlayer
          title={item.title}
          src={item.cloudflareId}
          responsive={false}
          primaryColor="#4B27E0"
          height={size.height}
          width={size.width}
        />
      )}
    </Box>
  );
};
