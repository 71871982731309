import * as React from "react";
// import { User } from '@sparkademy/app-common/models/user';
import {
  Assignment,
  ExerciseStatus,
  ExerciseSubmission,
  ExerciseTeamMap,
} from "../models/assignment";
import { User } from "../models/user";
import { AssignmentService } from "../services/assignment-service";

const blankAssignment = { id: "", module_id: "", cohort_id: "", exercises: [] };

export const AssignmentContext = React.createContext<{
  fetchModuleAssignment: (
    moduleId: string,
    cohortId: string,
    user: User,
    retry: boolean | null
  ) => void;
  fetchAssignmentStatus: (moduleId: string, retry: boolean | null, user: User) => void;
  fetchAssignmentSubmissions: (cohortId: string, user: User) => void;
  fetchFlaggedSubmissions: (user: User) => void;
  fetchTeams: (moduleId: string, user: User, userIdOverride?: string) => void;
  assignment: Assignment;
  teams: ExerciseTeamMap;
  assignmentStatus: ExerciseStatus[];
  setAssignmentStatus: (exerciseStatuses: ExerciseStatus[]) => void;
  assignmentSubmissions: ExerciseSubmission[];
  loading: boolean;
}>({
  fetchModuleAssignment: () => null,
  fetchTeams: () => null,
  fetchAssignmentStatus: () => null,
  fetchAssignmentSubmissions: () => null,
  fetchFlaggedSubmissions: () => null,
  assignment: blankAssignment,
  teams: {},
  assignmentStatus: [],
  setAssignmentStatus: () => null,
  assignmentSubmissions: [],
  loading: false,
});

export const useAssignmentContext = () => React.useContext(AssignmentContext);

export const AssignmentContextProvider: React.FC = props => {
  const [loading, setLoading] = React.useState(false);
  const [assignment, setAssignment] = React.useState<Assignment>(blankAssignment);
  const [teams, setTeams] = React.useState<ExerciseTeamMap>({});
  const [assignmentStatus, setAssignmentStatus] = React.useState<ExerciseStatus[]>([]);
  const [assignmentSubmissions, setAssignmentSubmissions] = React.useState<ExerciseSubmission[]>(
    []
  );

  const fetchModuleAssignment = React.useCallback(
    (moduleId: string, cohortId: string, user: User, retry: boolean | null = false) => {
      if ((assignment.module_id === moduleId && assignment.cohort_id === cohortId) || loading) {
        return;
      }

      setLoading(true);
      AssignmentService.getModuleAssignment(moduleId, cohortId, user, retry)
        .then(res => {
          // set the cohort here so the early return above work when it should
          // usually PA's have a blank cohort id so it serves all cohorts
          res.cohort_id = cohortId;
          setAssignment(res);
          setLoading(false);
        })
        .catch(err => {
          console.error(err);
          // set an empty assignment to avoid remaking this very same request
          setAssignment({ id: "", module_id: moduleId, cohort_id: cohortId, exercises: [] });
          setLoading(false);
        });
    },
    [assignment.module_id, loading]
  );

  const fetchTeams = React.useCallback((moduleId: string, user: User) => {
    setLoading(true);
    AssignmentService.getTeams(moduleId, user)
      .then(res => {
        const teamsMap = res.reduce((acc, cur) => {
          acc[cur.exercise_id] = cur;
          return acc;
        }, {} as ExerciseTeamMap);
        setTeams(teamsMap);
        setLoading(false);
      })
      .catch(err => {
        console.error(err);
      });
  }, []);

  const fetchAssignmentStatus = React.useCallback(
    (moduleId: string, retry: boolean | null, user: User) => {
      setLoading(true);
      AssignmentService.getUserAssignmentStatus(moduleId, user, retry)
        .then(res => {
          setAssignmentStatus(res);
          setLoading(false);
        })
        .catch(err => {
          console.error(err);
          setLoading(false);
        });
    },
    []
  );

  const fetchAssignmentSubmissions = React.useCallback((cohortId: string, user: User) => {
    setLoading(true);
    AssignmentService.getAssignmentSubmissions(cohortId, user)
      .then(res => {
        setAssignmentSubmissions(res);
        setLoading(false);
      })
      .catch(err => {
        console.error(err);
        setLoading(false);
      });
  }, []);

  const fetchFlaggedSubmissions = React.useCallback((user: User) => {
    setLoading(true);
    AssignmentService.getFlaggedSubmissions(user)
      .then(res => {
        setAssignmentSubmissions(res);
        setLoading(false);
      })
      .catch(err => {
        console.error(err);
        setLoading(false);
      });
  }, []);

  return (
    <AssignmentContext.Provider
      value={{
        fetchModuleAssignment,
        fetchAssignmentStatus,
        fetchAssignmentSubmissions,
        fetchFlaggedSubmissions,
        fetchTeams,
        assignment,
        teams,
        assignmentStatus,
        setAssignmentStatus,
        assignmentSubmissions,
        loading,
      }}
    >
      {props.children}
    </AssignmentContext.Provider>
  );
};
