import { ButtonLink } from "@sparkademy/app-common/elements/Button";
import { ButtonOutlineLink } from "@sparkademy/app-common/elements/ButtonOutline";
import { Container } from "@sparkademy/app-common/elements/Container";
import { Narrative } from "@sparkademy/app-common/elements/Narrative";
import { ReactComponent as Confetti } from "@sparkademy/app-common/materials/illustrations/confetti.svg";
import * as React from "react";
import { useParams } from "react-router-dom";
import { Box, Heading } from "theme-ui";
import { LayoutHeader } from "../components/Layout";
import { courseAtom } from "../stores/course";
import { useAtom } from "jotai";

export const LessonComplete: React.FC<{}> = () => {
  const { lessonId } = useParams<{
    lessonId: string;
  }>();
  const [course] = useAtom(courseAtom);
  const lesson = course?.lessons.find(l => l.id === lessonId);

  if (!course || !lesson) {
    return null;
  }

  const courseLink = "/course/" + course.id;

  const lessonIndex = course.lessons.findIndex(l => l.id === lessonId);
  const isLastLesson = course.lessons.length - 1 === lessonIndex;
  const nextLesson = course.lessons[lessonIndex + 1];

  return (
    <LayoutHeader sx={{ backgroundColor: "white" }}>
      <Container
        sx={{
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          backgroundColor: "white",
        }}
      >
        <Box
          sx={{
            width: 170,
            height: 170,
            svg: {
              width: "100%",
              height: "auto",
            },
            mb: "21px",
            mt: ["25px", "50px"],
          }}
        >
          <Confetti />
        </Box>
        <Heading
          as="h1"
          sx={{
            fontSize: [2, 3],
            fontWeight: "bold",
            textAlign: "center",
          }}
        >
          Congratulations!
        </Heading>
        <Narrative sx={{ mt: "24px" }}>
          <p sx={{ fontSize: "20px", lineHeight: "28px" }}>
            You have finished <b>{lesson.title}</b>.
            <br />
            {isLastLesson ? (
              <p sx={{ mb: "50px" }}>This is the last lesson! Congratulations!</p>
            ) : (
              <>
                Next: <b>{nextLesson.title}</b> {/* No time Commitment for lessons*/}
              </>
            )}
          </p>
        </Narrative>

        {!isLastLesson ? (
          <ButtonLink
            sx={{
              mt: ["32px", "57px"],
              mb: "16px",
              width: "348px",
              display: "flex",
              justifyContent: "center",
            }}
            to={
              "/course/" +
              course.id +
              "/lesson/" +
              nextLesson.id +
              "/block/" +
              nextLesson.blocks[0].id +
              "/unit/" +
              nextLesson.blocks[0].units[0].id
            }
          >
            Next
          </ButtonLink>
        ) : (
          <Box sx={{ mb: "50px" }} />
        )}

        <ButtonOutlineLink sx={{ width: "348px", mx: "auto", mb: "24px" }} to={courseLink}>
          Back to Course Dashboard
        </ButtonOutlineLink>
      </Container>
    </LayoutHeader>
  );
};
