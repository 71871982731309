import * as React from "react";
import { Box } from "theme-ui";
import { WorkshopSessionListItem } from "./WorkshopSessionListItem";
import { useSessionContext } from "@sparkademy/app-common/contexts/session-context";
import { useWorkshopEnrollmentData } from "../../hooks/useWorkshopEnrollmentData";
import { useAtom } from "jotai";
import { dateStateAtom } from "../../stores/current-date";

type WorkshopSession = {
  id: string;
  schedules: {
    startAt: Date;
    endAt: Date;
  }[];
};
const columnProps = {
  py: 6,
  display: "flex",
  borderBottom: "0.5px solid",
  borderBottomColor: "new.secondary.grey",
  alignItems: "center",
};
export const WorkshopSessionList: React.FC<{
  workshopId: number;
  sessions: WorkshopSession[];
}> = ({ workshopId, sessions }) => {
  const { currentUser } = useSessionContext();
  const { enrollments, loading } = useWorkshopEnrollmentData(currentUser!.data.id);

  const [currentDate] = useAtom(dateStateAtom);
  const sessionsToRender = sessions.filter(
    s => s.schedules[0].startAt > currentDate || isEnrolled(enrollments, workshopId, s.id)
  );

  return (
    <Box
      sx={{
        mt: 8,
        borderTop: "1px solid",
        borderTopColor: "new.secondary.grey",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          fontWeight: "bold",
          ...columnProps,
        }}
      >
        <Box sx={{ width: "25%", pl: 10 }}>Number</Box>
        <Box sx={{ width: "20%" }}>Date</Box>
        <Box sx={{ width: "25%" }}>Time</Box>
        <Box sx={{ width: "30%" }}></Box>
      </Box>
      {loading
        ? null // Super weird! Doesn't work without this.
        : sessionsToRender.map((session: WorkshopSession, index: number) => (
            <WorkshopSessionListItem
              workshopId={workshopId}
              session={session}
              index={index}
              enrolled={isEnrolled(enrollments, workshopId, session.id)}
              key={session.id}
            />
          ))}
    </Box>
  );
};

function isEnrolled(enrollments: any, workshopId: number, sessionId: string) {
  return Array.isArray(enrollments[workshopId]) && enrollments[workshopId].includes(sessionId);
}
