import * as React from "react";
import { useSessionContext } from "@sparkademy/app-common/contexts/session-context";
import { LayoutSideMenu } from "../components/Layout";
import { ContainerSmall } from "@sparkademy/app-common/elements/Container";
import { Heading, Box, ThemeProvider } from "theme-ui";
import { Loader } from "@sparkademy/app-common/components/Loader";
import { themeNew } from "@sparkademy/app-common/materials/theme";
import { List, ListItem } from "@sparkademy/app-common/elements/List";
import { CardWorkshop } from "../components/Workshop/CardWorkshop";
import { useWorkshopsData } from "../hooks/useBackendData";

export const WorkshopList: React.FC = () => {
  const { currentUser } = useSessionContext();
  const isDevelopmentEnv = process.env.NODE_ENV === "development";
  const { data: workshops, loading } = useWorkshopsData(currentUser!);

  if (loading) {
    return (
      <Box
        sx={{
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          position: "fixed",
          zIndex: 9,
          bg: "background",
        }}
      >
        <Loader />
      </Box>
    );
  }

  return (
    <ThemeProvider theme={themeNew}>
      <LayoutSideMenu
        sx={{
          bg: "new.bg",
        }}
      >
        {/* Workshop Title */}
        <ContainerSmall
          sx={{
            // color: "new.primary.white",
            height: 100,
            mt: 79,
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "space-between",
            px: 10,
          }}
        >
          <Heading
            as="h1"
            sx={{
              fontSize: 4,
              fontWeight: "bold",
            }}
          >
            Workshops
          </Heading>
        </ContainerSmall>

        <ContainerSmall
          sx={{
            pb: 7,
            px: 10,
          }}
        >
          <Box
            sx={{
              bg: "new.primary.white",
              fontSize: ["18px", "20px"],
              borderRadius: 10,
              px: 10,
              pb: 8,
              pt: 3,

              "p": {
                mb: "32px",
                maxWidth: "65ch",
                lineHeight: 1.4,
              },
              ul: {
                margin: 0,
                pl: "32px",
                listStylePosition: "outside",
              },
              "ul li": {
                pl: "16px",
              },
              "ul li::marker": {
                content: '">"',
                fontWeight: "bold",
              },
            }}
          >
            <p>
              Sign up for these workshops (2-3h) after completing the Yellow Belt certification, and
              you will get to practice what you've learned as an innovator by applying a case study
              alongside other catalysts.
            </p>
            <p>Here's what you need to know:</p>
            <ul>
              <li>Yellow Belt certification is required</li>
              <li>Workshops can be completed in any order</li>
              <li>
                Discuss cases and learnings with other innovators (active participation is required)
              </li>
              <li>Join to practice your Yellow Belt skills or to work towards the Green Belt</li>
            </ul>
          </Box>

          <Box sx={{ mt: 3 }} />

          <List id="workshop-list">
            {workshops
              .filter(workshop => isDevelopmentEnv || workshop.hidden === false)
              .map((workshop, _index) => {
                return (
                  <ListItem key={workshop.id}>
                    <CardWorkshop workshop={workshop} />
                  </ListItem>
                );
              })}
          </List>
          <Box sx={{ mt: 10 }} />
          <Box
            as="hr"
            sx={{
              border: 0,
              height: "1px",
              borderBottom: theme => `2px solid ${theme.colors.new.secondary.violet}`,
              width: "70%",
              marginLeft: "auto",
              marginRight: "auto",
              mb: 13,
            }}
          />
        </ContainerSmall>
      </LayoutSideMenu>
    </ThemeProvider>
  );
};
