import * as React from "react";
import { Heading, Box, Text, Link } from "theme-ui";
import { dateStateAtom } from "../../stores/current-date";

import { HTMLContentWrapper } from "./HTMLContentWrapper";
import { ButtonGhost, ButtonGhostAnchor } from "@sparkademy/app-common/elements/ButtonGhost";
import { dayFormatter, timeFormatter } from "@sparkademy/app-common/utils/date-formatters";
import { useAtom } from "jotai";
import {
  SkillWorkshopResource,
  SkillWorkshopSession,
  SkillWorkshopSessionSchedule,
} from "@sparkademy/app-common/models/workshops";
import { addHours } from "@sparkademy/app-common/utils/date";
import { WorkshopCancelSignupDialog } from "./WorkshopCancelSignupDialog";
import { unenrollUserFromWorkshopSession } from "../../services/http-api-service";
import { useUpdateAtom } from "jotai/utils";
import { showErrorToastAtom } from "@sparkademy/app-common/stores/ui";
import { LoaderWithLabel } from "@sparkademy/app-common/components/Loader";
import { useSessionContext } from "@sparkademy/app-common/contexts/session-context";
import { useHistory } from "react-router-dom";
import { TrackingService } from "../../services/tracking-service";

type Props = {
  session: SkillWorkshopSession;
  schedule: SkillWorkshopSessionSchedule;
  isMultiSession: boolean;
  index: number;
  resources: SkillWorkshopResource[] | undefined;
};

export const CardWorkshopSessionOverview: React.FC<Props> = ({
  session,
  schedule,
  isMultiSession,
  index,
  resources,
}) => {
  const [showCancelDialog, setShowCancelDialog] = React.useState(false);
  const [currentDate, updateCurrentDate] = useAtom(dateStateAtom);

  const { currentUser } = useSessionContext();
  const [isLoading, setIsLoading] = React.useState(false);
  const showErrorToast = useUpdateAtom(showErrorToastAtom);
  const history = useHistory();

  const collab_boards = resources?.filter(element => element.type === "collab_board");
  const meeting = resources?.find(element => element.type === "meeting");

  const courseUrl = `/course/${schedule.courseId}`;

  const callUnenrollment = () => {
    setIsLoading(true);
    unenrollUserFromWorkshopSession(session.id, currentUser!)
      .then(() => {
        setIsLoading(false);
      })
      .catch(() => showErrorToast("An error occurred, try again later."))
      .finally(() => {
        setIsLoading(false);
        history.push("/workshops");
      });
  };

  React.useEffect(() => {
    const isUpcoming = schedule.startAt > currentDate;
    if (!isUpcoming) {
      return;
    }
    // update current date atom every minute so the UI can update
    // and eventually open the workshop links
    const interval = setInterval(() => {
      updateCurrentDate(new Date());
    }, 1000 * 60);

    return () => clearInterval(interval);
  }, []);

  if (isLoading) {
    return (
      <Box
        sx={{
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          position: "fixed",
          zIndex: 9,
          bg: "background",
        }}
      >
        <LoaderWithLabel label="Just a few extra seconds to get you ready..." />
      </Box>
    );
  }

  return (
    <Box
      id="workshop-session-card"
      sx={{
        bg: "new.primary.white",
        borderRadius: 10,
        p: 8,
        mt: 8,
        width: "90%",
      }}
    >
      {showCancelDialog && (
        <WorkshopCancelSignupDialog
          onClose={() => {
            setShowCancelDialog(false);
          }}
          onCancel={() => {
            callUnenrollment();
          }}
        />
      )}
      <Box
        as="header"
        sx={{
          display: "flex",
          alignItems: "center",
          mb: 8,
        }}
      >
        <Heading
          as="h2"
          sx={{
            fontSize: 2,
            fontWeight: "bold",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          Workshop{isMultiSession ? ": Part " + (index + 1) : null}
        </Heading>
      </Box>
      <Box
        as="article"
        sx={{
          bg: "new.primary.white",
          borderRadius: 10,
          maxWidth: 820,
          // p: 8,
        }}
      >
        <Text sx={{ fontWeight: 700, mb: 3 }}>Time</Text>
        <Box sx={{ display: "flex" }}>
          <Text sx={{ mr: 3 }}>
            {dayFormatter.format(schedule.startAt)} {timeFormatter.format(schedule.startAt)} -{" "}
            {timeFormatter.format(schedule.endAt)}{" "}
          </Text>

          {schedule.startAt < addHours(currentDate, 0.25) ? (
            <Box
              sx={{
                display: "flex",
                borderLeft: "0.5px solid lightgray",
                pl: 3,
              }}
            >
              {/* <Text sx={{ color: "new.grays.lockedGrey", mr: 3 }}>|</Text> */}
              {schedule.endAt < currentDate ? (
                <Text sx={{ color: "new.primary.green" }}> Completed</Text>
              ) : (
                <Text> In Progress</Text>
              )}
            </Box>
          ) : (
            <Box
              sx={{
                display: "flex",
                borderLeft: "0.5px solid lightgray",
                pl: 3,
              }}
            >
              <Text> Upcoming</Text>
            </Box>
          )}
        </Box>

        <HTMLContentWrapper html={schedule.description} />

        {/* <WorkshopSessionInfoTable schedule={schedule} meeting={meeting} /> */}
        {schedule.startAt < addHours(currentDate, 0.25) ? (
          <Box>
            <Box id="meeting-link-container" sx={{ mb: 8 }}>
              <Text sx={{ fontWeight: 700, mt: 7, mb: 3 }}>1. Begin by opening the meeting</Text>
              {meeting?.linkUrl ? (
                <ButtonGhostAnchor
                  href={meeting?.linkUrl}
                  sx={{ p: 0, fontWeight: 400 }}
                  target="_blank"
                  onClick={() =>
                    TrackingService.WorkshopSessionJoined(
                      session.workshop.id,
                      session.workshop.title,
                      session.id,
                      session.title,
                      timeFormatter.format(schedule.startAt),
                      isMultiSession ? ": Part " + (index + 1) : "Single Part"
                    )
                  }
                >
                  🗣️ {meeting?.linkText}
                </ButtonGhostAnchor>
              ) : (
                <Text sx={{ mt: 5 }}>
                  ⚠️ Meeting link not available yet, try{" "}
                  <Link href="" sx={{ textDecoration: "none", color: "new.primary.purple" }}>
                    reloading the page.
                  </Link>
                </Text>
              )}
            </Box>

            <Box id="guided-learning-container">
              <Text sx={{ fontWeight: 700, mb: 3, mt: 7 }}>
                2. Watch the first video in the guided learning
              </Text>
              <ButtonGhostAnchor
                sx={{ p: 0, my: 0, fontWeight: 400 }}
                target="_blank"
                href={courseUrl}
              >
                🎦 Click here to open guided learning
              </ButtonGhostAnchor>
            </Box>

            <Box id="collab-boards-container">
              <Text sx={{ fontWeight: 700, mb: 3, mt: 7 }}>
                3. Use the tools below as you work through the guided learning
              </Text>
              {!!collab_boards?.length ? (
                collab_boards?.concat(schedule.resources).map(resource => {
                  return (
                    <ButtonGhostAnchor
                      sx={{ p: 0, mb: 0, fontWeight: 400 }}
                      href={resource.linkUrl}
                      target="_blank"
                    >
                      📌 {resource.linkText}
                    </ButtonGhostAnchor>
                  );
                })
              ) : (
                <Text sx={{ mt: 5 }}>
                  ⚠️ Mural link not available yet, try{" "}
                  <Link href="" sx={{ textDecoration: "none", color: "new.primary.purple" }}>
                    reloading the page.
                  </Link>
                </Text>
              )}
            </Box>
          </Box>
        ) : (
          <Box sx={{ mb: 8 }}>
            <Box id="meeting-link-container">
              <Text sx={{ fontWeight: 700, mt: 7, mb: 3 }}>1. Begin by opening the meeting</Text>
              <Text sx={{ color: "new.grays.lockedGrey", mb: 8, mt: 5 }}>
                🗣️ Opens on {dayFormatter.format(schedule.startAt)},{" "}
                {timeFormatter.format(schedule.startAt)}
              </Text>
            </Box>

            <Box id="guided-learning-container">
              <Text sx={{ fontWeight: 700, mb: 3 }}>
                2. Watch the first video in the guided learning
              </Text>
              <Text sx={{ color: "new.grays.lockedGrey", mb: 8, mt: 5 }}>
                🎦 Opens on {dayFormatter.format(schedule.startAt)},{" "}
                {timeFormatter.format(schedule.startAt)}
              </Text>
            </Box>

            <Box id="collab-boards-container">
              <Text sx={{ fontWeight: 700, mb: 3 }}>
                3. Use the tools below as you work through the guided learning
              </Text>
              <Text sx={{ color: "new.grays.lockedGrey", mt: 5 }}>
                📌 Tool available on {dayFormatter.format(schedule.startAt)},{" "}
                {timeFormatter.format(schedule.startAt)}
              </Text>
            </Box>
          </Box>
        )}
      </Box>
      <Text sx={{ mt: 8 }}>
        Need any help with this workshop?{" "}
        <Link
          href="mailto:team@sparkademy.com"
          sx={{ textDecoration: "none", color: "new.primary.purple" }}
        >
          Contact us
        </Link>
      </Text>
      <Text>
        Won't be able to attend?
        <ButtonGhost
          sx={{ pl: 2, color: "new.primary.purple", fontWeight: 400 }}
          onClick={() => {
            setShowCancelDialog(true);
          }}
        >
          Cancel your registration.
        </ButtonGhost>
      </Text>
    </Box>
  );
};
