import { gql, useQuery } from "@apollo/client";
import { CourseRun } from "@sparkademy/app-common/models/course";
import { DirecutsCourseRunResponse } from "@sparkademy/app-common/models/directus";

export function useAvailableCoursesData(company: string) {
  const { loading, error, data } = useQuery(
    gql`
      query getAvailableCourses {
        course_runs {
          id
          company {
            id
            name
          }
          course {
            id
            title
            module
          }
          schedule {
            start_on
            end_on
            max_enrollments
          }
        }
      }
    `,
    {
      variables: {
        "COMPANY_NAME": company,
      },
    }
  );

  return {
    courseRuns: data ? parseDirectusCoursesResponse(data) : [],
    loading,
    error,
  };
}

function parseDirectusCoursesResponse(data: DirecutsCourseRunResponse): CourseRun[] {
  return data.course_runs.flatMap(course_run => {
    return {
      id: parseInt(course_run.id),
      company: course_run.company
        ? {
            id: course_run.company.id,
            name: course_run.company.name,
          }
        : null,
      schedule: {
        startOn: new Date(course_run.schedule.start_on),
        endOn: new Date(course_run.schedule.end_on),
        maxEnrollments: course_run.schedule.max_enrollments,
      },
      course: {
        id: course_run.course.id, //TODO: Waiting to remove the array index -> CMS dependent
        title: course_run.course.title,
        module: course_run.course.module,
      },
    };
  });
}

// "course_runs": [
//       {
//         "id": "2",
//         "company": null,
//         "course": [
//           {
//             "courses_id": {
//               "id": "68",
//               "title": "User Discovery and Needfinding",
//               "module": "m1"
//             }
//           }
//         ],
//         "schedule": {
//           "start_on": "2022-10-01",
//           "end_on": "2022-10-31",
//           "max_enrollments": 50
//         }
//       },
//       {
//         "id": "3",
//         "company": null,
//         "course": [
//           {
//             "courses_id": {
//               "id": "134",
//               "title": "Problem Solving and Idea Evaluation",
//               "module": "m2"
//             }
//           }
//         ],
//         "schedule": {
//           "start_on": "2022-11-01",
//           "end_on": "2022-11-30",
//           "max_enrollments": 25
//         }
//       },
//       {
//         "id": "4",
//         "company": null,
//         "course": [
//           {
//             "courses_id": {
//               "id": "167",
//               "title": "Prototyping, Testing, & Experimentation",
//               "module": "m3"
//             }
//           }
//         ],
//         "schedule": {
//           "start_on": "2022-11-01",
//           "end_on": "2022-11-30",
//           "max_enrollments": null
//         }
//       },
//       {
//         "id": "5",
//         "company": null,
//         "course": [
//           {
//             "courses_id": {
//               "id": "10",
//               "title": "Team Dynamics & Leadership",
//               "module": "m4"
//             }
//           }
//         ],
//         "schedule": {
//           "start_on": "2022-11-01",
//           "end_on": "2022-11-30",
//           "max_enrollments": null
//         }
//       },
//       {
//         "id": "6",
//         "company": {
//           "id": "2",
//           "name": "CARGILL"
//         },
//         "course": [
//           {
//             "courses_id": {
//               "id": "68",
//               "title": "User Discovery and Needfinding",
//               "module": "m1"
//             }
//           }
//         ],
//         "schedule": {
//           "start_on": "2022-10-31",
//           "end_on": "2022-11-30",
//           "max_enrollments": 35
//         }
//       }
//     ]
