import * as React from "react";
import { Stream, StreamProps, StreamPlayerApi } from "@cloudflare/stream-react";
import { TrackingService } from "../services/tracking-service";
import { useSessionContext } from "@sparkademy/app-common/contexts/session-context";

interface Props extends StreamProps {
  title: string;
}

export const CloudFlarePlayer: React.FC<Props> = props => {
  const { currentUser } = useSessionContext();
  const streamRef = React.useRef<StreamPlayerApi | undefined>();
  const getArgs: () => [string, string, string, number] = () => {
    return [currentUser!.data.id, props.src, props.title, streamRef.current?.currentTime!];
  };

  // Need to refactor arguments
  const hooks = {
    onPlay: () => TrackingService.videoPlay(...getArgs()),
    onPause: () => TrackingService.videoStop(...getArgs()),
    onEnded: () => TrackingService.videoEnded(...getArgs()),
    onRateChange: () => {
      if (streamRef.current?.currentTime !== 0) {
        //@ts-ignore
        TrackingService.videoSpeedChange(...getArgs(), streamRef.current?.playbackRate);
      }
    },
    onSeeking: () => {
      // 0.1 is to avoid jitter at the beginning of the video. only Occurs sometimes
      // Values of currentTime when this happens are 0.02 to at start and 0.08 at end.
      if (streamRef.current?.currentTime! > 0.1) TrackingService.videoSeekStart(...getArgs());
    },
    onSeeked: () => {
      if (streamRef.current?.currentTime! > 0.1) TrackingService.videoSeekEnd(...getArgs());
    },
    onError: () => TrackingService.videoError(...getArgs()),
    onAbort: () => TrackingService.videoAborted(...getArgs()),
  };

  return <Stream {...props} {...hooks} streamRef={streamRef} controls />;
};
