import * as React from "react";
import { Level } from "@sparkademy/app-common/models/level";
import { Heading, Box } from "theme-ui";
import { uiCopy } from "../data/ui-copy";
import { ButtonLink } from "@sparkademy/app-common/elements/Button";
import { ButtonOutlineLink } from "@sparkademy/app-common/elements/ButtonOutline";
import { LevelIcon } from "@sparkademy/app-common/elements/Levelcon";
import { ReactComponent as Calendar } from "@sparkademy/app-common/materials/icons/new/calendar.svg";
import { ReactComponent as Time } from "@sparkademy/app-common/materials/icons/new/time.svg";
import { IconDescriptionList, IconDescriptionListItem } from "./IconDescriptionList";
import { dateFormatter } from "@sparkademy/app-common/utils/date-formatters";

import { useSessionContext } from "@sparkademy/app-common/contexts/session-context";
import { useContentContext } from "@sparkademy/app-common/contexts/content-context";
import { Narrative } from "@sparkademy/app-common/elements/Narrative";
import { ErrorCard } from "./ErrorCard";

type Props = {
  level: Level;
  showOpenCourseButton?: boolean;
  showMethodKitButton?: boolean;
};

export const CardLevel: React.FC<Props> = ({ level }) => {
  const { currentUser } = useSessionContext();

  const { levels } = useContentContext();
  const levelInfo = levels.find(l => l.id === level.id);

  if (!currentUser || !levelInfo) {
    return null;
  }

  return (
    <React.Fragment>
      <Box
        as="article"
        sx={{
          bg: "new.primary.white",
          borderRadius: 10,
          p: 8,
        }}
      >
        <Box
          as="header"
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <LevelIcon
            level={levelInfo}
            sx={{
              width: theme => theme.space[9],
            }}
          />
          <Box sx={{ ml: 4 }} />
          <Heading as="h2" sx={{ fontSize: 3, fontWeight: "bold" }}>
            {levelInfo.name}
          </Heading>
        </Box>
        <Box sx={{ mt: 7 }} />

        <Box
          sx={{
            fontSize: 1,
            maxWidth: ["100%", "100%", "80%"],
          }}
        >
          <Narrative>{levelInfo.description}</Narrative>

          {level.status === "failed" ? (
            <ErrorCard sx={{ mt: 7 }}>{uiCopy.LEVEL_CARD_FAILED_TEXT()}</ErrorCard>
          ) : null}
        </Box>

        <Box sx={{ mt: 7 }} />
        <IconDescriptionList
          sx={{
            fontSize: 1,
          }}
        >
          <IconDescriptionListItem Icon={Calendar} title="Duration">
            <Box sx={{ color: "new.secondary.violet", fontWeight: 500 }}>
              {level.modules.length > 0
                ? dateFormatter.format(level.modules[0].materials.start)
                : null}
              &mdash;
              <br />
              {level.modules.length > 0
                ? dateFormatter.format(level.modules[level.modules.length - 1].assessment.end)
                : null}
            </Box>
            {level.isActive ? (
              <React.Fragment>
                <Box sx={{ mt: 2 }} />
                {/* <ButtonGhostAnchor
                  download
                  target='_blank'
                  rel='noopened noreferrer'
                  href={api.getCalendarUrlForLevel(level, currentUser)}
                  sx={{
                    p: 0,
                    fontSize: 0,
                    minHeight: 'initial'
                  }}
                >
                  {uiCopy.MODULES_ADD_TO_CALENDAR_BUTTON}
                </ButtonGhostAnchor> */}
              </React.Fragment>
            ) : null}
          </IconDescriptionListItem>

          <IconDescriptionListItem Icon={Time} title="Time Commitment">
            <Box sx={{ color: "new.secondary.violet", fontWeight: 500 }}>
              {levelInfo.commitment}
            </Box>
          </IconDescriptionListItem>
        </IconDescriptionList>
        <Box sx={{ mt: 7 }} />
        <Box
          sx={{
            display: "flex",
            justifyContent: ["center", "flex-end"],
            flexWrap: "wrap",
            m: -4,
            "& > *": {
              p: 4,
            },
          }}
        >
          <Box>
            <ButtonLink id="level-detail-btn" sx={{ mr: 5 }} to={"/programs/" + level.id}>
              View Program
            </ButtonLink>
          </Box>

          {level.status === "completed" ? (
            <Box>
              <ButtonLink id="certificate-btn" to={`/certificate-download/${level.id}`}>
                Certificate
              </ButtonLink>
            </Box>
          ) : null}
        </Box>
      </Box>
    </React.Fragment>
  );
};
