import * as React from "react";
import { Box, Heading, Text, Textarea, Select } from "theme-ui";
import { Container } from "@sparkademy/app-common/elements/Container";
import { Button } from "@sparkademy/app-common/elements/Button";
import { Loader } from "@sparkademy/app-common/components/Loader";
import { UserExtraInfo } from "@sparkademy/app-common/models/user";
import { FourSquares } from "../../models/four-squares";
import { addUserExtraInfo } from "../../services/http-api-service";
import { Logger } from "@sparkademy/app-common/services/logger-service";
import { TrackingService } from "../../services/tracking-service";

import {
  FormSelectField,
  FormTextField,
  validateFields,
  validateFields_v2,
} from "@sparkademy/app-common/components/FormFields";

import {
  pronounOpts,
  roleOpts,
  responsibilityOpts,
  countryOpts,
  referralSourceOpts,
  motivationOpts,
} from "@sparkademy/app-common/components/FormFieldOpts";

import {
  levelOfEducationOpts,
  primaryWorkingLanguageOpts,
  priorStartupExperienceOpts,
  roleOptsForFounders,
} from "@sparkademy/app-common/components/FormFieldOptsForFounders";

type UserExtraInfoState = UserExtraInfo & { errors: { [field: string]: string } };

const REGULAR_FIELD_SET = [
  "pronoun",
  "jobRole",
  "responsibility",
  "country",
  "referralSource",
  "educationalBackground",
  "professionalBackground",
  "programExpectation",
  "programMotivation",
];

const FOUNDER_FIELD_SET = [
  "companyName",
  "pronoun",
  "jobRole",
  "country",
  "educationalBackground",
  "professionalBackground",
  "levelOfEducation",
  "primaryWorkingLanguage",
  "priorStartupExperience",
];

export const CollectExtraUserInfo: React.FC<{
  userId: string;
  cohortId: string;
  gameState: FourSquares;
  updateGame: (choice: number | null) => void;
}> = ({ userId, cohortId, updateGame }) => {
  const userType: "regular" | "founder" = cohortId.includes("founder") ? "founder" : "regular";
  const isFounder = userType === "founder";
  const fields = isFounder ? FOUNDER_FIELD_SET : REGULAR_FIELD_SET;

  const [loading, setLoading] = React.useState<boolean>(false);
  const [userAttrs, setUserAttrs] = React.useState<UserExtraInfoState>({
    pronoun: "",
    jobRole: "",
    responsibility: "",
    country: "",
    referralSource: "",
    educationalBackground: "",
    professionalBackground: "",
    programExpectation: "",
    programMotivation: "",
    companyName: "",
    levelOfEducation: "",
    primaryWorkingLanguage: "",
    priorStartupExperience: "",
    errors: {},
  });

  const viewStartTimeRef = React.useRef<Date>(new Date());
  React.useEffect(() => {
    // run this once on mount
    viewStartTimeRef.current = new Date();
    return () => {
      // run this on unmount
      TrackingService.timeSpentOnMotivationCheck(cohortId, viewStartTimeRef.current, new Date());
    };
  }, []);

  const attributeToLabelMap = {
    pronoun: "Pronoun",
    jobRole: "Job Role",
    responsibility: "Responsibility",
    country: "Country",
    referralSource: "Referral Source",
    educationalBackground: "Educational Background",
    professionalBackground: "Professional Background",
    programExpectation: "Program Expectation",
    programMotivation: "Program Motivation",
    companyName: "Company Name",
    levelOfEducation: "Level of Education",
    primaryWorkingLanguage: "Primary Working Language",
    priorStartupExperience: "Prior Startup Experience",
  };

  if (loading) {
    return <Loader />;
  }

  const onChangeHandler = (field: string) => (event: React.ChangeEvent<HTMLInputElement>) =>
    setUserAttrs({
      ...userAttrs,
      [field]: event.currentTarget.value,
      errors: { ...userAttrs.errors, [field]: "" },
    });

  return (
    <Container
      sx={{
        textAlign: "center",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        mt: "-50px",
      }}
    >
      <form
        onSubmit={event => {
          event.preventDefault();
          const errors = validateFields_v2(fields, userAttrs, attributeToLabelMap);

          if (Object.keys(errors).length === 0) {
            setLoading(true);
            addUserExtraInfo(userId, userAttrs)
              .then(() => updateGame(null))
              .catch(ex => {
                Logger.error(ex);
                setLoading(false);
              });
          } else {
            setUserAttrs({ ...userAttrs, errors });
          }
        }}
      >
        <Box
          sx={{
            mt: 8,
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            alignItems: "flex-start",
          }}
        >
          <Box sx={{ mb: 8 }}>
            <Heading as="h1" sx={{}}>
              Candidate Information Survey
            </Heading>
            <Text sx={{ mt: 2 }}>Please answer all of the questions below.</Text>
          </Box>

          {fields.includes("companyName") && (
            <FormTextField
              id="companyName"
              text="Company Name"
              value={userAttrs.companyName || ""}
              hasError={!!userAttrs.errors["companyName"]}
              onChange={onChangeHandler("companyName")}
            />
          )}

          {fields.includes("pronoun") && (
            <FormSelectField
              id="pronoun"
              text="Pronoun"
              options={pronounOpts}
              selected={userAttrs.pronoun}
              hasError={!!userAttrs.errors["pronoun"]}
              onChange={onChangeHandler("pronoun")}
            />
          )}

          {fields.includes("jobRole") && (
            <FormSelectField
              id="jobRole"
              text="Role"
              options={isFounder ? roleOptsForFounders : roleOpts}
              selected={userAttrs.jobRole || ""}
              hasError={!!userAttrs.errors["jobRole"]}
              onChange={onChangeHandler("jobRole")}
              note="(If more than one apply, please select the most relevant one for you)"
            />
          )}

          {fields.includes("responsibility") && (
            <FormSelectField
              id="responsibility"
              text="Responsibilities"
              options={responsibilityOpts}
              selected={userAttrs.responsibility || ""}
              hasError={!!userAttrs.errors["responsibility"]}
              onChange={onChangeHandler("responsibility")}
              note="(If more than one apply, please select the most relevant one for you)"
            />
          )}

          {fields.includes("country") && (
            <FormSelectField
              id="country"
              text="Country Of Origin"
              options={countryOpts}
              selected={userAttrs.country || ""}
              hasError={!!userAttrs.errors["country"]}
              onChange={onChangeHandler("country")}
            />
          )}

          {fields.includes("referralSource") && (
            <FormSelectField
              id="referralSource"
              text="How did you hear about this program?"
              options={referralSourceOpts}
              selected={userAttrs.referralSource || ""}
              hasError={!!userAttrs.errors["referralSource"]}
              onChange={onChangeHandler("referralSource")}
              note="(If more than one apply, please select the most relevant one for you)"
            />
          )}

          {fields.includes("educationalBackground") && (
            <FormTextField
              id="educationalBackground"
              text="What is your educational background?"
              value={userAttrs.educationalBackground || ""}
              hasError={!!userAttrs.errors["educationalBackground"]}
              onChange={onChangeHandler("educationalBackground")}
            />
          )}

          {fields.includes("professionalBackground") && (
            <FormTextField
              id="professionalBackground"
              text="What is your professional background (roles, industries, etc.)?"
              value={userAttrs.professionalBackground || ""}
              hasError={!!userAttrs.errors["professionalBackground"]}
              onChange={onChangeHandler("professionalBackground")}
            />
          )}

          {fields.includes("programExpectation") && (
            <FormTextField
              id="programExpectation"
              text="What impact do you expect this program to have? (Professional and Personal)"
              value={userAttrs.programExpectation || ""}
              hasError={!!userAttrs.errors["programExpectation"]}
              onChange={onChangeHandler("programExpectation")}
            />
          )}

          {fields.includes("programMotivation") && (
            <FormSelectField
              id="programMotivation"
              text="Which of these statements best explains your motivation to take part in this program?"
              options={motivationOpts}
              selected={userAttrs.programMotivation || ""}
              hasError={!!userAttrs.errors["programMotivation"]}
              onChange={onChangeHandler("programMotivation")}
              note="(If more than one apply, please select the most relevant one for you)"
            />
          )}

          {fields.includes("levelOfEducation") && (
            <FormSelectField
              id="levelOfEducation"
              text="Level of education"
              options={levelOfEducationOpts}
              selected={userAttrs.levelOfEducation || ""}
              hasError={!!userAttrs.errors["levelOfEducation"]}
              onChange={onChangeHandler("levelOfEducation")}
              note="(If more than one apply, please select the most relevant one for you)"
            />
          )}

          {fields.includes("primaryWorkingLanguage") && (
            <FormSelectField
              id="primaryWorkingLanguage"
              text="Primary Working Language"
              options={primaryWorkingLanguageOpts}
              selected={userAttrs.primaryWorkingLanguage || ""}
              hasError={!!userAttrs.errors["primaryWorkingLanguage"]}
              onChange={onChangeHandler("primaryWorkingLanguage")}
              note="(If more than one apply, please select the most relevant one for you)"
            />
          )}

          {fields.includes("priorStartupExperience") && (
            <FormSelectField
              id="priorStartupExperience"
              text="Prior start-up experience"
              options={priorStartupExperienceOpts}
              selected={userAttrs.priorStartupExperience || ""}
              hasError={!!userAttrs.errors["priorStartupExperience"]}
              onChange={onChangeHandler("priorStartupExperience")}
              note="(If more than one apply, please select the most relevant one for you)"
            />
          )}
        </Box>
        <Box
          sx={{
            my: 8,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {new Array(4).fill(0).map((e, i) => (
            <Box
              key={`box-${i}`}
              sx={{
                width: "10px",
                height: "10px",
                borderRadius: "50%",
                bg: i === 0 ? "new.primary.purple" : "new.secondary.grey",
                mx: 2,
              }}
            />
          ))}
        </Box>

        <Button
          type="submit"
          disabled={Object.values(userAttrs.errors).filter(e => !!e).length > 0}
        >
          Next
        </Button>
      </form>
    </Container>
  );
};
