import * as Sentry from "@sentry/browser";

export class Logger {
  static error(error: unknown) {
    console.error(error);
    Sentry.captureException(error);
  }

  static info(msg: string) {
    console.log(msg);
    Sentry.captureMessage(msg);
  }
}
