/** @jsx jsx */
import { jsx } from "theme-ui";
import * as React from "react";
import { Container } from "@sparkademy/app-common/elements/Container";
import { ButtonGhostAnchor } from "@sparkademy/app-common/elements/ButtonGhost";
import { TrackingService } from "../services/tracking-service";
import { useBreakpointIndex } from "@theme-ui/match-media";

const REGULAR_FOOTER_HEIGHT = 68;
const MOBILE_FOOTER_HEIGHT = 200;

export function useFooterHeight() {
  const breakpointIndex = useBreakpointIndex();
  return breakpointIndex === 0 ? MOBILE_FOOTER_HEIGHT : REGULAR_FOOTER_HEIGHT;
}

export const Footer: React.FC = () => {
  function trackLinkClick(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
    const url = event.currentTarget.href;
    TrackingService.footerLinkClick(url);
  }

  const footerHeight = useFooterHeight();

  return (
    <footer
      sx={{
        bg: "new.primary.black",
        color: "new.primary.white",
        py: 4,
        height: `${footerHeight}px`,
      }}
    >
      <Container
        sx={{
          display: "flex",
          flexWrap: "wrap",
          flexDirection: ["column", "row"],
          alignItems: ["center", "flex-start"],
          justifyContent: "center",
        }}
      >
        <ButtonGhostAnchor
          onClick={trackLinkClick}
          href="https://www.sparkademy.com/"
          target="_blank"
          rel="noopened noreferrer"
          sx={{
            px: 7,
            color: "inherit",
            fontSize: `${11 / 16}rem`,
          }}
        >
          &copy; 2020 Sparkademy AG
        </ButtonGhostAnchor>
        <nav
          sx={{
            display: "flex",
            flexWrap: "wrap",
            flexDirection: ["column", "row"],
            alignItems: ["center", "flex-start"],
          }}
        >
          <ul
            sx={{
              listStyle: "none",
              m: 0,
              p: 0,
              display: "flex",
              flexWrap: "wrap",
              flexDirection: ["column", "row"],
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <li
              sx={{
                px: 7,
              }}
            >
              <ButtonGhostAnchor
                className="iubenda-nostyle no-brand iubenda-embed"
                sx={{ px: 0, color: "inherit", fontSize: `${11 / 16}rem` }}
                href="https://www.iubenda.com/terms-and-conditions/43449139"
                rel="noopened noreferrer"
              >
                Terms and Conditions
              </ButtonGhostAnchor>
            </li>
            <li
              sx={{
                px: 7,
              }}
            >
              <ButtonGhostAnchor
                className="iubenda-nostyle no-brand iubenda-embed"
                sx={{ px: 0, color: "inherit", fontSize: `${11 / 16}rem` }}
                href="https://www.iubenda.com/privacy-policy/43449139"
                rel="noopened noreferrer"
              >
                Privacy Policy
              </ButtonGhostAnchor>
            </li>
            {/* {pages
              .filter(p => {
                if (p.hideFromFooter) {
                  return false;
                }

                if (!p.userIdFilter) {
                  return true;
                }
                return currentUser && faqId === p.userIdFilter;
              })
              .map(p => (
                <li key={p.slug} sx={{
                  px: 7,
                }}>
                  <ButtonGhostLink
                    onClick={trackLinkClick}
                    to={`/${p.slug}`}
                    sx={{
                      px: 0,
                      color: 'inherit',
                      fontSize: `${11 / 16}rem`,
                    }}
                  >{p.title}</ButtonGhostLink>
                </li>
              ))} */}
            <li
              sx={{
                px: 7,
              }}
            >
              <ButtonGhostAnchor
                onClick={trackLinkClick}
                sx={{
                  px: 0,
                  color: "inherit",
                  fontSize: `${11 / 16}rem`,
                }}
                href={`mailto:info@sparkademy.com`}
                target="_blank"
                rel="noopened noreferrer"
              >
                Contact
              </ButtonGhostAnchor>
            </li>
          </ul>
          {/* <ul sx={{
            listStyle: 'none',
            m: 0,
            p: 0,
            ml: [0, 0, 6],
            display: 'flex',
            flexWrap: 'wrap',
            alignItems: 'center',
            justifyContent: 'center',
          }}>
            <li sx={{
              px: 0
            }}>
              <ButtonGhostIconAnchor
                onClick={trackLinkClick}
                href='https://twitter.com/sparkademy'
                target='_blank'
                rel='noopened noreferrer'
                aria-label='Twitter'
                icon={Twitter}
                sx={{
                  px: 0,
                  color: 'inherit',
                }} />
            </li>
            <li sx={{
              px: 0
            }}>
              <ButtonGhostIconAnchor
                onClick={trackLinkClick}
                href='https://www.linkedin.com/company/sparkademy/'
                target='_blank'
                rel='noopened noreferrer'
                aria-label='LinkedIn'
                icon={Linkedin}
                sx={{
                  px: 0,
                  color: 'inherit',
                }} />

            </li>
          </ul> */}
        </nav>
      </Container>
    </footer>
  );
};
