import * as React from "react";
import {
  AlertDialogLabel,
  AlertDialogDescription,
  AlertDialogOverlay,
  AlertDialogContent,
} from "@sparkademy/app-common/elements/Alert";
import { Box, Text, Button as BaseButton } from "theme-ui";
import { Button } from "@sparkademy/app-common/elements/Button";
import { ReactComponent as CalendarIllustration } from "@sparkademy/app-common/materials/illustrations/calendar.svg";
import { dayFormatter } from "@sparkademy/app-common/utils/date-formatters";
// import { AddToCalendarButton } from "./AddToCalendarButton";
import "@szhsin/react-menu/dist/index.css";
import "@szhsin/react-menu/dist/transitions/slide.css";
import VisuallyHidden from "@reach/visually-hidden";
import { ReactComponent as Close } from "@sparkademy/app-common/materials/icons/close.svg";
import { enrollUserIntoCourseRuns } from "../../services/http-api-service";
import { CourseRun } from "@sparkademy/app-common/models/course";
import { useSessionContext } from "@sparkademy/app-common/contexts/session-context";
import { ScrollableSelectList } from "@sparkademy/app-common/components/ScrollableSelectList";
import { useUpdateAtom } from "jotai/utils";
import { showErrorToastAtom } from "@sparkademy/app-common/stores/ui";
import { LoaderInline } from "@sparkademy/app-common/components/Loader";

const CourseRunSelect = ScrollableSelectList<CourseRun>();

export const CourseSignupDialog: React.FC<{
  onClose: () => void;
  onSubmit: (run: CourseRun) => void;
  courseRuns: CourseRun[];
}> = ({ onClose, courseRuns, onSubmit }) => {
  const [selectedCourseRun, setSelectedCourseRun] = React.useState<CourseRun | null>(null);
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const cancelRef = React.useRef<HTMLButtonElement>();
  const { currentUser } = useSessionContext();

  const showErrorToast = useUpdateAtom(showErrorToastAtom);

  return (
    <AlertDialogOverlay
      // @ts-ignore
      leastDestructiveRef={cancelRef}
      id="SaveAnswersDialog"
      onDismiss={onClose}
    >
      <AlertDialogContent>
        <Box sx={{ maxWidth: "311px" }}>
          <AlertDialogLabel
            style={{ height: "32px", width: "100%", position: "sticky", top: "32px" }}
          >
            <BaseButton
              sx={{
                all: "unset",
                fontFamily: "inherit",
                float: "right",
                color: "primary",
                cursor: "pointer",
                bg: "transparent",
              }}
              onClick={onClose}
            >
              <VisuallyHidden>Close</VisuallyHidden>
              <Close width={32} height={32} aria-hidden="true" focusable="false" />
            </BaseButton>
          </AlertDialogLabel>
          <AlertDialogDescription>
            <Box
              sx={{
                margin: "auto",
                width: 170,
                height: 170,
                svg: {
                  width: "100%",
                  height: "auto",
                },
              }}
            >
              <CalendarIllustration />
            </Box>
            <Text as="p" sx={{ textAlign: "center", fontWeight: 700, fontSize: "20px", mb: 4 }}>
              Select Your Cohort
            </Text>
            <Text as="p" sx={{ textAlign: "center", fontWeight: 300, fontSize: "16px" }}>
              Make sure you meet the requirements for this module before enrolling. If in doubt,
              reach out to <a href="mailto:team@sparkademy.com">team@sparkademy.com</a>.
            </Text>
          </AlertDialogDescription>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <Box sx={{ my: 7, width: "200px" }}>
            <CourseRunSelect
              getIsSelected={courseRun => courseRun.id === selectedCourseRun?.id}
              items={courseRuns}
              onSelectItem={courseRun => setSelectedCourseRun(courseRun)}
              itemRenderer={courseRun => {
                return `${dayFormatter.format(
                  new Date(courseRun.schedule.startOn)
                )} - ${dayFormatter.format(new Date(courseRun.schedule.endOn))}`;
              }}
            />
          </Box>

          {isSubmitting ? (
            <LoaderInline />
          ) : (
            <Button
              sx={{ fontSize: "20px", my: 5 }}
              type="submit"
              onClick={() => {
                setIsSubmitting(true);
                enrollUserIntoCourseRuns(selectedCourseRun?.id!, currentUser!).then(res => {
                  if (res.status === 201) {
                    onSubmit(selectedCourseRun!);
                  } else {
                    showErrorToast(
                      "Something went wrong with the enrollment. Please try again later."
                    );
                  }
                  setIsSubmitting(false);
                  onClose();
                });
              }}
              disabled={selectedCourseRun === null}
            >
              Enroll
            </Button>
          )}
        </Box>
      </AlertDialogContent>
    </AlertDialogOverlay>
  );
};
