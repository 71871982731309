import {
  Heading,
  Text,
  Box,
  Image,
  ThemeProvider,
  SxStyleProp,
  Button as BaseButton,
} from "theme-ui";
import * as React from "react";
import VisuallyHidden from "@reach/visually-hidden";
import { tools } from "../data/tools";
import { useAtom } from "jotai";
import { methodCardModalVisibleAtom } from "../stores/ui";

import { ReactComponent as Close } from "@sparkademy/app-common/materials/icons/close.svg";
import { Narrative } from "@sparkademy/app-common/elements/Narrative-v2";
import {
  AlertDialogLabel,
  AlertDialogDescription,
  AlertDialogOverlay,
  AlertDialogContent,
} from "@sparkademy/app-common/elements/Alert";
import { useContentContext } from "@sparkademy/app-common/contexts/content-context";
import { getMethodKitColorForLevel } from "@sparkademy/app-common/utils/theme";
import { ButtonGhostAnchor } from "../elements/ButtonGhost";

export const MethodCardModal: React.FC = () => {
  const cancelRef = React.useRef<HTMLButtonElement>();

  const [methodCardOpen, setMethodCardOpen] = useAtom(methodCardModalVisibleAtom);

  const { modules } = useContentContext();
  const tool = tools.find(t => t.slug === methodCardOpen);

  const moduleInfo = modules.find(m => m.id.toLowerCase() === tool?.moduleId.toLowerCase());

  if (!tool || !moduleInfo || !methodCardOpen) {
    return null;
  }

  // close the modal when clicking outside of it

  return (
    <AlertDialogOverlay
      // @ts-ignore
      leastDestructiveRef={cancelRef}
      onKeyUp={e => e.key === "Escape" && setMethodCardOpen("")}
    >
      <AlertDialogContent>
        <AlertDialogLabel
          style={{ height: "16px", width: "100%", position: "sticky", top: "16px" }}
        >
          <BaseButton
            sx={{
              all: "unset",
              fontFamily: "inherit",
              float: "right",
              color: "primary",
              cursor: "pointer",
              bg: "transparent",
            }}
            onClick={() => setMethodCardOpen("")}
          >
            <VisuallyHidden>Close</VisuallyHidden>
            <Close width={16} height={16} aria-hidden="true" focusable="false" />
          </BaseButton>
        </AlertDialogLabel>

        <Box sx={{ mt: 6, maxWidth: "740px" }}>
          <AlertDialogLabel></AlertDialogLabel>
          <AlertDialogDescription>
            <Box
              sx={{
                bg: "white",
                display: "flex",
                flexDirection: "column",
                maxHeight: "650px",
                overflowY: "auto",
                "scrollbar-width": "7px" /* Firefox */,
                "scrollbar-color": "#BFBFBF transparent" /* Firefox */,
                "-ms-overflow-style": "none" /* IE 10+ */,
                "::-webkit-scrollbar-thumb": {
                  bg: "#BFBFBF",
                  borderRadius: "10px",
                },
                "::-webkit-scrollbar": {
                  bg: "transparent",
                  width: "7px",
                },
                "::-webkit-scrollbar-track": {
                  bg: "transparent",
                },
                px: "4em",
              }}
            >
              <Heading
                as="h3"
                sx={{
                  color: "new.secondary.grey",
                  fontWeight: 700,
                  fontSize: "16px",
                }}
              >
                {moduleInfo.name}
              </Heading>

              <Heading
                as="h1"
                sx={{
                  fontSize: 3,
                  fontWeight: "bold",
                  color: "text",
                  mt: 6,
                }}
              >
                {tool.name}
              </Heading>

              <Box>
                {tool.backImage && (
                  <Box
                    sx={{
                      display: ["flex"],
                      alignItems: "center",
                      justifyContent: "center",
                      p: 0,
                      my: 8,
                      bg: getMethodKitColorForLevel(moduleInfo.level),
                      borderRadius: "10px",
                      minHeight: 328,
                    }}
                  >
                    <Image
                      id="method-card-image"
                      src={tool.backImage}
                      alt={tool.backImage}
                      sx={{ width: "95%", height: "auto", maxHeight: "500px" }}
                    />
                  </Box>
                )}

                <Box id="method-card-content" sx={{ my: 8 }}>
                  <Narrative>
                    <tool.content />
                  </Narrative>
                </Box>
              </Box>
            </Box>

            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <ButtonGhostAnchor
                sx={{ px: 0, py: 0, fontSize: 0, textDecoration: "underline" }}
                href={`/method-kit/${tool.slug}`}
                target="_blank"
              >
                View Method Kit
              </ButtonGhostAnchor>
            </Box>
          </AlertDialogDescription>
        </Box>
      </AlertDialogContent>
    </AlertDialogOverlay>
  );
};
