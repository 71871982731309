/** @jsx jsx */
import React, { useState } from "react";
import { jsx, Box, Button, Text, Container } from "theme-ui";
import { Helmet } from "react-helmet";
import useWindowSize from "react-use/lib/useWindowSize";
import { LayoutHeader } from "../components/Layout";
import { Narrative } from "@sparkademy/app-common/elements/Narrative";
import {
  SparkCheckProfileCard,
  SparkCheckProfile,
} from "../components/SparkCheck/SparkCheckProfileCard";
import { Loader } from "@sparkademy/app-common/components/Loader";
import { ButtonAnchor } from "@sparkademy/app-common/elements/Button";
import { useQuery } from "@sparkademy/app-common/utils/useQuery";
import { ReactComponent as Linkedin } from "@sparkademy/app-common/materials/icons/linkedin.svg";
import { ReactComponent as SparkademyLogo } from "@sparkademy/app-common/materials/icons/logo.svg";
import sectionImage from "@sparkademy/app-common/materials/illustrations/white-upside-down.png";
import { ProfileAssessmentResult } from "../models/profile-assessment";

import { getProfileAssessmentResults } from "../services/http-api-service";

function getResultClassification(results?: ProfileAssessmentResult): SparkCheckProfile | undefined {
  const empathy50thPerc = 4.0;
  const game50thPerc = 2552.0;

  if (!results) return;

  const emapathyScore =
    results.empathy.emotion_matching_score + results.empathy.understanding_emotions_score;
  const gameScore = results.four_squares.score;

  if (emapathyScore >= empathy50thPerc) {
    return gameScore >= game50thPerc
      ? SparkCheckProfile.ChangeMaker
      : SparkCheckProfile.PeoplesPerson;
  }

  return gameScore >= game50thPerc
    ? SparkCheckProfile.FrontRunner
    : SparkCheckProfile.ProblemSolver;
}

const BookACallButton: React.FC = () => (
  <Box
    sx={{
      flexBasis: ["100%", "50%"],
      alignItems: "center",
      justifyContent: "center",
      display: "flex",
      width: "100%",
    }}
  >
    <ButtonAnchor
      id="book-a-call-btn"
      href="https://www.sparkademy.com/contact"
      target="_blank"
      sx={{
        justifyContent: "center",
        bg: "primary",
        color: "#fff",
        borderRadius: 30,
        cursor: "pointer",
        width: ["100%", 370],
        fontSize: [0, 1],
        height: 44,
      }}
    >
      Book A Call With Sparkademy
    </ButtonAnchor>
  </Box>
);

export const SparkCheckResults: React.FC = () => {
  const { width } = useWindowSize();
  const [loading, setLoading] = useState(true);
  const [result, setResult] = useState<ProfileAssessmentResult>();
  const query = useQuery();

  // User anomymous ID
  // If not present in the query it should be present in localStorage (set after saving the test)
  let id = query.get("id");
  if (!id) {
    if (window.localStorage) {
      id = window.localStorage.getItem("uid");
    }
  }

  React.useEffect(() => {
    if (id) {
      getProfileAssessmentResults(id).then(res => {
        setResult(res);
        setLoading(false);
      });
    }
  }, [id]);

  const classification = getResultClassification(result);

  const shareOnLinkedIn = () => {
    let profile = "";
    switch (classification) {
      case SparkCheckProfile.ChangeMaker:
        profile = "change-maker";
        break;
      case SparkCheckProfile.FrontRunner:
        profile = "front-runner";
        break;
      case SparkCheckProfile.PeoplesPerson:
        profile = "peoples-person";
        break;
      case SparkCheckProfile.ProblemSolver:
        profile = "problem-solver";
        break;
    }

    const shareUrl = `${window.location.protocol}//${window.location.host}/spark-check-profile/${profile}.html`;
    window.open(
      `https://www.linkedin.com/sharing/share-offsite?url=${shareUrl}`,
      "_blank",
      "width=600,height=800"
    );
  };

  return (
    <LayoutHeader>
      <Helmet>
        <title>Check your innovation potential with the Spark Check</title>
        <meta property="og:title" content="Check your innovation potential with the Spark Check" />
      </Helmet>

      {loading && (
        <Box
          sx={{
            width: "100vw",
            height: "100vh",
            position: "absolute",
            zIndex: 9,
            bg: "background",
          }}
        >
          <Loader />
        </Box>
      )}

      <Box
        sx={{
          bg: "white",
          width: "100vw",
          mt: "1px",
        }}
      >
        <Box
          sx={{
            maxWidth: [width, 800],
            pt: "80px",
            mb: 10,
            mx: [3, "auto"],
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-around",
          }}
        >
          <Text as="p" sx={{ fontSize: 2, fontWeight: "bold", mb: 8 }}>
            Your results
          </Text>
          <SparkCheckProfileCard profile={classification!} />

          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              mt: [5, 10],
            }}
          >
            <Box
              sx={{
                flexBasis: ["100%", "50%"],
                alignItems: "center",
                justifyContent: "center",
                display: "flex",
                mb: [5, "auto"],
              }}
            >
              <Button
                id="share-results-btn"
                onClick={shareOnLinkedIn}
                sx={{
                  margin: "auto",
                  bg: "#fff",
                  color: "primary",
                  fontFamily: "body",
                  fontSize: 1,
                  fontWeight: 500,
                  borderRadius: 30,
                  border: "2px solid",
                  p: 0,
                  cursor: "pointer",
                  width: ["100vw", 300],
                  height: 44,
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Box
                    sx={{
                      px: 2,
                    }}
                  ></Box>
                  <Linkedin width={32} height={32} />
                  <Box
                    sx={{
                      px: 4,
                    }}
                  >
                    Share My Results
                  </Box>
                </Box>
              </Button>
            </Box>

            <BookACallButton />
          </Box>
        </Box>
      </Box>

      <Box
        sx={{
          backgroundImage: `url(${sectionImage})`,
          backgroundPosition: "50% 0",
          backgroundSize: "contain",
          backgroundRepeat: "no-repeat",
          backgroundColor: "background",
          height: [25, 75],
        }}
      />

      <Box sx={{ mx: [3, "auto"], my: 10, maxWidth: [width, 800] }}>
        <Container
          sx={{
            textAlign: "center",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <Box
            sx={{
              width: 170,
              height: 100,
              svg: {
                width: "100%",
                height: "auto",
              },
            }}
          >
            <SparkademyLogo />
          </Box>

          <Narrative sx={{ p: { textAlign: "center", fontSize: 0 } }}>
            <p>
              Now that you know what kind of innovator <b>you are</b>, the next step is to identify
              the innovator profiles of <b>your employees</b>.
            </p>
            <p>
              Book a 15-minute call with us to learn how you can use Spark Check in your company and
              the next steps to take for upskilling!
            </p>
          </Narrative>

          <Box sx={{ mt: 10 }}>
            <BookACallButton />
          </Box>
        </Container>
      </Box>
    </LayoutHeader>
  );
};
