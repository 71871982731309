import * as React from "react";
import {
  AlertDialogLabel,
  AlertDialogDescription,
  AlertDialogOverlay,
  AlertDialogContent,
} from "@sparkademy/app-common/elements/Alert";
import { Box, Text, Button as BaseButton } from "theme-ui";
import { Button } from "@sparkademy/app-common/elements/Button";
import { ReactComponent as Close } from "@sparkademy/app-common/materials/icons/close.svg";
import { ReactComponent as IllustrationTeam } from "@sparkademy/app-common/materials/illustrations/team-faces.svg";
import VisuallyHidden from "@reach/visually-hidden";
import { useAssignmentAnswersContext } from "@sparkademy/app-common/contexts/assignment-answers-context";
import { dateFormatter } from "@sparkademy/app-common/utils/date-formatters";

export const TeamSaveWarningDialog: React.FC<{ onClose: () => void; onSave: () => void }> = ({
  onClose,
  onSave,
}) => {
  const { lastActionsLog } = useAssignmentAnswersContext();
  const cancelRef = React.useRef<HTMLButtonElement>();

  return (
    <AlertDialogOverlay
      // @ts-ignore
      leastDestructiveRef={cancelRef}
    >
      <AlertDialogContent>
        <AlertDialogLabel
          style={{ height: "32px", width: "100%", position: "sticky", top: "32px" }}
        >
          <BaseButton
            sx={{
              all: "unset",
              fontFamily: "inherit",
              float: "right",
              color: "primary",
              cursor: "pointer",
              bg: "transparent",
            }}
            onClick={onClose}
          >
            <VisuallyHidden>Close</VisuallyHidden>
            <Close width={32} height={32} aria-hidden="true" focusable="false" />
          </BaseButton>
        </AlertDialogLabel>

        <Box sx={{ maxWidth: "500px" }}>
          <AlertDialogLabel></AlertDialogLabel>
          <AlertDialogDescription>
            <Box
              sx={{
                margin: "auto",
                width: 170,
                height: 170,
                svg: {
                  width: "100%",
                  height: "auto",
                },
              }}
            >
              <IllustrationTeam />
            </Box>
            <Text as="p" sx={{ textAlign: "center", fontWeight: 700, fontSize: "20px" }}>
              Are you sure you want to save?
            </Text>
            <Text as="p" sx={{ textAlign: "center", fontSize: "16px", mt: "20px" }}>
              This assignment was last saved by {lastActionsLog?.lastSavedByName} on{" "}
              {dateFormatter.format(lastActionsLog?.lastSavedAt)}. Any new changes will overwrite
              the last version.
            </Text>
          </AlertDialogDescription>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            flexDirection: "row-reverse",
            mt: "48px",
            mb: "16px",
          }}
        >
          <Button
            type="submit"
            onClick={() => {
              onSave();
              onClose();
            }}
          >
            Save
          </Button>
        </Box>
      </AlertDialogContent>
    </AlertDialogOverlay>
  );
};
