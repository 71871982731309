import * as React from "react";
import { Box, SxStyleProp } from "theme-ui";

type Props = {
  sx?: SxStyleProp;
};

export const ErrorCard: React.FC<Props> = ({ children, sx = {} }) => (
  <Box
    id="error-card"
    sx={{
      borderLeftWidth: theme => theme.space[5],
      borderLeftStyle: "solid",
      borderLeftColor: "new.secondary.red",
      bg: "new.exceptions.errorCardBg",
      py: 8,
      px: 5,
      fontSize: 0,
      lineHeight: "body",
      p: {
        margin: 0,
      },
      "p + p": {
        mt: 7,
      },
      ...sx,
    }}
  >
    {children}
  </Box>
);
