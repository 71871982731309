import { ContainerSmall } from "@sparkademy/app-common/elements/Container";
import React from "react";
import { Layout, LayoutSideMenu } from "../components/Layout";
import { useUserData } from "../hooks/useUserData";
import { useParams } from "react-router-dom";
import { Loader } from "@sparkademy/app-common/components/Loader";
import { CardModule } from "../components/CardModule";
import { List, ListItem } from "@sparkademy/app-common/elements/List";
import { Breadcrumb } from "../components/Breadcrumb";
import { useContentContext } from "@sparkademy/app-common/contexts/content-context";
import { CardProject } from "../components/CardProject";

export const ProgramDetail: React.FC = () => {
  // get program id from url
  const { programId } = useParams<{
    programId: string;
  }>();

  const { levels } = useUserData();
  const { levels: levelsInfo } = useContentContext();

  const currentLevel = levels.find(level => level.id === programId);
  const currentLevelInfo = levelsInfo.find(l => l.id === currentLevel?.id);

  if (levels.length === 0 || !currentLevel || !currentLevelInfo) {
    return (
      <Layout>
        <Loader />
      </Layout>
    );
  }

  const breadcrumbItems = [{ name: "Programs", url: "/programs" }, { name: currentLevelInfo.name }];

  return (
    <LayoutSideMenu sx={{ bg: "new.bg" }}>
      <ContainerSmall
        sx={{
          py: 7,
          px: 10,
        }}
      >
        <Breadcrumb items={breadcrumbItems}></Breadcrumb>
        <List id="module-list">
          {currentLevel.modules.map((module, idx) => {
            return (
              <ListItem sx={{ mt: 10 }} key={idx}>
                {currentLevel.project && module.id === currentLevel.project.moduleId ? (
                  <CardProject project={currentLevel.project} index={idx} />
                ) : (
                  <CardModule module={module} numberOfFailedModules={0} />
                )}
              </ListItem>
            );
          })}
        </List>
      </ContainerSmall>
    </LayoutSideMenu>
  );
};
