import * as React from "react";
import { Box } from "theme-ui";
import { ButtonGhostIcon } from "../../elements/ButtonGhostIcon";
import { ReactComponent as Upload } from "../../materials/icons/upload.svg";

export const FilesUpload: React.FC<{
  disabled: boolean;
  onFilesAdded?: (files: FileList) => void;
  accept?: string;
  multiple?: boolean;
}> = ({ disabled, onFilesAdded, accept = ".jpg,.jpeg,.png,.pdf", multiple = true }) => {
  const fileInputRef = React.useRef<HTMLInputElement>(null);

  const bubbleUpUploadedFiles = (files: FileList) => {
    if (!files.length) {
      return;
    }

    onFilesAdded && onFilesAdded(files);
  };

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (!files) {
      return;
    }
    bubbleUpUploadedFiles(files);
  };

  const openFileDialog = () => {
    const { current } = fileInputRef;
    current && current.click();
  };

  return (
    <Box>
      <input
        ref={fileInputRef}
        className="file-input"
        type="file"
        multiple={multiple}
        onChange={onChange}
        style={{ display: "none" }}
        accept={accept}
      />
      <ButtonGhostIcon
        disabled={disabled}
        icon={Upload}
        iconPosition="right"
        onClick={openFileDialog}
        sx={{ pl: 0 }}
      >
        Browse Files
      </ButtonGhostIcon>
    </Box>
  );
};
