import "es7-object-polyfill";
import ReactGA from "react-ga";
import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import { App } from "./App";
import * as serviceWorker from "./serviceWorker";
import { SessionContextProvider } from "@sparkademy/app-common/contexts/session-context";
import { BrowserRouter, Route } from "react-router-dom";
import { ThemeProvider } from "theme-ui";
import { theme } from "@sparkademy/app-common/materials/theme";
import { ContentContextProvider } from "@sparkademy/app-common/contexts/content-context";
import { withTracker } from "./utils/withTracker";
import { ScrollToTop } from "@sparkademy/app-common/utils/scrollToTop";
import * as Sentry from "@sentry/browser";
import LogRocket from "logrocket";
import { ApolloClient, InMemoryCache, ApolloProvider, from, HttpLink, split } from "@apollo/client";
import { RetryLink } from "@apollo/client/link/retry";
import { BASE_URL } from "./data/directus";
import { Provider } from "jotai";
import { UserDataSynchronizer } from "./components/UserDataSynchronizer";
import { HASURA_API_URL } from "@sparkademy/app-common/constants";

declare global {
  interface Window {
    E2E_TESTING: boolean;
    CURRENT_VERSION: string;
    IMPERSONATE_SESSION: boolean;
    Intercom: (op: string, attrs?: object) => void | undefined;
  }
}

const searchParams = window.location.search;
window.E2E_TESTING = searchParams.includes("e2eTest");
window.IMPERSONATE_SESSION = searchParams.includes("impersonated");

window.CURRENT_VERSION = "";

if (process.env.NODE_ENV === "production" && !window.E2E_TESTING && !window.IMPERSONATE_SESSION) {
  LogRocket.init("ck1rz5/sparkademy-frontend");
  ReactGA.initialize("UA-159143633-2");
}

const httpLink = new HttpLink({ uri: `${BASE_URL}/graphql` });
const retryLink = new RetryLink();
const hasuraLink = new HttpLink({
  uri: `${HASURA_API_URL}/graphql`,
});
const directusGqlClient = new ApolloClient({
  cache: new InMemoryCache(),
  link: split(
    operation => operation.getContext().clientName === "hasura",
    hasuraLink,
    from([retryLink, httpLink])
  ),
});

const tree = (
  <React.StrictMode>
    <BrowserRouter>
      {/* FIXME: [ScrollToTop] This might not be desired after saving a PA exercise */}
      <ScrollToTop />
      <Provider>
        <SessionContextProvider>
          <UserDataSynchronizer />
          <ThemeProvider theme={theme}>
            <ContentContextProvider>
              <ApolloProvider client={directusGqlClient}>
                <Route path="/" render={({ location }) => <Route component={withTracker(App)} />} />
              </ApolloProvider>
            </ContentContextProvider>
          </ThemeProvider>
        </SessionContextProvider>
      </Provider>
    </BrowserRouter>
  </React.StrictMode>
);

const rootElement = document.getElementById("root");

if (rootElement!.hasChildNodes()) {
  ReactDOM.hydrate(tree, rootElement);
} else {
  ReactDOM.render(tree, rootElement);
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
