/** @jsx jsx */
import { jsx, Box, SxStyleProp } from "theme-ui";
import * as React from "react";

export const AcademicIcon: React.FC<{ sxOverride?: SxStyleProp; color: string }> = ({
  sxOverride,
  color,
}) => (
  <Box sx={{ mr: 3, display: "flex", ...sxOverride }}>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.78272 3.49965C11.2037 2.83345 12.7962 2.83345 14.2172 3.49965L20.9084 6.63664C22.3639 7.31899 22.3639 9.68105 20.9084 10.3634L14.2173 13.5004C12.7963 14.1665 11.2038 14.1665 9.78281 13.5004L3.0916 10.3634C1.63613 9.68101 1.63614 7.31895 3.0916 6.63659L9.78272 3.49965Z"
        stroke={color}
        strokeWidth="1.2"
      />
      <path d="M2 8.5V14" stroke={color} strokeWidth="1.2" strokeLinecap="round" />
      <path
        d="M6.38533 19.0656L6.67601 18.5407L6.67601 18.5407L6.38533 19.0656ZM17.6147 19.0656L17.324 18.5407L17.6147 19.0656ZM19.6 11.5C19.6 11.1686 19.3314 10.9 19 10.9C18.6686 10.9 18.4 11.1686 18.4 11.5H19.6ZM5.6 11.5C5.6 11.1686 5.33137 10.9 5 10.9C4.66863 10.9 4.4 11.1686 4.4 11.5H5.6ZM6.09465 19.5905C6.84287 20.0048 7.8241 20.5025 8.85489 20.8979C9.87589 21.2896 10.9933 21.6 12 21.6V20.4C11.2107 20.4 10.255 20.1498 9.28472 19.7775C8.32426 19.4091 7.39617 18.9395 6.67601 18.5407L6.09465 19.5905ZM12 21.6C13.0067 21.6 14.1241 21.2896 15.1451 20.8979C16.1759 20.5025 17.1571 20.0048 17.9053 19.5905L17.324 18.5407C16.6038 18.9395 15.6757 19.4091 14.7153 19.7775C13.745 20.1498 12.7893 20.4 12 20.4V21.6ZM19.6 16.6254V11.5H18.4V16.6254H19.6ZM5.6 16.6254V11.5H4.4V16.6254H5.6ZM17.9053 19.5905C18.9967 18.9861 19.6 17.8295 19.6 16.6254H18.4C18.4 17.4374 17.9963 18.1684 17.324 18.5407L17.9053 19.5905ZM6.67601 18.5407C6.0037 18.1684 5.6 17.4374 5.6 16.6254H4.4C4.4 17.8295 5.00331 18.9861 6.09465 19.5905L6.67601 18.5407Z"
        fill={color}
      />
    </svg>
  </Box>
);
