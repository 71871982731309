import * as React from "react";
import { Layout } from "../Layout";
import {
  FourSquares,
  FourSquaresState,
  FourSquaresRoundPayload,
  TIME_OUT_MS,
} from "../../models/four-squares";
import { GamblingGameService } from "../../services/gambling-game-service";
import { fourSquaresUpdate } from "../../services/http-api-service";

import useTimer from "@sparkademy/app-common/hooks/useTimer";
import { ProgressBar } from "@sparkademy/app-common/components/ProgressBar";

import { Redirect, useHistory } from "react-router-dom";
import { Container } from "@sparkademy/app-common/elements/Container";
import { themeNew } from "@sparkademy/app-common/materials/theme";
import { Box, ThemeProvider } from "theme-ui";
import { GameFourSquaresFinished } from "./GameFourSquaresFinished";
import { GameFourSquaresPick } from "./GameFourSquaresPick";
import { GameFourSquaresStart } from "./GameFourSquaresStart";
import { GameFourSquaresRoundBefore } from "./GameFourSquaresRoundBefore";
import { useQuery } from "@sparkademy/app-common/utils/useQuery";
import { TrackingService } from "../../services/tracking-service";

const AFTER_PICK_TIME = 1000;
const PICK_TIME = 1500;
const PICKED_WAIT_TIME = 1500;
const TIMEOUT_MESSAGE_TIME = TIME_OUT_MS;
const JITTER_MAX_TIME = 2500;

const gameService = new GamblingGameService();

function generateInitialState(queryState: string | null): FourSquares {
  switch (queryState) {
    case "finished":
      return {
        type: "finished",
        step: 1,
        total: 1,
        state: {
          current: 0,
          finished: true,
          rounds: [],
        },
      };
    default:
      return { type: "start" };
  }
}

export const GameFourSquares: React.FC = () => {
  const [events, setEvents] = React.useState<{ event: string; time: Date }[]>([]);
  const query = useQuery();
  const cohort = query.get("cohort") || "sparkchecklite";

  const state = query.get("state");
  const initialState = generateInitialState(state);

  const history = useHistory();

  function addEvent(event: string) {
    setEvents(events.concat([{ event, time: new Date() }]));
  }

  const [nextTimeout1, setNextTimeout] = React.useState<Date | null>(null);
  const [lastPickStart, setLastPickStart] = React.useState(new Date());
  const [lastPickEnd, setLastPickEnd] = React.useState(new Date());

  const emailRef = React.useRef<string>("");

  const [gameState, setGameState] = React.useState<FourSquares>(initialState);

  const [currentLevel, setCurrentLevel] = React.useState<number>(3);

  const { pause, restart } = useTimer({
    expiryTimestamp: new Date().getTime() + Number.MAX_SAFE_INTEGER,
    onExpire: () => updateGame(null),
  });

  React.useEffect(() => {
    // Get the email saved from a previous test (spark check part 1 - emotions)
    const storedEmail = localStorage.getItem("gamblingTestEmail");
    if (storedEmail) {
      emailRef.current = storedEmail;
      localStorage.removeItem("gamblingTestEmail");
    }
  }, []);

  if (!localStorage.getItem("gamblingTestEmail") && !emailRef.current) {
    return <Redirect to="/spark-check-part1" />;
  }

  async function saveGameRound(gameState: FourSquaresState, cohort: string) {
    // update round in the database
    const round = gameState.rounds[gameState.current];
    const payload: FourSquaresRoundPayload = {
      index: gameState.current,
      total: round.total,
      trials: round.trials,
      payoffs: round.payoffs,
      timed_out: round.timeOuts,
      max_trials: round.maxTrials,
      optimal_total: round.optimalTotal,
      total_delay_ms: round.totalDelayMs,
    };

    await fourSquaresUpdate(payload, emailRef.current, cohort);
  }

  async function updateGame(choice: number | null) {
    let now = new Date();

    switch (gameState.type) {
      case "start": {
        addEvent(`start -> picking`);
        let gamblingArgs: number[] = [4, 30, 40];
        if (!!localStorage.getItem("gamblingTest")) {
          gamblingArgs = [4, 3, 3, 3];
        }


        let startState: FourSquaresState | undefined;
        try {
          startState = await gameService.start(
            gamblingArgs,
            { firstName: "", lastName: "", email: emailRef.current },
            cohort
          );
        } catch (error: any) {
          console.error(error);
          if (error.message == "game already finished") {
            TrackingService.attemptToRetakeSparkCheck(emailRef.current, "four_squares");
            history.push("/error?reason=spark-check-taken");
          } else if (error.message == "invalid cohort") {
            history.push("/error?reason=invalid-cohort");
          } else {
            history.push("/error");
          }
          return;
        }

        setGameState({
          type: "picking",
          state: startState!,
        });
        now = new Date();
        setLastPickStart(now);

        const nextTimeout = new Date(now.getTime() + PICK_TIME);
        restart(nextTimeout.getTime());
        setNextTimeout(nextTimeout);
        addEvent(`start -> picking (${nextTimeout.toISOString()})`);
        break;
      }
      case "picking":
        if (choice === null) {
          setGameState({
            ...gameState,
            type: "timeout",
          });
          now = new Date();
          setLastPickEnd(now);
          const nextTimeout = new Date(now.getTime() + TIMEOUT_MESSAGE_TIME);
          restart(nextTimeout.getTime());
          setNextTimeout(nextTimeout);
          addEvent(`picking -> timeout ${nextTimeout.toISOString()}`);
        } else {
          setGameState({
            ...gameState,
            type: "picked-wait",
            choice,
          });
          now = new Date();
          setLastPickEnd(now);
          addEvent(`picking -> pick-wait`);
          const nextTimeout = new Date(now.getTime() + PICKED_WAIT_TIME);
          restart(nextTimeout.getTime());
          setNextTimeout(nextTimeout);
        }
        break;
      case "round-before":
        if (gameState.nextRound === -1) {
          addEvent(`round-before -> round-before`);
          setGameState({
            ...gameState,
            nextRound: 0,
          });
        } else {
          now = new Date();
          setGameState({
            type: "picking",
            state: gameState.state,
          });
          setLastPickStart(now);
          const nextTimeout = new Date(now.getTime() + PICK_TIME);
          restart(nextTimeout.getTime());
          setNextTimeout(nextTimeout);
          addEvent(`round-before -> picking ${nextTimeout.toISOString()}`);
        }
        break;
      case "picked-wait":
        setGameState({
          type: "picked",
          state: gameState.state,
          choice: gameState.choice,
        });
        now = new Date();
        const nextTimeout = new Date(now.getTime() + AFTER_PICK_TIME);
        restart(nextTimeout.getTime());
        setNextTimeout(nextTimeout);
        addEvent(`picked-wait -> picked ${nextTimeout.toISOString()}`);
        break;
      case "timeout":
        pause();
        const timeoutUpdatedState = gameService.update(0, null);

        if (timeoutUpdatedState.finished) {
          addEvent(`timeout -> finished`);
          setCurrentLevel(4);
          setGameState({
            type: "finished",
            state: timeoutUpdatedState,
            total: timeoutUpdatedState.rounds.slice(1).reduce((acc, cur) => acc + cur.total, 0),
            step: 1,
          });
          saveGameRound(gameState.state, cohort);
          // round finished
        } else if (gameState.state.current !== timeoutUpdatedState.current) {
          addEvent(`timeout -> round-before`);
          // end of round
          setGameState({
            type: "round-before",
            nextRound: timeoutUpdatedState.current,
            previousRoundPoints: timeoutUpdatedState.rounds[gameState.state.current].total,
            state: timeoutUpdatedState,
          });
          saveGameRound(gameState.state, cohort);
        } else {
          // next pick
          setGameState({
            type: "picking",
            state: timeoutUpdatedState,
          });
          now = new Date();
          setLastPickStart(now);
          const nextTimeout = new Date(now.getTime() + PICK_TIME);
          restart(nextTimeout.getTime());
          setNextTimeout(nextTimeout);
          addEvent(`timeout -> picking ${nextTimeout.toISOString()}`);
        }
        break;
      case "picked":
        pause();
        const delay = lastPickEnd.getTime() - lastPickStart.getTime();
        const updatedState = gameService.update(delay, gameState.choice);
        // game finished

        if (updatedState.finished) {
          addEvent(`picked -> finished`);
          setCurrentLevel(4);
          setGameState({
            type: "finished",
            state: updatedState,
            total: updatedState.rounds.slice(1).reduce((acc, cur) => acc + cur.total, 0),
            step: 1,
          });
          saveGameRound(gameState.state, cohort);
          // round finished
        } else if (gameState.state.current !== updatedState.current) {
          addEvent(`picked -> round-before`);
          // end of round
          setGameState({
            type: "round-before",
            nextRound: updatedState.current,
            previousRoundPoints: updatedState.rounds[gameState.state.current].total,
            state: updatedState,
          });
          saveGameRound(gameState.state, cohort);
        } else {
          // jitter before next pick
          setGameState({
            type: "jitter-between-rounds",
            state: updatedState,
          });

          now = new Date();
          // randomJitter will be a value between 1500 and 2500
          const randomJitter = JITTER_MAX_TIME - Math.round(Math.random() * 1000);

          const nextTimeout = new Date(now.getTime() + randomJitter);
          restart(nextTimeout.getTime());
          setNextTimeout(nextTimeout);
          addEvent(`picked -> jitter-between-rounds ${nextTimeout.toISOString()}`);
        }
        break;
      case "jitter-between-rounds": {
        addEvent(`jitter-between-rounds -> picking`);
        setGameState({
          type: "picking",
          state: gameState.state,
        });

        now = new Date();
        setLastPickStart(now);

        const nextTimeout = new Date(now.getTime() + PICK_TIME);
        restart(nextTimeout.getTime());
        setNextTimeout(nextTimeout);

        break;
      }
      case "finished":
        addEvent(`finished -> finished`);
        setGameState({
          type: "finished",
          state: gameState.state,
          total: gameState.total,
          step: gameState.step + 1,
        });
        break;
      default:
        console.log("invalid game state");
        break;
    }
  }

  const currentRound = gameService.game?.round;

  return (
    <ThemeProvider theme={themeNew}>
      <Layout
        hideFooter
        sx={{
          bg: "new.primary.white",
        }}
        logoIsClickable={false}
      >
        <Container
          sx={{
            position: "relative",
            my: [7, 14],
          }}
        >
          <Box sx={{ width: ["95%", "348px"], m: ["20px 10px 48px", "0 auto 48px"] }}>
            <ProgressBar level={currentLevel} numLevels={3} />
          </Box>

          <pre
            style={{
              display: "none",
              // display: 'inline-block',
              zIndex: 10,
              position: "fixed",
              top: 0,
              left: 0,
              padding: 4,
              maxHeight: "80vh",
              overflow: "scroll",
              background: "black",
              color: "white",
            }}
          >
            {JSON.stringify(
              {
                lastPickStart,
                lastPickEnd,
                state: gameState.type,
                nextEventIn: nextTimeout1 ? nextTimeout1.getTime() - new Date().getTime() : 0,
              },
              null,
              2
            )}
          </pre>
          <pre
            style={{
              display: "none",
              position: "fixed",
              bottom: 0,
              right: 0,
              padding: 4,
              maxHeight: "80vh",
              overflow: "scroll",
              background: "black",
              color: "white",
            }}
          >
            {JSON.stringify(events, null, 2)}
          </pre>

          {gameState.type === "start" ? (
            <GameFourSquaresStart gameState={gameState} updateGame={updateGame} />
          ) : gameState.type === "round-before" ? (
            <GameFourSquaresRoundBefore gameState={gameState} updateGame={updateGame} />
          ) : ["timeout", "picking", "picked", "picked-wait", "jitter-between-rounds"].includes(
              gameState.type
            ) ? (
            <React.Fragment>
              <GameFourSquaresPick gameState={gameState} updateGame={updateGame} />
              <Box
                sx={{
                  fontWeight: 700,
                  fontSize: "24px",
                  display: "flex",
                  justifyContent: "center",
                  pt: "48px",
                }}
              >
                {`${(currentRound?.trials.length || 0) + 1}/${currentRound?.maxTrials}`}
              </Box>
            </React.Fragment>
          ) : gameState.type === "finished" ? (
            <GameFourSquaresFinished gameState={gameState} updateGame={updateGame} />
          ) : null}
        </Container>
      </Layout>
    </ThemeProvider>
  );
};
