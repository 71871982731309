import { Loader } from "@sparkademy/app-common/components/Loader";
import { useAtom } from "jotai";
import * as React from "react";
import { shouldShowLoaderAtom } from "@sparkademy/app-common/stores/ui";

export const LoaderWithState: React.FC = () => {
  const [shouldShowLoader] = useAtom(shouldShowLoaderAtom);

  if (!shouldShowLoader) return null;

  return <Loader bgColor="white" />;
};
