import * as React from "react";
import { Heading, Text, Box, SxProps } from "theme-ui";
import { uiCopy } from "../data/ui-copy";
import { Button, ButtonLink } from "@sparkademy/app-common/elements/Button";
import { ButtonOutlineLink } from "@sparkademy/app-common/elements/ButtonOutline";
import { ReactComponent as Calendar } from "@sparkademy/app-common/materials/icons/new/calendar.svg";
import { ReactComponent as Time } from "@sparkademy/app-common/materials/icons/new/time.svg";
import { Module, ModuleInfo } from "@sparkademy/app-common/models/module";
import { IconDescriptionList, IconDescriptionListItem } from "./IconDescriptionList";
import { dateFormatter } from "@sparkademy/app-common/utils/date-formatters";
import { toTitleCase } from "../utils/string-formatters";
import { ErrorCard } from "./ErrorCard";
import { useContentContext } from "@sparkademy/app-common/contexts/content-context";
import { ModuleAssignments } from "../components/ModuleAssignments";
import { CourseSignupDialog } from "./Course/CourseSignupDialog";
import { CourseRun } from "@sparkademy/app-common/models/course";
import { formatDistance } from "date-fns";
import { LevelIcon } from "@sparkademy/app-common/elements/Levelcon";

import { AddToCalendarButton } from "../components/Workshop/AddToCalendarButton";
import { ButtonGhostAnchor } from "@sparkademy/app-common/elements/ButtonGhost";
import { CourseCancelSignupDialog } from "./Course/CourseCancelSignupDialog";

type Props = {
  module: Module;
  numberOfFailedModules: number;
  showDuration?: boolean;
  sx?: SxProps;
};

const OpenCourseButton: React.FC<{ module: Module }> = ({ module }) => {
  return (
    <React.Fragment>
      {module.isCompleted || module.isFailed ? (
        <ButtonOutlineLink className="open-module-btn" to={module.materials.url!}>
          Open Course
        </ButtonOutlineLink>
      ) : module.isActive ? (
        // Render a different type of button when its active vs when its completed/failed
        <ButtonLink className="open-module-btn" to={module.materials.url!}>
          Open Course
        </ButtonLink>
      ) : (
        <Button disabled>Locked</Button>
      )}
    </React.Fragment>
  );
};

export const CardModule: React.FC<Props> = ({
  module,
  numberOfFailedModules,
  showDuration = true,
  sx = {},
}) => {
  const { modules } = useContentContext();
  const moduleInfo = modules.find(m => m.id.toLowerCase() === module.id.toLowerCase());
  const { levels } = useContentContext();

  const levelInfo = levels.find(l => l.id === moduleInfo?.levelId);

  if (!moduleInfo || !levelInfo) {
    return null;
  }

  return (
    <Box
      as="article"
      sx={{
        bg: "new.primary.white",
        borderRadius: 10,
        flex: "1 1 auto",
        p: 8,
        border: module.isStandaloneModule ? "1px solid black" : "none",
        // Space between modules, so that the left timeline fits this gap
        // mb: nextModule ? 8 : 0,
        ...sx,
      }}
      id={module.id}
    >
      <Box sx={{ display: "flex", mb: 9 }}>
        <LevelIcon
          level={levelInfo}
          sx={{
            width: theme => theme.space[7],
          }}
        />
        <Box sx={{ ml: 4 }} />
        <Text as="p" sx={{ fontSize: 1 }}>
          {levelInfo.name}
        </Text>
      </Box>
      <Heading as="h2" sx={{ fontSize: 3, fontWeight: "bold" }}>
        {moduleInfo.name}
      </Heading>

      <Box
        sx={{
          maxWidth: "65ch",
          fontSize: 0,
          mt: 8,
        }}
      >
        <Text
          as="p"
          sx={{
            fontSize: 0,
            lineHeight: "body",
          }}
        >
          {moduleInfo.description}
        </Text>

        {module.status === "failed" && (
          <ErrorCard sx={{ mt: 7 }}>
            {uiCopy.MODULE_CARD_FAILED_TEXT(toTitleCase(moduleInfo.level))}
          </ErrorCard>
        )}
        {module.status === "failed-retries-expired" ? (
          <ErrorCard sx={{ mt: 7 }}>
            {numberOfFailedModules === 1
              ? uiCopy.LEVEL_CARD_FAILED_RETRY_TWICE_TEXT()
              : uiCopy.LEVEL_CARD_FAILED_TWO_MODULES_TEXT()}
          </ErrorCard>
        ) : null}
      </Box>

      <Box sx={{ mt: 8 }} />

      <IconDescriptionList>
        {showDuration && (
          <IconDescriptionListItem
            titlesx={{ fontWeight: 700 }}
            id="duration"
            Icon={Calendar}
            title="Duration"
          >
            <Box sx={{ color: "new.secondary.violet", fontWeight: 500 }}>
              {dateFormatter.format(new Date(module.materials.start))}
              &mdash;
              <br />
              {dateFormatter.format(new Date(module.assessment.end))}
            </Box>
          </IconDescriptionListItem>
        )}

        <IconDescriptionListItem
          titlesx={{ fontWeight: 700 }}
          id="time-commitment"
          Icon={Time}
          title="Time Commitment"
        >
          <Box sx={{ color: "new.secondary.violet", fontWeight: 500 }}>{moduleInfo.commitment}</Box>
        </IconDescriptionListItem>
      </IconDescriptionList>

      <Box sx={{ mt: 7 }} />

      {module.materials.url && (
        <Box
          id="button-container"
          sx={{
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <OpenCourseButton module={module} />
        </Box>
      )}
      <ModuleAssignments module={module} />
    </Box>
  );
};
type EnrollmentProps = {
  module: ModuleInfo;
  sx: SxProps;
  courseRuns: CourseRun[];
  courseRun?: CourseRun;
};
export const CardCourseEnrollment: React.FC<EnrollmentProps> = ({
  module,
  sx = {},
  courseRuns,
  courseRun,
}) => {
  const { modules } = useContentContext();
  const [enrollment, setEnrollment] = React.useState<CourseRun | undefined>(courseRun);

  const [showDialog, setShowDialog] = React.useState(false);
  const [showCancelDialog, setShowCancelDialog] = React.useState(false);

  const { levels } = useContentContext();
  const levelInfo = levels.find(l => l.id === module.levelId);

  const moduleInfo = modules.find(m => m.id.toLowerCase() === module.id.toLowerCase());
  if (!moduleInfo || !levelInfo) {
    return null;
  }

  return (
    <Box
      as="article"
      sx={{
        bg: "new.primary.white",
        borderRadius: 10,
        flex: "1 1 auto",
        p: 8,
        // Space between modules, so that the left timeline fits this gap
        // mb: nextModule ? 8 : 0,
        mb: 10,
        ...sx,
      }}
    >
      {showDialog && (
        <CourseSignupDialog
          onClose={() => setShowDialog(false)}
          courseRuns={courseRuns}
          onSubmit={courseRun => {
            setEnrollment(courseRun);
          }}
        />
      )}
      {showCancelDialog && <CourseCancelSignupDialog onClose={() => setShowCancelDialog(false)} />}
      <Box sx={{ display: "flex", mb: 5 }}>
        <LevelIcon
          level={levelInfo}
          sx={{
            width: theme => theme.space[7],
          }}
        />
        <Box sx={{ ml: 4 }} />
        <Text as="p" sx={{ fontSize: 1 }}>
          {levelInfo.name}
        </Text>
      </Box>
      <Heading as="h2" sx={{ fontSize: 3, fontWeight: "bold" }}>
        {moduleInfo.name}
      </Heading>

      <Box
        sx={{
          maxWidth: "65ch",
          fontSize: 0,
          mt: 5,
        }}
      >
        <Text
          as="p"
          sx={{
            fontSize: 0,
            lineHeight: "body",
          }}
        >
          {moduleInfo.description}
        </Text>
      </Box>

      <Box sx={{ mt: 7 }} />

      <IconDescriptionList>
        {enrollment ? (
          <IconDescriptionListItem
            titlesx={{ fontWeight: 700 }}
            id="Begins-Date"
            Icon={Calendar}
            title="Begins"
          >
            <Box sx={{ color: "new.secondary.violet", fontWeight: 500 }}>
              {dateFormatter.format(enrollment.schedule.startOn)}
            </Box>
          </IconDescriptionListItem>
        ) : (
          <IconDescriptionListItem
            titlesx={{ fontWeight: 700 }}
            id="Duration-time"
            Icon={Calendar}
            title="Duration"
          >
            <Box sx={{ color: "new.secondary.violet", fontWeight: 500 }}>
              {formatDistance(courseRuns[0].schedule.endOn, courseRuns[0].schedule.startOn)}
            </Box>
          </IconDescriptionListItem>
        )}
        <IconDescriptionListItem
          titlesx={{ fontWeight: 700 }}
          id="time-commitment"
          Icon={Time}
          title="Time Commitment"
        >
          <Box sx={{ color: "new.secondary.violet", fontWeight: 500 }}>{moduleInfo.commitment}</Box>
        </IconDescriptionListItem>
      </IconDescriptionList>

      <Box sx={{ mt: 8 }}>
        {enrollment ? (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: ["center", "flex-end"],
              alignItems: "end",
            }}
          >
            <AddToCalendarButton
              isFilled
              title="Sparkademy Course"
              events={[{ startAt: enrollment.schedule.startOn, endAt: enrollment.schedule.endOn }]}
              link="/Overview"
            />
            <ButtonGhostAnchor onClick={() => setShowCancelDialog(true)}>
              Cancel Enrollment
            </ButtonGhostAnchor>
          </Box>
        ) : (
          <Box
            sx={{
              display: "flex",
              justifyContent: ["center", "flex-end"],
              flexWrap: "wrap",
              m: -4,
              "& > *": {
                p: 4,
              },
            }}
          >
            <Button
              disabled={Boolean(enrollment)}
              sx={{ minWidth: "150px" }}
              onClick={() => setShowDialog(true)}
            >
              Join a Cohort
            </Button>
          </Box>
        )}
      </Box>
    </Box>
  );
};
