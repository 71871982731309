import * as React from "react";
import { FourSquares } from "../../models/four-squares";
import { Heading, Box } from "theme-ui";
import { Container } from "@sparkademy/app-common/elements/Container";
import { Button } from "@sparkademy/app-common/elements/Button";
import { ReactComponent as IllustrationFourSquares } from "@sparkademy/app-common/materials/illustrations/four-squares.svg";

import { Narrative } from "@sparkademy/app-common/elements/Narrative";
import useEventListener from "@use-it/event-listener";

export const GameFourSquaresRoundBefore: React.FC<{
  gameState: FourSquares;
  updateGame: (choice: number | null) => void;
}> = ({ gameState, updateGame }) => {
  React.useEffect(() => {
    // This is used to show a confirmation prompt for the user before navigating away
    const handler = (e: Event) => {
      e.preventDefault();
      e.returnValue = true;
    };

    window.addEventListener("beforeunload", handler);

    return () => window.removeEventListener("beforeunload", handler);
  }, []);

  useEventListener("keydown", (event: KeyboardEvent) => {
    // @ts-ignore
    if (event.keyCode === 13) {
      updateGame(null);
    }
  });

  if (gameState.type !== "round-before") {
    return null;
  }

  return (
    <Container
      sx={{
        textAlign: "center",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          width: 170,
          height: 170,
          svg: {
            width: "100%",
            height: "auto",
          },
        }}
      >
        <IllustrationFourSquares />
      </Box>

      <Heading
        as="h1"
        sx={{
          fontSize: [2, 3],
          fontWeight: "bold",
          textAlign: "center",
          my: "40px",
        }}
      >
        {gameState.nextRound === 1
          ? "Practice Round Completed"
          : gameState.nextRound === 2
          ? "First Round Completed"
          : gameState.nextRound === 3
          ? "Second Round Completed"
          : null}
      </Heading>
      <Narrative>
        {gameState.nextRound === 1 ? (
          <p>
            Well done! Now that you are ready, click <b>Start</b> to begin Part 2.
          </p>
        ) : gameState.nextRound === 2 ? (
          `Well done! Your result is ${gameState.previousRoundPoints || 0} points.`
        ) : gameState.nextRound === 3 ? (
          `Well done! Your result is ${gameState.previousRoundPoints || 0} points.`
        ) : null}
      </Narrative>

      <Button sx={{ mt: 11 }} onClick={() => updateGame(null)}>
        {gameState.nextRound === 1
          ? "Start"
          : gameState.nextRound === 2
          ? "Start Second Round"
          : gameState.nextRound === 3
          ? "Start Final Round"
          : null}
      </Button>
    </Container>
  );
};
