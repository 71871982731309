import { Assignment, Exercise } from "@sparkademy/app-common/models/assignment";
import { Module } from "@sparkademy/app-common/models/module";

export class TrackingService {
  static track(event: string, props: object = {}) {
    if (!window.E2E_TESTING && !window.IMPERSONATE_SESSION) {
      console.info(`Tracking event: ${event}`, props);
      window.analytics && window.analytics.track(event, props);
    }
  }

  static headerLogoClick() {
    this.track("Header_Logo Clicked");
  }

  static logoutClick() {
    this.track("Header_Logout_Button Clicked");
  }

  static communityClick() {
    this.track("Header_Community_Button Clicked");
  }

  static sideMenuActivated() {
    this.track("Side_Menu Activated");
  }

  static sideMenuItemClicked(menuItem: string) {
    this.track("Side_Menu_Item Clicked", { menuItem });
  }

  static footerLinkClick(url: string) {
    this.track("Footer_Link Clicked", { url });
  }

  static staticPageBackClick() {
    this.track("Static_Page_Back_Button Clicked");
  }

  static moduleCourseClick(module: Module) {
    this.track("Module_Card_Open_Button Clicked", { id: module.id, status: module.status });
  }

  static downloadCertificateClick(userId: string, level: string) {
    this.track("Certificate_Download_Button Clicked", { userId, level });
  }

  static shareCertificateOnLinkedInClick(userId: string, level: string) {
    this.track("Certificate_Share_Button Clicked", { userId, level });
  }

  static addCertificateToLinkedInClick(userId: string, level: string) {
    this.track("Certificate_Add_To_Profile_Button Clicked", { userId, level });
  }

  static methodKitRequestToLogIn(email: string) {
    this.track("Public_Method_Kit Login_Attempt", { email });
  }

  static methodKitLoggedIn(email: string) {
    this.track("Public_Method_Kit Logged_In", { email });
  }

  static methodKitFailedLogIn(email: string, token: string = "") {
    this.track("Public_Method_Kit Login_Failed", { email, token });
  }

  static attemptToRetakeSparkCheck(email: string, part: string) {
    this.track("Spark_Check Retake_Attempt", { email, part });
  }

  static videoPlay(userId: string, videoID: string, title: string, currentTime: number) {
    this.track("Video Played", { userId, videoID, title, currentTime });
  }
  static videoStop(userId: string, videoID: string, title: string, currentTime: number) {
    this.track("Video Stopped", { userId, videoID, title, currentTime });
  }
  static videoSpeedChange(
    userId: string,
    videoID: string,
    title: string,
    currentTime: number,
    playbackRate: number
  ) {
    this.track("Video Speed_Changed", { userId, videoID, title, currentTime, playbackRate });
  }

  static videoEnded(userId: string, videoID: string, title: string, currentTime: number) {
    this.track("Video Ended", { userId, videoID, title, currentTime });
  }
  static videoSeekStart(userId: string, videoID: string, title: string, currentTime: number) {
    this.track("Video Seeking_Started", { userId, videoID, title, currentTime });
  }
  static videoSeekEnd(userId: string, videoID: string, title: string, currentTime: number) {
    this.track("Video Seeking_Ended", { userId, videoID, title, currentTime });
  }
  static videoAborted(userId: string, videoID: string, title: string, currentTime: number) {
    this.track("Video Aborted", { userId, videoID, title, currentTime });
  }
  static videoError(userId: string, videoID: string, title: string, currentTime: number) {
    this.track("Video Errored", { userId, videoID, title, currentTime });
  }
  static videoTrackChange(userId: string, videoID: string, title: string, currentTime: number) {
    this.track("Video Track_Changed", { userId, videoID, title, currentTime });
  }

  static courseNavigationNextButtonClick(
    courseTitle: string,
    lessonTitle: string,
    blockTitle: string,
    unitTitle: string
  ) {
    this.track("Lesson_Navigator_Next_Button Clicked", {
      courseTitle,
      lessonTitle,
      blockTitle,
      unitTitle,
    });
  }
  static courseNavigationPreviousButtonClick(
    courseTitle: string,
    lessonTitle: string,
    blockTitle: string,
    unitTitle: string
  ) {
    this.track("Lesson_Navigator_Back_Button Clicked", {
      courseTitle,
      lessonTitle,
      blockTitle,
      unitTitle,
    });
  }

  static lessonBlocksNavigatorClick(
    courseTitle: string,
    lessonTitle: string,
    blockTitle: string,
    unitTitle: string | null
  ) {
    unitTitle
      ? this.track("Lesson_Menu_Item Clicked", { courseTitle, lessonTitle, blockTitle, unitTitle })
      : this.track("Lesson_Menu_Item Clicked", { courseTitle, lessonTitle, blockTitle });
  }

  static breadcrumbItemClick(item: { name: string; url?: string }) {
    this.track("Breadcrumb_Item Clicked", { name: item.name, url: item.url });
  }

  static lessonBlockClick(courseTitle: string, lessonTitle: string, blockTitle: string) {
    this.track("Course_Dashboard_Lesson_Block Clicked", { courseTitle, lessonTitle, blockTitle });
  }

  static timeSpentOnCourseDashboard(courseTitle: string, startTime: Date, endTime: Date) {
    const timeSpentInSecs = (endTime.getTime() - startTime.getTime()) / 1000;
    if (timeSpentInSecs > 1) {
      this.track("Course_Dashboard Time_Spent", {
        courseTitle,
        timeSpentInSecs,
      });
    }
  }

  static timeSpentOnContent(
    courseTitle: string,
    lessonTitle: string,
    blockTitle: string,
    unitTitle: string,
    startTime: Date,
    endTime: Date
  ) {
    const timeSpentInSecs = (endTime.getTime() - startTime.getTime()) / 1000;
    if (timeSpentInSecs > 1) {
      this.track("Block_Unit Time_Spent", {
        courseTitle,
        lessonTitle,
        blockTitle,
        unitTitle,
        timeSpentInSecs,
      });
    }
  }
  static timeSpentOnPracticalAssignment(
    currentAssignment: Assignment,
    startTime: Date,
    endTime: Date
  ) {
    const timeSpentInSecs = (endTime.getTime() - startTime.getTime()) / 1000;
    if (timeSpentInSecs > 1) {
      this.track("Practical_Assignment Time_Spent", {
        assignment: currentAssignment.id,
        assignmentModule: currentAssignment.module_id,
        timeSpentInSecs,
      });
    }
  }
  static timeSpentOnPracticalAssignmentExercise(
    currentAssignment: Assignment,
    currentExercise: Exercise,
    startTime: Date,
    endTime: Date
  ) {
    const timeSpentInSecs = (endTime.getTime() - startTime.getTime()) / 1000;
    if (timeSpentInSecs > 1) {
      this.track("Practical_Assignment_Exercise Time_Spent", {
        assignment: currentAssignment.id,
        assignmentModule: currentAssignment.module_id,
        exerciseID: currentExercise.id,
        exerciseTitle: currentExercise.title,
        exerciseType: currentExercise.type,
        timeSpentInSecs,
      });
    }
  }
  static exerciseChanged(
    currentAssignment: Assignment,
    previousExercise: Exercise,
    nextExercise: Exercise
  ) {
    this.track("Practical_Assignment_Exercise Changed", {
      assignment: currentAssignment.id,
      assignmentModule: currentAssignment.module_id,
      prevExerciseID: previousExercise.id,
      prevExerciseTitle: previousExercise.title,
      prevExerciseType: previousExercise.type,
      nextExerciseID: nextExercise.id,
      nextExerciseTitle: nextExercise.title,
      nextExerciseType: nextExercise.type,
    });
  }
  static PracticalAssignmentExerciseSubmit(
    currentAssignment: Assignment,
    currentExercise: Exercise
  ) {
    this.track("Practical_Assignment_Exercise Submit", {
      assignment: currentAssignment.id,
      assignmentModule: currentAssignment.module_id,
      exerciseID: currentExercise.id,
      exerciseTitle: currentExercise.title,
      exerciseType: currentExercise.type,
    });
  }
  static PracticalAssignmentExerciseSave(currentAssignment: Assignment, currentExercise: Exercise) {
    this.track("Practical_Assignment_Exercise Save", {
      assignment: currentAssignment.id,
      assignmentModule: currentAssignment.module_id,
      exerciseID: currentExercise.id,
      exerciseTitle: currentExercise.title,
      exerciseType: currentExercise.type,
    });
  }
  static PracticalAssignmentDashboardClick(
    currentAssignment: Assignment,
    currentExercise: Exercise
  ) {
    this.track("Practical_Assignment_Dashboard_Button Click", {
      assignment: currentAssignment.id,
      assignmentModule: currentAssignment.module_id,
      exerciseID: currentExercise.id,
      exerciseTitle: currentExercise.title,
      exerciseType: currentExercise.type,
    });
  }
  static PracticalAssignmentMethodCardClick(
    currentAssignment: Assignment,
    currentExercise: Exercise,
    methodCard: string
  ) {
    this.track("Practical_Assignment_Method_Card Click", {
      assignment: currentAssignment.id,
      assignmentModule: currentAssignment.module_id,
      exerciseID: currentExercise.id,
      exerciseTitle: currentExercise.title,
      exerciseType: currentExercise.type,
      methodCard,
    });
  }
  static QuizAnswerSubmitted(
    courseId: string,
    lessonId: string,
    lessonBlockId: string,
    lessonBlockUnitId: string,
    quizContentId: string,
    questionId: string,
    optionIndex: number,
    optionWeight: number
  ) {
    this.track("Quiz_Answer Submitted", {
      courseId,
      lessonId,
      lessonBlockId,
      lessonBlockUnitId,
      quizContentId,
      questionId,
      optionIndex,
      optionWeight,
    });
  }

  static KnowledgeCheckSubmitted(
    courseId: string,
    lessonId: string,
    lessonBlockId: string,
    lessonBlockUnitId: string,
    knowledgeCheckContentId: string,
    knowledgeCheckContentTitle: string,
    totalPoints: number,
    earnedPoints: number,
    relativeScore: number,
    startTime: Date,
    endTime: Date
  ) {
    this.track("Knowledge_Check Submitted", {
      courseId,
      lessonId,
      lessonBlockId,
      lessonBlockUnitId,
      knowledgeCheckContentId,
      knowledgeCheckContentTitle,
      totalPoints,
      earnedPoints,
      relativeScore,
      timeSpentInSecs: (endTime.getTime() - startTime.getTime()) / 1000,
    });
  }
  static AddToCalendarClicked() {
    this.track("Add To Calendar Clicked", {});
  }

  static WorkshopSessionJoined(
    workshopId: string,
    workshopTitle: string,
    sessionId: string,
    sessionTitle: string,
    scheduleTime: string,
    schedulePart: string
  ) {
    this.track("Workshop_Session Joined", {
      workshopId,
      workshopTitle,
      sessionId,
      sessionTitle,
      scheduleTime,
      schedulePart,
    });
  }

  static WorkshopTutorialStarted(
    workshopId: string,
    workshopTitle: string,
    sessionId: string,
    sessionTitle: string
  ) {
    this.track("Workshop_Tutorial Started", {
      workshopId,
      workshopTitle,
      sessionId,
      sessionTitle,
    });
  }

  static ProductTourViewed(id: string) {
    this.track("ProductTour Viewed", {
      id,
    });
  }

  static timeSpentOnMotivationCheck(cohortId: string, startTime: Date, endTime: Date) {
    const timeSpentInSecs = (endTime.getTime() - startTime.getTime()) / 1000;
    if (timeSpentInSecs > 1) {
      this.track("Motivation_Check Time_Spent", {
        cohortId,
        timeSpentInSecs,
      });
    }
  }
}
