import * as React from "react";
import { SxStyleProp } from "theme-ui";

import { Box } from "theme-ui";
import { ReactComponent as Certificate } from "../materials/icons/certificate.svg";
import { LevelInfo } from "../models/level";

type Props = {
  level: LevelInfo;
  sx?: SxStyleProp;
};

export const LevelIcon: React.FC<Props> = ({ level, sx = {} }) => {
  let iconColor = "";
  if (level.id.toLowerCase() === "advanced") {
    iconColor = "new.primary.green";
  } else if (level.id.toLowerCase() === "core") {
    iconColor = "new.secondary.yellow";
  } else {
    iconColor = "new.primary.purple";
  }

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        svg: {
          width: "100%",
          height: "auto",
        },
        ".top": {
          fill: "new.primary.darkGrey",
        },
        ".bottom": {
          fill: iconColor,
        },
        ...sx,
      }}
    >
      <Certificate />
    </Box>
  );
};
