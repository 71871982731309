import * as React from "react";
import { Box } from "theme-ui";
import { ButtonGhost } from "@sparkademy/app-common/elements/ButtonGhost";
import { Button } from "@sparkademy/app-common/elements/Button";
import { courseAtom } from "../../stores/course";
import { BlockUnit, Course, Lesson, LessonBlock } from "@sparkademy/app-common/models/course";
import { getLessonCompleteLink, getBlockUnitLink, isLastUnitInCourse } from "./helpers";
import { getResumeCourseData } from "../../services/http-api-service";
import { useSessionContext } from "@sparkademy/app-common/contexts/session-context";
import { useHistory } from "react-router-dom";
import { ReactComponent as IllustrationPlay } from "@sparkademy/app-common/materials/illustrations/play.svg";
import { useAtom } from "jotai";
export const ResumeButton: React.FC<{
  courseId: string;
  type: "button" | "link";
}> = ({ courseId, type }) => {
  const [course] = useAtom(courseAtom);

  const { currentUser } = useSessionContext();
  const history = useHistory();

  const [shouldRender, setShouldRender] = React.useState(false);
  const resumeUrlRef = React.useRef<string>("");

  React.useEffect(() => {
    if (!course) return;

    getResumeCourseData(course.id, currentUser!).then(data => {
      const { lessonId, lessonBlockId, lessonBlockUnitId } = data;

      if (lessonId && lessonBlockId && lessonBlockUnitId) {
        const lesson = course.lessons.find(l => l.id === lessonId.toString());
        const block = lesson?.blocks.find(b => b.id === lessonBlockId.toString());
        const unit = block?.units.find(u => u.id === lessonBlockUnitId.toString());

        if (lesson && block && unit) {
          const link = getResumeLink(course, lesson, block, unit);
          resumeUrlRef.current = link;
        }
        if (
          !isLastUnitInCourse(course, lessonBlockUnitId) &&
          // not already at the resume course location
          !alreadyAtResumeLocation(resumeUrlRef.current, window.location.pathname)
        ) {
          setShouldRender(true);
        }
      }
    });
  }, [course, currentUser]);

  if (!course || !shouldRender) return null;

  const resumeButtonClick = async () => {
    if (resumeUrlRef.current) {
      history.push(resumeUrlRef.current);
    }
  };

  // button type link with icon for the lesson menu
  if (type === "link") {
    return (
      <Box sx={{ mt: "20px", width: "100%" }}>
        <Box sx={{ display: "flex" }}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <IllustrationPlay />
          </Box>
          <ButtonGhost
            sx={{
              p: 0,
              ml: "8px",
              lineHeight: 1.4,
              minHeight: "unset",
            }}
            onClick={resumeButtonClick}
          >
            Resume Course
          </ButtonGhost>
        </Box>
      </Box>
    );
  }

  // regular button for the course dashboard
  return (
    <Box sx={{ mt: "calc(48px - 16px)", width: "100%" }}>
      <Button onClick={resumeButtonClick}>Resume course</Button>
    </Box>
  );
};

function getResumeLink(
  course: Course,
  lesson: Lesson,
  currentBlock: LessonBlock,
  currentUnit: BlockUnit
) {
  const unitIndex = currentBlock.units.findIndex(unit => unit.id === currentUnit.id);
  const isLastUnit = currentBlock.units.length - 1 === unitIndex;

  if (isLastUnit) {
    return getNextLessonBlockLink(course, lesson, currentBlock);
  } else {
    return getBlockUnitLink(course, lesson, currentBlock, currentBlock.units[unitIndex + 1]!, true);
  }
}

function getNextLessonBlockLink(course: Course, lesson: Lesson, currentBlock: LessonBlock): string {
  const blockIndex = lesson.blocks.findIndex(block => block.id === currentBlock.id);
  const lessonIndex = course.lessons.findIndex(l => l.id === lesson.id);
  const isLastBlock = lesson.blocks.length - 1 === blockIndex;

  if (isLastBlock) {
    const nextLesson =
      lessonIndex + 1 < course.lessons.length ? course.lessons[lessonIndex + 1] : null;
    return nextLesson
      ? getBlockUnitLink(
          course,
          nextLesson,
          nextLesson.blocks[0],
          nextLesson.blocks[0].units[0],
          true
        )
      : getLessonCompleteLink(course, lesson);
  } else {
    const nextBlock = lesson.blocks[blockIndex + 1];
    return getBlockUnitLink(course, lesson, nextBlock, nextBlock.units[0], true);
  }
}

function alreadyAtResumeLocation(resumePath: string, currentPath: string): boolean {
  // remove query string from resume path
  resumePath = resumePath.split("?")[0];
  return resumePath === currentPath;
}
