/** @jsx jsx */
import { jsx, Box, SxStyleProp } from "theme-ui";
import * as React from "react";

export const HomeworkIcon: React.FC<{ sxOverride?: SxStyleProp; color: string }> = ({
  sxOverride,
  color,
}) => (
  <Box sx={{ mr: 3, display: "flex", ...sxOverride }}>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="2" y="2" width="20" height="20" fill="none" />
      <line x1="2" y1="11.975" x2="22" y2="11.975" stroke="white" strokeWidth="0.05" />
      <path
        d="M7.7031 20.0594L3.73544 20.8529C3.38557 20.9229 3.07711 20.6144 3.14709 20.2646L3.94062 16.2969C3.97934 16.1033 4.07449 15.9255 4.21409 15.7859L16.3944 3.60555C16.7893 3.2107 17.2922 2.94156 17.8397 2.83205C19.8189 2.43623 21.5638 4.18114 21.1679 6.16025C21.0584 6.70781 20.7893 7.2107 20.3944 7.60555L8.2141 19.7859C8.07449 19.9255 7.89669 20.0207 7.7031 20.0594Z"
        stroke={color}
      />
      <path d="M4 16V16C6.307 15.7437 8.25633 17.693 8 20V20" stroke={color} />
      <path d="M15 5V5C17.307 4.74367 19.2563 6.693 19 9V9" stroke={color} />
      <path d="M13 7V7C15.307 6.74367 17.2563 8.693 17 11V11" stroke={color} />
      <path d="M3.5 18.5V18.5C4.6893 18.2621 5.73786 19.3107 5.5 20.5V20.5" stroke={color} />
      <path d="M7 17L16 8" stroke={color} />
      <path
        d="M10.1464 6.85355C10.3417 7.04882 10.6583 7.04882 10.8536 6.85355C11.0488 6.65829 11.0488 6.34171 10.8536 6.14645L10.1464 6.85355ZM6.14645 10.8536C6.34171 11.0488 6.65829 11.0488 6.85355 10.8536C7.04882 10.6583 7.04882 10.3417 6.85355 10.1464L6.14645 10.8536ZM7.20711 3.20711L6.85355 3.56066L7.20711 3.20711ZM3.56066 6.14645L6.14645 3.56066L5.43934 2.85355L2.85355 5.43934L3.56066 6.14645ZM6.85355 3.56066L10.1464 6.85355L10.8536 6.14645L7.56066 2.85355L6.85355 3.56066ZM2.85355 7.56066L6.14645 10.8536L6.85355 10.1464L3.56066 6.85355L2.85355 7.56066ZM6.14645 3.56066C6.34171 3.3654 6.65829 3.3654 6.85355 3.56066L7.56066 2.85355C6.97487 2.26777 6.02513 2.26777 5.43934 2.85355L6.14645 3.56066ZM2.85355 5.43934C2.26777 6.02513 2.26777 6.97487 2.85355 7.56066L3.56066 6.85355C3.3654 6.65829 3.3654 6.34171 3.56066 6.14645L2.85355 5.43934Z"
        fill={color}
      />
      <path
        d="M5.85355 6.85355C6.04882 6.65829 6.04882 6.34171 5.85355 6.14645C5.65829 5.95118 5.34171 5.95118 5.14645 6.14645L5.85355 6.85355ZM4.35355 8.35355L5.85355 6.85355L5.14645 6.14645L3.64645 7.64645L4.35355 8.35355Z"
        fill={color}
      />
      <path
        d="M7.35355 8.35355C7.54882 8.15829 7.54882 7.84171 7.35355 7.64645C7.15829 7.45118 6.84171 7.45118 6.64645 7.64645L7.35355 8.35355ZM5.85355 9.85355L7.35355 8.35355L6.64645 7.64645L5.14645 9.14645L5.85355 9.85355Z"
        fill={color}
      />
      <path d="M16 20L17.5 18.5" stroke={color} strokeLinecap="round" />
      <path
        d="M13.8536 17.1464C13.6583 16.9512 13.3417 16.9512 13.1464 17.1464C12.9512 17.3417 12.9512 17.6583 13.1464 17.8536L13.8536 17.1464ZM17.8536 13.1464C17.6583 12.9512 17.3417 12.9512 17.1464 13.1464C16.9512 13.3417 16.9512 13.6583 17.1464 13.8536L17.8536 13.1464ZM13.1464 17.8536L16.4393 21.1464L17.1464 20.4393L13.8536 17.1464L13.1464 17.8536ZM18.5607 21.1464L21.1464 18.5607L20.4393 17.8536L17.8536 20.4393L18.5607 21.1464ZM21.1464 16.4393L17.8536 13.1464L17.1464 13.8536L20.4393 17.1464L21.1464 16.4393ZM21.1464 18.5607C21.7322 17.9749 21.7322 17.0251 21.1464 16.4393L20.4393 17.1464C20.6346 17.3417 20.6346 17.6583 20.4393 17.8536L21.1464 18.5607ZM16.4393 21.1464C17.0251 21.7322 17.9749 21.7322 18.5607 21.1464L17.8536 20.4393C17.6583 20.6346 17.3417 20.6346 17.1464 20.4393L16.4393 21.1464Z"
        fill={color}
      />
      <path
        d="M16.3536 17.3536C16.5488 17.1583 16.5488 16.8417 16.3536 16.6464C16.1583 16.4512 15.8417 16.4512 15.6464 16.6464L16.3536 17.3536ZM14.8536 18.8536L16.3536 17.3536L15.6464 16.6464L14.1464 18.1464L14.8536 18.8536Z"
        fill={color}
      />
    </svg>
  </Box>
);
