import * as React from "react";
import LogRocket from "logrocket";
import VisuallyHidden from "@reach/visually-hidden";
import { Box, Heading, Text } from "theme-ui";
import { Container } from "@sparkademy/app-common/elements/Container";
import { Button } from "@sparkademy/app-common/elements/Button";
import { Input } from "@sparkademy/app-common/elements/Input";
import { ReactComponent as IllustrationIdea } from "@sparkademy/app-common/materials/illustrations/idea.svg";
import { FourSquares } from "../../models/four-squares";
import { Narrative } from "@sparkademy/app-common/elements/Narrative";
import { Loader } from "@sparkademy/app-common/components/Loader";
import { PrivacyTermsCheckbox } from "../PrivacyTermsCheckbox";
import { PublicUserAttrs } from "@sparkademy/app-common/models/user";
import isEmail from "@sparkademy/app-common/utils/isEmail";

type UserDataState = PublicUserAttrs & { errors: { [field: string]: string } };

export const GameFourSquaresStart: React.FC<{
  gameState: FourSquares;
  updateGame: (choice: number | null) => void;
  userAttrs: UserDataState;
  setUserAttrs: (attrs: UserDataState) => void;
}> = ({ gameState, updateGame, userAttrs, setUserAttrs }) => {
  const [termsConsentGiven, setTermsConsentGiven] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(false);

  if (gameState.type !== "start") {
    return null;
  }

  if (loading) {
    return <Loader />;
  }

  return (
    <Container
      sx={{
        textAlign: "center",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          width: 170,
          height: 170,
          svg: {
            width: "100%",
            height: "auto",
          },
        }}
      >
        <IllustrationIdea />
      </Box>

      <Heading
        as="h1"
        sx={{
          fontSize: [2, 3],
          fontWeight: "bold",
          textAlign: "center",
          mb: 8,
        }}
      >
        Welcome to Spark Check
      </Heading>
      <Narrative>
        <p>
          Spark Check consists of two parts and requires a total of 40 minutes to complete.
          <br />
          Please put in your work email address and click “Next” to start.
        </p>
      </Narrative>
      <form
        onSubmit={event => {
          event.preventDefault();
          const errors: { [field: string]: string } = {};
          if (!userAttrs.firstName) {
            errors["firstName"] = "First name is required";
          }
          if (!userAttrs.lastName) {
            errors["lastName"] = "Last name is required";
          }
          if (!userAttrs.email) {
            errors["email"] = "Email is required";
          } else if (!isEmail(userAttrs.email)) {
            errors["email"] = "Email is invalid";
          }

          if (Object.keys(errors).length === 0) {
            setLoading(true);
            updateGame(null);
            localStorage.setItem("gamblingTestEmail", userAttrs.email);
            LogRocket.identify(userAttrs.email);
          } else {
            setUserAttrs({ ...userAttrs, errors });
          }
        }}
      >
        <Box
          sx={{
            mt: 8,
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <VisuallyHidden>
            <label htmlFor="firstName">First Name</label>
          </VisuallyHidden>
          <Input
            id="firstName"
            value={userAttrs.firstName}
            onChange={event =>
              setUserAttrs({
                ...userAttrs,
                firstName: event.currentTarget.value,
                errors: { ...userAttrs.errors, "firstName": "" },
              })
            }
            placeholder="First Name"
            sx={{
              width: [330, 500],
              mb: [4, 6],
              borderColor: userAttrs.errors["firstName"] ? "new.failingRed" : "auto",
            }}
          />

          <VisuallyHidden>
            <label htmlFor="lastName">Last Name</label>
          </VisuallyHidden>
          <Input
            id="lastName"
            value={userAttrs.lastName}
            onChange={event =>
              setUserAttrs({
                ...userAttrs,
                lastName: event.currentTarget.value,
                errors: { ...userAttrs.errors, "lastName": "" },
              })
            }
            placeholder="Last Name"
            sx={{
              width: [330, 500],
              mb: [4, 6],
              borderColor: userAttrs.errors["lastName"] ? "new.failingRed" : "auto",
            }}
          />

          <VisuallyHidden>
            <label htmlFor="email">Work Email Address</label>
          </VisuallyHidden>
          <Input
            type="email"
            value={userAttrs.email}
            onChange={event =>
              setUserAttrs({
                ...userAttrs,
                email: event.currentTarget.value,
                errors: { ...userAttrs.errors, "email": "" },
              })
            }
            placeholder="Work Email Address"
            sx={{
              width: [330, 500],
              mb: [4, 6],
              ":focus": {
                borderColor: userAttrs.errors["email"] ? "new.failingRed" : "auto",
              },
            }}
          />
        </Box>
        <Box
          sx={{
            my: 8,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {new Array(4).fill(0).map((e, i) => (
            <Box
              key={`box-${i}`}
              sx={{
                width: "10px",
                height: "10px",
                borderRadius: "50%",
                bg: i === 0 ? "new.primary.purple" : "new.secondary.grey",
                mx: 2,
              }}
            />
          ))}
        </Box>

        <PrivacyTermsCheckbox
          consentGiven={termsConsentGiven}
          setConsentGiven={setTermsConsentGiven}
        />

        <Button
          type="submit"
          disabled={
            Object.values(userAttrs.errors).filter(e => !!e).length > 0 || !termsConsentGiven
          }
        >
          Next
        </Button>
      </form>
    </Container>
  );
};
