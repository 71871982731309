import * as React from "react";
import { Box, Text } from "theme-ui";
import { ReactComponent as IllustrationQuestion } from "@sparkademy/app-common/materials/illustrations/question.svg";

import { ReactComponent as FaceVeryHappy } from "@sparkademy/app-common/materials/illustrations/face-very-happy.svg";
import { ReactComponent as FaceVeryHappyFilled } from "@sparkademy/app-common/materials/illustrations/face-very-happy-filled.svg";
import { ReactComponent as FaceHappy } from "@sparkademy/app-common/materials/illustrations/face-happy.svg";
import { ReactComponent as FaceHappyFilled } from "@sparkademy/app-common/materials/illustrations/face-happy-filled.svg";
import { ReactComponent as FaceNeutral } from "@sparkademy/app-common/materials/illustrations/face-neutral.svg";
import { ReactComponent as FaceNeutralFilled } from "@sparkademy/app-common/materials/illustrations/face-neutral-filled.svg";
import { ReactComponent as FaceSad } from "@sparkademy/app-common/materials/illustrations/face-sad.svg";
import { ReactComponent as FaceSadFilled } from "@sparkademy/app-common/materials/illustrations/face-sad-filled.svg";
import { ReactComponent as FaceVerySad } from "@sparkademy/app-common/materials/illustrations/face-very-sad.svg";
import { ReactComponent as FaceVerySadFilled } from "@sparkademy/app-common/materials/illustrations/face-very-sad-filled.svg";
import { LoaderInline } from "@sparkademy/app-common/components/Loader";
import { ButtonGhostAnchor, ButtonGhostLink } from "@sparkademy/app-common/elements/ButtonGhost";
import { AssignmentService } from "@sparkademy/app-common/services/assignment-service";
import { useSessionContext } from "@sparkademy/app-common/contexts/session-context";
import { Logger } from "@sparkademy/app-common/services/logger-service";
import { useAssignmentContext } from "@sparkademy/app-common/contexts/assignment-context";

const feedbackSurveyMap: { [key: string]: string } = {
  "m1_ex1": "https://airtable.com/shrJftz7C9zx0987A?prefill_exerciseID=M1_1",
  "m1_ex2": "https://airtable.com/shrJftz7C9zx0987A?prefill_exerciseID=M1_2",
  "m1_ex3": "https://airtable.com/shrJftz7C9zx0987A?prefill_exerciseID=M1_3",
  "m1_ex4": "https://airtable.com/shrJftz7C9zx0987A?prefill_exerciseID=M1_4",
  "m1_ex5": "https://airtable.com/shrJftz7C9zx0987A?prefill_exerciseID=M1_5",
  "m2_ex1": "https://airtable.com/shrJftz7C9zx0987A?prefill_exerciseID=M2_1",
  "m2_ex2": "https://airtable.com/shrJftz7C9zx0987A?prefill_exerciseID=M2_2",
  "m2_ex3": "https://airtable.com/shrJftz7C9zx0987A?prefill_exerciseID=M2_3",
  "m2_ex4": "https://airtable.com/shrJftz7C9zx0987A?prefill_exerciseID=M2_4",
  "m3_ex1": "https://airtable.com/shrJftz7C9zx0987A?prefill_exerciseID=M3_1",
  "m3_ex2": "https://airtable.com/shrJftz7C9zx0987A?prefill_exerciseID=M3_2",
  "m3_ex3": "https://airtable.com/shrJftz7C9zx0987A?prefill_exerciseID=M3_3",
  "m4_ex1": "https://airtable.com/shrJftz7C9zx0987A?prefill_exerciseID=M4_1",
  "m4_ex2": "https://airtable.com/shrJftz7C9zx0987A?prefill_exerciseID=M4_2",
  "m4_ex3": "https://airtable.com/shrJftz7C9zx0987A?prefill_exerciseID=M4_3",
  "m4_ex4": "https://airtable.com/shrJftz7C9zx0987A?prefill_exerciseID=M4_4",
};

export const FeedbackRating: React.FC<{
  moduleId: string;
  exerciseId: string;
  assignmentExerciseId: number;
  nextExerciseIndex: number | null;
}> = ({ moduleId, exerciseId, assignmentExerciseId, nextExerciseIndex }) => {
  const { currentUser } = useSessionContext();
  const { setAssignmentStatus, assignmentStatus } = useAssignmentContext();
  const exerciseStatus = assignmentStatus.find(as => as.exercise_id === exerciseId);

  const [rating, setRating] = React.useState<number | null>(null);
  const [submitted, setSubmitted] = React.useState(false);
  const [submitting, setSubmitting] = React.useState(false);
  const ratingValues = [1, 2, 3, 4, 5];

  const positiveFeedbackMessage =
    "We're glad to hear the feedback is useful! Keep up the good work!";
  const negativeFeedbackMessage = "We're sorry to hear that. We'll do better next time.";
  const neutralFeedbackMessage = "Thank you for your input, we appreciate it.";

  const feedbackSurveyUrl = feedbackSurveyMap[`${moduleId}_${exerciseId}`];

  const submitFeedbackRating = async (value: number) => {
    setSubmitting(true);

    try {
      await AssignmentService.rateExerciseFeedback(assignmentExerciseId, value, currentUser!);
      // update grader rating status for the current exercise in the local state
      setAssignmentStatus([
        ...assignmentStatus.filter(as => as.exercise_id !== exerciseId),
        { ...exerciseStatus!, grader_rating_status: "updating" },
      ]);
      setSubmitted(true);
    } catch (error) {
      Logger.error(error);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        pb: 8,
      }}
    >
      <Box sx={{ maxWidth: "600px" }}>
        <Box
          sx={{
            margin: "auto",
            width: 170,
            height: 170,
            svg: {
              width: "100%",
              height: "auto",
            },
          }}
        >
          <IllustrationQuestion />
        </Box>

        <React.Fragment>
          <Box>
            <Text
              as="p"
              sx={{
                textAlign: "center",
                fontWeight: 700,
                fontSize: "25px",
                mb: 8,
                mt: 5,
              }}
            >
              How satisfied are you with the feedback you received on this exercise?
            </Text>

            {!submitting && (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "350px",
                  margin: "auto",
                }}
              >
                {ratingValues.map(value => (
                  <Face
                    key={value}
                    value={value}
                    selected={rating === value}
                    onClick={async () => {
                      setRating(value);
                      await submitFeedbackRating(value);
                    }}
                  />
                ))}
              </Box>
            )}

            {submitting && <LoaderInline />}
          </Box>
        </React.Fragment>

        {submitted && !submitting && (
          <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
            <Box>
              <Text
                as="p"
                sx={{
                  textAlign: "center",
                  mt: 5,
                }}
              >
                {rating && rating > 3 ? positiveFeedbackMessage : null}
                {rating && rating === 3 ? neutralFeedbackMessage : null}
                {rating && rating < 3 ? negativeFeedbackMessage : null}
              </Text>
            </Box>

            {feedbackSurveyUrl && (
              <Box sx={{ mt: 5 }}>
                To give more detailed feedback,&nbsp;
                <ButtonGhostAnchor
                  sx={{ px: 0, py: 0, fontSize: 0, textDecoration: "underline" }}
                  href={feedbackSurveyUrl}
                  target="_blank"
                >
                  click here
                </ButtonGhostAnchor>
              </Box>
            )}

            {nextExerciseIndex && (
              <ButtonGhostLink
                sx={{ px: 0, py: 0, fontSize: 0, mt: 5, textDecoration: "underline" }}
                to={`/practical-assignment/${moduleId}?activeExerciseIndex=${nextExerciseIndex}`}
              >
                Continue to next exercise →
              </ButtonGhostLink>
            )}
          </Box>
        )}
      </Box>
    </Box>
  );
};

export const Face: React.FC<{ value: number; selected: boolean; onClick: () => void }> = ({
  value,
  selected,
  onClick,
}) => {
  const getFace = (value: number, selected: boolean) => {
    switch (value) {
      case 1:
        return selected ? <FaceVerySadFilled /> : <FaceVerySad />;
      case 2:
        return selected ? <FaceSadFilled /> : <FaceSad />;
      case 3:
        return selected ? <FaceNeutralFilled /> : <FaceNeutral />;
      case 4:
        return selected ? <FaceHappyFilled /> : <FaceHappy />;
      case 5:
        return selected ? <FaceVeryHappyFilled /> : <FaceVeryHappy />;
      default:
        return null;
    }
  };

  return (
    <Box sx={{ mb: 4, cursor: "pointer" }} onClick={onClick}>
      {getFace(value, selected)}
    </Box>
  );
};
