import * as React from "react";
import {
  AnswersMap,
  UploadedFile,
  GradingFeedbackMap,
  GradingScoresMap,
  GradingCriteriaAppliedMap,
} from "../models/assignment";
import { User } from "../models/user";
import { AssignmentService } from "../services/assignment-service";

export type UpdateAnswerFn = (questionId: string, answer: string | UploadedFile) => void;

type LastActionsLog = {
  lastSavedAt?: Date;
  lastSavedById?: string;
  lastSavedByName?: string;
  submittedAt?: Date;
  submittedById?: string;
  submittedByName?: string;
};

type GraderRatingState = "unfetched" | "submitted" | "pending";

const AssignmentAnswersContext = React.createContext<{
  localAnswers: AnswersMap;
  savedAnswers: AnswersMap;
  updateLocalAnswer: UpdateAnswerFn;
  clearLocalAnswers: () => void;
  clearSavedAnswers: () => void;
  savedFeedback: GradingFeedbackMap;
  savedScores: GradingScoresMap;
  savedCriteriaApplied: GradingCriteriaAppliedMap;
  lastActionsLog: LastActionsLog | null;
  graderRatingState: GraderRatingState;
  fetchExerciseAnswers: (assignmentExerciseId: number, user: User) => void;
  setScore: (evaluationItemId: string, value: number) => void;
  clearScores: () => void;
  addFeedback: (evaluationItemId: string, values: string[]) => void;
  updateFeedback: (evaluationItemId: string, index: number, value: string) => void;
  clearFeedback: () => void;
  dirtyState: boolean;
  setDirtyState: (val: boolean) => void;
  clearLastActionsLog: () => void;
  clearGraderRatingState: () => void;
}>({
  localAnswers: new Map(),
  savedAnswers: new Map(),
  clearSavedAnswers: () => null,
  savedFeedback: {},
  savedScores: {},
  savedCriteriaApplied: {},
  lastActionsLog: null,
  graderRatingState: "unfetched",
  fetchExerciseAnswers: () => null,
  updateLocalAnswer: () => null,
  clearLocalAnswers: () => null,
  setScore: () => null,
  clearScores: () => null,
  addFeedback: () => null,
  updateFeedback: () => null,
  clearFeedback: () => null,
  dirtyState: false,
  setDirtyState: () => null,
  clearLastActionsLog: () => null,
  clearGraderRatingState: () => null,
});

export const useAssignmentAnswersContext = () => React.useContext(AssignmentAnswersContext);

export const AssignmentAnswersContextProvider: React.FC = props => {
  const [savedAnswers, setSavedAnswers] = React.useState<AnswersMap>(new Map());
  const savedFeedbackRef = React.useRef<GradingFeedbackMap>({});
  const assignmentExerciseIdRef = React.useRef<number | null>(null);
  const savedScoresRef = React.useRef<GradingScoresMap>({});
  const lastActionsLogRef = React.useRef<LastActionsLog | null>(null);
  const graderRatingStateRef = React.useRef<GraderRatingState>("unfetched");
  const savedCriteriaAppliedRef = React.useRef<GradingCriteriaAppliedMap>({});
  const [dirtyState, setDirtyState] = React.useState<boolean>(false);

  const localAnswersRef = React.useRef<AnswersMap>(new Map());
  const updateLocalAnswer = (questionId: string, answer: string | UploadedFile) => {
    localAnswersRef.current.set(questionId, answer);
  };
  const clearLocalAnswers = () => {
    assignmentExerciseIdRef.current = null;
    localAnswersRef.current = new Map();
    setDirtyState(false);
  };

  const fetchExerciseAnswers = React.useCallback((assignmentExerciseId: number, user: User) => {
    // prevents fetching answers for the same exercise multiple times
    if (assignmentExerciseIdRef.current === assignmentExerciseId) {
      return;
    }

    assignmentExerciseIdRef.current = assignmentExerciseId;
    clearSavedAnswers();
    AssignmentService.getExerciseAnswers(assignmentExerciseId, user)
      .then(res => {
        const answers: AnswersMap = new Map();
        for (const answer of res.answers) {
          if (answer.upload_id) {
            // answer of `file` type
            answers.set(answer.question_id, { id: answer.upload_id, file_url: answer.file_url });
          } else {
            // answer of `text` type
            answers.set(answer.question_id, answer.answer);
          }
        }

        lastActionsLogRef.current = {
          lastSavedAt: res.last_saved_at ? new Date(res.last_saved_at * 1000) : undefined,
          lastSavedById: res.last_saved_by_id,
          lastSavedByName: res.last_saved_by_name,
          submittedAt: res.submitted_at,
          submittedById: res.submitted_by_id,
          submittedByName: res.submitted_by_name,
        };

        if (res.feedback) {
          savedFeedbackRef.current = res.feedback;
        }

        if (graderRatingStateRef.current === "unfetched" && res.grader_rating) {
          graderRatingStateRef.current = "submitted";
        } else {
          graderRatingStateRef.current = "pending";
        }

        if (res.criteria_applied) {
          savedCriteriaAppliedRef.current = res.criteria_applied;
        }

        // should update state after all the refs are updated, otherwise components relying on the refs will not be updated
        setSavedAnswers(answers);
      })
      .catch(err => {
        console.error(err);
      });
  }, []);

  const clearSavedAnswers = () => {
    setSavedAnswers(new Map());
  };

  const setScore = (evaluationItemId: string, value: number) => {
    savedScoresRef.current[evaluationItemId] = value;
  };

  const clearScores = () => {
    savedScoresRef.current = {};
  };

  const addFeedback = (evaluationItemId: string, values: string[]) => {
    savedFeedbackRef.current[evaluationItemId] = values;
  };

  const clearFeedback = () => {
    savedFeedbackRef.current = {};
  };

  const updateFeedback = (evaluationItemId: string, index: number, value: string) => {
    const itemFeedback = savedFeedbackRef.current[evaluationItemId];
    if (itemFeedback) {
      itemFeedback[index] = value;
    }
  };

  const clearLastActionsLog = () => {
    lastActionsLogRef.current = null;
  };

  const clearGraderRatingState = () => {
    graderRatingStateRef.current = "unfetched";
  };

  return (
    <AssignmentAnswersContext.Provider
      value={{
        localAnswers: localAnswersRef.current,
        savedAnswers,
        updateLocalAnswer,
        clearLocalAnswers,
        savedFeedback: savedFeedbackRef.current,
        savedScores: savedScoresRef.current,
        savedCriteriaApplied: savedCriteriaAppliedRef.current,
        lastActionsLog: lastActionsLogRef.current,
        graderRatingState: graderRatingStateRef.current,
        setScore,
        clearScores,
        addFeedback,
        updateFeedback,
        clearFeedback,
        clearSavedAnswers,
        fetchExerciseAnswers,
        dirtyState,
        setDirtyState,
        clearLastActionsLog,
        clearGraderRatingState,
      }}
    >
      {props.children}
    </AssignmentAnswersContext.Provider>
  );
};
