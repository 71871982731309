import * as React from "react";
import { FourSquares } from "../../models/four-squares";
import { Text, Heading } from "theme-ui";
import { Container } from "@sparkademy/app-common/elements/Container";
import { Button } from "@sparkademy/app-common/elements/Button";

import { Narrative } from "@sparkademy/app-common/elements/Narrative";
import useEventListener from "@use-it/event-listener";

export const GameFourSquaresRoundBefore: React.FC<{
  gameState: FourSquares;
  updateGame: (choice: number | null) => void;
}> = ({ gameState, updateGame }) => {
  React.useEffect(() => {
    // This is used to show a confirmation prompt for the user before navigating away
    const handler = (e: Event) => {
      e.preventDefault();
      e.returnValue = true;
    };

    window.addEventListener("beforeunload", handler);

    return () => window.removeEventListener("beforeunload", handler);
  }, []);

  useEventListener("keydown", (event: KeyboardEvent) => {
    // @ts-ignore
    if (event.keyCode === 13) {
      updateGame(null);
    }
  });

  if (gameState.type !== "round-before") {
    return null;
  }

  return (
    <Container
      sx={{
        textAlign: "center",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <Heading
        as="h1"
        sx={{
          fontSize: [2, 3],
          fontWeight: "bold",
          textAlign: "center",
          mb: 4,
        }}
      >
        {gameState.nextRound === 1
          ? "Practice Round Completed"
          : gameState.nextRound === 2
          ? "First Round Completed"
          : gameState.nextRound === 3
          ? "Second Round Completed"
          : null}
      </Heading>
      <Text
        sx={{
          fontSize: [25, 50],
          fontWeight: "bold",
          color: "brand.blue",
          lineHeight: 1.4,
          mb: 4,
        }}
      >
        {gameState.nextRound === 1
          ? `Well done! Your result is ${gameState.previousRoundPoints || 0} points.`
          : gameState.nextRound === 2
          ? `Well done! Your result is ${gameState.previousRoundPoints || 0} points.`
          : gameState.nextRound === 3
          ? `Well done! Your result is ${gameState.previousRoundPoints || 0} points.`
          : null}
      </Text>
      <Narrative>
        {gameState.nextRound === 1 ? (
          <p>
            The first part of the Spark Check consists of 3 rounds with 75 tries each. There will be
            a short break between the rounds. Click on the button below to start.
          </p>
        ) : gameState.nextRound === 2 ? (
          <p>
            There is a certain logic to maximize your score. You are consciously or unconsciously
            uncovering the pattern to do so. Give it another try!
          </p>
        ) : gameState.nextRound === 3 ? (
          <p>Take a deep breath, it’s time to relax for a minute.</p>
        ) : null}
      </Narrative>

      <Button sx={{ mt: 11 }} onClick={() => updateGame(null)}>
        {gameState.nextRound === 1
          ? "Start Round 1"
          : gameState.nextRound === 2
          ? "Start Second Round"
          : gameState.nextRound === 3
          ? "Start Final Round"
          : null}
      </Button>
    </Container>
  );
};
