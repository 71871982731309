/** @jsx jsx */
import { jsx, SxStyleProp, Textarea } from "theme-ui";
import * as React from "react";

const styles: SxStyleProp = {};

type Props = {};

const getScrollHeight = (el: ExpandableTextAreaElement) => {
  const savedValue = el.value;
  el.value = "";
  el._baseScrollHeight = el.scrollHeight;
  el.value = savedValue;
};

const onExpandableTextareaInput = (el: ExpandableTextAreaElement) => {
  if (el === null) {
    return;
  }

  const minRows = parseInt(el.getAttribute("data-min-rows") as string);
  !el._baseScrollHeight && getScrollHeight(el);

  el.rows = minRows;
  const rows = Math.ceil((el.scrollHeight - el._baseScrollHeight) / 22);
  el.rows = minRows + rows;
};

type ExpandableTextAreaElement = HTMLTextAreaElement & { _baseScrollHeight: number };

export const TextareaInput: React.FC<Props & React.HTMLProps<HTMLInputElement>> = ({
  value,
  onChange,
  children,
  sx = {},
  ...rest
}) => {
  const inputRef = React.useRef<ExpandableTextAreaElement>(null);

  React.useEffect(() => {
    if (inputRef.current) {
      onExpandableTextareaInput(inputRef.current);
    }
  }, [inputRef.current]);

  return (
    <Textarea
      ref={inputRef}
      sx={{
        border: "2px solid",
        borderColor: "new.secondary.grey",
        fontFamily: "inherit",
        fontSize: "16px",
        lineHeight: "22px",
        py: "32px",
        px: "48px",
        outline: "none",
        ...sx,
      }}
      rows={3}
      data-min-rows="3"
      value={value}
      onChange={onChange}
      onInput={ev => onExpandableTextareaInput(ev.target as ExpandableTextAreaElement)}
      {...rest}
    />
  );
};
