import { ThemeProvider } from "theme-ui";
import * as React from "react";
import { pages } from "../data/static-content";
import { Layout } from "../components/Layout";
import { Container } from "@sparkademy/app-common/elements/Container";
import { Narrative } from "@sparkademy/app-common/elements/Narrative";
import { Heading, Box } from "theme-ui";
import { Redirect } from "react-router-dom";
import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
} from "@sparkademy/app-common/elements/Accordion";
import { ArrowLink } from "@sparkademy/app-common/elements/ArrowLink";
import { useSessionContext } from "@sparkademy/app-common/contexts/session-context";
import { TrackingService } from "../services/tracking-service";
import { themeNew } from "@sparkademy/app-common/materials/theme";

type Props = {
  slug: string;
};

export const StaticPage: React.FC<Props> = ({ slug }) => {
  const { currentUser } = useSessionContext();

  const page = pages.find(p => p.slug === slug);

  if (!page) {
    return <Redirect to="/" />;
  }

  return (
    <ThemeProvider theme={themeNew}>
      <Layout sx={{ bg: "new.primary.white" }}>
        <Container sx={{ maxWidth: "88ch", pb: 14 }}>
          <Box sx={{ mt: 14 }} />
          {currentUser ? (
            <Box
              sx={{
                display: "flex",
              }}
            >
              <ArrowLink
                sx={{
                  mb: 6,
                }}
                to="/"
                onClick={() => TrackingService.staticPageBackClick()}
              >
                Dashboard
              </ArrowLink>
            </Box>
          ) : null}
          <Heading
            sx={{
              mb: 10,
              fontSize: 4,
              fontWeight: "bold",
            }}
          >
            {page.h1 || page.title}
          </Heading>
          {page.content ? (
            // @ts-ignore
            <Narrative
              styleNames={page.narrativeStyleNames}
              sx={{
                p: {
                  fontSize: 1,
                  maxWidth: "100%",
                  lineHeight: 1.4,
                },
              }}
            >
              <page.content />
            </Narrative>
          ) : null}

          {page.accordions.map(({ content: AccordionContent, accordion }, index) => (
            <React.Fragment key={index}>
              <Narrative
                sx={{
                  p: {
                    fontSize: 1,
                    maxWidth: "100%",
                    lineHeight: 1.4,
                  },
                }}
              >
                <AccordionContent />
              </Narrative>
              <Accordion multiple collapsible>
                {accordion.map(({ title, content: AccordionItemContent }) => (
                  <AccordionItem>
                    <h3
                      sx={{
                        m: 0,
                      }}
                    >
                      <AccordionButton sx={{ fontWeight: "normal", fontSize: 1 }}>
                        {title}
                      </AccordionButton>
                    </h3>
                    <AccordionPanel>
                      <Narrative
                        sx={{
                          p: {
                            fontSize: 1,
                            maxWidth: "100%",
                            lineHeight: 1.4,
                          },
                        }}
                      >
                        <AccordionItemContent />
                      </Narrative>
                      <Box sx={{ mb: 3 }} />
                    </AccordionPanel>
                  </AccordionItem>
                ))}
              </Accordion>
              <Box
                sx={{
                  mb: 9,
                }}
              />
            </React.Fragment>
          ))}

          {page.contentAfter ? (
            <React.Fragment>
              <Box sx={{ mt: 11 }} />
              <Narrative
                sx={{
                  p: {
                    fontSize: 1,
                    maxWidth: "100%",
                    lineHeight: 1.4,
                  },
                }}
              >
                <page.contentAfter />
              </Narrative>
            </React.Fragment>
          ) : null}
        </Container>
      </Layout>
    </ThemeProvider>
  );
};
