/** @jsx jsx */
import { jsx, Box, Label } from "theme-ui";
import * as React from "react";
import {
  KnowledgeCheckContent,
  KnowledgeCheckQuestion,
  KnowledgeCheckQuestionGroup,
  KnowledgeCheckQuestionOption,
} from "@sparkademy/app-common/models/course";
import { Radio } from "@sparkademy/app-common/elements/Radio";
import { useParams } from "react-router-dom";
import {
  fetchKnowledgeCheckAnswers,
  saveKnowledgeCheckAnswers,
} from "../../services/http-api-service";
import { useSessionContext } from "@sparkademy/app-common/contexts/session-context";
import { TrackingService } from "../../services/tracking-service";
import { HTMLContentWrapper } from "./HTMLContentWrapper";
import { Button } from "@sparkademy/app-common/elements/Button";
import { courseAtom, lessonNavigatorVisibleAtom } from "../../stores/course";
import { shouldShowLoaderAtom } from "@sparkademy/app-common/stores/ui";
import { useAtom } from "jotai";
import { useUpdateAtom } from "jotai/utils";
import { letterFromIndex } from "@sparkademy/app-common/utils/array";
import { useUserData } from "../../hooks/useUserData";

export const BlockUnitKnowledgeCheckContent: React.FC<{
  knowledgeCheck: KnowledgeCheckContent;
}> = ({ knowledgeCheck }) => {
  const { currentUser } = useSessionContext();
  const { getModuleById } = useUserData();
  const [wasSubmitted, setWasSubmitted] = React.useState(false);
  const [submitButtonDisabled, setSubmitButtonDisabled] = React.useState(false);
  const setLessonNavigatorVisible = useUpdateAtom(lessonNavigatorVisibleAtom);
  const setShouldShowLoader = useUpdateAtom(shouldShowLoaderAtom);
  const startTimeRef = React.useRef<Date>(new Date());

  React.useEffect(() => {
    // that's so we don't fetch answers again after calling `setWasSubmitted(submitted)` below
    // this effect will execute twice because `wasSubmitted` is a dependency
    if (wasSubmitted) {
      return;
    }

    setShouldShowLoader(true);
    fetchKnowledgeCheckAnswers(knowledgeCheck.id, currentUser!)
      .then(answers => {
        const mappedAnswers = answers.reduce((acc, a) => {
          acc[a.questionId] = a.index;
          return acc;
        }, {} as CheckedOptionsMap);

        let submitted = false;
        if (Object.keys(mappedAnswers).length > 0) {
          setCheckedOptionsMap(mappedAnswers);
          submitted = true;
        }

        setWasSubmitted(submitted);
        setLessonNavigatorVisible(submitted);
      })
      .finally(() => setShouldShowLoader(false));
  }, [
    currentUser,
    knowledgeCheck.id,
    setLessonNavigatorVisible,
    wasSubmitted,
    setShouldShowLoader,
  ]);

  const allQuestions = knowledgeCheck.items.flatMap(item =>
    item.type === "group" ? item.questions : [item]
  );

  const initialState = allQuestions.reduce((acc, question) => {
    acc[question.id] = -1;
    return acc;
  }, {} as CheckedOptionsMap);

  const { courseId, lessonId, blockId, unitId } = useParams<{
    courseId: string;
    lessonId: string;
    blockId: string;
    unitId: string;
  }>();

  const [course] = useAtom(courseAtom);

  const [checkedOptionsMap, setCheckedOptionsMap] = React.useState<CheckedOptionsMap>(initialState);
  const onChange = (questionId: string, index: number) => {
    setCheckedOptionsMap({
      ...checkedOptionsMap,
      [questionId]: index,
    });
  };

  React.useEffect(() => {
    const module = getModuleById(course!.module);
    if (module?.assessment.status === "upcoming") {
      setSubmitButtonDisabled(true);
    }
  }, [course, getModuleById]);

  const totalPoints = allQuestions.reduce((acc, question) => {
    const questionPoints = question.options.reduce((acc, option) => acc + option.weight, 0);
    acc += questionPoints;
    return acc;
  }, 0);
  let earnedPoints = 0;

  const submitAnswers = async () => {
    if (!course) return;

    setShouldShowLoader(true);

    // saves the answer on our database
    const answers = [];
    for (const questionId in checkedOptionsMap) {
      const questionIndex = allQuestions.findIndex(q => q.id === questionId);
      const questionGroupId = allQuestions[questionIndex].groupId;
      const optionIndex = checkedOptionsMap[questionId];
      const optionWeight = allQuestions[questionIndex].options[optionIndex]?.weight || 0;
      earnedPoints += optionWeight;
      answers.push({
        questionGroupId,
        questionId,
        questionIndex,
        maxScore: allQuestions[questionIndex].options.reduce((acc, cur) => acc + cur.weight, 0),
        optionIndex,
        optionWeight,
      });
    }

    await saveKnowledgeCheckAnswers(
      currentUser?.data.cohort_id!,
      courseId,
      lessonId,
      blockId,
      unitId,
      knowledgeCheck.id,
      course.module,
      knowledgeCheck.pageNumber,
      answers,
      currentUser!
    );

    // additionally send the event to Segment
    TrackingService.KnowledgeCheckSubmitted(
      courseId,
      lessonId,
      blockId,
      unitId,
      knowledgeCheck.id,
      knowledgeCheck.title,
      totalPoints,
      earnedPoints,
      Number((earnedPoints / totalPoints).toFixed(2)),
      startTimeRef.current,
      new Date()
    );

    setWasSubmitted(true);
    setLessonNavigatorVisible(true);
    setShouldShowLoader(false);

    // scroll to top
    const layoutElement = document.querySelector(".layout-container");
    if (layoutElement) {
      layoutElement.scrollTo({ top: 160, behavior: "smooth" });
    }
  };

  if (allQuestions.length === 0) {
    return null;
  }

  const numOfQuestionsAnswered = Object.values(checkedOptionsMap).filter(v => v > -1).length;
  const numOfQuestions = allQuestions.length;

  return (
    <Box id="kc-container" sx={{ mb: "48px", mt: ["-16px", "-32px"] }}>
      <Box sx={{ mt: ["16px", "32px"] }}>
        <div dangerouslySetInnerHTML={{ __html: knowledgeCheck.content }} />
      </Box>

      {knowledgeCheck.items.map((item, index) =>
        item.type === "group" ? (
          <KnowledgeCheckContentGroup
            key={index}
            group={item}
            numberOrLetter={String(index + 1)}
            checkedOptionsMap={checkedOptionsMap}
            wasSubmitted={wasSubmitted}
            onChange={onChange}
          />
        ) : (
          <KnowledgeCheckContentQuestion
            key={index}
            question={item}
            numberOrLetter={String(index + 1)}
            checkedIndex={checkedOptionsMap[item.id]}
            wasSubmitted={wasSubmitted}
            onChange={onChange}
          />
        )
      )}

      {!wasSubmitted && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            borderTop: "1px solid #BFBFBF",
            mt: "48px",
            pt: "48px",
          }}
        >
          <Box
            id="completion-status"
            sx={{ mr: "32px", alignSelf: "center", fontWeight: 400, fontSize: "20px" }}
          >{`${numOfQuestionsAnswered}/${numOfQuestions} completed`}</Box>
          <Button type="submit" disabled={submitButtonDisabled} onClick={submitAnswers}>
            Submit
          </Button>
        </Box>
      )}
    </Box>
  );
};

export const KnowledgeCheckContentGroup: React.FC<{
  group: KnowledgeCheckQuestionGroup;
  numberOrLetter: string;
  checkedOptionsMap: CheckedOptionsMap;
  wasSubmitted: boolean;
  onChange: (questionId: string, index: number) => void;
}> = ({ group, numberOrLetter, checkedOptionsMap, wasSubmitted, onChange }) => {
  return (
    <Box className="knowledge-check-question-group">
      <Box className="group-text" sx={{ display: "flex", mt: "32px", mb: "-32px" }}>
        <Box sx={{ mr: "10px" }}>
          <p>{numberOrLetter}.</p>
        </Box>
        <HTMLContentWrapper html={group.questionGroupText} />
      </Box>
      {group.questions.map((question, index) => (
        <KnowledgeCheckContentQuestion
          key={index}
          question={question}
          numberOrLetter={letterFromIndex(index)}
          checkedIndex={checkedOptionsMap[question.id]}
          wasSubmitted={wasSubmitted}
          onChange={onChange}
        />
      ))}
    </Box>
  );
};

export const KnowledgeCheckContentQuestion: React.FC<{
  question: KnowledgeCheckQuestion;
  numberOrLetter: string;
  checkedIndex: number;
  wasSubmitted: boolean;
  onChange: (questionId: string, index: number) => void;
}> = ({ question, numberOrLetter, checkedIndex, onChange, wasSubmitted }) => {
  const noAnswer = wasSubmitted && checkedIndex === -1;
  const noAnswerExplanationBg = "rgba(243, 171, 31, 0.15);"; // carrot orange

  return (
    <Box className="knowledge-check-question">
      <Box sx={{ mt: "32px", mb: "8px" }}>
        <Box className="question-text" sx={{ display: "flex" }}>
          <Box sx={{ mr: "10px" }}>
            <p>{numberOrLetter}.</p>
          </Box>
          <HTMLContentWrapper html={question.text} />
        </Box>
        {noAnswer && (
          <Box
            className="no-answer-notice"
            sx={{
              bg: noAnswerExplanationBg,
              borderLeft: "16px solid #F19F00",
              lineHeight: "62px",
              pl: "16px",
              width: "293px",
              mb: "32px",
            }}
          >
            Oops, you didn't select an answer.
          </Box>
        )}
      </Box>

      <Box sx={{ width: "100%", "@media screen and (min-width: 1366px)": { width: "894px" } }}>
        {question.options.map((option, index) => (
          <QuestionOption
            questionId={question.id}
            option={option}
            key={index}
            index={index}
            checkedIndex={checkedIndex}
            wasSubmitted={wasSubmitted}
            onChange={onChange}
          />
        ))}
      </Box>
    </Box>
  );
};

export const QuestionOption: React.FC<{
  questionId: string;
  option: KnowledgeCheckQuestionOption;
  index: number;
  checkedIndex: number;
  wasSubmitted: boolean;
  onChange: (questionId: string, index: number) => void;
}> = ({ questionId, option, index, checkedIndex, wasSubmitted, onChange }) => {
  const isChecked = checkedIndex === index;
  const colorStyles = {
    borderColor: "new.primary.darkGrey",
    bg: "new.primary.white",
  };

  if (wasSubmitted && checkedIndex > -1) {
    if (option.weight > 0) {
      colorStyles.borderColor = "new.primary.green";
      colorStyles.bg = "rgba(23, 229, 160, 0.1)";
    } else if (isChecked && option.weight === 0) {
      colorStyles.borderColor = "new.secondary.red";
      colorStyles.bg = "rgba(255, 95, 95, 0.1);";
    }
  }

  const noAnswer = wasSubmitted && checkedIndex === -1;
  const noAnswerExplanationBg = "rgba(243, 171, 31, 0.15);"; // carrot orange

  return (
    <React.Fragment>
      <Box
        key={index}
        className="knowledge-check-question-option"
        sx={{
          display: "flex",
          border: "1px solid",
          borderRadius: 5,
          py: "10px",
          px: "20px",
          ":not(:first-of-type)": {
            mt: "12px",
          },
          ...colorStyles,
        }}
      >
        <Label sx={{ fontSize: "16px", display: "flex", alignItems: "center" }}>
          <Box sx={{ minWidth: 25 }}>
            <Radio
              sx={{
                flex: "0 0 auto",
                bg: "transparent",
                width: 25,
                height: 25,
                m: 0,
              }}
              name={questionId}
              checked={isChecked}
              disabled={wasSubmitted}
              onChange={() => onChange(questionId, index)}
            />
          </Box>
          <Box sx={{ ml: 5, display: "inline" }} />
          <Box sx={{ display: "inline", cursor: "pointer", lineHeight: "25px" }}>{option.text}</Box>
        </Label>
        <Box sx={{ mt: 4 }} />
      </Box>

      {((wasSubmitted && isChecked) || (noAnswer && option.weight > 0)) && option.explanation && (
        <Box
          className="explanation"
          sx={{
            fontSize: "16px",
            bg: noAnswer ? noAnswerExplanationBg : "new.secondary.lightGrey",
            borderRadius: 5,
            py: "8px",
            px: "20px",
            mt: "5px",
          }}
        >
          {option.explanation}
        </Box>
      )}
    </React.Fragment>
  );
};

type CheckedOptionsMap = {
  [questionId: string]: number;
};
