// @ts-ignore
import buildFormatter from "react-timeago/lib/formatters/buildFormatter";

export const dateFormatter = new Intl.DateTimeFormat("en", {
  month: "short",
  day: "2-digit",
  hour: "numeric",
  minute: "numeric",
});
export const timeFormatter = new Intl.DateTimeFormat("en", {
  hour: "numeric",
  minute: "numeric",
});

export const dayFormatter = new Intl.DateTimeFormat("en", {
  month: "short",
  day: "2-digit",
});

export const timeAgoFormatter = buildFormatter({
  prefixAgo: "in",
  prefixFromNow: "in",
  suffixAgo: null,
  suffixFromNow: null,
  seconds: "less than a minute",
  minute: "about a minute",
  minutes: "%d minutes",
  hour: "about an hour",
  hours: "about %d hours",
  day: "a day",
  days: "%d days",
  month: "about a month",
  months: "%d months",
  year: "about a year",
  years: "%d years",
  wordSeparator: " ",
});

export const timeLeftFormatter = buildFormatter({
  suffixAgo: "",
  suffixFromNow: "",
  seconds: "today",
  minute: "about a minute",
  minutes: "%d minutes",
  hour: "about an hour",
  hours: "about %d hours",
  day: "a day left",
  days: "%d days left",
  month: "about a month",
  months: "%d months",
  year: "about a year",
  years: "%d years",
  wordSeparator: " ",
});

export function dateOrdinal(dom: number) {
  if (dom === 31 || dom === 21 || dom === 1) return "st";
  else if (dom === 22 || dom === 2) return "nd";
  else if (dom === 23 || dom === 3) return "rd";
  else return "th";
}

export function daysDiff(a: Date, b: Date): number {
  const diffInTime = b.getTime() - a.getTime();

  const diffInDays = diffInTime / (1000 * 3600 * 24);
  return diffInDays;
}
