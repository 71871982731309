import { useState, useEffect, useRef } from "react";

class Time {
  static getSecondsFromExpiry(expiry) {
    const now = new Date().getTime();
    const milliSecondsDistance = expiry - now;
    if (milliSecondsDistance > 0) {
      return milliSecondsDistance / 1000;
    }
    return 0;
  }
}

export default function useTimer(settings) {
  const { expiryTimestamp: expiry, onExpire } = settings || {};
  const [expiryTimestamp, setExpiryTimestamp] = useState(expiry);
  const [seconds, setSeconds] = useState(Time.getSecondsFromExpiry(expiryTimestamp));
  const [isRunning, setIsRunning] = useState(true);
  const intervalRef = useRef<number>();

  function clearIntervalRef() {
    if (intervalRef.current) {
      setIsRunning(false);
      clearInterval(intervalRef.current);
      intervalRef.current = undefined;
    }
  }

  function handleExpire() {
    clearIntervalRef();
    onExpire();
  }

  function start() {
    if (!intervalRef.current) {
      setIsRunning(true);
      intervalRef.current = setInterval(() => {
        const secondsValue = Time.getSecondsFromExpiry(expiryTimestamp);
        if (secondsValue <= 0) {
          handleExpire();
        }
        setSeconds(secondsValue);
      }, 500);
    }
  }

  function pause() {
    clearIntervalRef();
  }

  function resume() {
    if (!intervalRef.current) {
      setIsRunning(true);
      intervalRef.current = setInterval(
        () =>
          setSeconds(prevSeconds => {
            const secondsValue = prevSeconds - 1;
            if (secondsValue <= 0) {
              handleExpire();
            }
            return secondsValue;
          }),
        500
      );
    }
  }

  function restart(newExpiryTimestamp) {
    clearIntervalRef();
    setExpiryTimestamp(newExpiryTimestamp);
  }

  useEffect(() => {
    setSeconds(Time.getSecondsFromExpiry(expiryTimestamp));
    start();
    return clearIntervalRef;
  }, [expiryTimestamp]);

  return {
    seconds,
    start,
    pause,
    resume,
    restart,
    isRunning,
  };
}
