import React from "react";
import { Box, SxStyleProp } from "theme-ui";

const RadioChecked = props => (
  <SVG {...props}>
    <circle cx="12.5" cy="12.5" r="11.5" stroke="#4B27E0" strokeWidth="2" fill="none" />
    <circle cx="12.5" cy="12.5" r="7.5" fill="#4B27E0" />
  </SVG>
);

const SVG: React.FC<{
  size?: number;
}> = ({ size = 25, ...props }) => (
  <Box
    as="svg"
    // @ts-ignore
    xmlns="http://www.w3.org/2000/svg"
    width={size + ""}
    height={size + ""}
    viewBox="0 0 25 25"
    {...props}
  />
);

const RadioUnchecked = props => (
  <SVG {...props}>
    <circle cx="12.5" cy="12.5" r="11.5" stroke="black" strokeWidth="2" fill="none" />
  </SVG>
);

const RadioIcon = props => (
  <React.Fragment>
    <RadioChecked
      {...props}
      __css={{
        display: "none",
        "input:checked ~ &": {
          display: "block",
        },
      }}
    />
    <RadioUnchecked
      {...props}
      __css={{
        display: "block",
        "input:checked ~ &": {
          display: "none",
        },
      }}
    />
  </React.Fragment>
);

export const Radio: React.FC<
  {
    sx?: SxStyleProp;
    wrapperSx?: SxStyleProp;
    className?: string;
  } & React.ComponentProps<"input">
> = ({ className, sx = {}, wrapperSx = {}, ...props }) => (
  <Box sx={wrapperSx}>
    <Box
      as="input"
      type="radio"
      readOnly
      {...props}
      sx={{
        position: "absolute",
        opacity: 0,
        zIndex: -1,
        width: 1,
        height: 1,
        overflow: "hidden",
      }}
    />
    <Box
      as={RadioIcon}
      aria-hidden="true"
      className={className}
      sx={{
        mr: 2,
        flexShrink: 0,
        ...sx,
      }}
    />
  </Box>
);
