import { Heading, Text, Box, ThemeProvider } from "theme-ui";
import * as React from "react";
import { useSessionContext } from "@sparkademy/app-common/contexts/session-context";
import { Layout, LayoutSideMenu } from "../components/Layout";
import { Container } from "@sparkademy/app-common/elements/Container";
import { List, ListItem } from "@sparkademy/app-common/elements/List";
import { Module, ModuleInfo } from "@sparkademy/app-common/models/module";
import { Tool } from "@sparkademy/app-common/models/tool";
import { tools } from "@sparkademy/app-common/data/tools";
import { MethodCardThumb } from "../components/MethodCardThumb";

// @ts-ignore
import templatesM1 from "@sparkademy/app-common/data/method-kit/SA_Templates_M1.pdf";
// @ts-ignore
import templatesM2 from "@sparkademy/app-common/data/method-kit/SA_Templates_M2.pdf";
// @ts-ignore
import templatesM3 from "@sparkademy/app-common/data/method-kit/SA_Templates_M3.pdf";
// @ts-ignore
import templatesM4 from "@sparkademy/app-common/data/method-kit/SA_Templates_M4.pdf";

import { ArrowLink } from "@sparkademy/app-common/elements/ArrowLink";
import { Loader } from "@sparkademy/app-common/components/Loader";
import { uiCopy } from "../data/ui-copy";
import { useContentContext } from "@sparkademy/app-common/contexts/content-context";
import { themeNew } from "@sparkademy/app-common/materials/theme";
import { Button } from "@sparkademy/app-common/elements/Button";
import { MessageToast } from "@sparkademy/app-common/components/MessageToast";
import { copyToClipboard } from "@sparkademy/app-common/utils/clipboard";
import { useUserData } from "../hooks/useUserData";
import { Input } from "@sparkademy/app-common/elements/Input";

function getTemplateForModule(m: Module) {
  switch (m.id.toLowerCase()) {
    case "m1":
      return templatesM1;
    case "m2":
      return templatesM2;
    case "m3":
      return templatesM3;
    case "m4":
      return templatesM4;
    default:
      console.warn(`module id ${m.id} does not have a coded template`);
      return "";
  }
}

const toolFilter = (search: string, module_name: string, module_description: string) => (t: Tool) =>
  t.name.toLowerCase().includes(search.toLowerCase()) ||
  module_name.toLowerCase().includes(search.toLowerCase()) ||
  module_description.toLowerCase().includes(search.toLowerCase());

export const UserMethodKit: React.FC = props => {
  const [showMessage, setShowMessage] = React.useState<boolean>(false);
  function showCopiedUrlMessage() {
    setShowMessage(true);
    setTimeout(() => setShowMessage(false), 2000);
  }

  const { currentUser } = useSessionContext();
  const { allModules } = useUserData();
  const { modules: modulesInfo } = useContentContext();

  const [search, setSearch] = React.useState("");

  if (!currentUser) {
    return null;
  }

  if (allModules.length === 0 || modulesInfo.length === 0) {
    return (
      <Layout>
        <Loader />
      </Layout>
    );
  }

  let userModules: Module[] = allModules.filter(m => !["m0", "projectwork"].includes(m.id));

  const moduleInfoMap = modulesInfo.reduce((acc: { [key: string]: ModuleInfo }, cur) => {
    acc[cur.id] = cur;
    return acc;
  }, {});

  let allToolsByModule: Array<{
    module: Module;
    tools: Tool[];
    moduleIdx: number;
    templatesUrl: string;
  }> = userModules.map((m, idx) => ({
    module: m,
    moduleIdx: idx,
    tools: tools.filter(t => t.moduleId.toLowerCase() === m.id.toLowerCase()),
    templatesUrl: getTemplateForModule(m),
  }));

  let toolsByModule: Array<{
    module: Module;
    tools: Tool[];
    moduleIdx: number;
    templatesUrl: string;
  }> = allToolsByModule;

  return (
    <ThemeProvider theme={themeNew}>
      <LayoutSideMenu sx={{ bg: "new.primary.white" }}>
        <Container sx={{ maxWidth: "894px", pb: 14 }}>
          <MessageToast visible={showMessage} message="The link was copied to the clipboard!" />

          <Box sx={{ mt: 14 }} />
          <Box
            sx={{
              display: "flex",
            }}
          >
            <ArrowLink
              id="dashboard-link"
              sx={{
                mb: 6,
              }}
              to="/"
            >
              Dashboard
            </ArrowLink>
          </Box>
          <Heading
            sx={{
              mb: 10,
              fontSize: 4,
              fontWeight: "bold",
            }}
          >
            {uiCopy.TOOLS_TITLE}
          </Heading>

          <Text
            as="p"
            sx={{
              fontSize: 1,
              fontWeight: 300,
              lineHeight: 1.4,
              mb: 8,
            }}
          >
            {uiCopy.TOOLS_LEAD}
          </Text>

          <Text as="p" sx={{ fontSize: 1, mb: 8 }}>
            Copy a shareable link of the Method Kit and share it with your team!
          </Text>
          <Box
            sx={{
              display: ["flex", "flex"],
              flexDirection: ["column", "row"],
              justifyContent: "space-between",
              alignItems: ["start", "center"],
            }}
          >
            <Button
              id="copy-link-btn"
              // sx={{
              //   alignSelf: "center",
              // }}
              onClick={() => {
                const url = "https://method-kit.sparkademy.com";
                copyToClipboard(url);
                showCopiedUrlMessage();
              }}
            >
              Copy Link
            </Button>
            <Box sx={{ display: ["initial", "none"] }} />
            <Input
              value={search}
              onChange={event => setSearch(event.currentTarget.value)}
              placeholder="Search for a tool"
              sx={{ mt: 10, mb: 8, maxWidth: "80vw" }}
            />
          </Box>

          <List
            id="module-list"
            sx={{
              textAlign: "left",
            }}
          >
            {toolsByModule.map(({ module, tools: moduleTools, moduleIdx }) => {
              moduleTools = moduleTools.filter(
                toolFilter(
                  search,
                  moduleInfoMap[module.id].name,
                  moduleInfoMap[module.id].description
                )
              ); // filter out modules that dont conain any mathed tools
              if (moduleTools.length === 0) {
                return null;
              }
              return (
                <ListItem
                  key={moduleIdx}
                  sx={{
                    mb: 10,
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      flexDirection: ["column", "row"],
                      mb: 8,
                    }}
                  >
                    <Heading
                      as="h2"
                      sx={{
                        fontSize: [1, 2],
                        fontWeight: "bold",
                      }}
                    >
                      {moduleInfoMap[module.id].name}
                    </Heading>
                  </Box>

                  <List
                    sx={{
                      display: "flex",
                      flexWrap: "wrap",
                      m: -3,
                    }}
                  >
                    {moduleTools.length > 0
                      ? moduleTools
                          .filter(t => !t.disabled)
                          .filter(
                            toolFilter(
                              search,
                              moduleInfoMap[module.id].name,
                              moduleInfoMap[module.id].description
                            )
                          )
                          .map(tool => (
                            <ListItem
                              key={tool.slug}
                              sx={{
                                p: 3,
                                width: [
                                  `${(1 / 1) * 100}%`,
                                  `${(1 / 2) * 100}%`,
                                  `${(1 / 3) * 100}%`,
                                  `${(1 / 4) * 100}%`,
                                ],
                              }}
                            >
                              <MethodCardThumb
                                isPublic={false}
                                tool={tool}
                                module={module}
                                moduleInfo={moduleInfoMap[module.id]}
                              />
                            </ListItem>
                          ))
                      : null}
                  </List>
                </ListItem>
              );
            })}
          </List>
        </Container>
      </LayoutSideMenu>
    </ThemeProvider>
  );
};
