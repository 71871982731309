import * as React from "react";
import { User } from "@sparkademy/app-common/models/user";
import { dateFormatter } from "@sparkademy/app-common/utils/date-formatters";

type UICopyMap = {
  [key: string]: any;
};

export const uiCopy = {
  LOGIN_IDLE_TITLE: "Welcome!",
  LOGIN_IDLE_LEAD: "Continue With Email",
  LOGIN_IDLE_FORM_SUBMIT_LABEL: "Continue",
  LOGIN_ERROR_TITLE: "Something Went Wrong",
  LOGIN_ERROR_LEAD: () => (
    <p>
      Make sure your email is correct. If you think there's a problem{" "}
      <a href="mailto:team@sparkademy.com">contact us.</a>
    </p>
  ),
  SIGNUP_ERROR_LEAD: () => (
    <p>
      Make sure all data is correct. If you think there's a problem{" "}
      <a href="mailto:team@sparkademy.com">contact us.</a>
    </p>
  ),
  LOGIN_SUBMITTED_TITLE: "Check your email",
  LOGIN_SUBMITTED_LEAD: () => (
    <>
      <p style={{ marginTop: "0.4em" }}>
        We've sent a confirmation email to you. <br />
        Click the link, and you'll be signed in.
      </p>
      <p>If you can't find the email in your inbox, check your spam folder</p>
    </>
  ),

  LEVEL_CARD_FAILED_TEXT: () => (
    <>
      <p>
        <strong>No Pass:</strong>
      </p>
      <p>
        You have failed to pass the minimum requirement for completing this level. You will be able
        to access the course material, but you won’t be granted a certificate.
      </p>
    </>
  ),
  LEVEL_CARD_FAILED_RETRY_TWICE_TEXT: () => (
    <>
      <p>
        <strong>No Pass:</strong>
      </p>
      <p>You have failed to pass this module on the second try.</p>
    </>
  ),
  LEVEL_CARD_FAILED_TWO_MODULES_TEXT: () => (
    <>
      <p>
        <strong>No Pass:</strong>
      </p>
      <p>You have failed to pass this module along with another module.</p>
    </>
  ),

  MODULES_ADD_TO_CALENDAR_BUTTON: "Add to calendar",

  MODULE_CARD_FAILED_TEXT: (levelName: string) => (
    <>
      <p>
        <strong>No Pass:</strong> You will be given a second chance to pursue this module.
      </p>
      <p>
        You will no longer be able to complete the {levelName} Level if you fail to pass another
        module or fail to pass this module on the second try.
      </p>
    </>
  ),

  TOOLS_TITLE: "Method Kit",
  TOOLS_LEAD:
    "The Sparkademy Method Kit compiles a collection of the best theoretical frameworks, practical tools and hands-on tips. These method cards will support your learning journey and your team through relevant project work.",
  TOOLS_CLOSED: (inXDayx: React.ReactNode | string) => (
    <React.Fragment>Opens {inXDayx}</React.Fragment>
  ),

  DASHBOARD_TITLE: (currentUser: User) => `Welcome, ${currentUser.username}`,
  GENERIC_HELP_MESSAGE: () => (
    <p>
      Have a problem? <a href="mailto:team@sparkademy.com">Contact us.</a>
    </p>
  ),
  GENERIC_ERROR_HEADER: () => "Ups...something’s wrong",
  GENERIC_ERROR_MESSAGE: () => <p>The page you have requested is not available.</p>,
  LOGIN_ERROR_HEADER: () => "Your Login Didn't Work",
  LOGIN_ERROR_MESSAGE: () => (
    <p>
      There was an issue while we were signing you in. Please try again using the link on your
      invitation email.
    </p>
  ),
  NOT_AUTHED_ERROR_HEADER: () => "Not Authenticated",
  NOT_AUTHED_ERROR_MESSAGE: () => (
    <p>
      It looks like you are not signed into our platform. Click{" "}
      <a style={{ fontWeight: 700 }} href="/login">
        here
      </a>{" "}
      to login.
    </p>
  ),
  SESSION_EXPIRED_ERROR_HEADER: () => "Your session has expired",
  SESSION_EXPIRED_ERROR_MESSAGE: () => (
    <p>
      Your session has expired, you need to sign into the platform again. Click{" "}
      <a style={{ fontWeight: 700 }} href="/login">
        here
      </a>{" "}
      to continue.
    </p>
  ),
  MODULE_FAILED_ERROR_HEADER: () => "Module Failed",
  MODULE_FAILED_ERROR_MESSAGE: () => (
    <p>
      You have failed this module, the Knowledge Check or Practical Assessment is not available.
    </p>
  ),
  ASSESSMENT_UPCOMING_ERROR_HEADER: () => "Already here?",
  ASSESSMENT_UPCOMING_ERROR_MESSAGE: (openDate: Date, closeDate: Date) => (
    <>
      <p>The Knowledge Check is currently closed.</p>
      <p>
        It opens up on {dateFormatter.format(openDate)} and will close by{" "}
        {dateFormatter.format(closeDate)}.
      </p>
      <p>It will take approximately 1 hour of your time to complete.</p>
    </>
  ),
  ASSESSMENT_CLOSED_ERROR_HEADER: () => "Evaluation Completed",
  ASSESSMENT_CLOSED_ERROR_MESSAGE: () => (
    <p>
      We have already evaluated this deliverable. You should have received an automatic email with
      your results. Do you have any questions? Contact us.
    </p>
  ),
  ASSESSMENT_LOCkED_ERROR_HEADER: () => "Knowledge Check Locked",
  ASSESSMENT_LOCkED_ERROR_MESSAGE: () => (
    <p>The Knowledge Check is locked because you have failed the level.</p>
  ),
  ASSESSMENT_PENDING_EVALUATION_ERROR_HEADER: () => "We're checking your work",
  ASSESSMENT_PENDING_EVALUATION_ERROR_MESSAGE: () => (
    <p>
      You’ve submitted this deliverable and we are currently evaluating it. You will receive an
      automatic email with your results shortly.
    </p>
  ),
  HOMEWORk_UPCOMING_ERROR_HEADER: () => "Already here?",
  HOMEWORk_UPCOMING_ERROR_MESSAGE: (openDate: Date, closeDate: Date) => (
    <>
      <p>The Practical Assignment is currently closed.</p>
      <p>
        It opens up on {dateFormatter.format(openDate)} and will close by{" "}
        {dateFormatter.format(closeDate)}.
      </p>
      <p>It will take approximately 1 hour of your time to complete.</p>
    </>
  ),
  HOMEWORk_CLOSED_ERROR_HEADER: () => "Evaluation Completed",
  HOMEWORk_CLOSED_ERROR_MESSAGE: () => (
    <p>
      We have already evaluated this deliverable. You should have received an automatic email with
      your results. Do you have any questions? Contact us.
    </p>
  ),
  HOMEWORk_LOCkED_ERROR_HEADER: () => "Practical Assessment Locked",
  HOMEWORk_LOCkED_ERROR_MESSAGE: () => (
    <p>The Practical Assessment is locked because you have failed the level.</p>
  ),
  HOMEWORk_PENDING_EVALUATION_ERROR_HEADER: () => "We're checking your work",
  HOMEWORk_PENDING_EVALUATION_ERROR_MESSAGE: () => (
    <p>
      You’ve submitted this deliverable and we are currently evaluating it. You will receive an
      automatic email with your results shortly.
    </p>
  ),
  FEEDBACk_OPEN_ERROR_HEADER: () => "Feedback Survey Not Available",
  FEEDBACk_OPEN_ERROR_MESSAGE: () => (
    <p>
      The feedback survey is only available after submitting the Knowledge Check and Practical
      Assessment.
    </p>
  ),

  SPARK_CHECK_TAKEN_ERROR_HEADER: () => "You have already done this part of Spark Check",
  SPARK_CHECK_TAKEN_ERROR_MESSAGE: () => (
    <p>
      The email address you used already has a result linked to it, meaning that you have already
      completed this part of Spark Check.
    </p>
  ),
  SPARK_CHECK_TAKEN_ERROR_HELP: () => (
    <p>
      Need to re-take Spark Check, or have another problem?{" "}
      <a href="mailto:team@sparkademy.com">Contact us</a>
    </p>
  ),

  EMPATHY_MATCHING_EMOTION_INTRO_TITLE: "Section 2",
  EMPATHY_MATCHING_EMOTION_INTRO_BODY: () => (
    <React.Fragment>
      <p>
        In the following, you will find a series of pictures that show different facial expressions.
        Your task is to match each facial expression with the corresponding emotion. There is only
        one right answer.
      </p>
    </React.Fragment>
  ),

  EMPATHY_UNDERSTAND_EMOTIONS_INTRO_TITLE: "Section 1",
  EMPATHY_UNDERSTAND_EMOTIONS_INTRO_BODY: () => (
    <React.Fragment>
      <p>
        In the following, we will present to you a series of scenarios and questions that contain
        emotional information. Please mark the answer you think is the right one. There is only one
        right answer.
      </p>
    </React.Fragment>
  ),
  EMPATHY_SUBMIT_DIALOG_TITLE: (sectionId: number) =>
    sectionId === 1
      ? "You have completed the first section"
      : sectionId === 2
      ? "You have completed the second section"
      : "You have completed the third section",

  EMPATHY_SUBMIT_DIALOG_BODY: (sectionId: number) =>
    sectionId === 1 ? (
      <p>Submit your answers to continue to the second section.</p>
    ) : sectionId === 2 ? (
      <p>Submit your answers to finish the test.</p>
    ) : (
      <p>Submit your answers to finish the test.</p>
    ),

  CERTIFICATE_DOWNLOAD_TITLE: (currentUser: User) => `Congratulations, ${currentUser.username}!`,
  CERTIFICATE_DOWNLOAD_INTRO_TITLE: "Congratulations!",
  CERTIFICATE_DOWNLOAD_INTRO_BODY: () => (
    <React.Fragment>
      <p>
        You have successfully completed the Core Catalyst Certification Program. <br />
        Sparkademy thanks you for your time and hard work for completing this remarkable journey.
      </p>
    </React.Fragment>
  ),
  CERTIFICATE_DOWNLOAD_CERTIFICATE_TITLE: "Your Certificate of Completion",
  CERTIFICATE_DOWNLOAD_CERTIFICATE_BODY:
    "Download your well-deserved certificate below and celebrate your achievement by sharing this news with your friends, family, and collegues!",
  CERTIFICATE_DOWNLOAD_CERTIFICATE_BOX_BODY: "Download your certificate as a pdf.",
  CERTIFICATE_DOWNLOAD_CERTIFICATE_BOX_DOWNLOAD: "Download",
  CERTIFICATE_DOWNLOAD_CERTIFICATE_BOX_LINKEDIN:
    "Post this Certificate of Completion to your LinkedIn profile and share this achievement with your professional network.",
  CERTIFICATE_DOWNLOAD_CERTIFICATE_BOX_LINKEDIN_ADD: "Add to profile",
} as UICopyMap;
