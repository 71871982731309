export function last<T>(arr: T[]): T {
  return arr[arr.length - 1];
}

export function letterFromIndex(n: number): string {
  if (n > -1 && n < 27) {
    return String.fromCharCode(65 + n).toLowerCase();
  }
  return "";
}
