import * as React from "react";
import { Box, Text } from "theme-ui";
import {
  AssignmentType,
  ExerciseStatusType,
  Question,
  UploadedFile,
} from "../../models/assignment";
import { useSessionContext } from "../../contexts/session-context";
import { AssignmentService } from "../../services/assignment-service";
import { FilesUpload } from "./FilesUpload";
import { LoaderInline } from "../Loader";
import { UploadedFileItem } from "./UploadedFileItem";
import { useAssignmentContext } from "../../contexts/assignment-context";
import { useAssignmentAnswersContext } from "../../contexts/assignment-answers-context";
import { levelsAtom } from "@sparkademy/app-common/stores/user";
import { useAtom } from "jotai";

export const QuestionInputFile: React.FC<{ question: Question }> = ({ question }) => {
  const { assignment, assignmentStatus } = useAssignmentContext();
  const [questionExerciseId, _] = question.id.split(".");
  const exerciseId = "ex" + questionExerciseId;
  const exerciseStatus = assignmentStatus.find(as => as.exercise_id === exerciseId);
  const readonly =
    exerciseStatus?.status === ExerciseStatusType.SUBMITTED ||
    exerciseStatus?.status === ExerciseStatusType.GRADED;

  const { localAnswers, savedAnswers, updateLocalAnswer, dirtyState, setDirtyState } =
    useAssignmentAnswersContext();
  const localAnswer = localAnswers.get(question.id) as UploadedFile;
  const savedAnswer = savedAnswers.get(question.id) as UploadedFile;

  const [uploadedFile, setUploadedFile] = React.useState<UploadedFile | null>(localAnswer || null);
  const [showLoader, setShowLoader] = React.useState<boolean>(false);
  const { currentUser } = useSessionContext();
  const [levels] = useAtom(levelsAtom);

  const module = levels.flatMap(l => l.modules).find(m => m.id === assignment.module_id);
  const isRetry = module?.retry;

  const isGradingTool =
    window.location.hostname.includes("evaluation") ||
    window.location.hostname.includes("localhost");

  React.useEffect(() => {
    if (savedAnswer) {
      setUploadedFile(savedAnswer);
    }
  }, [savedAnswer]);

  const uploadFiles = async (assignmentType: AssignmentType, files: FileList) => {
    setShowLoader(true);
    try {
      const res = await AssignmentService.uploadAssignmentFiles(
        question.id,
        assignment.module_id,
        assignmentType,
        isRetry || false,
        files,
        currentUser!
      );
      if (res.error) {
        console.error(res.error);
      } else if (res.files && res.files.length > 0) {
        const uploaded = res.files[0];
        setUploadedFile(uploaded);
        updateLocalAnswer(question.id, uploaded);
        // mark the answer's state as dirty to enable the save button
        if (!dirtyState) {
          setDirtyState(true);
        }
      }
      setShowLoader(false);
    } catch (err) {
      console.error(err);
      setShowLoader(false);
    }
  };

  const deleteUploadedFile = async (assignmentUploadId: number) => {
    setShowLoader(true);
    try {
      await AssignmentService.deleteAssignmentUpload(assignmentUploadId, currentUser!);
      localAnswers.delete(question.id);
      setUploadedFile(null);
      setShowLoader(false);
      if (dirtyState && localAnswers.size === 0) {
        setDirtyState(false);
      }
    } catch (err) {
      console.error(err);
      setShowLoader(false);
    }
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      {!readonly && (
        <FilesUpload
          onFilesAdded={files => uploadFiles(AssignmentType.PRACTICAL_ASSIGNMENT, files)}
          disabled={showLoader || !!uploadedFile}
          accept=".jpg,.jpeg,.png,.pdf,.xls,.xlsx"
          multiple={false}
        />
      )}

      {showLoader ? (
        <LoaderInline />
      ) : (
        <Box
          sx={{
            p: 4,
            bg: "new.secondary.lightGrey",
            borderRadius: "5px",
            minHeight: "40px",
          }}
        >
          {uploadedFile?.file_url ? (
            <UploadedFileItem
              key={uploadedFile.id}
              file={uploadedFile}
              onItemRemove={!readonly ? () => deleteUploadedFile(uploadedFile.id) : undefined}
              forGrading={isGradingTool}
            />
          ) : readonly ? (
            <Text>[No file uploaded]</Text>
          ) : null}
        </Box>
      )}
    </Box>
  );
};
