import { getStatus } from "../services/http-api-service";
import { levelsAtom, isSyncingAtom, modulesAtom } from "@sparkademy/app-common/stores/user";
import { User } from "@sparkademy/app-common/models/user";
import { useAtom } from "jotai";

export function useUserData() {
  const [levels, setLevels] = useAtom(levelsAtom);
  const [enrolledModules] = useAtom(modulesAtom);
  const [isSyncing, setIsSyncing] = useAtom(isSyncingAtom);

  async function sync(user: User) {
    setIsSyncing(true);
    const newStatus = await getStatus(user);
    if (newStatus) {
      setLevels(newStatus.levels);
    }
    setIsSyncing(false);
  }

  function getModuleById(moduleId: string) {
    const programModules = levels.flatMap(l => l.modules);
    const mod = programModules.find(m => m.id === moduleId);
    if (mod) return mod;

    return enrolledModules.find(m => m.id === moduleId);
  }

  const allModules = levels ? levels.flatMap(level => level.modules) : [];
  allModules.push(...enrolledModules);
  return {
    levels,
    allModules,
    getModuleById,
    isSyncing,
    sync,
  };
}
