declare var _iub: any; // iubenda js sdk

export class IubendaService {
  static submitConsent(id: string, email: string, proofs: object) {
    if (typeof _iub === "undefined") {
      console.warn("iubenda js sdk not loaded, consent will not be submitted.");
      return;
    }

    if (window.E2E_TESTING || window.IMPERSONATE_SESSION) {
      return;
    }

    try {
      _iub.cons_instructions.push([
        "submit",
        {
          consent: {
            subject: { id, email },
            legal_notices: [{ identifier: "privacy_policy" }, { identifier: "term" }],
            preferences: {
              privacy_policy: true,
              term: true,
            },
            proofs: [
              {
                content: JSON.stringify(proofs),
              },
            ],
          },
        },
        {
          success: function () {
            console.log("consent successfully submitted");
          },
          error: function (response: any) {
            console.error(response);
          },
        },
      ]);
    } catch (err) {
      console.error(err);
    }
  }
}
