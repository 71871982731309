/** @jsx jsx */
import { jsx } from "theme-ui";
import * as React from "react";
import {
  AlertDialog as AlertDialogBase,
  AlertDialogLabel as AlertDialogLabelBase,
  AlertDialogDescription as AlertDialogDescriptionBase,
  AlertDialogOverlay as AlertDialogOverlayBase,
  AlertDialogContent as AlertDialogContentBase,
  AlertDialogProps,
  AlertDialogContentProps,
} from "@reach/alert-dialog";

type Props = {};

export {
  AlertDialogBase as AlertDialog,
  AlertDialogLabelBase as AlertDialogLabel,
  AlertDialogDescriptionBase as AlertDialogDescription,
};

export const AlertDialogOverlay: React.FC<AlertDialogProps> = props => (
  <AlertDialogOverlayBase
    sx={{
      background: "rgba(0, 0, 0, 0.6)",
      position: "fixed",
      zIndex: 10,
      top: "0px",
      left: "0px",
      right: "0px",
      bottom: "0px",
      overflow: "hidden",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      textAlign: "left",
    }}
    {...props}
  />
);

export const AlertDialogContent: React.FC<Props & AlertDialogContentProps> = props => (
  <AlertDialogContentBase
    sx={{
      outline: "none",
      margin: 0,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      maxWidth: "80vw",
      maxHeight: "99vh",
      bg: "new.primary.white",
      borderRadius: 10,
      p: 8,
    }}
    {...props}
  />
);
