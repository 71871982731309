import * as React from "react";
import { Heading, Text, Box } from "theme-ui";
import { ReactComponent as Calendar } from "@sparkademy/app-common/materials/icons/new/calendar.svg";
import { ReactComponent as Time } from "@sparkademy/app-common/materials/icons/new/time.svg";
import { ReactComponent as AssessmentIcon } from "@sparkademy/app-common/materials/icons/new/assessment.svg";
import { IconDescriptionList, IconDescriptionListItem } from "./IconDescriptionList";
import { Project } from "@sparkademy/app-common/models/level";
import { ButtonGhostAnchor } from "@sparkademy/app-common/elements/ButtonGhost";

type Props = {
  index: number;
  project: Project;
};

export const CardProject: React.FC<Props> = ({ index, project }) => {
  const columnProps = {
    py: 6,
    display: "flex",
    borderBottom: "0.5px solid",
    borderBottomColor: "new.secondary.grey",
    alignItems: "center",
  };

  const practicalAssignmentUrl = `${window.location.protocol}//${window.location.host}/practical-assignment/${project.moduleId}`;
  const openAssignmentButton = (
    <ButtonGhostAnchor href={practicalAssignmentUrl} sx={{ minHeight: "unset", p: 0 }}>
      Open
    </ButtonGhostAnchor>
  );
  const displayStatus = project.status === "submitted" ? "Submitted" : "In Progress";
  const displayStatusColor =
    project.status === "submitted" ? "new.primary.green" : "new.secondary.grey";

  return (
    <Box
      id="card-project"
      as="article"
      sx={{
        bg: "new.primary.white",
        borderRadius: 10,
        flex: "1 1 auto",
        // Space between modules, so that the left timeline fits this gap
        mb: 0,
      }}
    >
      <Box
        sx={{
          borderTopRightRadius: 10,
          borderTopLeftRadius: 10,
          bg: "#000",
          height: "80px",
          color: "#fff",
          p: "32px 0 27px 32px",
        }}
      >
        <Heading as="h3" sx={{ fontSize: 2, fontWeight: "bold" }}>
          Project Work
        </Heading>
      </Box>

      <Box sx={{ p: 8 }}>
        <Box sx={{ mt: 3 }} />
        <Box
          sx={{
            maxWidth: "65ch",
            fontSize: 0,
          }}
        >
          <Text
            as="p"
            sx={{
              fontSize: 0,
              lineHeight: "body",
            }}
          >
            {project.content}
          </Text>
        </Box>
        <Box sx={{ mt: 7 }} />
        <IconDescriptionList>
          <IconDescriptionListItem id="duration" Icon={Calendar} title="Duration">
            <Box sx={{ color: "new.secondary.violet", fontWeight: 500 }}>{project.duration}</Box>
          </IconDescriptionListItem>

          <IconDescriptionListItem id="time-commitment" Icon={Time} title="Time Commitment">
            <Box sx={{ color: "new.secondary.violet", fontWeight: 500 }}>
              {project.timeCommitment}
            </Box>
          </IconDescriptionListItem>
        </IconDescriptionList>

        <Box sx={{ mt: 7 }} />

        <Box
          sx={{
            mt: 8,
            borderTop: "1px solid",
            borderTopColor: "new.secondary.grey",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box
            sx={{
              fontWeight: "bold",
              ...columnProps,
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center", width: "30%" }}>
              <AssessmentIcon />
              Assignments
            </Box>
            <Box sx={{ width: "20%" }}>Instructions</Box>
            <Box sx={{ width: "50%" }}>Status</Box>
          </Box>

          <Box sx={columnProps}>
            <Box sx={{ width: "30%" }}>Deliverables</Box>
            <Box sx={{ width: "20%" }}>
              <ButtonGhostAnchor
                href={project.instructionUrl}
                target="_blank"
                sx={{ minHeight: "unset", p: 0 }}
              >
                Download PDF
              </ButtonGhostAnchor>
            </Box>
            <Box id="pratical-assignment-status" sx={{ width: "50%", fontWeight: 500 }}>
              {project.status !== null ? (
                <Box sx={{ color: displayStatusColor }}>
                  <Text sx={{ display: "inline" }}>{displayStatus}</Text>
                  <Text as="span" sx={{ px: "10px" }}>
                    |
                  </Text>
                  {openAssignmentButton}
                </Box>
              ) : (
                openAssignmentButton
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
