/** @jsx jsx */
import { jsx, SxProps } from "theme-ui";
import { useFocusRing } from "@react-aria/focus";
import * as React from "react";
import { Box } from "theme-ui";
import { ReactComponent as ArrowDown } from "@sparkademy/app-common/materials/icons/keyboard_arrow_down.svg";
import { ReactComponent as Arrow } from "@sparkademy/app-common/materials/icons/new/arrow.svg";
import {
  Accordion as AccordionBase,
  AccordionItem as AccordionItemBase,
  AccordionButton as AccordionButtonBase,
  AccordionPanel as AccordionPanelBase,
  AccordionButtonProps,
  AccordionPanelProps,
} from "@reach/accordion";
// TODO: maybe this is not needed
import "@reach/accordion/styles.css";

export const AccordionButton: React.FC<
  AccordionButtonProps & {
    sx?: SxProps;
  }
> = ({ children, sx = {}, ...rest }) => {
  let { isFocusVisible, focusProps } = useFocusRing();
  return (
    <AccordionButtonBase
      sx={{
        fontFamily: "inherit",
        background: "none",
        border: 0,
        textAlign: "left",
        padding: 0,
        fontSize: 2,
        color: "new.primary.purple",
        fontWeight: "bold",
        lineHeight: 1.4,
        width: "100%",
        cursor: "pointer",
        ":hover": {
          color: "new.secondary.violet",
        },
        ":focus:not(.focus-keyboard)": {
          outline: "none",
        },
        svg: {
          transition: "transform 200ms ease-out",
          transform: "rotate(-90deg)",
          color: "black",
        },
        '&[aria-expanded="true"]': {
          svg: {
            transform: "none",
            transformOrigin: "center center",
          },
        },
        "@keyframes wiggle": {
          "0%": { transform: " rotate(-90deg)" },
          "80%": { transform: "rotate(-90deg)" },
          "85%": { transform: "rotate(-100deg)" },
          " 95%": { transform: "rotate(-80deg)" },
          "100%": { transform: "rotate(-90deg)" },
        },
        '&[aria-expanded="false"]:hover': {
          svg: {
            animation: "wiggle 2.5s ease-in-out infinite",
          },
        },
        ...sx,
      }}
      className={isFocusVisible ? "focus-keyboard" : ""}
      {...focusProps}
      {...rest}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
        }}
      >
        {children}
        <Box
          sx={{
            ml: 8,
            display: "flex",
            flex: "0 0 auto",
            justifyContent: "center",
            alignItems: "center",
            // transform: "translateY(10px)",
            svg: {
              width: ".7em",
              height: "auto",
            },
          }}
        >
          <Arrow />
        </Box>
      </Box>
    </AccordionButtonBase>
  );
};

export const Accordion = AccordionBase;
export const AccordionItem = AccordionItemBase;
export const AccordionPanel: React.FC<AccordionPanelProps> = props => {
  let { isFocusVisible, focusProps } = useFocusRing();
  return (
    <AccordionPanelBase
      sx={{
        pb: 3,
        ":focus:not(.focus-keyboard)": {
          outline: "none",
        },
      }}
      className={isFocusVisible ? "focus-keyboard" : ""}
      {...focusProps}
      {...props}
    />
  );
};
