import * as React from "react";
import { useSessionContext } from "@sparkademy/app-common/contexts/session-context";
import { Layout, LayoutSideMenu } from "../components/Layout";
import { ContainerSmall } from "@sparkademy/app-common/elements/Container";
import { Heading, Box, Link } from "theme-ui";
import { Loader } from "@sparkademy/app-common/components/Loader";
import { useContentContext } from "@sparkademy/app-common/contexts/content-context";
import { List, ListItem } from "@sparkademy/app-common/elements/List";
import { useUserData } from "../hooks/useUserData";
import { Module } from "@sparkademy/app-common/models/module";
import { CardProject } from "../components/CardProject";
import { CardModule } from "../components/CardModule";
import { Level } from "@sparkademy/app-common/models/level";
import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
} from "@sparkademy/app-common/elements/Accordion";

export const Overview: React.FC = () => {
  const { currentUser } = useSessionContext();
  const { levels, isSyncing, allModules } = useUserData();
  const { isLoading: isLoadingContent } = useContentContext();

  if (!currentUser || isLoadingContent || isSyncing) {
    return (
      <Layout>
        <Loader />
      </Layout>
    );
  }

  // map module ids to levels so we can look them up
  const levelByModuleIdMap = {} as Record<string, Level>;
  levels.forEach(level => {
    level.modules.forEach(module => {
      levelByModuleIdMap[module.id] = level;
    });
  });

  const activeModules = allModules.filter(module =>
    ["pending-evaluation", "open"].includes(module.status)
  );
  const pastModules = allModules.filter(module => module.isCompleted || module.isFailed);
  const upcomingModules = allModules.filter(
    module => !module.isActive && !(module.isCompleted || module.isFailed)
  );

  return (
    <LayoutSideMenu
      sx={{
        bg: "new.bg",
      }}
    >
      <Box
        className="welcome-banner"
        sx={{
          background: theme => `
          linear-gradient(
            165.14deg,
            ${theme.colors.new.primary.white} -264%,
            rgba(255, 255, 255, 0) 104.92%
          ), ${theme.colors.new.primary.purple}
          `,
        }}
      >
        <ContainerSmall
          sx={{
            color: "new.primary.white",
            height: 100,
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-end",
            pb: 5,
            pl: 10,
          }}
        >
          <Heading
            as="h1"
            sx={{
              fontSize: 3,
              fontWeight: "bold",
            }}
          >
            Welcome back, {currentUser.data.firstName}!
          </Heading>
        </ContainerSmall>
      </Box>
      <ContainerSmall
        sx={{
          // pl: 10,
          py: 10,
        }}
      >
        {allModules.length > 0 ? (
          <Accordion collapsible multiple defaultIndex={0}>
            <CourseSection
              title="Active Courses"
              levelByModuleIdMap={levelByModuleIdMap}
              modules={activeModules}
            />
            <CourseSection
              title="Upcoming Courses"
              levelByModuleIdMap={levelByModuleIdMap}
              modules={upcomingModules}
            />
            <CourseSection
              title="Past Courses"
              levelByModuleIdMap={levelByModuleIdMap}
              modules={pastModules}
            />
          </Accordion>
        ) : (
          <Box as="p" sx={{ fontSize: [1, 2], mb: 10 }}>
            You're not enrolled in any courses yet,{" "}
            <Link sx={{ textDecoration: "none" }} href="/courses">
              check available courses here.
            </Link>
          </Box>
        )}
      </ContainerSmall>
    </LayoutSideMenu>
  );
};

const CourseSection: React.FC<{
  title: string;
  levelByModuleIdMap: Record<string, Level>;
  modules: Module[];
}> = ({ title, levelByModuleIdMap, modules }) => {
  if (modules.length > 0)
    return (
      <AccordionItem>
        <Box sx={{ mx: [2, 10, 10], mb: 8 }} id={title.split(" ").join("-")}>
          <AccordionButton>
            <Heading as="h1" sx={{ py: 5, fontSize: [2, 3], fontWeight: "bold", color: "black" }}>
              {title}
            </Heading>
          </AccordionButton>
          <AccordionPanel>
            <Box
              sx={{
                width: "100%",
              }}
            >
              <List id="module-list">
                {modules.map((module, i) =>
                  module.isStandaloneModule ? (
                    <StandaloneModule i={i} module={module} key={i} />
                  ) : (
                    <CompanyCohortModule
                      i={i}
                      module={module}
                      key={i}
                      level={levelByModuleIdMap[module.id]}
                    />
                  )
                )}
              </List>
            </Box>
          </AccordionPanel>
        </Box>
      </AccordionItem>
    );
  else return null;
};

const StandaloneModule: React.FC<{
  i: number;
  module: Module;
}> = ({ i, module }) => {
  const numberOfFailedModules = 0;

  return (
    <ListItem
      key={i}
      sx={{
        mt: 7,
      }}
    >
      <CardModule module={module} numberOfFailedModules={numberOfFailedModules} />
    </ListItem>
  );
};

const CompanyCohortModule: React.FC<{
  i: number;
  module: Module;
  level: Level;
}> = ({ i, module, level }) => {
  // const level = levels.find(l => l.id === module.levelId);

  const numberOfFailedModules = level.modules.filter(m => m.isFailed).length;

  return (
    <ListItem
      key={i}
      sx={{
        mt: 7,
      }}
    >
      {level.project && module.id === level.project.moduleId ? (
        <CardProject project={level.project} index={i} />
      ) : (
        <CardModule module={module} numberOfFailedModules={numberOfFailedModules} />
      )}
    </ListItem>
  );
};
