import { Box, Heading } from "theme-ui";

import * as React from "react";
import { LayoutSideMenu } from "../components/Layout";
import { Breadcrumb } from "../components/Breadcrumb";
import { LessonSection, sectionLabelHalfHeight } from "../components/Course/LessonSection";
import { Container } from "@sparkademy/app-common/elements/Container";
import { courseAtom } from "../stores/course";
import { TrackingService } from "../services/tracking-service";
import { ResumeButton } from "../components/Course/ResumeButton";
import { useAtom } from "jotai";
import { useContentContext } from "@sparkademy/app-common/contexts/content-context";
import { useSessionContext } from "@sparkademy/app-common/contexts/session-context";

// Course Dashboard Page
export const Course: React.FC = () => {
  const [course] = useAtom(courseAtom);
  const { levels, modules } = useContentContext();
  const { currentUser } = useSessionContext();

  const viewStartTimeRef = React.useRef<Date>(new Date());
  React.useEffect(() => {
    // run this once on mount
    viewStartTimeRef.current = new Date();
    return () => {
      // run this on unmount
      if (course) {
        TrackingService.timeSpentOnCourseDashboard(
          course.title,
          viewStartTimeRef.current,
          new Date()
        );
      }
    };
  }, [course]);

  if (!course) {
    return null;
  }

  const courseModule = modules.find(m => m.id === course.module);
  const courseLevel = levels.find(l => l.id === courseModule?.level);

  // b2c users are not enrolled in a full program
  const breadcrumbItems = currentUser?.b2cUser
    ? [
        { name: "Dashboard", url: "/dashboard" },
        { name: course.title, url: "" },
      ]
    : [
        { name: "Programs", url: "/programs" },
        { name: courseLevel?.name, url: `/programs/${courseLevel?.id}` },
        { name: course.title, url: "" },
      ];

  const lessonContainerMarginTop = 80 - sectionLabelHalfHeight;
  const lessonContainerMarginTopMobile = 60 - sectionLabelHalfHeight;

  return (
    <LayoutSideMenu sx={{ bg: "new.primary.white" }}>
      <Container
        sx={{
          flex: "1 1 auto",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          pt: ["70px", "60px"],
          maxWidth: "894px",
          px: "32px",
        }}
      >
        <Box sx={{ width: "100%" }}>
          <Breadcrumb items={breadcrumbItems} />
        </Box>
        <Box>
          <Heading as="h2" sx={{ fontWeight: 700, mt: "8px" }}>
            {course.title}
          </Heading>

          <div
            style={{ marginTop: "48px", lineHeight: "1.4", fontSize: "16px" }}
            dangerouslySetInnerHTML={{ __html: course.description }}
          />
        </Box>

        <ResumeButton courseId={course.id} type="button" />

        <Box
          sx={{
            mt: [`${lessonContainerMarginTopMobile}px`, `${lessonContainerMarginTop}px`],
            mb: ["60px", "80px"],
          }}
        >
          {course.lessons.map(l => (
            <LessonSection key={l.id} courseId={course.id} lesson={l} />
          ))}
        </Box>
      </Container>
    </LayoutSideMenu>
  );
};
