import { atom } from "jotai";

const savedData = sessionStorage.getItem("dateRef");
const saveDateInit = sessionStorage.getItem("showDate");

const updateCurrentDate = (d: Date) => {
  sessionStorage.setItem("dateRef", d.toISOString());
  return d;
};

const dateAtom = atom(savedData ? new Date(savedData) : new Date());
const showDateAtom = atom(saveDateInit ? saveDateInit : false);

const dateStateAtom = atom(
  get => get(dateAtom),
  (_, set, date: Date) => set(dateAtom, updateCurrentDate(date))
);

const toggleShowDateAtom = atom(null, (get, set) => {
  const showDate = get(showDateAtom);
  set(showDateAtom, !showDate);
  if (showDate) {
    sessionStorage.removeItem("showDate");
  } else {
    sessionStorage.setItem("showDate", "1");
  }
});

export { dateStateAtom, showDateAtom, toggleShowDateAtom };
