/** @jsx jsx */
import { jsx, Box, Text, SxStyleProp } from "theme-ui";
import * as React from "react";
import { useSessionContext } from "@sparkademy/app-common/contexts/session-context";
import { LinkProps } from "react-router-dom";
import {
  ButtonGhost,
  ButtonGhostAnchor,
  ButtonGhostLink,
} from "@sparkademy/app-common/elements/ButtonGhost";
import { dateStateAtom, showDateAtom } from "../stores/current-date";
import { TrackingService } from "../services/tracking-service";
import { useLocation } from "react-router-dom";
import { useAtom } from "jotai";
import { useResponsiveValue } from "@theme-ui/match-media";

import { sideMenuIsShowingAtom } from "@sparkademy/app-common/stores/ui";
import { useUpdateAtom } from "jotai/utils";
import { ReactComponent as GroupIcon } from "@sparkademy/app-common/materials/icons/new/users-group.svg";
import { ProductTour } from "./ProductTour";
import { HomeworkIcon } from "./CustomIcons/HomeworkIcon";
import { SuitcaseIcon } from "./CustomIcons/SuitcaseIcon";
import { BookIcon } from "./CustomIcons/BookIcon";
import { AcademicIcon } from "./CustomIcons/AcademicIcon";
import { HomeIcon } from "./CustomIcons/HomeIcon";
import { ExitIcon } from "./CustomIcons/ExitIcon";

export const MainSideMenu: React.FC = () => {
  const { currentUser, logout } = useSessionContext();
  const [date, update] = useAtom(dateStateAtom);
  const [showDate] = useAtom(showDateAtom);
  const [dateStr, setDateStr] = React.useState("");

  React.useEffect(() => {
    setDateStr(date.toISOString().slice(0, 10));
  }, [date]);

  return (
    <nav
      sx={{
        bg: "new.primary.white",
        zIndex: 1,
      }}
    >
      <Box
        sx={{
          top: 10,
          height: "90vh",
          display: "flex",
          flexDirection: "column",
          justifyContent: ["inital", "space-between"],
          alignItems: "center",
          //alignContent: "start",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "100%",
          }}
        >
          <SideMenuButton
            to={"/"}
            text={"Home"}
            style={{ mt: [5, 10] }}
            iconRenderer={selected => <HomeIcon color={selected ? "#4b27e0" : "#3C3C3B"} />}
          />
          {/*
            users part of a cohort should see the "programs" page and not the "courses" page
            they are enrolled in all modules of a program automatically
          */}
          {currentUser && !currentUser.b2cUser && (
            <SideMenuButton
              to={"/programs"}
              text={"Programs"}
              iconRenderer={selected => <AcademicIcon color={selected ? "#4b27e0" : "#3C3C3B"} />}
            />
          )}
          {/* users not part of a cohort should see the "courses" page so they can enroll in them */}
          {currentUser && !currentUser.data.cohort_id && (
            <SideMenuButton
              to={"/courses"}
              text={"Courses"}
              iconRenderer={selected => <BookIcon color={selected ? "#4b27e0" : "#3C3C3B"} />}
            />
          )}
          {currentUser && ["CARGILL", "ZHAW", "Test"].includes(currentUser.company) ? (
            <SideMenuButton
              to={"/workshops"}
              text={"Workshops"}
              iconRenderer={selected => <SuitcaseIcon color={selected ? "#4b27e0" : "#3C3C3B"} />}
            />
          ) : null}

          <Text
            sx={{
              mt: 10,
              mb: 2,
              fontWeight: 400,
              fontSize: "15px",
              alignSelf: "start",
              ml: 12,
              textTransform: "uppercase",
            }}
          >
            Resources
          </Text>

          {currentUser && currentUser.data.cohort_id && (
            <SideMenuButton
              to={"/method-kit"}
              text={"Method Kit"}
              iconRenderer={selected => <HomeworkIcon color={selected ? "#4b27e0" : "#3C3C3B"} />}
            />
          )}

          <SideMenuButton
            to={
              "https://community.sparkademy.com/users/sign_in?post_login_redirect=https%3A%2F%2Fcommunity.sparkademy.com"
            }
            text={"Community"}
            openInNewTab
            iconRenderer={() => <GroupIcon sx={{ mr: 3 }} />}
          />
        </Box>

        {currentUser ? (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              width: "100%",
              pb: 10,
            }}
          >
            <Text
              as="span"
              sx={{
                mt: 10,
                fontWeight: 400,
                fontSize: "15px",
                alignSelf: "start",
                ml: 12,
                textTransform: "uppercase",
              }}
            >
              {currentUser.username}
            </Text>
            <Box
              sx={{
                display: "flex",
                alignContent: "center",
                mt: 5,
                mb: 10,
                alignItems: "center",
                // justifyContent: "start",
                width: "90%",
                ml: 15,
              }}
            >
              <ExitIcon color="#4b27e0" />
              <ButtonGhost
                id="logout-btn"
                onClick={() => {
                  TrackingService.logoutClick();
                  logout();
                }}
                sx={{
                  fontSize: 1,
                  fontWeight: 500,
                  pl: 0,
                  // px: 15,
                }}
              >
                Logout
              </ButtonGhost>
            </Box>
          </Box>
        ) : null}
      </Box>

      {/* Date Debugging tool */}
      {/* Should move? Doesn't seem right place... */}
      {showDate ? (
        <Box
          sx={{
            position: "fixed",
            bottom: 3,
            left: 3,
            bg: "grays.light",
            color: "grays.medium",
            px: 2,
            py: 1,
            borderRadius: 5,
            fontSize: 0,
          }}
        >
          <form
            onSubmit={event => {
              event.preventDefault();
              const newDate = Date.parse(dateStr);
              if (isNaN(newDate)) {
                return;
              }

              update(new Date(newDate));
            }}
          >
            <input
              value={dateStr}
              onChange={event => {
                setDateStr(event.target.value);
              }}
            />
            <button type="submit">set</button>
          </form>
        </Box>
      ) : null}
    </nav>
  );
};

const SideMenuButton: React.FC<{
  to: LinkProps["to"];
  text: string;
  style?: SxStyleProp;
  openInNewTab?: boolean;
  iconRenderer: (selected: boolean) => React.ReactNode;
}> = ({ to, text, style, openInNewTab = false, iconRenderer }) => {
  const location = useLocation();
  const toggleSideMenu = useUpdateAtom(sideMenuIsShowingAtom);
  const isSelected =
    ((to as string) !== "/" && location.pathname.includes(to as string)) ||
    (location.pathname === "/" && to === "/") ||
    (location.pathname === "/dashboard" && to === "/");

  if (to.startsWith("https://")) {
    // external link
    return (
      <ButtonGhostAnchor
        href={to}
        target="_blank"
        sx={{
          fontSize: 1,
          fontWeight: 500,
          mt: 5,
          width: "90%",
          color: isSelected ? "new.primary.purple" : "new.primary.darkGrey",
        }}
        onClick={() => {
          //TODO: add tracking here...
          toggleSideMenu(false);
        }}
      >
        {iconRenderer(false)}
        {text}
      </ButtonGhostAnchor>
    );
  }

  return (
    <ButtonGhostLink
      to={to}
      className={`menu-item-${text.replace(/\s/, "").toLowerCase()}`}
      target={openInNewTab ? "_blank" : undefined}
      sx={{
        fontSize: 1,
        fontWeight: isSelected ? 700 : 500,
        width: "90%",
        mt: 5,
        color: isSelected ? "new.primary.purple" : "new.primary.darkGrey",
        backgroundColor: isSelected ? "new.grays.nearlyWhite" : "initial",
        borderRadius: "10px",
        fill: isSelected ? "#4b27e0" : "initial",
        ...style,
      }}
      onClick={() => {
        //TODO: add tracking here...
        toggleSideMenu(false);
      }}
    >
      {iconRenderer(isSelected)}
      {text}
    </ButtonGhostLink>
  );
};
