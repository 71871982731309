import * as React from "react";
import {
  AlertDialogLabel,
  AlertDialogDescription,
  AlertDialogOverlay,
  AlertDialogContent,
} from "@sparkademy/app-common/elements/Alert";
import { Box, Text, Button as BaseButton } from "theme-ui";
import { Button } from "@sparkademy/app-common/elements/Button";
import { ReactComponent as IllustrationActivity } from "@sparkademy/app-common/materials/illustrations/activity.svg";
import { ReactComponent as Close } from "@sparkademy/app-common/materials/icons/close.svg";
import VisuallyHidden from "@reach/visually-hidden";

export const SaveAnswersDialog: React.FC<{ onClose: () => void }> = ({ onClose }) => {
  const cancelRef = React.useRef<HTMLButtonElement>();

  return (
    <AlertDialogOverlay
      // @ts-ignore
      leastDestructiveRef={cancelRef}
      id="SaveAnswersDialog"
    >
      <AlertDialogContent>
        <AlertDialogLabel
          style={{ height: "32px", width: "100%", position: "sticky", top: "32px" }}
        >
          <BaseButton
            sx={{
              all: "unset",
              fontFamily: "inherit",
              float: "right",
              color: "primary",
              cursor: "pointer",
              bg: "transparent",
            }}
            onClick={onClose}
          >
            <VisuallyHidden>Close</VisuallyHidden>
            <Close width={32} height={32} aria-hidden="true" focusable="false" />
          </BaseButton>
        </AlertDialogLabel>

        <Box sx={{ maxWidth: "600px" }}>
          <AlertDialogLabel></AlertDialogLabel>
          <AlertDialogDescription>
            <Box
              sx={{
                margin: "auto",
                width: 170,
                height: 170,
                svg: {
                  width: "100%",
                  height: "auto",
                },
              }}
            >
              <IllustrationActivity />
            </Box>
            <Text as="p" sx={{ textAlign: "center", fontWeight: 700, fontSize: "25px" }}>
              Your progress has been saved.
            </Text>
            <Text as="p" sx={{ textAlign: "center", fontWeight: 700, fontSize: "25px" }}>
              To submit it for evaluation, click on Submit at the end of the exercise.
            </Text>
          </AlertDialogDescription>
        </Box>
        <Box sx={{ mt: 11 }} />
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            flexDirection: "row-reverse",
          }}
        >
          <Button type="submit" onClick={() => onClose()}>
            OK
          </Button>
        </Box>
      </AlertDialogContent>
    </AlertDialogOverlay>
  );
};
