/** @jsx jsx */
import React, { useState } from "react";
import { jsx, Box, Heading, Container, Slider } from "theme-ui";
import { Helmet } from "react-helmet";
import useWindowSize from "react-use/lib/useWindowSize";
import { LayoutHeader } from "../components/Layout";
import { Narrative } from "@sparkademy/app-common/elements/Narrative";
import { ButtonAnchor } from "@sparkademy/app-common/elements/Button";
import { ReactComponent as IllustrationTeam } from "@sparkademy/app-common/materials/illustrations/team.svg";
import { ReactComponent as HatWithCheck } from "@sparkademy/app-common/materials/icons/new/hat-with-a-check.svg";
import { ReactComponent as ArrowDown } from "@sparkademy/app-common/materials/icons/new/arrow-down.svg";
import { ReactComponent as LogoWhite } from "@sparkademy/app-common/materials/icons/logo-white.svg";
import { ReactComponent as IdeaOnHand } from "@sparkademy/app-common/materials/illustrations/idea-on-hand.svg";
import { ReactComponent as SparkProfileGraph } from "@sparkademy/app-common/materials/illustrations/spark-profile-graph.svg";
import irregularSection from "@sparkademy/app-common/materials/illustrations/irregular-section.png";
import whiteUpsideDown from "@sparkademy/app-common/materials/illustrations/white-upside-down.png";

const Card: React.FC = ({ children }) => (
  <Box
    sx={{
      maxWidth: 390,
      m: ["10px", "unset"],
      bg: "white",
      boxShadow: "0px 1px 15px rgba(0, 0, 0, 0.1)",
      borderRadius: 20,
    }}
  >
    {children}
  </Box>
);

const TrySparkCheckButton: React.FC = () => (
  <ButtonAnchor
    className="try-spark-check"
    href={`${window.location.protocol}//${window.location.host}/spark-check-part1`}
    sx={{ mt: 8, width: 301, justifyContent: "center", alignSelf: "center" }}
  >
    Try Spark Check Lite
  </ButtonAnchor>
);

function calcNumberOfInnovators(numOfEmployees: number): number {
  if (!isNaN(numOfEmployees)) {
    return Math.round(numOfEmployees * 0.025);
  }
  return 0;
}

export const SparkCheckLanding: React.FC = () => {
  const { width } = useWindowSize();
  const [numOfEmployees, setNumOfEmployees] = useState<number>(10_000);
  const [numOfInnovators, setNumOfInnovators] = useState<number>(250);
  const showPlusSign = numOfEmployees >= 50_000;

  return (
    <LayoutHeader>
      <Helmet>
        <title>Check your innovation potential with the Spark Check</title>
        <meta property="og:title" content="Check your innovation potential with the Spark Check" />
      </Helmet>

      <Box
        sx={{
          background: "linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #fff 60%), #f9f7fe",
          width: "100vw",
          mt: "1px",
        }}
      >
        <Box
          sx={{
            margin: "auto",
            width: [width, 800],
            my: 10,
            display: "flex",
            flexWrap: "wrap",
          }}
        >
          <Box
            sx={{
              flexBasis: ["100%", "50%"],
              maxWidth: 400,
              p: 5,
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <Heading
              as="h1"
              sx={{
                fontSize: [1, 2],
                fontWeight: "bold",
                textAlign: "left",
                mb: 5,
              }}
            >
              <span sx={{ color: "primary" }}>The 2 Steps</span> to Start your Company
              Transformation
            </Heading>

            <p sx={{ fontSize: 0 }}>
              Some people have a stronger inclination towards challenging the status quo and driving
              change in big organisations. Finding those people is the best strategy to break
              through silos and transform your company.
            </p>
          </Box>

          <Box sx={{ flexBasis: ["100%", "50%"] }}>
            <Card>
              <Box sx={{ p: [25, 40] }}>
                <p sx={{ fontWeight: "bold", color: "primary", mt: 0 }}>Step 1</p>
                <p sx={{ fontSize: 1, fontWeight: "bold", mt: 4 }}>
                  Focus on high potential employees
                </p>
                <p>Set your Total Number of Employees</p>
                <p sx={{ fontSize: 1, fontWeight: "bold" }}>
                  {showPlusSign && "+"}
                  {new Intl.NumberFormat().format(numOfEmployees)}
                </p>

                <Slider
                  sx={{
                    color: "primary",
                    bg: "primary",
                    "::-moz-range-thumb": {
                      border: "4px solid",
                      borderColor: "primary",
                      background: "#ffffff",
                      cursor: "pointer",
                    },
                    "::-webkit-slider-thumb": {
                      border: "4px solid",
                      borderColor: "primary",
                      background: "#ffffff",
                      cursor: "pointer",
                    },
                    "::-ms-thumb": {
                      border: "4px solid",
                      borderColor: "primary",
                      background: "#ffffff",
                      cursor: "pointer",
                    },
                  }}
                  min={800}
                  max={50000}
                  step={100}
                  value={numOfEmployees}
                  onChange={e => {
                    const val = parseInt(e.target.value);
                    setNumOfEmployees(val);
                    const innovators = calcNumberOfInnovators(val);
                    setNumOfInnovators(innovators);
                  }}
                />
              </Box>

              <Box
                sx={{
                  backgroundImage: `url(${irregularSection})`,
                  backgroundPosition: "50% 100%",
                  backgroundSize: "contain",
                  backgroundRepeat: "no-repeat",
                  backgroundColor: "white",
                  height: 18,
                }}
              />

              <Box
                sx={{
                  p: [25, 40],
                  bg: "#ede9fc",
                  borderRadius: "0 0 20px 20px",
                }}
              >
                <p sx={{ color: "primary", fontWeight: "bold" }}>
                  <span sx={{ fontSize: "60px" }}>
                    {showPlusSign && "+"}
                    {new Intl.NumberFormat().format(numOfInnovators)}
                  </span>
                  &nbsp;
                  <span sx={{ fontSize: 2 }}>Innovators</span>
                </p>
                <p>
                  This is the number of employees that have the highest potential to transform your
                  company.
                </p>
              </Box>
            </Card>
          </Box>
        </Box>

        <Box
          sx={{
            margin: "auto",
            width: [width, 800],
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <p sx={{ fontSize: 0, color: "primary" }}>How you can find those innovators</p>
          <ArrowDown />
        </Box>

        <Box
          sx={{
            margin: "auto",
            width: [width, 800],
            my: 10,
            display: "flex",
            flexWrap: "wrap",
          }}
        >
          <Box sx={{ flexBasis: ["100%", "50%"] }}>
            <Card>
              <Box sx={{ p: [25, 40] }}>
                <Box sx={{ textAlign: "center", margin: "auto", mb: 8 }}>
                  <HatWithCheck />
                  <Heading as="h3" sx={{ fontWeight: "bold" }}>
                    Spark Check
                  </Heading>
                </Box>
                <p>
                  Changing the status quo means dealing with uncertainty and emotional push-back
                  from bosses, colleagues and customers.
                </p>
                <p>
                  Spark Check measures the ability to deal with uncertainty and other people’s
                  emotions.
                </p>
              </Box>

              <Box
                sx={{
                  backgroundImage: `url(${irregularSection})`,
                  backgroundPosition: "50% 100%",
                  backgroundSize: "contain",
                  backgroundRepeat: "no-repeat",
                  backgroundColor: "white",
                  height: 18,
                }}
              />

              <Box
                sx={{
                  p: [25, 40],
                  bg: "#ede9fc",
                  borderRadius: "0 0 20px 20px",
                }}
              >
                <Heading as="h3" sx={{ fontWeight: "bold" }}>
                  <span
                    sx={{
                      bg: "primary",
                      borderRadius: "50%",
                      color: "white",
                      width: 25,
                      height: 25,
                      lineHeight: "25px",
                      display: "inline-block",
                      textAlign: "center",
                    }}
                  >
                    1
                  </span>
                  &nbsp;Dealing With Uncertainty
                </Heading>
                <p>
                  This test identifies innovation potential by examining the participant’s abilities
                  to deal with uncertain choices.
                </p>
                <Heading as="h3" sx={{ fontWeight: "bold" }}>
                  <span
                    sx={{
                      bg: "primary",
                      borderRadius: "50%",
                      color: "white",
                      width: 25,
                      height: 25,
                      lineHeight: "25px",
                      display: "inline-block",
                      textAlign: "center",
                    }}
                  >
                    2
                  </span>
                  &nbsp;Dealing With Emotions
                </Heading>
                <p>
                  This test identifies innovation potential by examining the participant’s abilities
                  to perceive and manage other’s emotions.
                </p>
              </Box>
            </Card>
          </Box>

          <Box
            sx={{
              flexBasis: ["100%", "50%"],
              maxWidth: 400,
              p: 5,
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <p sx={{ fontWeight: "bold", color: "primary", mt: 1 }}>Step 2</p>
            <Heading as="h3" sx={{ fontWeight: "bold" }}>
              Identify the right innovators to lead your transformation movement
            </Heading>
            <p>
              Spark Check sorts your innovators into 4 categories;&nbsp;
              <span sx={{ color: "primary", fontWeight: "bold" }}>Front Runner</span>
              ,&nbsp;
              <span sx={{ color: "primary", fontWeight: "bold" }}>Problem Solver</span>
              ,&nbsp;
              <span sx={{ color: "primary", fontWeight: "bold" }}>People’s Person</span>, and&nbsp;
              <span sx={{ color: "primary", fontWeight: "bold" }}>Change Maker</span>.
            </p>
            <p>
              The people best equipped to push your transformation forward are the&nbsp;
              <span sx={{ color: "primary", fontWeight: "bold" }}>Change Makers</span>. Try Spark
              Check Lite our <b>15-minute</b> version of the Spark Check and find out what kind of
              innovator you are!
            </p>
            <TrySparkCheckButton />
          </Box>
        </Box>

        <Box
          sx={{
            margin: "auto",
            width: [width, 800],
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <p sx={{ fontSize: 0, color: "primary" }}>A little more about Spark Check</p>
          <ArrowDown />
        </Box>
      </Box>

      <Box
        sx={{
          backgroundImage: `url(${whiteUpsideDown})`,
          backgroundPosition: "50% 0",
          backgroundSize: "contain",
          backgroundRepeat: "no-repeat",
          backgroundColor: "background",
          height: [25, 75],
        }}
      />

      <Box sx={{ margin: "auto", width: [width, 800], pr: [5, 0], pl: [5, 0] }}>
        <Container
          sx={{
            textAlign: "center",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <Box
            sx={{
              width: 170,
              height: 170,
              svg: {
                width: "100%",
                height: "auto",
              },
            }}
          >
            <IllustrationTeam />
          </Box>

          <Heading
            as="h1"
            sx={{
              fontSize: [1, 2],
              fontWeight: "bold",
              textAlign: "center",
              mb: 10,
            }}
          >
            About the Transformation Typology
          </Heading>

          <Narrative
            sx={{
              p: {
                textAlign: "left",
                fontSize: 0,
              },
            }}
          >
            <p>
              People who drive organizational transformation combine two core abilities: they can
              deal with uncertainty to identify a way forward, and they are good at identifying
              needs by reading people’s (users, potential users, those within the company) emotional
              responses. Individuals differ in both dealing with uncertainty (how to adapt to
              fast-moving and unpredictable environments) and dealing with the emotions of others
              (how to interact with the people involved).
            </p>
            <p>
              When it comes to organizational transformation, individual approaches can range from
              reducing uncertainty (focus on solving existing problems) to adapting uncertainty
              (finding strategies to cope with future challenges). Meanwhile, one’s interaction
              style with others ranges from task-oriented (focus on goal achievement) to
              people-oriented (focus on the well-being of the team).
            </p>
            <p>
              These two dimensions, dealing with uncertainty and dealing with emotions of others,
              reveal four key transformation types: The <b>Change Maker</b>, the{" "}
              <b>People’s Person</b>, the <b>Front Runner</b>, and the <b>Problem Solver</b>.
              Getting an idea of your transformation type makes you aware of your strengths.
              Moreover, by consciously using them, you will support your company best in solving
              future challenges.
            </p>
          </Narrative>

          <Box
            sx={{
              my: 10,
              svg: {
                width: "100%",
                height: "auto",
              },
            }}
          >
            <SparkProfileGraph />
          </Box>
        </Container>
      </Box>

      <Box
        sx={{
          backgroundImage: `url(${whiteUpsideDown})`,
          backgroundPosition: "50% 0",
          backgroundSize: "contain",
          backgroundRepeat: "no-repeat",
          backgroundColor: "background",
          transform: "rotate(180deg)",
          height: [25, 75],
        }}
      />

      <Box sx={{ bg: "white", width: "100vw" }}>
        <Box
          sx={{
            margin: "auto",
            width: [width, 800],
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-around",
            my: 12,
          }}
        >
          <Container
            sx={{
              textAlign: "center",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <Box
              sx={{
                width: 120,
                height: 120,
                svg: {
                  width: "100%",
                  height: "auto",
                },
              }}
            >
              <IdeaOnHand />
            </Box>

            <Heading
              as="h1"
              sx={{
                fontSize: [1, 2],
                fontWeight: "bold",
                textAlign: "center",
                p: 5,
                mt: 8,
              }}
            >
              Want to find out what kind of innovator you are?
            </Heading>

            <TrySparkCheckButton />
          </Container>
        </Box>
      </Box>

      <Box sx={{ bg: "new.primary.darkGrey", width: "100vw" }}>
        <Box sx={{ margin: "auto", width: [width, 800] }}>
          <Container
            sx={{
              textAlign: "center",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              my: 15,
            }}
          >
            <Box
              sx={{
                width: 170,
                svg: {
                  width: "100%",
                  height: "auto",
                },
              }}
            >
              <LogoWhite />
            </Box>

            <p sx={{ color: "white", fontSize: 1, p: 5 }}>
              Sparkademy is an employee training program that helps companies like yours scale
              customer-centric skills and reduce spending.
            </p>

            <ButtonAnchor
              href="https://sparkademy.com"
              target="_blank"
              sx={{
                color: "primary",
                bg: "white",
                borderColor: "primary",
                border: "2px solid",
                ":hover": { bg: "white" },
              }}
            >
              Visit Our Website
            </ButtonAnchor>
          </Container>
        </Box>
      </Box>
    </LayoutHeader>
  );
};
