import { Text, Heading, Box, ThemeProvider } from "theme-ui";
import * as React from "react";
import { Layout, LayoutSideMenu } from "../components/Layout";
import { ExerciseSelector } from "../components/Assignment/ExerciseSelector";
import { SaveAnswersDialog } from "../components/Assignment/SaveAnswersDialog";
import { SubmitConfirmationDialog } from "../components/Assignment/SubmitConfirmationDialog";
import { SubmitDoneDialog } from "../components/Assignment/SubmitDoneDialog";
import { TeamFormationDialog } from "../components/Assignment/TeamFormationDialog";
import { TeamSaveConflictDialog } from "../components/Assignment/TeamSaveConflictDialog";
import { TeamSaveErrorDialog } from "../components/Assignment/TeamSaveErrorDialog";
import { TeamFormationPanel } from "../components/Assignment/TeamFormationPanel";
import { TeamSaveWarningDialog } from "../components/Assignment/TeamSaveWarningDialog";
import { FeedbackRating } from "../components/Assignment/FeedbackRating";
import { Container } from "@sparkademy/app-common/elements/Container";

import { Narrative } from "@sparkademy/app-common/elements/Narrative-v2";
import { useContentContext } from "@sparkademy/app-common/contexts/content-context";
import { themeNew } from "@sparkademy/app-common/materials/theme";
import { useParams, useHistory } from "react-router-dom";
import { useQuery } from "@sparkademy/app-common/utils/useQuery";
import { updateQuery } from "@sparkademy/app-common/utils/updateQuery";
import { ArrowLink } from "@sparkademy/app-common/elements/ArrowLink";
import { Loader } from "@sparkademy/app-common/components/Loader";
import { Button } from "@sparkademy/app-common/elements/Button";
import { ButtonOutline } from "@sparkademy/app-common/elements/ButtonOutline";
import { useSessionContext } from "@sparkademy/app-common/contexts/session-context";
import { useAssignmentContext } from "@sparkademy/app-common/contexts/assignment-context";
import { useAssignmentAnswersContext } from "@sparkademy/app-common/contexts/assignment-answers-context";
import { dateFormatter } from "@sparkademy/app-common/utils/date-formatters";
import { Exercise, ExerciseStatusType } from "@sparkademy/app-common/models/assignment";
import { ExercisePart } from "@sparkademy/app-common/components/assignment/ExercisePart";
import { MarkdownContent } from "@sparkademy/app-common/components/assignment/MarkdownContent";
import { AssignmentService } from "@sparkademy/app-common/services/assignment-service";
import { dateStateAtom } from "../stores/current-date";
import { useAtom } from "jotai";

import { TeamGuidelinesPanel } from "../components/Assignment/TeamGuidelinesPanel";

import { TrackingService } from "../services/tracking-service";
import { useUserData } from "../hooks/useUserData";
import { Module } from "@sparkademy/app-common/models/module";
import { ButtonGhostAnchor } from "@sparkademy/app-common/elements/ButtonGhost";
import { generateMethodCardLinks, getNextExerciseIndex } from "../components/Assignment/helpers";
import { FeedbackRatingPendingWarning } from "../components/Assignment/FeedbackRatingPendingWarning";

export const PracticalAssignment: React.FC = () => {
  const { moduleId } = useParams<{ moduleId: string }>();
  const query = useQuery();
  const history = useHistory();
  const { modules: modulesInfo } = useContentContext();
  const {
    assignment,
    assignmentStatus,
    loading: loadingExercises,
    teams,
    fetchTeams,
    fetchModuleAssignment,
    fetchAssignmentStatus,
  } = useAssignmentContext();
  const {
    lastActionsLog,
    localAnswers,
    clearLocalAnswers,
    clearLastActionsLog,
    clearGraderRatingState,
  } = useAssignmentAnswersContext();
  const { currentUser } = useSessionContext();
  const status = useUserData();

  const activeExerciseIdx = parseInt(query.get("activeExerciseIndex") || "0");
  // retrySwitchFlag is used to select a retry/no retry version of a practical assignment
  const retrySwitchFlag = query.get("retry") ? query.get("retry") === "1" : null;

  const [dialogToShow, setDialogToShow] = React.useState<string>("");
  const [isSaving, setIsSaving] = React.useState<boolean>(false);
  const [currentExerciseIndex, setCurrentExerciseIndex] = React.useState<number>(activeExerciseIdx);

  const [date] = useAtom(dateStateAtom);

  const viewStartTimeRef = React.useRef<Date>(new Date());
  const exerciseViewStartTimeRef = React.useRef<Date>(new Date());

  React.useEffect(() => {
    if (!assignment.id) return;
    const startTime = viewStartTimeRef.current;

    return () => {
      TrackingService.timeSpentOnPracticalAssignment(assignment, startTime, new Date());
    };
  }, [assignment]);

  React.useEffect(() => {
    if (!assignment.id) return;
    const startTime = exerciseViewStartTimeRef.current;

    return () => {
      const currentExercise = assignment.exercises[currentExerciseIndex];
      if (currentExercise) {
        TrackingService.timeSpentOnPracticalAssignmentExercise(
          assignment,
          currentExercise,
          startTime,
          new Date()
        );
      }
    };
  }, [assignment, currentExerciseIndex]);

  React.useEffect(() => {
    if (!currentUser) {
      return;
    }
    fetchModuleAssignment(moduleId, currentUser!.data.cohort_id, currentUser!, retrySwitchFlag);
  }, [moduleId, currentUser, fetchModuleAssignment]);

  React.useEffect(() => {
    fetchAssignmentStatus(moduleId, retrySwitchFlag, currentUser!);
  }, [moduleId, retrySwitchFlag, currentUser, fetchAssignmentStatus]);

  React.useEffect(() => {
    fetchTeams(moduleId, currentUser!);
  }, [moduleId, currentUser, fetchTeams]);

  if (modulesInfo.length === 0 || status.allModules.length === 0 || isSaving || loadingExercises) {
    return (
      <Layout>
        <Loader />
      </Layout>
    );
  }

  const module = status.allModules.find((m: Module) => m.id === moduleId);
  if (!module) {
    return null;
  }

  const moduleInfo = modulesInfo.find(m => m.id === moduleId);
  if (!moduleInfo) {
    return null;
  }

  const currentExercise = assignment.exercises[currentExerciseIndex];
  if (!currentExercise) {
    return null;
  }

  if (module.homework.start > date) {
    const openTimestamp = module.homework.start.getTime() / 1000;
    const closeTimestamp = module.homework.end.getTime() / 1000;
    history.push(`/error?reason=homework-upcoming&open=${openTimestamp}&close=${closeTimestamp}`);
  }

  const currentlyOnRetry = module.retry && retrySwitchFlag === null;
  const searchParams = new URLSearchParams(window.location.search);
  searchParams.set("retry", "0");
  const previousSubmissionUrl = `${window.location.pathname}?${searchParams}`;

  const exerciseStatus = assignmentStatus.find(as => as.exercise_id === currentExercise.id);
  const wasSubmitted = exerciseStatus?.status === ExerciseStatusType.SUBMITTED;
  const wasGraded = exerciseStatus?.status === ExerciseStatusType.GRADED;

  /************************************************************************
  IMPORTANT NOTE: it might happen that a learner started an exercise before we set it up as a team exercise.
  In that case, he doesn't have a team but have an exercise status of "in_progress".
  If the learner tries to create a team now, he will get an error because there's already an assignment exercise for him.
  We need to treat this exercise as a regular individual exercise then.
  ************************************************************************/
  const shouldShowTeamGuidelines =
    currentExercise.type === "team" && !teams[currentExercise.id] && !exerciseStatus;
  const shouldShowTeamFormation = currentExercise.type === "team" && teams[currentExercise.id];
  const isTeamSubmission =
    currentExercise.type === "team" &&
    !!(teams[currentExercise.id] || (!teams[currentExercise.id] && !exerciseStatus));
  const teamLeader =
    teams[currentExercise.id] && teams[currentExercise.id].members.find(m => m.is_leader);
  const isTeamLeader = isTeamSubmission && teamLeader?.id === currentUser?.data.id;

  const onExerciseChanged = (exerciseIndex: number) => {
    const prevExercise = currentExerciseIndex;
    setCurrentExerciseIndex(exerciseIndex);
    updateQuery("activeExerciseIndex", exerciseIndex.toString());
    TrackingService.exerciseChanged(
      assignment,
      assignment.exercises[prevExercise],
      assignment.exercises[exerciseIndex]
    );
    exerciseViewStartTimeRef.current = new Date();
    clearLastActionsLog();
    clearGraderRatingState();
  };

  const onSubmit = async () => {
    setIsSaving(true);
    try {
      await AssignmentService.submitExerciseAnswers(
        assignment.id,
        currentExercise.id,
        currentUser!
      );
      setIsSaving(false);
      setDialogToShow("submitDone");
      fetchAssignmentStatus(moduleId, retrySwitchFlag, currentUser!);
      status.sync(currentUser!);
      TrackingService.PracticalAssignmentExerciseSubmit(assignment, currentExercise);
    } catch (error) {
      setIsSaving(false);
      console.error(error);
    }
  };

  const saveHandler = async () => {
    setIsSaving(true);

    if (isTeamSubmission && !exerciseStatus?.id) {
      setIsSaving(false);
      setDialogToShow("teamSaveError");
      return;
    }

    try {
      const saved = await AssignmentService.saveExerciseAnswers(
        assignment.id,
        currentExercise.id,
        localAnswers,
        currentUser!,
        exerciseStatus?.id, // assignmentExerciseId
        lastActionsLog?.lastSavedAt,
        lastActionsLog?.lastSavedById
      );
      fetchAssignmentStatus(moduleId, retrySwitchFlag, currentUser!);
      setIsSaving(false);
      clearLocalAnswers();
      if (saved && (!isTeamSubmission || (isTeamSubmission && isTeamLeader))) {
        setDialogToShow("save");
        TrackingService.PracticalAssignmentExerciseSave(assignment, currentExercise);
      }
    } catch (error) {
      setIsSaving(false);
      if ((error as Error).message.toUpperCase() === "CONFLICT") {
        setDialogToShow("teamSaveConflict");
      } else {
        setDialogToShow("teamSaveError");
      }
    }
  };

  const onSave = async () => {
    const hasBeenSavedBeforeByTeammate =
      lastActionsLog?.lastSavedAt &&
      lastActionsLog?.lastSavedById &&
      lastActionsLog?.lastSavedById !== currentUser?.data.id;

    if (hasBeenSavedBeforeByTeammate) {
      setDialogToShow("teamSaveWarning");
    } else {
      saveHandler();
    }
  };

  return (
    <ThemeProvider theme={themeNew}>
      <LayoutSideMenu sx={{ bg: "new.primary.white" }}>
        <Container sx={{ maxWidth: "894px", pb: 14 }}>
          {dialogToShow === "save" && <SaveAnswersDialog onClose={() => setDialogToShow("")} />}
          {dialogToShow === "teamSaveWarning" && (
            <TeamSaveWarningDialog onSave={saveHandler} onClose={() => setDialogToShow("")} />
          )}
          {dialogToShow === "teamSaveConflict" && (
            <TeamSaveConflictDialog onClose={() => setDialogToShow("")} />
          )}
          {dialogToShow === "teamSaveError" && (
            <TeamSaveErrorDialog onClose={() => setDialogToShow("")} />
          )}
          {dialogToShow === "submitConfirmation" && (
            <SubmitConfirmationDialog onClose={() => setDialogToShow("")} onSubmit={onSubmit} />
          )}
          {dialogToShow === "submitDone" && (
            <SubmitDoneDialog onClose={() => setDialogToShow("")} />
          )}
          {dialogToShow === "teamFormation" && (
            <TeamFormationDialog
              assignmentId={assignment.id}
              moduleId={moduleId}
              exerciseId={currentExercise.id}
              user={currentUser!}
              onClose={() => setDialogToShow("")}
              onContinueWithoutTeam={() => {
                currentExercise.type = "standard";
                setDialogToShow("");
              }}
            />
          )}

          <Box sx={{ mt: [7, 14] }} />
          <Box sx={{ display: "flex", flexWrap: "wrap" }}>
            <Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                {/* Track click */}
                <ArrowLink
                  id="dashboard-link"
                  to="/dashboard"
                  onClick={() => {
                    TrackingService.PracticalAssignmentDashboardClick(assignment, currentExercise);
                  }}
                >
                  Dashboard
                </ArrowLink>

                <Heading
                  as="h3"
                  sx={{
                    color: "new.secondary.grey",
                    fontWeight: 700,
                    my: 8,
                  }}
                >
                  {`Module ${moduleInfo?.index}`}: {moduleInfo?.name}
                </Heading>

                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                    <Heading as="h1" sx={{ fontSize: "35px", fontWeight: 700 }}>
                      {module.isProjectWork ? "Deliverables" : "Practical Assignment"}
                    </Heading>

                    {currentlyOnRetry && (
                      <ButtonGhostAnchor target="_blank" href={previousSubmissionUrl}>
                        Previous Submission
                      </ButtonGhostAnchor>
                    )}
                  </Box>
                </Box>
              </Box>
              {retrySwitchFlag === null && (
                <Narrative sx={{ fontSize: 0, mt: "48px" }}>
                  Make sure to save your work by clicking the Save button for each exercise. You may
                  submit your exercises as you complete them, and you will receive feedback
                  accordingly.
                  {!moduleId.includes("projectwork") && (
                    <p>
                      All exercises of the Practical Assignment should be submitted by{" "}
                      <b>{dateFormatter.format(module.homework.end)}</b>.
                    </p>
                  )}
                </Narrative>
              )}
              <ExerciseSelector
                exercises={assignment.exercises}
                currentExerciseIndex={currentExerciseIndex}
                onExerciseChanged={onExerciseChanged}
              />
              {shouldShowTeamGuidelines && (
                <Box sx={{ pb: "48px" }}>
                  <TeamGuidelinesPanel
                    selectTeamCallback={() => setDialogToShow("teamFormation")}
                  />
                </Box>
              )}
              {shouldShowTeamFormation && (
                <Box sx={{ pb: "48px" }}>
                  <TeamFormationPanel
                    team={teams[currentExercise.id]}
                    currentUserIsLeader={isTeamLeader}
                    wasSubmitted={wasSubmitted || wasGraded}
                  />
                </Box>
              )}

              <ExerciseContent
                exercise={currentExercise}
                onSave={onSave}
                onSubmit={() => setDialogToShow("submitConfirmation")}
                isTeamSubmission={isTeamSubmission}
                isTeamLeader={isTeamLeader}
                isTeamReady={!!teams[currentExercise.id]}
              />
            </Box>
          </Box>
        </Container>
      </LayoutSideMenu>
    </ThemeProvider>
  );
};

const ExerciseContent: React.FC<{
  exercise: Exercise;
  onSave: () => void;
  onSubmit: () => void;
  isTeamSubmission: boolean;
  isTeamLeader: boolean;
  isTeamReady: boolean;
}> = ({ exercise, onSave, onSubmit, isTeamSubmission, isTeamLeader, isTeamReady }) => {
  const { fetchExerciseAnswers, dirtyState, lastActionsLog, graderRatingState } =
    useAssignmentAnswersContext();
  const { currentUser } = useSessionContext();

  const { assignment, assignmentStatus } = useAssignmentContext();

  const exerciseStatus = assignmentStatus.find(as => as.exercise_id === exercise.id);
  const wasSubmitted = exerciseStatus?.status === ExerciseStatusType.SUBMITTED;
  const wasGraded = exerciseStatus?.status === ExerciseStatusType.GRADED;
  const inProgress = exerciseStatus?.status === ExerciseStatusType.IN_PROGRESS;

  React.useEffect(() => {
    if (!exerciseStatus || dirtyState) {
      return;
    }
    fetchExerciseAnswers(exerciseStatus.id, currentUser!);
  }, [exerciseStatus, dirtyState, currentUser, fetchExerciseAnswers]);

  const lockedProps =
    isTeamSubmission && !isTeamReady
      ? { bg: "white", opacity: "0.3", pointerEvents: "none" }
      : undefined;

  const pendingFeedbackRating = assignmentStatus.find(
    as => as.grader_rating_status === "pending" && as.exercise_id !== exercise.id
  );
  // flag to indicate there's a pending feedback rating for another exercise, this will prevent submitting the current exercise
  const hasPendingFeedbackRating = !!pendingFeedbackRating;

  return (
    <Box
      sx={{
        pt: "48px",
        pb: "16px",
        borderTop: "1px solid",
        borderColor: "new.secondary.grey",
        borderBottom: wasGraded ? "initial" : "1px solid",
        borderBottomColor: wasGraded ? "initial" : "new.secondary.grey",
        ...lockedProps,
      }}
    >
      <Heading as="h2" sx={{ fontWeight: 700 }}>
        {exercise.title || "No Title"}
      </Heading>

      <Box>
        <Narrative
          id="exerciseInfo"
          sx={{ py: "48px", fontSize: 0, p: { p: 0, lineHeight: "22px" } }}
        >
          <p>
            <b>Methods: </b>
            {generateMethodCardLinks(exercise.method_cards, assignment, exercise)}
          </p>
          <p>
            <b>Recommended Effort: </b>
            {exercise.recommended_effort}
          </p>
          {exercise.work_style.length === 0 ? null : (
            <p>
              <b>Work Style: </b>
              {exercise.work_style}
            </p>
          )}
          {exercise.setting.length === 0 ? null : (
            <p>
              <b>Setting: </b>
              {exercise.setting}
            </p>
          )}
        </Narrative>

        <Box sx={{ mb: "64px" }}>
          <MarkdownContent content={exercise.content} />
        </Box>

        {exercise.sections.map(s => (
          <ExercisePart key={s.id} section={s} />
        ))}
      </Box>

      {wasGraded && graderRatingState === "pending" && (
        <FeedbackRating
          moduleId={assignment.module_id}
          exerciseId={exercise.id}
          assignmentExerciseId={exerciseStatus?.id}
          nextExerciseIndex={getNextExerciseIndex(exercise.id, assignment.exercises)}
        />
      )}

      {inProgress && hasPendingFeedbackRating && (
        <FeedbackRatingPendingWarning exerciseId={pendingFeedbackRating.exercise_id} />
      )}

      <Box
        sx={{
          mt: "48px",
          display: "flex",
          width: "100%",
          float: "right",
          alignItems: "center",
          justifyContent: "flex-end",
          flexDirection: ["column", "row"],
        }}
      >
        {isTeamSubmission && !wasSubmitted && lastActionsLog?.lastSavedByName && (
          <Text>
            Last saved by {lastActionsLog.lastSavedByName} on{" "}
            {dateFormatter.format(lastActionsLog.lastSavedAt)}
          </Text>
        )}
        {isTeamSubmission && wasSubmitted && lastActionsLog?.submittedByName && (
          <Text sx={{ color: "brand.green" }}>
            Submitted by {lastActionsLog.submittedByName} on{" "}
            {dateFormatter.format(lastActionsLog.submittedAt)}
          </Text>
        )}

        <ActionButtons
          onSave={onSave}
          onSubmit={onSubmit}
          inProgress={inProgress}
          wasGraded={wasGraded}
          wasSubmitted={wasSubmitted}
          isTeamSubmission={isTeamSubmission}
          isTeamLeader={isTeamLeader}
          dirtyState={dirtyState}
          hasPendingFeedbackRating={hasPendingFeedbackRating}
        />
      </Box>
    </Box>
  );
};

const ActionButtons: React.FC<{
  onSave: () => void;
  onSubmit: () => void;
  inProgress: boolean;
  wasGraded: boolean;
  wasSubmitted: boolean;
  isTeamSubmission: boolean;
  isTeamLeader: boolean;
  dirtyState: boolean;
  hasPendingFeedbackRating: boolean;
}> = ({
  onSave,
  onSubmit,
  inProgress,
  wasGraded,
  wasSubmitted,
  isTeamSubmission,
  isTeamLeader,
  dirtyState,
  hasPendingFeedbackRating,
}) => {
  if (wasGraded) {
    return null;
  }

  return (
    <React.Fragment>
      <ButtonOutline
        className="save-btn"
        sx={{ ml: [0, "20px"], mt: ["16px", 0] }}
        onClick={onSave}
        disabled={wasSubmitted || !dirtyState}
      >
        Save Progress
      </ButtonOutline>

      {(!isTeamSubmission || (isTeamSubmission && isTeamLeader)) && (
        <Button
          className="submit-btn"
          sx={{ ml: [0, "20px"], mt: ["16px", 0] }}
          onClick={onSubmit}
          disabled={wasSubmitted || !inProgress || dirtyState || hasPendingFeedbackRating}
        >
          Submit Exercise
        </Button>
      )}
    </React.Fragment>
  );
};

