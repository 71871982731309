/** @jsx jsx */
import { jsx, SxStyleProp } from "theme-ui";

import * as React from "react";
import { LinkProps } from "react-router-dom";
import { Box } from "theme-ui";
import { ButtonGhostLink, ButtonGhost, ButtonGhostAnchor } from "./ButtonGhost";
import { ReactComponent as Lock } from "../materials/icons/lock.svg";

type Icon = React.FunctionComponent<
  React.SVGProps<SVGSVGElement> & {
    title?: string | undefined;
  }
>;

type Props = {
  icon?: Icon;
  sx?: SxStyleProp;
  onDark?: boolean;
  iconPosition?: "left" | "right";
};

export const ButtonGhostIcon: React.FC<Props & React.HTMLProps<HTMLButtonElement>> = ({
  icon: Icon = Lock,
  iconPosition = "left",
  children,
  sx = {},
  ...rest
}) => (
  <ButtonGhost
    sx={{
      position: "relative",
      fontSize: 0,
      ...(children
        ? iconPosition === "left"
          ? { pl: `${16 + 24 + 8}px` }
          : { pr: `${16 + 24 + 8}px` }
        : {}),
      ...sx,
    }}
    {...rest}
  >
    <Box
      sx={{
        position: children ? "absolute" : "static",
        top: "50%",
        transform: children ? "translateY(-50%)" : "none",
        ...(iconPosition === "left" ? { left: 4 } : { right: 4 }),
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: 24,
        height: 24,
        svg: {
          height: "100%",
          width: "auto",
        },
      }}
    >
      <Icon />
    </Box>
    {children}
  </ButtonGhost>
);

export const ButtonGhostIconLink: React.FC<Props & LinkProps> = ({
  icon: Icon = Lock,
  iconPosition = "left",
  children,
  sx = {},
  ...rest
}) => (
  <ButtonGhostLink
    sx={{
      position: "relative",
      fontSize: 0,
      ...(children
        ? iconPosition === "left"
          ? { pl: `${16 + 24 + 8}px` }
          : { pr: `${16 + 24 + 8}px` }
        : {}),
      ...sx,
    }}
    {...rest}
  >
    <Box
      sx={{
        position: children ? "absolute" : "static",
        top: "50%",
        transform: children ? "translateY(-50%)" : "none",
        ...(iconPosition === "left" ? { left: 4 } : { right: 4 }),
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: 24,
        height: 24,
        svg: {
          height: "100%",
          width: "auto",
        },
      }}
    >
      <Icon />
    </Box>
    {children}
  </ButtonGhostLink>
);

export const ButtonGhostIconAnchor: React.FC<Props & React.HTMLProps<HTMLAnchorElement>> = ({
  icon: Icon = Lock,
  iconPosition = "left",
  children,
  sx = {},
  ...rest
}) => (
  <ButtonGhostAnchor
    sx={{
      position: "relative",
      fontSize: 0,
      ...(children
        ? iconPosition === "left"
          ? { pl: `${16 + 24 + 8}px` }
          : { pr: `${16 + 24 + 8}px` }
        : {}),
      ...sx,
    }}
    {...rest}
  >
    <Box
      sx={{
        position: children ? "absolute" : "static",
        top: "50%",
        transform: children ? "translateY(-50%)" : "none",
        ...(iconPosition === "left" ? { left: 4 } : { right: 4 }),
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: 24,
        height: 24,
        svg: {
          height: "100%",
          width: "auto",
        },
      }}
    >
      <Icon />
    </Box>
    {children}
  </ButtonGhostAnchor>
);
