import * as React from "react";
import { ContentType } from "@sparkademy/app-common/models/course";
import { BlockUnitHTMLContent } from "./BlockUnitHTMLContent";
import { BlockUnitVideoContent } from "./BlockUnitVideoContent";
import { BlockUnitQuizContent } from "./BlockUnitQuizContent";
import { BlockUnitKnowledgeCheckContent } from "./BlockUnitKnowledgeCheckContent";

export const BlockUnitContent: React.FC<{
  item: ContentType;
}> = ({ item }) => {
  return (
    <React.Fragment>
      {item.type === "html" && <BlockUnitHTMLContent item={item} />}
      {item.type === "video" && <BlockUnitVideoContent item={item} />}
      {item.type === "quiz" && item.status === "published" && <BlockUnitQuizContent quiz={item} />}
      {item.type === "knowledge_check" && <BlockUnitKnowledgeCheckContent knowledgeCheck={item} />}
    </React.Fragment>
  );
};
