import * as React from "react";
import { Box, Heading } from "theme-ui";
import { Container } from "@sparkademy/app-common/elements/Container";
import { Button } from "@sparkademy/app-common/elements/Button";
import { ReactComponent as IllustrationFourSquares } from "@sparkademy/app-common/materials/illustrations/four-squares.svg";
import { FourSquares } from "../../models/four-squares";
import { Narrative } from "@sparkademy/app-common/elements/Narrative";

export const GameFourSquaresStart: React.FC<{
  gameState: FourSquares;
  updateGame: (choice: number | null) => void;
}> = ({ gameState, updateGame }) => {
  if (gameState.type !== "start") {
    return null;
  }

  return (
    <Container
      sx={{
        textAlign: "center",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          width: 170,
          height: 170,
          svg: {
            width: "100%",
            height: "auto",
          },
        }}
      >
        <IllustrationFourSquares />
      </Box>

      <Heading
        as="h1"
        sx={{
          fontSize: [2, 3],
          fontWeight: "bold",
          textAlign: "center",
          mb: 10,
        }}
      >
        Part 2
      </Heading>
      <Narrative>
        <p>
          <b>Your goal in this part is to collect a maximum number of points.</b> You can collect
          points by clicking on one of four squares. Each square is tied to different points, and
          the points vary from click to click. You only have 1.5 seconds to click on a square, then
          the next try starts. If you take too long to click, you will receive zero points for that
          try.
        </p>
        <p>
          Complicated? Don’t worry! You’ll do a practice round before the actual test{" "}
          <span role="img" aria-label="Smile">
            😃
          </span>
        </p>
      </Narrative>

      <Button type="submit" onClick={() => updateGame(null)} sx={{ mt: 11 }}>
        Start Practice Round
      </Button>
    </Container>
  );
};
