/** @jsx jsx */
import { jsx, Box } from "theme-ui";
import * as React from "react";
import { LessonBlocksNavigator } from "./LessonBlocksNavigator";
import { Lesson } from "@sparkademy/app-common/models/course";
import { AppSideMenu } from "../AppSideMenu";

export const CourseSideMenu: React.FC<{ lesson: Lesson; blockId: string; unitId: string }> = ({
  lesson,
  blockId,
  unitId,
}) => {
  return (
    <AppSideMenu bgColor="new.secondary.lightGrey">
      <Box
        sx={{
          bg: "new.secondary.lightGrey",
          alignSelf: "flex-start",
          mt: "60px",
        }}
      >
        <Box
          sx={{
            bg: "new.bg",
            p: "14px",
            textAlign: "center",
            fontWeight: 700,
            // mt: "60px",
          }}
        >
          {lesson.title}
        </Box>

        <LessonBlocksNavigator lesson={lesson} blockId={blockId!} unitId={unitId!} />
      </Box>
    </AppSideMenu>
  );
};
