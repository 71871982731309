import * as React from "react";
import { Box, Heading, Button as BaseButton } from "theme-ui";
import { ReactComponent as PickLoader } from "@sparkademy/app-common/materials/icons/game-square-loader.svg";
import { ReactComponent as GameJitter } from "@sparkademy/app-common/materials/icons/game-jitter.svg";
import { FourSquares } from "../../models/four-squares";
import { Narrative } from "@sparkademy/app-common/elements/Narrative";

function getButtonPropsByIndex(idx: number, disabled: boolean = false) {
  if (idx === 0) {
    return {
      bg: `new.turquoise${disabled ? "Light" : ""}`,
    };
  }

  if (idx === 1) {
    return {
      bg: `new.advancedGreen${disabled ? "Light" : ""}`,
    };
  }

  if (idx === 2) {
    return {
      bg: `new.failingRed${disabled ? "Light" : ""}`,
    };
  }

  return {
    bg: `new.coreYellow${disabled ? "Light" : ""}`,
  };
}

export const GameFourSquaresPick: React.FC<{
  gameState: FourSquares;
  updateGame: (choice: number | null) => void;
}> = ({ gameState, updateGame }) => {
  React.useEffect(() => {
    // This is used to show a confirmation prompt for the user before navigating away
    const handler = (e: Event) => {
      e.preventDefault();
      e.returnValue = true;
    };

    window.addEventListener("beforeunload", handler);

    return () => window.removeEventListener("beforeunload", handler);
  }, []);

  if (
    gameState.type !== "timeout" &&
    gameState.type !== "picking" &&
    gameState.type !== "picked" &&
    gameState.type !== "picked-wait" &&
    gameState.type !== "jitter-between-rounds"
  ) {
    return null;
  }

  const timedOut = gameState.type === "timeout";

  return (
    <React.Fragment>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <Heading
          as="h1"
          sx={{
            fontSize: [2, 3],
            fontWeight: "bold",
            textAlign: "center",
            mb: 4,
          }}
        >
          {gameState.state.current === 0
            ? "Practice Round"
            : gameState.state.current === 1
            ? "First Round"
            : gameState.state.current === 2
            ? "Second Round"
            : "Final Round"}
        </Heading>

        <Box
          sx={{
            minHeight: 50,
          }}
        >
          {timedOut ? (
            <Narrative sx={{ color: "new.secondary.red" }}>
              Timed out. You exceeded the time limit
            </Narrative>
          ) : gameState.state.current === 0 ? (
            <Narrative sx={{ color: "new.primary.black" }}>
              <p>
                {gameState.type === "picking"
                  ? "Pick a square, you have 1.5 seconds"
                  : gameState.type === "picked"
                  ? "See your points"
                  : gameState.type === "picked-wait"
                  ? "Wait for 1.5 seconds"
                  : gameState.type === "jitter-between-rounds"
                  ? "Think about your next pick, wait for ~2 seconds"
                  : null}
              </p>
            </Narrative>
          ) : gameState.type === "jitter-between-rounds" ? (
            <GameJitter />
          ) : null}
        </Box>

        <Box
          sx={{
            mt: 6,
            position: "relative",
            display: "grid",
            gridTemplateColumns: "10rem 10rem",
            gridTemplateRows: "10rem 10rem",
            gap: ["1rem 1rem", "2rem 2rem"],
            justifyContent: "center",
          }}
        >
          {new Array(4).fill(0).map((_, i) => (
            <Box
              key={`button-${i}`}
              sx={{
                flex: "1 0 auto",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <BaseButton
                type="button"
                onClick={() => updateGame(i)}
                disabled={gameState.type !== "picking"}
                sx={{
                  ...getButtonPropsByIndex(
                    i,
                    gameState.type === "timeout" || gameState.type === "jitter-between-rounds"
                      ? true
                      : gameState.type === "picking"
                      ? false
                      : (gameState.type === "picked" || gameState.type === "picked-wait") &&
                        gameState.choice !== i
                  ),
                  color: "new.primary.black",
                  width: "100%",
                  height: "100%",
                  fontSize: 4,
                  fontWeight: "bold",
                  "&:not(:disabled)": {
                    cursor: "pointer",
                  },
                  "&:hover:not(:disabled)": {
                    transform: "scale(1.05)",
                  },
                }}
              >
                {gameState.type === "picked-wait" && gameState.choice === i && <PickLoader />}

                {gameState.type === "picked" && gameState.choice === i
                  ? `${gameState.state.rounds[gameState.state.current]?.payoffs[gameState.choice]}`
                  : ``}
              </BaseButton>
            </Box>
          ))}
        </Box>
      </Box>
    </React.Fragment>
  );
};
