import * as React from "react";
import {
  AlertDialogLabel,
  AlertDialogDescription,
  AlertDialogOverlay,
  AlertDialogContent,
} from "@sparkademy/app-common/elements/Alert";
import { Box, Text, Button as BaseButton } from "theme-ui";
import { Button } from "@sparkademy/app-common/elements/Button";
import { ReactComponent as PersonSad } from "@sparkademy/app-common/materials/illustrations/person-sad.svg";
import VisuallyHidden from "@reach/visually-hidden";

import { ReactComponent as Close } from "@sparkademy/app-common/materials/icons/close.svg";
export const WorkshopCancelSignupDialog: React.FC<{
  onClose: () => void;
  onCancel: () => void;
}> = ({ onClose, onCancel }) => {
  const cancelRef = React.useRef<HTMLButtonElement>();

  return (
    <AlertDialogOverlay
      // @ts-ignore
      leastDestructiveRef={cancelRef}
      id="SaveAnswersDialog"
    >
      <AlertDialogContent>
        <Box sx={{ maxWidth: "311px" }}>
          <AlertDialogLabel
            style={{ height: "32px", width: "100%", position: "sticky", top: "32px" }}
          >
            <BaseButton
              sx={{
                all: "unset",
                fontFamily: "inherit",
                float: "right",
                color: "primary",
                cursor: "pointer",
                bg: "transparent",
              }}
              onClick={onClose}
            >
              <VisuallyHidden>Close</VisuallyHidden>
              <Close width={32} height={32} aria-hidden="true" focusable="false" />
            </BaseButton>
          </AlertDialogLabel>
          <AlertDialogDescription>
            <Box
              sx={{
                margin: "auto",
                width: 170,
                height: 170,
                svg: {
                  width: "100%",
                  height: "auto",
                },
              }}
            >
              <PersonSad />
            </Box>
            <Text as="p" sx={{ textAlign: "center", fontWeight: 700, fontSize: "20px", mb: 4 }}>
              We're sad to see you go!
            </Text>

            <Text as="p" sx={{ textAlign: "center", fontSize: "14px", mb: 4 }}>
              If you want to cancel your enrollment, please click the button below. However, please
              be aware that your company might still be charged if you cancel your enrollment.
            </Text>
          </AlertDialogDescription>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          {/* a button to add the event to an outlook calendar */}
          <Box sx={{ mt: 4 }}></Box>

          <Button
            sx={{ fontSize: "20px" }}
            type="submit"
            onClick={() => {
              onCancel();
              onClose();
            }}
          >
            Cancel Sign Up
          </Button>
        </Box>
      </AlertDialogContent>
    </AlertDialogOverlay>
  );
};
