/** @jsx jsx */
import { jsx } from "theme-ui";
import * as React from "react";
import Joyride, { Step } from "react-joyride";
import { TrackingService } from "../services/tracking-service";

type Props = {
  // unique name for the tour
  name: string;
  // optional period in which the product tour will be active
  startOn?: Date;
  endOn?: Date;
  // steps to show in the tour, we send `disableBeacon: true` if we want the tour
  // to start automatically instead of showing a pulsing beacon next to the element in the page.
  steps: Step[];
  // whether to show the skip button on each step
  skippable?: boolean;
  // whether to run the tour automatically
  run?: boolean;
  // whether to track the tour completion via localStorage so we don't show it again
  trackTourCompletion?: boolean;
};

export const ProductTour: React.FC<Props> = ({
  name,
  startOn,
  endOn,
  steps,
  skippable = false,
  run = false,
  trackTourCompletion = true,
}) => {
  // we use the `name` prop to store the tour state in local storage
  const key = `product-tour-${name}`;
  // check the tour state from local storage
  const isTourCompleted = localStorage.getItem(key) === "completed";

  if (window.E2E_TESTING) {
    return null;
  }

  if (isTourCompleted && trackTourCompletion) {
    return null;
  }

  const today = new Date();
  if (startOn && endOn) {
    if (today < startOn || today > endOn) {
      return null;
    }
  }

  return (
    <Joyride
      steps={steps}
      run={run}
      callback={data => {
        if (data.type === "tour:end" && data.status === "finished") {
          TrackingService.ProductTourViewed(name);
          localStorage.setItem(key, "completed");
        }
      }}
      continuous
      showSkipButton={skippable}
      styles={{
        options: {
          primaryColor: "#4B27E0",
        },
      }}
    />
  );
};
