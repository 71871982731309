import * as React from "react";
import { Switch, Route, RouteComponentProps, Redirect } from "react-router-dom";
import { ProtectedRoute, ProtectedRouteProps } from "@sparkademy/app-common/utils/ProtectedRoute";
import { useSessionContext } from "@sparkademy/app-common/contexts/session-context";

import { Login } from "./pages/Login";
import { Logout } from "./pages/Logout";
import { Certificate } from "./pages/Certificate";
import { PracticalAssignment } from "./pages/PracticalAssignment";

// User version of the Method Kit
import { UserMethodKit } from "./pages/UserMethodKit";
import { MethodCard } from "@sparkademy/app-common/components/MethodCard";

import { StaticPage } from "./components/StaticPage";
import { pages } from "./data/static-content";
import { ErrorBoundary } from "./utils/ErrorBoundary";
import { GameFourSquares } from "./components/GameFourSquares/GameFourSquaresController";
import { GameFourSquares as GameFourSquaresLite } from "./components/GameFourSquaresLite/GameFourSquaresController";
import { Overview } from "./pages/Overview";
import { CertificateDownload } from "./pages/CertificateDownload";
import { EmpathyTest } from "./components/EmpathyTest";
import { EmpathyTestLite } from "./components/EmpathyTestLite";
import { CourseContainer } from "./containers/CourseContainer";
import { Course } from "./pages/Course";
import { Lesson } from "./pages/Lesson";
import { GenericErrorPage } from "./pages/GenericErrorPage";
import { SparkCheckResults } from "./pages/SparkCheckResults";
import { SparkCheckLanding } from "./pages/SparkCheckLanding";
import { OAuthAuthorize } from "./pages/OAuthAuthorize";
import { themeNew } from "@sparkademy/app-common/materials/theme";
import { ThemeProvider } from "theme-ui";
import { AssignmentContextProvider } from "@sparkademy/app-common/contexts/assignment-context";
import { AssignmentAnswersContextProvider } from "@sparkademy/app-common/contexts/assignment-answers-context";
import { LessonBlockComplete } from "./pages/LessonBlockComplete";
import { LessonComplete } from "./pages/LessonComplete";
import { WorkshopList } from "./pages/WorkshopList";
import { WorkshopSession } from "./pages/WorkshopSession";
import { Layout } from "./components/Layout";
import { Programs } from "./pages/Programs";
import { ProgramDetail } from "./pages/ProgramDetail";
import { AvailableCourses } from "./pages/AvailableCourses";
import { toggleShowDateAtom } from "./stores/current-date";
import { useUpdateAtom } from "jotai/utils";
import { Signup } from "./pages/Signup";

type AppProps = RouteComponentProps;

export const App: React.FC<AppProps> = props => {
  const { redirectPathOnAuthentication, isAuthenticated, loaded, currentUser } =
    useSessionContext();

  const defaultProtectedRouteProps: ProtectedRouteProps = {
    isAuthenticated,
    authenticationPath: "/login",
    redirectPathOnAuthentication,
    loaded,
    ...(currentUser ? { currentUser } : {}),
  };

  const toggleTestDate = useUpdateAtom(toggleShowDateAtom);
  const searchParams = window.location.search;
  const toggleTestDateParam = searchParams.toLowerCase().includes("toggletestdate");
  React.useEffect(() => {
    if (toggleTestDateParam) {
      toggleTestDate();
    }
  }, [toggleTestDateParam, toggleTestDate]);

  return (
    <ThemeProvider theme={themeNew}>
      <ErrorBoundary>
        <Switch>
          <ProtectedRoute {...defaultProtectedRouteProps} exact path="/" component={Overview} />

          <ProtectedRoute
            {...defaultProtectedRouteProps}
            exact
            path="/oauth/authorize"
            component={OAuthAuthorize}
          />

          <Route exact path="/login" component={Login} />
          <Route exact path="/signup" component={Signup} />
          <Route exact path="/logout" component={Logout} />
          <Route exact path="/certificate/:userId/:levelOrModuleId" component={Certificate} />

          <ProtectedRoute
            {...defaultProtectedRouteProps}
            exact
            path="/method-kit/:cardSlug"
            component={() => (
              <Layout sx={{ bg: "new.primary.white" }}>
                <MethodCard />
              </Layout>
            )}
          />
          <ProtectedRoute
            {...defaultProtectedRouteProps}
            exact
            path="/method-kit"
            component={UserMethodKit}
          />
          <ProtectedRoute
            {...defaultProtectedRouteProps}
            exact
            path="/tools"
            component={UserMethodKit}
          />

          {/* Profile Assessment Routes */}
          <Route exact path="/assessment-part1" component={GameFourSquares} />
          <Route exact path="/assessment-part2" component={EmpathyTest} />

          {/* Spark Check Lite Routes */}
          <Route exact path="/spark-check-part1" component={EmpathyTestLite} />
          <Route exact path="/spark-check-part2" component={GameFourSquaresLite} />
          <Route exact path="/spark-check-results" component={SparkCheckResults} />
          <Redirect from="/spark-check" to="/sparkcheck-lite" />
          <Route exact path="/sparkcheck-lite" component={SparkCheckLanding} />

          <Route exact path="/error" component={GenericErrorPage} />
          {pages.map(p => (
            <Route key={p.slug} path={`/${p.slug}`}>
              <StaticPage slug={p.slug} />
            </Route>
          ))}
          <ProtectedRoute
            {...defaultProtectedRouteProps}
            exact
            path="/dashboard"
            component={Overview}
          />
          <ProtectedRoute
            {...defaultProtectedRouteProps}
            exact
            path="/certificate-download/:levelSlug"
            component={CertificateDownload}
          />

          <ProtectedRoute
            {...defaultProtectedRouteProps}
            exact
            path="/practical-assignment/:moduleId"
            component={() => (
              <AssignmentContextProvider>
                <AssignmentAnswersContextProvider>
                  <PracticalAssignment />
                </AssignmentAnswersContextProvider>
              </AssignmentContextProvider>
            )}
          />

          <ProtectedRoute
            {...defaultProtectedRouteProps}
            exact
            path="/course/:courseId"
            component={() => (
              <CourseContainer>
                <Course />
              </CourseContainer>
            )}
          />
          <ProtectedRoute
            {...defaultProtectedRouteProps}
            exact
            path="/course/:courseId/lesson/:lessonId/block/:blockId/unit/:unitId"
            component={() => (
              <CourseContainer>
                <Lesson />
              </CourseContainer>
            )}
          />
          <ProtectedRoute
            {...defaultProtectedRouteProps}
            exact
            path="/course/:courseId/lesson/:lessonId/block/:blockId/completed"
            component={() => (
              <CourseContainer>
                <LessonBlockComplete />
              </CourseContainer>
            )}
          />
          <ProtectedRoute
            {...defaultProtectedRouteProps}
            exact
            path="/course/:courseId/lesson/:lessonId/completed"
            component={() => (
              <CourseContainer>
                <LessonComplete />
              </CourseContainer>
            )}
          />
          <ProtectedRoute
            {...defaultProtectedRouteProps}
            exact
            path="/workshops"
            component={() => <WorkshopList />}
          />
          <ProtectedRoute
            {...defaultProtectedRouteProps}
            exact
            path="/workshop/:workshopId/session/:sessionId"
            component={() => <WorkshopSession />}
          />
          <ProtectedRoute
            {...defaultProtectedRouteProps}
            exact
            path="/programs"
            component={() => <Programs />}
          />
          <ProtectedRoute
            {...defaultProtectedRouteProps}
            exact
            path="/programs/:programId"
            component={() => <ProgramDetail />}
          />
          <ProtectedRoute
            {...defaultProtectedRouteProps}
            exact
            path="/courses"
            component={() => <AvailableCourses />}
          />

          {/* more routes go in here */}
        </Switch>
      </ErrorBoundary>
    </ThemeProvider>
  );
};
