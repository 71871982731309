import { DocumentNode, TypedDocumentNode } from "@apollo/client";
import { useQuery } from "@apollo/client/react";
import { useSessionContext } from "@sparkademy/app-common/contexts/session-context";

export const useHasuraQuery = (
  query: DocumentNode | TypedDocumentNode<any, any>,
  variables: any
) => {
  const { currentUser } = useSessionContext();

  return useQuery(query, {
    variables: variables,
    context: { clientName: "hasura", headers: { Authorization: `Bearer ${currentUser?.jwt}` } },
    fetchPolicy: "no-cache",
    nextFetchPolicy: "network-only",
  });
};
