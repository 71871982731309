/** @jsx jsx */
import { jsx, SxStyleProp, Box } from "theme-ui";
import * as React from "react";
import { Header } from "../components/Header";
import { Footer, useFooterHeight } from "../components/Footer";
// import useUserMovements from '@sparkademy/app-common/hooks/useUserMovements';

import "../App.css";
import { ErrorToast } from "@sparkademy/app-common/components/MessageToast";
import { AppSideMenu } from "./AppSideMenu";
import { MainSideMenu } from "./MainSideMenu";
import { useAtom } from "jotai";
import { sideMenuIsShowingAtom } from "@sparkademy/app-common/stores/ui";

type Props = {
  id?: string;
  sx?: SxStyleProp;
  hideFooter?: boolean;
  logoIsClickable?: boolean;
};

export const Layout: React.FC<Props> = ({ id, children, sx = {}, hideFooter = false }) => {
  const footerHeight = useFooterHeight();

  return (
    <div
      className="layout-container"
      sx={{
        width: "100vw",
        position: "relative",
        overflowY: "auto",
        overflowX: "hidden",
        display: "flex",
        flexDirection: "column",

        main: {
          flex: "1 1 auto",
          display: "flex",
          flexDirection: "column",
          position: "relative",
          minHeight: `calc(100vh - ${footerHeight}px)`,
        },
        ...sx,
      }}
    >
      <main id={id}>
        <ErrorToast />
        {children}
      </main>
      {hideFooter ? null : <Footer />}
    </div>
  );
};

export const LayoutSideMenu: React.FC<Props & { sideMenuOverride?: JSX.Element }> = ({
  id,
  children,
  sx,
  sideMenuOverride,
}) => {
  const sideMenu = sideMenuOverride || (
    <AppSideMenu>
      <MainSideMenu />
    </AppSideMenu>
  );

  const [sideMenuIsShowing] = useAtom(sideMenuIsShowingAtom);
  const footerHeight = useFooterHeight();

  return (
    <div
      className="layout-container"
      sx={{
        width: "100vw",
        height: "100vh",
        position: "relative",
        overflowY: sideMenuIsShowing ? "hidden" : "auto",
        overflowX: "hidden",
        display: "flex",
        flexDirection: "column",
        bg: "new.bg",

        main: {
          flex: "1 1 auto",
          display: "flex",
          flexDirection: "column",
          position: "relative",
          minHeight: `calc(100vh - ${footerHeight}px)`,
        },
        ...(sx ? sx : {}),
      }}
    >
      <Box sx={{ display: ["relative", "relative", "flex"] }}>
        {sideMenu}
        <main id={id}>
          <ErrorToast />
          {children}
        </main>
      </Box>
      <Footer />
    </div>
  );
};

export const LayoutHeader: React.FC<Props> = ({
  id,
  children,
  sx = {},
  hideFooter = false,
  logoIsClickable = true,
}) => {
  // useUserMovements()

  return (
    <div
      className="layout-container"
      sx={{
        width: "100vw",
        height: "100vh",
        position: "relative",
        overflowY: "auto",
        overflowX: "hidden",
        display: "flex",
        flexDirection: "column",

        main: {
          flex: "1 1 auto",
          display: "flex",
          flexDirection: "column",
          position: "relative",
        },
        ...sx,
      }}
    >
      <Header logoIsClickable={logoIsClickable} />
      <main id={id}>
        <ErrorToast />
        {children}
      </main>
      {hideFooter ? null : <Footer />}
    </div>
  );
};
