/** @jsx jsx */
import { jsx, Box, SxStyleProp } from "theme-ui";
import * as React from "react";

export const BookIcon: React.FC<{ sxOverride?: SxStyleProp; color: string }> = ({
  sxOverride,
  color,
}) => (
  <Box sx={{ mr: 3, display: "flex", ...sxOverride }}>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.846 18.7069L12.342 19.1794C12.1496 19.3597 11.8504 19.3597 11.658 19.1794L11.154 18.7069C10.4126 18.0118 9.43445 17.625 8.41821 17.625H4C3.72386 17.625 3.5 17.4011 3.5 17.125V5C3.5 4.72386 3.72386 4.5 4 4.5H8.68793C9.83327 4.5 10.9165 5.02063 11.632 5.91499C11.8207 6.15082 12.1793 6.15082 12.368 5.91499C13.0835 5.02063 14.1667 4.5 15.3121 4.5H20C20.2761 4.5 20.5 4.72386 20.5 5V17.125C20.5 17.4011 20.2761 17.625 20 17.625H15.5818C14.5655 17.625 13.5874 18.0118 12.846 18.7069Z"
        stroke={color}
      />
      <path d="M12 6.375V19.0312" stroke={color} />
    </svg>
  </Box>
);
