import * as React from "react";
import { Box, Heading } from "theme-ui";
import { ButtonAnchor } from "@sparkademy/app-common/elements/Button";
import { FourSquares } from "../../models/four-squares";
import { Narrative } from "@sparkademy/app-common/elements/Narrative";
import { ReactComponent as IllustrationConfetti } from "@sparkademy/app-common/materials/illustrations/confetti.svg";
import useEventListener from "@use-it/event-listener";
import { useQuery } from "@sparkademy/app-common/utils/useQuery";

export const GameFourSquaresFinished: React.FC<{
  gameState: FourSquares;
  updateGame: (choice: number | null) => void;
}> = ({ gameState, updateGame }) => {
  const query = useQuery();
  const cohort = query.get("cohort") || "sparkchecklite";

  useEventListener("keydown", (event: KeyboardEvent) => {
    // @ts-ignore
    if (event.keyCode === 13) {
      updateGame(null);
    }
  });

  if (gameState.type !== "finished") {
    return null;
  }

  return (
    <React.Fragment>
      {gameState.step === 1 && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            textAlign: "center",
          }}
        >
          <Box
            sx={{
              margin: "auto",
              width: 170,
              height: 170,
              svg: {
                width: "100%",
                height: "auto",
              },
            }}
          >
            <IllustrationConfetti />
          </Box>
          <Heading
            as="h1"
            sx={{
              fontSize: [2, 3],
              fontWeight: "bold",
              textAlign: "center",
              my: "40px",
            }}
          >
            Congratulations!
          </Heading>
          <Narrative>
            <p>
              Congratulations! You have completed Spark Check.
              <br />
              Click the button below to see your results!
            </p>
          </Narrative>

          <ButtonAnchor sx={{ mt: 11 }} href={`/spark-check-results?cohort=${cohort}`}>
            See My Results
          </ButtonAnchor>
        </Box>
      )}
    </React.Fragment>
  );
};
