import * as React from "react";
import { Box, Button as BaseButton } from "theme-ui";
import { ButtonGhostAnchor } from "../../elements/ButtonGhost";
import { ReactComponent as Document } from "../../materials/icons/new/document-white.svg";
import { API_URL } from "../../constants";
import { useSessionContext } from "../../contexts/session-context";
import { UploadedFile } from "@sparkademy/app-common/models/assignment";
import VisuallyHidden from "@reach/visually-hidden";
import { ReactComponent as Close } from "@sparkademy/app-common/materials/icons/close.svg";

const getFileNameFromUrl = (fileUrl: string) => {
  const parts = fileUrl.split("/");
  return parts[parts.length - 1];
};

export const UploadedFileItem: React.FC<{
  file: UploadedFile;
  onItemRemove?: () => void;
  forGrading?: boolean;
}> = ({ file, onItemRemove, forGrading = false }) => {
  const { currentUser } = useSessionContext();
  const downloadLink = `${API_URL}/assignments/download/${file.id}?token=${
    currentUser?.jwt
  }&forGrading=${forGrading ? 1 : 0}`;

  return (
    <Box sx={{ display: "flex", m: 4 }}>
      <Box sx={{ mr: 4 }}>
        <Document />
      </Box>

      <ButtonGhostAnchor
        href={downloadLink}
        target="_blank"
        sx={{
          minHeight: "unset",
          p: 0,
          fontSize: "16px",
        }}
      >
        {getFileNameFromUrl(file.file_url)}
      </ButtonGhostAnchor>

      {onItemRemove && (
        <BaseButton
          onClick={onItemRemove}
          sx={{
            all: "unset",
            fontFamily: "inherit",
            color: "primary",
            cursor: "pointer",
            bg: "transparent",
            marginLeft: "auto",
          }}
        >
          <VisuallyHidden>Close</VisuallyHidden>
          <Close width={18} height={18} aria-hidden="true" focusable="false" />
        </BaseButton>
      )}
    </Box>
  );
};
