import ky from "ky";
import useSWR from "swr";
import { API_URL } from "@sparkademy/app-common/constants";
import { User } from "@sparkademy/app-common/models/user";
import { addHours } from "@sparkademy/app-common/utils/date";
import {
  DirectusSkillWorkshop,
  DirectusSkillWorkshopSession,
} from "@sparkademy/app-common/models/directus";
import { SkillWorkshopSession } from "@sparkademy/app-common/models/workshops";

type SkillWorkshopSessionResponse = {
  session: DirectusSkillWorkshopSession;
  enrollments_count: number;
};

const fetcher = (request_url: string) => ky.get(API_URL + request_url).then(res => res.json());

function useBackend(path: string, parser?: (data: any) => any) {
  const isDev = process.env.NODE_ENV === "development";

  const { data, error } = useSWR(path, fetcher, {
    revalidateIfStale: isDev,
    revalidateOnFocus: isDev,
    revalidateOnReconnect: isDev,
  });

  return {
    data: data && parser ? parser(data) : data,
    loading: !error && !data,
    error,
  };
}

export function useCourse(id: string, user: User) {
  return useBackend(`/course/${id}?token=${user.jwt}`);
}

export function useBlockUnitContent(block_unit_id: string, user: User) {
  return useBackend(`/course/block_unit_contents/${block_unit_id}?token=${user.jwt}`);
}

export function useCourseRunEnrollments(user: User) {
  return useBackend(`/course_run/enrollments?token=${user.jwt}`);
}

function parseWorkshopsResponse(data: DirectusSkillWorkshop[]) {
  return data.map(workshop => {
    return {
      id: workshop.id,
      title: workshop.title,
      description: workshop.description,
      requirements: workshop.requirements,
      timeCommitment: workshop.time_commitment,
      skills: workshop.skills.map(skill => skill.Skills_id.short_name),
      hidden: workshop.hidden,
      companyId: workshop.company?.id,
      sessions: workshop.sessions.map(session => {
        return {
          id: session.id,
          schedules: session.schedules.map(schedule => {
            return {
              // UTC times in ISO 8601 format: YYYY-MM-DDTHH:mm:ss.sssZ
              startAt: new Date(schedule.start_at + "Z"),
              endAt: addHours(new Date(schedule.start_at + "Z"), schedule.duration),
            };
          }),
        };
      }),
    };
  });
}

export function useWorkshopsData(user: User) {
  return useBackend(`/workshops?token=${user.jwt}`, parseWorkshopsResponse);
}

function parseWorkshopSessionResponse(
  resp: SkillWorkshopSessionResponse
): SkillWorkshopSession | null {
  if (resp) {
    const { session, enrollments_count } = resp;
    return {
      id: session.id,
      title: session.title,
      preparationSummary: session.preparation_summary,
      instructions: session.instructions,
      workshop: {
        id: session.skill_workshop_id ? session.skill_workshop_id.id : "",
        title: session.skill_workshop_id ? session.skill_workshop_id.title : "n/a",
      },
      schedules: session.schedules.map(schedule => ({
        description: schedule.description,
        resources: schedule.resources.map(resource => ({
          id: resource.id,
          type: resource.type,
          linkText: resource.link_text,
          linkUrl: resource.link_url,
        })),
        // UTC times in ISO 8601 format: YYYY-MM-DDTHH:mm:ss.sssZ
        startAt: new Date(schedule.start_at + "Z"),
        endAt: addHours(new Date(schedule.start_at + "Z"), schedule.duration),
        courseId: schedule.material?.id || "unset",
      })),
      enrollmentsCount: enrollments_count,
    };
  }

  return null;
}

export function useWorkshopSessionData(sessionId: string, user: User) {
  return useBackend(
    `/workshops/session/${sessionId}?token=${user.jwt}`,
    parseWorkshopSessionResponse
  );
}
