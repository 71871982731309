export const roleOptsForFounders = [
  { value: "", label: "Select an option..." },
  { value: "ceo", label: "CEO" },
  { value: "cto", label: "CTO" },
  { value: "cfo", label: "CFO" },
  { value: "coo", label: "COO" },
  { value: "cmo", label: "CMO" },
  { value: "other", label: "Other" },
];

export const levelOfEducationOpts = [
  { value: "", label: "Select an option..." },
  { value: "vocational_education", label: "Vocational Education" },
  { value: "some_higher_education", label: "Some Higher Education" },
  { value: "bachelors", label: "Bachelors" },
  { value: "masters", label: "Masters" },
  { value: "doctorate", label: "Doctorate" },
];

export const primaryWorkingLanguageOpts = [
  { value: "", label: "Select an option..." },
  { value: "german", label: "German" },
  { value: "english", label: "English" },
  { value: "french", label: "French" },
  { value: "dutch", label: "Dutch" },
  { value: "italian", label: "Italian" },
  { value: "spanish", label: "Spanish" },
  { value: "swedish", label: "Swedish" },
  { value: "danish", label: "Danish" },
  { value: "russian", label: "Russian" },
  { value: "ukrainian", label: "Ukrainian" },
  { value: "turkish", label: "Turkish" },
  { value: "portuguese", label: "Portuguese" },
  { value: "hungarian", label: "Hungarian" },
  { value: "czech", label: "Czech" },
  { value: "polish", label: "Polish" },
  { value: "greek", label: "Greek" },
  { value: "romanian", label: "Romanian" },
  { value: "other", label: "Other" },
];

export const priorStartupExperienceOpts = [
  { value: "founder_role", label: "Founder role" },
  { value: "leadership_role", label: "Leadership role" },
  { value: "employee", label: "Employee" },
  { value: "no_prior_experience", label: "No prior experience" },
];
