import React from "react";
import { Layout } from "../components/Layout";
import { Loader } from "@sparkademy/app-common/components/Loader";
import { useSessionContext } from "@sparkademy/app-common/contexts/session-context";
import { useHistory } from "react-router-dom";

export const Logout: React.FC = () => {
  const history = useHistory();
  const { logout } = useSessionContext();

  React.useEffect(() => {
    logout();
    history.push("/");
  }, [history, logout]);

  return (
    <Layout>
      <Loader />
    </Layout>
  );
};
