/** @jsx jsx */
import { jsx, Box, Heading } from "theme-ui";

import * as React from "react";
import { Layout, LayoutSideMenu } from "../components/Layout";
import { Breadcrumb } from "../components/Breadcrumb";
import { BlockUnitContent } from "../components/Course/BlockUnitContent";
import { courseAtom, lessonNavigatorVisibleAtom } from "../stores/course";
import { useParams, Redirect } from "react-router-dom";
import { useQuery as useQueryString } from "@sparkademy/app-common/utils/useQuery";
import { parseBlockUnitResponse } from "@sparkademy/app-common/models/directus";
import { Loader, LoaderWithLabel } from "@sparkademy/app-common/components/Loader";
import { LessonNavigator } from "../components/Course/LessonNavigator";
import { CourseSideMenu } from "../components/Course/CourseSideMenu";
import { TrackingService } from "../services/tracking-service";
import { useAtom } from "jotai";
import { useUpdateAtom } from "jotai/utils";
import { LoaderWithState } from "../components/LoaderWithState";
import { useBlockUnitContent } from "../hooks/useBackendData";
import { useSessionContext } from "@sparkademy/app-common/contexts/session-context";

export const Lesson: React.FC = () => {
  const { currentUser } = useSessionContext();

  const query = useQueryString();
  const isResumingCourse = query.get("resume") === "true";
  const [resumingMinWait, setResumingMinWait] = React.useState(isResumingCourse ? 1500 : 0);

  const { lessonId, blockId, unitId } =
    useParams<{ lessonId: string; blockId: string; unitId: string }>();

  const { loading, data, error } = useBlockUnitContent(unitId, currentUser!);

  setTimeout(() => setResumingMinWait(0), resumingMinWait);

  const [course] = useAtom(courseAtom);
  const setNavigatorVisible = useUpdateAtom(lessonNavigatorVisibleAtom);

  const lesson = course?.lessons.find(l => l.id === lessonId);
  const currentBlock = lesson?.blocks.find(b => b.id === blockId);
  const currentUnit = currentBlock?.units.find(u => u.id === unitId);

  const viewStartTimeRef = React.useRef<Date>(new Date());
  React.useEffect(() => {
    // run this once on mount
    viewStartTimeRef.current = new Date();
    // make sure the back and next buttons are always visible at the beginning
    setNavigatorVisible(true);
    return () => {
      // run this on unmount
      if (course && lesson && currentBlock && currentUnit) {
        TrackingService.timeSpentOnContent(
          course.title,
          lesson.title,
          currentBlock.title,
          currentUnit.title,
          viewStartTimeRef.current,
          new Date()
        );
      }
    };
  }, [course, lesson, currentBlock, currentUnit, setNavigatorVisible]);

  if (!course || !lesson) {
    return null;
  }

  if (error) {
    return <Redirect to="/error" />;
  }

  if (loading || resumingMinWait > 0) {
    return (
      <Layout sx={{ bg: "new.primary.white" }}>
        {isResumingCourse ? (
          <LoaderWithLabel label="Going back to where you left off..." />
        ) : (
          <Loader />
        )}
      </Layout>
    );
  }

  const breadcrumbItems = [
    { name: "Dashboard", url: "/dashboard" },
    { name: course.title, url: `/course/${course?.id}` },
    { name: lesson.title },
  ];

  const contents = parseBlockUnitResponse(data);

  return (
    <LayoutSideMenu
      sx={{ bg: "new.primary.white", position: "relative" }}
      sideMenuOverride={<CourseSideMenu lesson={lesson} blockId={blockId!} unitId={unitId!} />}
    >
      <Box sx={{ height: "100%" }}>
        <LoaderWithState />

        <Box
          sx={{
            height: "100%",
            width: ["100%", "100%", "initial"],
            py: ["70px", "60px"],
            pl: ["16px", "32px"],
            pr: ["16px", "32px", "initial"],
            bg: "white",
            "@media screen and (min-width: 1366px)": { pl: "127px" },
          }}
        >
          <Breadcrumb items={breadcrumbItems} />
          <Box>
            <Heading as="h2" sx={{ pb: ["16px", "32px"], fontSize: ["24px", "35px"] }}>
              {currentUnit?.title}
            </Heading>

            <Box
              sx={{ width: "100%", "@media screen and (min-width: 1366px)": { width: "894px" } }}
            >
              {contents.map((item, idx) => (
                <BlockUnitContent item={item} key={idx} />
              ))}
            </Box>

            <LessonNavigator lesson={lesson} block={currentBlock!} unit={currentUnit!} />
          </Box>
        </Box>
      </Box>
    </LayoutSideMenu>
  );
};
