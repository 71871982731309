export type GameData = Array<Array<[number, number, number, number]>>;

export default [
  [
    [37, 9, 27, 26],
    [45, 16, 28, 31],
    [38, 4, 23, 32],
    [44, 15, 28, 30],
  ],
  [
    [1, 41, 16, 34],
    [15, 47, 15, 28],
    [15, 43, 13, 30],
    [15, 42, 9, 35],
    [20, 46, 13, 37],
    [16, 43, 13, 44],
    [26, 49, 14, 30],
    [27, 43, 14, 34],
    [27, 59, 15, 47],
    [36, 54, 22, 43],
    [29, 56, 20, 44],
    [24, 48, 12, 35],
    [28, 46, 23, 30],
    [32, 51, 12, 40],
    [24, 59, 18, 33],
    [22, 53, 18, 40],
    [34, 53, 15, 37],
    [34, 54, 24, 39],
    [46, 49, 25, 34],
    [39, 57, 17, 35],
    [46, 43, 23, 22],
    [43, 43, 22, 40],
    [47, 45, 24, 33],
    [42, 46, 13, 38],
    [45, 49, 16, 40],
    [54, 37, 17, 32],
    [50, 44, 19, 38],
    [51, 38, 24, 44],
    [57, 44, 33, 37],
    [60, 42, 28, 34],
    [51, 35, 25, 36],
    [57, 51, 24, 41],
    [55, 47, 25, 41],
    [50, 42, 28, 36],
    [51, 39, 30, 30],
    [50, 38, 22, 28],
    [53, 40, 26, 38],
    [56, 45, 31, 37],
    [44, 48, 38, 39],
    [52, 57, 25, 38],
    [56, 55, 28, 35],
    [52, 51, 29, 37],
    [60, 56, 13, 42],
    [52, 44, 20, 42],
    [62, 54, 16, 40],
    [65, 50, 24, 37],
    [65, 56, 20, 36],
    [72, 53, 17, 29],
    [64, 54, 22, 30],
    [67, 49, 23, 35],
    [67, 71, 22, 36],
    [68, 55, 25, 24],
    [72, 52, 35, 33],
    [73, 60, 27, 24],
    [74, 60, 17, 24],
    [77, 51, 22, 26],
    [71, 61, 22, 24],
    [70, 56, 29, 24],
    [74, 52, 22, 17],
    [73, 48, 23, 31],
    [77, 54, 26, 20],
    [81, 58, 32, 29],
    [73, 51, 34, 22],
    [67, 62, 24, 36],
    [72, 59, 28, 34],
    [73, 63, 33, 37],
    [74, 65, 38, 41],
    [70, 58, 39, 44],
    [70, 51, 31, 35],
    [65, 48, 33, 41],
    [68, 52, 22, 44],
    [63, 47, 28, 39],
    [69, 44, 32, 40],
    [68, 39, 27, 40],
    [81, 46, 30, 41],
  ],
  [
    [14, 36, 14, 18],
    [17, 30, 15, 13],
    [15, 33, 23, 17],
    [15, 30, 23, 22],
    [14, 31, 25, 18],
    [15, 26, 34, 24],
    [11, 36, 29, 21],
    [15, 36, 31, 21],
    [16, 31, 29, 21],
    [19, 31, 22, 18],
    [21, 26, 34, 18],
    [17, 23, 24, 12],
    [20, 22, 35, 8],
    [20, 29, 33, 8],
    [20, 15, 33, 15],
    [19, 11, 32, 14],
    [27, 22, 31, 15],
    [18, 23, 29, 11],
    [15, 31, 32, 10],
    [19, 30, 38, 13],
    [24, 32, 36, 15],
    [26, 32, 37, 15],
    [26, 33, 42, 7],
    [31, 34, 43, 18],
    [29, 33, 40, 10],
    [25, 31, 38, 21],
    [24, 22, 50, 24],
    [34, 26, 50, 18],
    [24, 32, 52, 22],
    [34, 34, 45, 15],
    [30, 36, 36, 31],
    [19, 32, 33, 30],
    [27, 26, 43, 25],
    [16, 35, 46, 29],
    [19, 40, 50, 30],
    [22, 31, 48, 30],
    [29, 37, 50, 36],
    [28, 35, 51, 39],
    [28, 30, 59, 36],
    [33, 26, 47, 43],
    [29, 16, 59, 45],
    [26, 14, 49, 43],
    [33, 15, 50, 46],
    [21, 18, 56, 40],
    [28, 17, 51, 40],
    [34, 23, 54, 45],
    [37, 18, 51, 40],
    [35, 12, 47, 53],
    [33, 21, 43, 61],
    [44, 18, 32, 60],
    [37, 18, 50, 54],
    [42, 24, 43, 60],
    [31, 20, 47, 48],
    [33, 30, 38, 56],
    [50, 22, 41, 50],
    [36, 30, 36, 47],
    [40, 26, 36, 52],
    [42, 35, 29, 55],
    [33, 33, 33, 57],
    [41, 36, 28, 55],
    [42, 39, 33, 60],
    [40, 36, 28, 47],
    [43, 25, 28, 54],
    [46, 31, 23, 46],
    [47, 37, 30, 55],
    [37, 29, 22, 59],
    [40, 30, 23, 60],
    [43, 33, 25, 64],
    [35, 41, 15, 64],
    [42, 26, 25, 58],
    [38, 21, 26, 53],
    [38, 26, 15, 58],
    [42, 33, 33, 51],
    [50, 22, 34, 57],
    [48, 25, 29, 54],
  ],
  [
    [18, 30, 17, 36],
    [10, 31, 17, 49],
    [14, 33, 13, 45],
    [16, 22, 27, 42],
    [18, 22, 29, 40],
    [19, 30, 24, 41],
    [19, 29, 28, 33],
    [15, 31, 34, 35],
    [15, 23, 36, 33],
    [14, 29, 44, 43],
    [16, 25, 41, 31],
    [6, 32, 44, 39],
    [15, 27, 42, 51],
    [7, 27, 42, 36],
    [15, 25, 50, 38],
    [14, 33, 52, 50],
    [7, 35, 53, 46],
    [12, 31, 47, 47],
    [13, 36, 50, 36],
    [9, 36, 50, 41],
    [18, 40, 61, 38],
    [19, 38, 47, 27],
    [7, 32, 38, 31],
    [14, 41, 46, 35],
    [11, 37, 46, 23],
    [18, 36, 51, 28],
    [18, 39, 45, 20],
    [14, 35, 41, 22],
    [19, 32, 42, 26],
    [23, 30, 46, 29],
    [15, 36, 51, 36],
    [14, 38, 48, 35],
    [28, 34, 41, 33],
    [37, 31, 41, 39],
    [37, 41, 39, 33],
    [35, 27, 43, 25],
    [36, 32, 41, 28],
    [45, 36, 43, 31],
    [40, 34, 47, 24],
    [38, 32, 42, 24],
    [28, 38, 38, 33],
    [37, 32, 37, 30],
    [42, 26, 36, 23],
    [53, 23, 43, 31],
    [53, 26, 38, 23],
    [48, 16, 45, 43],
    [51, 18, 40, 36],
    [58, 20, 40, 34],
    [58, 20, 41, 42],
    [59, 12, 41, 30],
    [54, 26, 48, 30],
    [61, 26, 45, 29],
    [64, 32, 44, 36],
    [62, 29, 47, 33],
    [63, 26, 49, 30],
    [64, 29, 54, 37],
    [57, 36, 41, 37],
    [54, 35, 45, 35],
    [58, 36, 40, 37],
    [54, 35, 55, 32],
    [59, 29, 51, 33],
    [67, 33, 36, 34],
    [60, 27, 35, 32],
    [66, 38, 36, 33],
    [60, 37, 45, 32],
    [65, 35, 43, 46],
    [62, 35, 33, 35],
    [58, 32, 29, 42],
    [59, 29, 33, 38],
    [53, 28, 38, 42],
    [62, 22, 40, 37],
    [56, 16, 41, 38],
    [64, 34, 29, 42],
    [51, 38, 25, 48],
    [54, 34, 36, 43],
  ],
] as GameData;
