import * as React from "react";

function getBrowserVisibilityProp() {
  if (typeof document.hidden !== "undefined") {
    // Opera 12.10 and Firefox 18 and later support
    return "visibilitychange";
    // @ts-ignore
  } else if (typeof document.msHidden !== "undefined") {
    return "msvisibilitychange";
    // @ts-ignore
  } else if (typeof document.webkitHidden !== "undefined") {
    return "webkitvisibilitychange";
  }
}

function getBrowserDocumentHiddenProp() {
  if (typeof document.hidden !== "undefined") {
    return "hidden";
    // @ts-ignore
  } else if (typeof document.msHidden !== "undefined") {
    return "msHidden";
    // @ts-ignore
  } else if (typeof document.webkitHidden !== "undefined") {
    return "webkitHidden";
  }
}

function getIsDocumentHidden() {
  // @ts-ignore
  return !document[getBrowserDocumentHiddenProp()];
}

export function usePageVisibility(cb: (isVisible: boolean) => void) {
  const [isVisible, setIsVisible] = React.useState(getIsDocumentHidden());
  const onVisibilityChange = () => {
    const newIsVisible = getIsDocumentHidden();
    setIsVisible(newIsVisible);
    cb(newIsVisible);
  };

  React.useEffect(() => {
    const visibilityChange = getBrowserVisibilityProp();

    // @ts-ignore
    window.addEventListener(visibilityChange, onVisibilityChange, false);

    return () => {
      // @ts-ignore
      window.removeEventListener(visibilityChange, onVisibilityChange);
    };
  });

  return isVisible;
}
