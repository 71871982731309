import * as React from "react";
import { Box, Text } from "theme-ui";
import { dayFormatter, timeFormatter } from "@sparkademy/app-common/utils/date-formatters";
// import { UploadAssignmentsModal } from "../components/UploadAssignmentsModal";
import { ButtonGhost, ButtonGhostLink } from "@sparkademy/app-common/elements/ButtonGhost";
import { WorkshopSignupDialog } from "./WorkshopSignupDialog";
import {
  enrollUserIntoWorkshopSession,
  unenrollUserFromWorkshopSession,
} from "../../services/http-api-service";
import { useSessionContext } from "@sparkademy/app-common/contexts/session-context";
import { LoaderWithLabel } from "@sparkademy/app-common/components/Loader";
import { showErrorToastAtom } from "@sparkademy/app-common/stores/ui";
import { useUpdateAtom } from "jotai/utils";
import { addHours } from "@sparkademy/app-common/utils/date";
import { useAtom } from "jotai";
import { dateStateAtom } from "../../stores/current-date";

type WorkshopSession = {
  id: string;
  schedules: {
    startAt: Date;
    endAt: Date;
  }[];
};

const columnProps = {
  py: 6,
  display: "flex",
  borderBottom: "0.5px solid",
  borderBottomColor: "new.secondary.grey",
  alignItems: "center",
};

export const WorkshopSessionListItem: React.FC<{
  workshopId: number;
  session: WorkshopSession;
  index: number;
  enrolled: boolean;
}> = ({ workshopId, session, index, enrolled }) => {
  const [showDialog, setShowDialog] = React.useState(false);
  const [enrollmentCount, setEnrollmentCount] = React.useState(0);
  const [isLoading, setIsLoading] = React.useState(false);
  const [isEnrolled, setIsEnrolled] = React.useState(enrolled);
  const { currentUser } = useSessionContext();
  const showErrorToast = useUpdateAtom(showErrorToastAtom);
  const [currentDate] = useAtom(dateStateAtom);

  React.useEffect(() => {
    setIsEnrolled(enrolled);
  }, [enrolled]);

  const callEnrollment = () => {
    setIsLoading(true);
    enrollUserIntoWorkshopSession(session.id, currentUser!)
      .then(async data => {
        if (data.error) {
          showErrorToast(data.error);
        } else {
          data.enrollment_count && setEnrollmentCount(data.enrollment_count);
          setIsLoading(false);
          setShowDialog(true);
          setIsEnrolled(true);
        }
      })
      .catch(() => showErrorToast("An error occurred, try again later."))
      .finally(() => setIsLoading(false));
  };

  const callUnenrollment = () => {
    setIsLoading(true);
    unenrollUserFromWorkshopSession(session.id, currentUser!)
      .then(() => {
        setIsLoading(false);
        setIsEnrolled(false);
      })
      .catch(() => showErrorToast("An error occurred, try again later."))
      .finally(() => setIsLoading(false));
  };

  if (isLoading) {
    return (
      <Box
        sx={{
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          position: "fixed",
          zIndex: 9,
          bg: "background",
        }}
      >
        <LoaderWithLabel label="Just a few extra seconds to get you ready..." />
      </Box>
    );
  }

  const signUpClosed = isSignUpClosed(session.schedules[0].startAt, currentDate);
  const year = session.schedules[0].startAt.getFullYear();

  return (
    <Box sx={columnProps}>
      <Box sx={{ width: "25%", pl: 10 }}>{index + 1}</Box>
      <Box sx={{ width: "20%" }}>
        {session.schedules.map((schedule, idx) => {
          return <Text key={idx}>{dayFormatter.format(schedule.startAt)}</Text>;
        })}
        <Text sx={{
          fontSize: "12px",
          fontWeight: "bold",
          mt: 2,
        }}>{year}</Text>
      </Box>
      <Box sx={{ width: "25%" }}>
        {session.schedules.map((schedule, idx) => {
          return (
            <Box sx={{ mt: 3 }} key={idx}>
              {timeFormatter.format(schedule.startAt)} - &nbsp;
              {timeFormatter.format(schedule.endAt)}
            </Box>
          );
        })}
      </Box>
      {showDialog && (
        <WorkshopSignupDialog
          sessionDetailLink={`/workshop/${workshopId}/session/${session.id}`}
          onClose={() => setShowDialog(false)}
          onCancel={callUnenrollment}
          session={session}
          enrollmentCount={enrollmentCount}
        />
      )}
      {isEnrolled ? (
        <Box sx={{ width: "30%", color: "new.primary.green" }}>
          <Text sx={{ display: "inline" }}>Signed Up</Text>
          <Text as="span" sx={{ px: "10px", color: "black" }}>
            |
          </Text>
          <ButtonGhostLink
            to={`/workshop/${workshopId}/session/${session.id}`}
            sx={{
              p: 0,
            }}
          >
            See Workshop Overview
          </ButtonGhostLink>
        </Box>
      ) : signUpClosed ? (
        <Box sx={{ width: "30%" }}>
          <Text sx={{ display: "inline" }}>Sign Up Closed</Text>
        </Box>
      ) : (
        <ButtonGhost
          sx={{ width: "30%", fontWeight: 400, color: "new.primary.purple" }}
          onClick={callEnrollment}
          id="sign-up-button"
        >
          Sign Up
        </ButtonGhost>
      )}
    </Box>
  );
};

function isSignUpClosed(sessionStartAt: Date, currentDate: Date) {
  // close sign up 48 hours before the workshop
  // if it starts on a Monday close 96 hours before so the weekend is taken into account
  let amountOfHoursToCloseSignUp = 48;
  if (sessionStartAt.getDay() === 1) {
    amountOfHoursToCloseSignUp = 96;
  }
  const signUpDeadline = addHours(currentDate, amountOfHoursToCloseSignUp);
  const signUpClosed = sessionStartAt < signUpDeadline;
  return signUpClosed;
}
