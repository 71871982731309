/** @jsx jsx */
import { jsx } from "theme-ui";
import * as React from "react";
import {
  DialogOverlay as DialogOverlayBase,
  DialogContent as DialogContentBase,
  DialogProps,
  DialogContentProps,
} from "@reach/dialog";

type Props = {};

export const DialogOverlay: React.FC<DialogProps> = props => (
  <DialogOverlayBase
    sx={{
      background: "rgba(0, 0, 0, 0.6)",
      position: "fixed",
      zIndex: 2,
      top: "0px",
      left: "0px",
      right: "0px",
      bottom: "0px",
      overflow: "auto",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    }}
    {...props}
  />
);

export const DialogContent: React.FC<Props & DialogContentProps> = ({ children, ...rest }) => (
  <DialogContentBase
    sx={{
      outline: "none",
      margin: 0,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      width: "80vw",
      maxHeight: "99vh",
    }}
    {...rest}
  >
    {children}
  </DialogContentBase>
);
