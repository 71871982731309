import { Heading, Text, Box, Image, ThemeProvider, SxStyleProp } from "theme-ui";
import * as React from "react";
import { MessageToast } from "./MessageToast";
import { Container } from "@sparkademy/app-common/elements/Container";
import { List, ListItemArrow } from "@sparkademy/app-common/elements/List";
import { tools } from "../data/tools";

import { Narrative } from "@sparkademy/app-common/elements/Narrative-v2";
import { useContentContext } from "@sparkademy/app-common/contexts/content-context";
import { themeNew } from "@sparkademy/app-common/materials/theme";
import { Link, Redirect, useParams } from "react-router-dom";
import { ArrowLink } from "@sparkademy/app-common/elements/ArrowLink";
import { Loader } from "@sparkademy/app-common/components/Loader";
import { Button } from "@sparkademy/app-common/elements/Button";
import { copyToClipboard } from "@sparkademy/app-common/utils/clipboard";
import { Tool } from "../models/tool";
import { ReactComponent as Resources } from "@sparkademy/app-common/materials/icons/new/resources.svg";
import { ReactComponent as Share } from "@sparkademy/app-common/materials/icons/new/share.svg";
import { ReactComponent as Document } from "@sparkademy/app-common/materials/icons/new/document.svg";
import { ButtonOutlineLink } from "@sparkademy/app-common/elements/ButtonOutline";
import { ModuleInfo } from "@sparkademy/app-common/models/module";
import { getMethodKitColorForLevel } from "@sparkademy/app-common/utils/theme";
import { Helmet } from "react-helmet";

export const MethodCard: React.FC = () => {
  const { modules } = useContentContext();
  const { cardSlug } = useParams<{ cardSlug: string }>();
  const tool = tools.find(t => t.slug === cardSlug);
  const [showMessage, setShowMessage] = React.useState<boolean>(false);
  function showCopiedUrlMessage() {
    setShowMessage(true);
    setTimeout(() => setShowMessage(false), 2000);
  }

  const isPublicVersion =
    window.location.href.startsWith("https://method-kit.sparkademy.com") ||
    window.location.href.startsWith("http://local.sparkademy.com:8001") ||
    window.location.href.startsWith("http://localhost:8001");

  if (modules.length === 0) {
    return <Loader />;
  }

  if (!tool) {
    return <Redirect to={isPublicVersion ? "/" : "/method-kit"} />;
  }

  const module = modules.find(m => m.id.toLowerCase() === tool.moduleId.toLowerCase());

  return (
    <ThemeProvider theme={themeNew}>
      <Helmet>
        <meta
          name="description"
          property="og:title"
          content={`Sparkademy Method: ${tool.name}`}
        ></meta>
        <meta
          name="image"
          property="og:image"
          content={`//images.weserv.nl/?url=https://method-kit.sparkademy.com/${tool.backImage}&w=955&h=500&output=png&fit=contain&cbg=FFE499`}
        ></meta>
      </Helmet>
      <Container sx={{ maxWidth: "894px", pb: 14 }}>
        <MessageToast visible={showMessage} message="The link was copied to the clipboard!" />

        <Box sx={{ mt: [7, 14] }} />

        <Box sx={{ display: "flex", flexWrap: "wrap" }}>
          <Box sx={{ flexBasis: ["100%", "60%"] }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <ArrowLink id="method-kit-link" to={isPublicVersion ? "/" : "/method-kit"}>
                Method Kit
              </ArrowLink>

              <Heading
                as="h3"
                sx={{
                  color: "new.secondary.grey",
                  fontWeight: 700,
                  my: 8,
                }}
              >
                {module?.name}
              </Heading>
            </Box>

            <ToolContent
              tool={tool}
              moduleInfo={module!}
              showCopiedUrlMessage={showCopiedUrlMessage}
            />
          </Box>

          <Box
            sx={{
              flexBasis: ["100%", "40%"],
              pl: [0, "62px"],
              mt: 10,
              display: "flex",
              flexDirection: "column",
              alignItems: ["center", "end"],
            }}
          >
            {tool.resources.length > 0 && <ResourcesWidget tool={tool} />}
            <Box sx={{ display: ["none", "initial"] }}>
              <ShareWidget tool={tool} showCopiedUrlMessage={showCopiedUrlMessage} />
            </Box>
            <MethodsWidget isPublicVersion={isPublicVersion} tool={tool} />
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
};

const ToolContent: React.FC<{
  tool: Tool;
  moduleInfo: ModuleInfo;
  showCopiedUrlMessage: () => void;
}> = ({ tool, moduleInfo, showCopiedUrlMessage }) => {
  return (
    <Box
      sx={{
        bg: "white",
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
      }}
    >
      <Box>
        <Heading
          as="h1"
          sx={{
            fontSize: 3,
            fontWeight: "bold",
            color: "text",
          }}
        >
          {tool.name}
        </Heading>
      </Box>
      <Box>
        {tool.backImage && (
          <Box
            sx={{
              display: ["flex"],
              alignItems: "center",
              justifyContent: "center",
              p: 0,
              my: 10,
              bg: getMethodKitColorForLevel(moduleInfo.level),
              borderRadius: "10px",
              minHeight: 328,
            }}
          >
            <Image
              id="method-card-image"
              src={tool.backImage}
              alt={tool.backImage}
              sx={{ width: "95%", height: "auto", maxHeight: "500px" }}
            />
          </Box>
        )}
        <Box
          sx={{
            display: ["flex", "none"],
            justifyContent: "space-around",
            flexWrap: "wrap",
            mt: 10,
          }}
        >
          <ShareWidget tool={tool} showCopiedUrlMessage={showCopiedUrlMessage} />
        </Box>

        <Box id="method-card-content" sx={{ mt: 8 }}>
          <Narrative>
            <tool.content />
          </Narrative>
        </Box>

        {tool.benefits.length > 0 && (
          <Box id="method-card-benefits" sx={{ mt: 10, mb: 8 }}>
            <Heading
              as="h4"
              sx={{
                mb: 3,
                fontSize: 0,
                fontWeight: "bold",
                color: "text",
              }}
            >
              Benefits:
            </Heading>
            <List
              sx={{
                mt: 0,
                ml: -3,
              }}
            >
              {tool.benefits.map(b => (
                <ListItemArrow
                  key={b}
                  sx={{
                    mt: "16px",
                    fontSize: 0,
                    div: { color: "#000" },
                  }}
                >
                  {b}
                </ListItemArrow>
              ))}
            </List>
          </Box>
        )}
      </Box>
    </Box>
  );
};

const ToolWidget: React.FC<{
  id: string;
  title: string;
  icon: React.ReactElement;
  sx?: SxStyleProp;
}> = ({ id, title, icon, children, sx }) => {
  return (
    <Box
      id={id}
      sx={{
        maxWidth: 305,
        width: "100%",
        mb: "32px",
        bg: "white",
        border: "1px solid",
        borderColor: "new.secondary.grey",
        borderRadius: "10px",
        ...sx,
      }}
    >
      <Box
        sx={{
          display: "flex",
          bg: "#000",
          color: "#fff",
          fontSize: 1,
          borderRadius: "10px 10px 0 0",
          p: 4,
          fontWeight: 700,
          minHeight: 50,
        }}
      >
        {icon}
        <span style={{ marginLeft: 10 }}>{title}</span>
      </Box>

      <Box sx={{ p: 5 }}>{children}</Box>
    </Box>
  );
};

const MethodsWidget: React.FC<{
  isPublicVersion: boolean;
  tool: Tool;
}> = ({ isPublicVersion, tool }) => {
  const otherModuleTools = tools.filter(
    t => t.moduleId.toLowerCase() === tool.moduleId.toLowerCase()
  );
  const isSameTool = (anotherTool: Tool) => anotherTool.slug === tool.slug;

  return (
    <ToolWidget id="methods-widget" title="Module Methods" icon={<Document />}>
      <List
        sx={{
          mt: 0,
          ml: -3,
        }}
      >
        {otherModuleTools
          .filter(t => !t.disabled)
          .map(t => (
            <ListItemArrow
              key={t.slug}
              sx={{
                fontSize: 0,
                div: {
                  color: isSameTool(t) ? "new.primary.purple" : "black",
                  fontWeight: isSameTool(t) ? "bold" : "normal",
                },
                my: 3,
              }}
            >
              <Link
                to={isPublicVersion ? t.slug : `/method-kit/${t.slug}`}
                style={{ textDecoration: "none" }}
              >
                <Text
                  sx={{
                    color: isSameTool(t) ? "new.primary.purple" : "black",
                    fontWeight: isSameTool(t) ? "bold" : "normal",
                  }}
                >
                  {t.name}
                </Text>
              </Link>
            </ListItemArrow>
          ))}
      </List>
    </ToolWidget>
  );
};

const ResourcesWidget: React.FC<{ tool: Tool }> = ({ tool }) => (
  <ToolWidget id="resources-widget" title="Resources" icon={<Resources />}>
    <Box sx={{ my: 5, minWidth: "40%" }}>
      <Text as="p" sx={{ fontWeight: "bold", my: 3 }}>
        Materials Needed:
      </Text>
      <Text as="p">{tool.resources.join(", ")}</Text>
      <Box sx={{ mt: 6, display: "flex" }}>
        {tool.template && (
          <ButtonOutlineLink
            id="download-template-btn"
            to={tool.template}
            target="_blank"
            rel="noopener noreferrer"
            sx={{ mx: "auto", maxWidth: "100%", fontSize: 0 }}
          >
            Download Template
          </ButtonOutlineLink>
        )}
      </Box>
    </Box>
  </ToolWidget>
);

const ShareWidget: React.FC<{ tool: Tool; showCopiedUrlMessage: () => void }> = ({
  tool,
  showCopiedUrlMessage,
}) => {
  return (
    <ToolWidget id="share-widget" title="Share" icon={<Share />} sx={{ mb: [0, "32px"] }}>
      <Box sx={{ my: 5, display: "flex", flexDirection: "column" }}>
        <Text as="p" sx={{ fontWeight: "bold", my: 3 }}>
          Share Method Kit
        </Text>
        <Text as="p">Copy a shareable link of the Method Kit and share it with your team.</Text>
        <Button
          id="copy-link-btn"
          sx={{ mt: 6, width: 190, maxWidth: "100%", mx: "auto" }}
          onClick={() => {
            const url = `https://method-kit.sparkademy.com/${tool.slug}`;
            copyToClipboard(url);
            showCopiedUrlMessage();
          }}
        >
          Copy Link
        </Button>
      </Box>
    </ToolWidget>
  );
};
