/** @jsx jsx */
import { jsx, Box, MenuButton } from "theme-ui";
import * as React from "react";
import { ReactComponent as Logo } from "@sparkademy/app-common/materials/logos/logo-sideways.svg";
import { Link } from "react-router-dom";
import { TrackingService } from "../services/tracking-service";
import { sideMenuIsShowingAtom } from "@sparkademy/app-common/stores/ui";
import { useAtom } from "jotai";
import { useFooterHeight } from "./Footer";

export const AppSideMenu: React.FC<{ bgColor?: string }> = ({
  children,
  bgColor = "new.primary.white",
}) => {
  const [sideMenuIsShowing, setSideMenuIsShowing] = useAtom(sideMenuIsShowingAtom);

  const toggleSideMenu = () => {
    TrackingService.sideMenuActivated();
    setSideMenuIsShowing(!sideMenuIsShowing);
  };

  const footerHeight = useFooterHeight();

  return (
    <Box
      id="app-side-menu"
      sx={{
        minWidth: "303px",
        bg: "new.primary.white",
        position: ["absolute", , , "initial"],
      }}
    >
      <Box
        id="mobile-menu-toggle-container"
        sx={{
          position: "absolute",
          display: "flex",
          width: "40px",
          height: "40px",
          justifyContent: "center",
          zIndex: 11,
        }}
        onClick={() => {
          toggleSideMenu();
        }}
        className="mobile-menu-toggle"
      >
        <MenuButton
          aria-label="Toggle Menu"
          sx={{ svg: { width: "40px", height: "40px" }, display: ["initial", , , "none"] }}
        />
      </Box>
      {sideMenuIsShowing ? (
        <div
          sx={{
            position: "absolute",
            width: "100vw",
            height: "100vh",
            bottom: 0,
            top: 0,
            left: 0,
            zIndex: 1,
          }}
          onClick={() => {
            setSideMenuIsShowing(!sideMenuIsShowing);
          }}
        ></div>
      ) : null}

      <Box
        sx={{
          width: "303px",
          height: ["initial", , , "100%"],
          bg: bgColor,
          transition: ["transform 1s", , , "none"],
          left: ["-303px", , , "initial"],
          transform: [
            sideMenuIsShowing ? "translate(303px,0)" : "none",
            sideMenuIsShowing ? "translate(303px,0)" : "none",
            sideMenuIsShowing ? "translate(303px,0)" : "none",
            "none",
          ],
          position: ["fixed", , , "relative"],
          top: 0,
          bottom: 0,
          zIndex: 10,
          boxShadow: "0px 4px 5px rgba(0, 0, 0, 0.2)",
          overflowY: sideMenuIsShowing ? "auto" : "initial",
          scrollbarWidth: "thin",
        }}
      >
        <Box
          sx={{
            position: ["initial", , , "sticky"],
            top: 0,
            bottom: 0,
            alignSelf: "flex-start",
            pt: "60px",
            minHeight: `calc(100vh - ${footerHeight}px)`,
          }}
        >
          <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", mb: 10 }}>
            <Link
              to="/"
              aria-label="homepage"
              onClick={() => {
                setSideMenuIsShowing(false);
                TrackingService.headerLogoClick();
              }}
              sx={{
                display: "block",
                svg: {
                  display: "block",
                },
              }}
            >
              <Logo height={21} aria-hidden="true" focusable="false" />
            </Link>
          </Box>

          {children}
        </Box>
      </Box>
    </Box>
  );
};
