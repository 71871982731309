import * as React from "react";
import { Box, Text } from "theme-ui";
import { BlockUnit, Lesson, LessonBlock } from "@sparkademy/app-common/models/course";
import { generateImageUrl } from "../../data/directus";
import { ButtonGhostLink } from "@sparkademy/app-common/elements/ButtonGhost";
import { TrackingService } from "../../services/tracking-service";
import { courseAtom } from "../../stores/course";
import { sideMenuIsShowingAtom } from "@sparkademy/app-common/stores/ui";
import { ResumeButton } from "./ResumeButton";
import { useAtom } from "jotai";
import { useUpdateAtom } from "jotai/utils";
import { isKnowledgeCheckBlock } from "./helpers";

// Lesson Block Navigator is the left menu of all lessons in the current lesson block.
export const LessonBlocksNavigator: React.FC<{ lesson: Lesson; blockId: string; unitId: string }> =
  ({ lesson, blockId, unitId }) => {
    const currentBlockIndex = lesson.blocks.findIndex(b => b.id === blockId);
    const currentBlock = currentBlockIndex > -1 ? lesson.blocks[currentBlockIndex] : null;
    const currentUnitIndex = currentBlock?.units.findIndex(u => u.id === unitId);
    const onLastBlock = lesson.blocks[lesson.blocks.length - 1].id === blockId;

    return (
      <Box sx={{ p: "48px" }} className="side-menu">
        {lesson.blocks.map((block: LessonBlock, i: number) => (
          <Box key={block.id} sx={{ mt: "12px" }} className="block-item">
            <Box sx={{ display: "flex", flexDirection: "column", mt: "8px", ml: "13px" }}>
              <LessonBlockItem
                lesson={lesson}
                block={block}
                index={i}
                active={currentBlock?.id === block.id}
                visited={i < currentBlockIndex}
              />

              {block.id === currentBlock?.id && block.units.length > 1 && (
                <Box>
                  {block.units.map((unit, j) => (
                    <BlockUnitItem
                      key={unit.id}
                      lesson={lesson}
                      block={block}
                      unit={unit}
                      index={j}
                      active={unit.id === unitId}
                      visited={j < (currentUnitIndex || 0)}
                      onLastBlock={onLastBlock}
                    />
                  ))}
                </Box>
              )}
            </Box>
          </Box>
        ))}

        <Box sx={{ borderTop: "1px solid #BFBFBF", width: "100%" }} />

        <ResumeButton courseId={lesson.courseId} type="link" />
      </Box>
    );
  };

const LessonBlockItem: React.FC<{
  lesson: Lesson;
  block: LessonBlock;
  index: number;
  active: boolean;
  visited: boolean;
}> = ({ lesson, block, index, active, visited }) => {
  const [course] = useAtom(courseAtom);
  const activeSingleUnit = active && block.units.length === 1;

  const shouldDrawBlockLine = (idx: number) => {
    // Decides whether to draw the block line on the stepper progress bar.
    // Draw if its not the last block or is the last block but its active
    return (
      idx < lesson.blocks.length - 1 ||
      (idx === lesson.blocks.length - 1 && active && !activeSingleUnit)
    );
  };

  const toggleSideMenu = useUpdateAtom(sideMenuIsShowingAtom);

  const greyedOutStyle = !visited && !active ? { filter: "grayscale(1)", opacity: "0.3" } : {};

  return (
    <Box sx={{ display: "flex", justifyContent: "flex-start" }}>
      {/* Icon and Line elements */}
      <Box sx={{ position: "relative", ...greyedOutStyle }}>
        <Box
          sx={{
            position: "absolute",
            top: "-4px",
            left: "-30px",
            width: "32px",
            height: "32px",
            display: "inline-block",
            zIndex: 1,
          }}
        >
          <img src={generateImageUrl(block.iconSmall)} alt={block.title} />
        </Box>

        {shouldDrawBlockLine(index) && (
          <Box
            sx={{
              top: "8px",
              left: "-15px",
              height: "100%",
              position: "absolute",
              borderLeft: "2px solid",
              borderColor: activeSingleUnit ? "#bcbdbe" : "primary",
            }}
          />
        )}
      </Box>

      <Box
        sx={{
          display: "inline-block",
          ml: "13px",
          pb: !active || activeSingleUnit ? "16px" : "unset",
          ...greyedOutStyle,
        }}
      >
        <ButtonGhostLink
          sx={{
            fontWeight: 700,
            fontSize: "16px",
            color: "black",
            p: 0,
            minHeight: "unset",
            lineHeight: "1.4",
          }}
          to={
            block.units.length > 0
              ? `/course/${lesson.courseId}/lesson/${lesson.id}/block/${block.id}/unit/${block.units[0].id}`
              : "/error?reason=cms-missing-content"
          }
          onClick={() => {
            TrackingService.lessonBlocksNavigatorClick(
              course!.title,
              lesson.title,
              block.title,
              null
            );
            toggleSideMenu(false);
          }}
        >
          {block.title}
        </ButtonGhostLink>
        <Text
          sx={{ py: "8px", color: "new.secondary.violet", fontSize: "14px" }}
          className="effort"
        >
          {`${block.timeCommitment} min`}
        </Text>
      </Box>
    </Box>
  );
};

const BlockUnitItem: React.FC<{
  lesson: Lesson;
  block: LessonBlock;
  unit: BlockUnit;
  index: number;
  active: boolean;
  visited: boolean;
  onLastBlock: boolean;
}> = ({ lesson, block, unit, index, active, visited, onLastBlock }) => {
  const isLastUnit = index === block.units.length - 1;
  const toggleSideMenu = useUpdateAtom(sideMenuIsShowingAtom);
  const [course] = useAtom(courseAtom);

  const isKnowledgeCheckUnit = isKnowledgeCheckBlock(block);

  return (
    <Box
      key={unit.id}
      sx={{ display: "flex", justifyContent: "flex-start" }}
      className="block-unit-item"
    >
      {/* Bullet point and Line elements */}
      <Box sx={{ position: "relative" }}>
        <Box
          sx={{
            position: "absolute",
            top: "5px",
            left: "-18px",
            width: "8px",
            height: "8px",
            border: "2px solid",
            borderColor: visited || active ? "primary" : "new.secondary.grey",
            bg: visited || active ? "primary" : "new.secondary.grey",
            borderRadius: "50%",
            display: "inline-block",
            zIndex: 1,
          }}
        />
        {!(onLastBlock && isLastUnit) && (
          <Box
            sx={{
              top: "8px",
              left: "-15px",
              height: "100%",
              position: "absolute",
              borderLeft: "2px solid",
              borderColor: visited ? "primary" : "new.secondary.grey",
            }}
          />
        )}
      </Box>

      {/* Block Unit item title */}
      <Box
        sx={{
          display: "inline-block",
          ml: "13px",
          pb: index === block.units.length - 1 ? "16px" : "8px",
        }}
      >
        {active || isKnowledgeCheckUnit ? (
          <Text key={unit.id} sx={{ fontSize: "14px", lineHeight: "140%" }}>
            {unit.title}
          </Text>
        ) : (
          <ButtonGhostLink
            to={`/course/${lesson.courseId}/lesson/${lesson.id}/block/${block.id}/unit/${block.units[index].id}`}
            key={unit.id}
            sx={{
              fontSize: "14px",
              lineHeight: "140%",
              color: "black",
              p: 0,
              minHeight: "20px",
              fontWeight: 400,
            }}
            onClick={() => {
              TrackingService.lessonBlocksNavigatorClick(
                course!.title,
                lesson.title,
                block.title,
                unit.title
              );
              toggleSideMenu(false);
            }}
          >
            {unit.title}
          </ButtonGhostLink>
        )}
      </Box>
    </Box>
  );
};
