import * as React from "react";
import {
  AlertDialogLabel,
  AlertDialogDescription,
  AlertDialogOverlay,
  AlertDialogContent,
} from "@sparkademy/app-common/elements/Alert";
import { Box, Text, Button as BaseButton, Link } from "theme-ui";
import { ReactComponent as SadPersonIllustration } from "@sparkademy/app-common/materials/illustrations/person-sad2.svg";
// import { AddToCalendarButton } from "./AddToCalendarButton";
import "@szhsin/react-menu/dist/index.css";
import "@szhsin/react-menu/dist/transitions/slide.css";
import VisuallyHidden from "@reach/visually-hidden";
import { ReactComponent as Close } from "@sparkademy/app-common/materials/icons/close.svg";

export const CourseCancelSignupDialog: React.FC<{
  onClose: () => void;
}> = ({ onClose }) => {
  const cancelRef = React.useRef<HTMLButtonElement>();

  return (
    <AlertDialogOverlay
      // @ts-ignore
      leastDestructiveRef={cancelRef}
      id="SaveAnswersDialog"
      onDismiss={onClose}
    >
      <AlertDialogContent>
        <Box sx={{ maxWidth: "504px" }}>
          <AlertDialogLabel
            style={{ height: "32px", width: "100%", position: "sticky", top: "32px" }}
          >
            <BaseButton
              sx={{
                all: "unset",
                fontFamily: "inherit",
                float: "right",
                color: "primary",
                cursor: "pointer",
                bg: "transparent",
              }}
              onClick={onClose}
            >
              <VisuallyHidden>Close</VisuallyHidden>
              <Close width={32} height={32} aria-hidden="true" focusable="false" />
            </BaseButton>
          </AlertDialogLabel>
          <AlertDialogDescription>
            <Box sx={{ padding: 10, pt: 0 }}>
              <Box
                sx={{
                  margin: "auto",
                  width: 170,
                  height: 170,
                  svg: {
                    width: "100%",
                    height: "auto",
                  },
                }}
              >
                <SadPersonIllustration />
              </Box>
              <Text as="h3" sx={{ textAlign: "center", fontWeight: 700, fontSize: "24px", mb: 4 }}>
                Are you sure you want to cancel your enrollment?
              </Text>
              <Text as="p" sx={{ textAlign: "center", fontWeight: 300, fontSize: "16px" }}>
                You will no longer have access to the course content. But you can always join again
                in a later cohort.
              </Text>
              <Text as="p" sx={{ textAlign: "center", fontWeight: 300, fontSize: "16px", mt: 5 }}>
                If you still want to cancel, please notify us by emailing{" "}
                <Link href="mailto:team@sparkademy.com" sx={{ textDecoration: "none" }}>
                  team@sparkademy.com.
                </Link>
              </Text>
              <Text
                as="p"
                sx={{
                  textAlign: "center",
                  fontWeight: 300,
                  fontSize: "16px",
                  fontStyle: "italic",
                  mt: 5,
                }}
              >
                We will process your request within 1-2 working days
              </Text>
            </Box>
          </AlertDialogDescription>
        </Box>
      </AlertDialogContent>
    </AlertDialogOverlay>
  );
};
