import * as React from "react";
import { Box, Text } from "theme-ui";

export const FeedbackRatingPendingWarning: React.FC<{
  exerciseId: string;
}> = ({ exerciseId }) => {
  const exerciseNumber = exerciseId.replace("ex", "");
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        bg: "new.failingRedLight",
        py: "32px",
        px: "48px",
        borderRadius: "5px",
        my: "32px",
      }}
    >
      <Text
        as="pre"
        sx={{
          fontFamily: "inherit",
          fontSize: "16px",
          wordBreak: "break-word",
          whiteSpace: "pre-wrap",
        }}
      >
        <b>Wait!</b> You have not reviewed the feedback you received on Exercise {exerciseNumber}.
        To submit, go back to Exercise {exerciseNumber} and fill out the feedback rating on the
        bottom of the page.
      </Text>
    </Box>
  );
};
