import * as React from "react";
import { toolsBySlug } from "@sparkademy/app-common/data/tools";
import { Assignment, Exercise } from "@sparkademy/app-common/models/assignment";
import { Logger } from "@sparkademy/app-common/services/logger-service";
import { TrackingService } from "../../services/tracking-service";

export function generateMethodCardLinks(
  methodCardSlugs: string[],
  assignment: Assignment,
  currentExercise: Exercise
): React.ReactNodeArray | string {
  if (methodCardSlugs.length === 0) return "None";

  const ret = [];
  const basePath = `${window.location.protocol}//${window.location.host}/method-kit`;

  for (let i = 0; i < methodCardSlugs.length; i++) {
    const slug = methodCardSlugs[i];
    const methodCard = toolsBySlug[slug];

    if (!methodCard) {
      Logger.error(`Method card ${slug} not found`);
    }

    const link = (
      <a
        href={`${basePath}/${slug}`}
        target="_blank"
        rel="noopener noreferrer"
        onClick={() => {
          TrackingService.PracticalAssignmentMethodCardClick(
            assignment,
            currentExercise,
            methodCard ? methodCard.name : slug
          );
        }}
      >
        {methodCard?.name || slug}
      </a>
    );

    if (i === methodCardSlugs.length - 1) {
      // last item
      ret.push(
        <span className="mc-link" key={i}>
          {link}
        </span>
      );
    } else {
      // first up until second last
      ret.push(
        <span className="mc-link" key={i}>
          {link},&nbsp;
        </span>
      );
    }
  }

  return ret;
}

export function getNextExerciseIndex(currentExerciseId: string, exercises: Exercise[]) {
  const currentExerciseIndex = exercises.findIndex(e => e.id === currentExerciseId);

  if (currentExerciseIndex === -1) {
    return null;
  }

  const nextExercise = exercises[currentExerciseIndex + 1];
  if (!nextExercise) {
    return null;
  }

  return currentExerciseIndex + 1;
}
