import * as React from "react";
import { Textarea, Box, Text } from "theme-ui";
import { ExerciseStatusType, Question } from "../../models/assignment";
import { useAssignmentAnswersContext } from "@sparkademy/app-common/contexts/assignment-answers-context";
import { useAssignmentContext } from "@sparkademy/app-common/contexts/assignment-context";
import { TextareaInput } from "@sparkademy/app-common/elements/Textarea";

export const QuestionInputText: React.FC<{ question: Question }> = ({ question }) => {
  const { assignmentStatus } = useAssignmentContext();
  const [questionExerciseId, _] = question.id.split(".");
  const exerciseId = "ex" + questionExerciseId;
  const exerciseStatus = assignmentStatus.find(as => as.exercise_id === exerciseId);
  const readonly =
    exerciseStatus?.status === ExerciseStatusType.SUBMITTED ||
    exerciseStatus?.status === ExerciseStatusType.GRADED;
  const isGradingTool =
    window.location.hostname.includes("evaluation") ||
    window.location.hostname.includes("localhost");

  const { localAnswers, savedAnswers, updateLocalAnswer, dirtyState, setDirtyState } =
    useAssignmentAnswersContext();

  const localAnswer = localAnswers.get(question.id) as string;
  const savedAnswer =
    typeof savedAnswers.get(question.id) === "string"
      ? (savedAnswers.get(question.id) as string)
      : "";

  const [value, setValue] = React.useState(localAnswer || "");

  React.useEffect(() => {
    // we don't want to overwrite local answer: `!localAnswer`
    // we clear local answer after saving so it gets reloaded here
    // we also check `!dirtyState` to prevent overwriting local changes when the whole text is deleted with back space
    if (savedAnswer && !localAnswer && !dirtyState) {
      setValue(savedAnswer);
    }
  }, [savedAnswer, localAnswer, dirtyState]);

  const onChangeHandler = (val: string) => {
    setValue(val);
    updateLocalAnswer(question.id, val);
    // mark the answer's state as dirty to enable the save button
    if (!dirtyState) {
      setDirtyState(true);
    }
  };

  if (readonly) {
    return (
      <Box
        className="answer-box"
        sx={{
          display: "flex",
          flexDirection: "column",
          bg: "new.secondary.lightGrey",
          py: "32px",
          px: "48px",
          borderRadius: "5px",
          mb: "32px",
        }}
      >
        <Text as="p" sx={{ fontWeight: 700, fontSize: "16px", mb: "32px" }}>
          {isGradingTool ? "Participant’s Response" : "Your Response"}
        </Text>
        <Text
          as="pre"
          sx={{
            wordBreak: "break-word",
            whiteSpace: "pre-wrap",
            fontFamily: "inherit",
            fontSize: "16px",
          }}
        >
          {value || "[BLANK]"}
        </Text>
      </Box>
    );
  }

  const aboveCharLimit = question.max_len && question.max_len <= value.length;

  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <TextareaInput
        value={value}
        onChange={e => onChangeHandler(e.target.value)}
        sx={{
          borderColor: aboveCharLimit ? "new.failingRed" : "new.secondary.grey",
          ":focus": {
            borderColor: aboveCharLimit ? "new.failingRed" : "primary",
          },
        }}
      />

      {question.max_len && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Text sx={{ textAlign: "left", mt: "32px", color: "new.failingRed" }}>
            {aboveCharLimit && `You may not exceed ${question.max_len} characters`}
          </Text>

          <Text sx={{ textAlign: "right", mt: "32px" }}>
            {`${value.trim().length}/${question.max_len} characters`}
          </Text>
        </Box>
      )}
    </Box>
  );
};
