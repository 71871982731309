import * as React from "react";
import { Box, Text } from "theme-ui";
import { LessonBlock as LessonBlockProps } from "@sparkademy/app-common/models/course";
import { generateImageUrl } from "../../data/directus";
import { ButtonGhostLink } from "@sparkademy/app-common/elements/ButtonGhost";
import { TrackingService } from "../../services/tracking-service";
import { courseAtom, blockUnitCompletionMapAtom } from "../../stores/course";
import { ReactComponent as LessonBlockCheck } from "@sparkademy/app-common/materials/icons/module-checkmark.svg";
import { useAtom } from "jotai";

export const LessonSectionBlock: React.FC<{
  courseId: string;
  lessonId: string;
  block: LessonBlockProps;
}> = ({ courseId, lessonId, block }) => {
  const [course] = useAtom(courseAtom);
  const [blockUnitCompletionsMap] = useAtom(blockUnitCompletionMapAtom);

  const lesson = course?.lessons.find(l => l.id === lessonId);
  const firstBlockUnitId = block.units[0]?.id ?? 0;
  let blockIsComplete = false;

  // Get only completions for this section block
  if (
    blockUnitCompletionsMap &&
    blockUnitCompletionsMap[lessonId] &&
    blockUnitCompletionsMap[lessonId][block.id]
  ) {
    const allUnits = block.units.map(val => parseInt(val.id)).sort();
    const completedUnits = blockUnitCompletionsMap[lessonId][block.id].sort();
    blockIsComplete =
      allUnits.length === completedUnits.length &&
      allUnits.every(function (value, index) {
        return value === completedUnits[index];
      });
  }

  return (
    <ButtonGhostLink
      className="lesson-block"
      to={`/course/${courseId}/lesson/${lessonId}/block/${block.id}/unit/${firstBlockUnitId}`}
      onClick={() =>
        TrackingService.lessonBlockClick(course?.title ?? "", lesson?.title ?? "", block.title)
      }
      sx={{
        color: "black",
        mt: ["40px", "48px"],
        p: 0,
        height: "172px",
        width: ["auto", "33%"],
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        textAlign: "center",
      }}
    >
      <Box
        sx={{
          height: "100px",
          width: "100px",
          position: "relative",
        }}
      >
        <img src={generateImageUrl(block.icon)} alt={block.title} />
        {blockIsComplete ? (
          <Box sx={{ position: "absolute", bottom: -3, right: -3 }}>
            <LessonBlockCheck />
          </Box>
        ) : null}
      </Box>
      <Text
        sx={{ fontSize: "16px", fontWeight: 700, mt: "24px", maxWidth: "80%", lineHeight: "22px" }}
      >
        {block.title}
      </Text>
      <Text sx={{ fontSize: "16px", color: "new.secondary.grey", fontWeight: 700, mt: "8px" }}>
        {`${block.timeCommitment} min`}
      </Text>
    </ButtonGhostLink>
  );
};
