import * as React from "react";
import { Box } from "theme-ui";
import { Button, ButtonLink } from "@sparkademy/app-common/elements/Button";
import { ButtonOutlineLink } from "@sparkademy/app-common/elements/ButtonOutline";
import { courseAtom, lessonNavigatorVisibleAtom } from "../../stores/course";
import { BlockUnit, Course, Lesson, LessonBlock } from "@sparkademy/app-common/models/course";
import { TrackingService } from "../../services/tracking-service";
import { last } from "@sparkademy/app-common/utils/array";
import { saveBlockUnitCompletion } from "../../services/http-api-service";
import { useSessionContext } from "@sparkademy/app-common/contexts/session-context";
import {
  getDashboardLink,
  getBlockUnitLink,
  getBlockCompleteLink,
  getLessonCompleteLink,
  isKnowledgeCheckBlock,
} from "./helpers";
import { useAtom } from "jotai";
import { useUserData } from "../../hooks/useUserData";

export function getPrevUnitLink(
  course: Course,
  lesson: Lesson,
  currentBlock: LessonBlock,
  currentUnit: BlockUnit
) {
  const unitIndex = currentBlock.units.findIndex(unit => unit.id === currentUnit.id);
  const isFirstUnit = 0 === unitIndex;

  if (!course || !lesson || !currentBlock || !currentUnit) {
    return "";
  }

  if (isFirstUnit) {
    return getPrevBlockUnitLink(course, lesson, currentBlock);
  } else {
    return getBlockUnitLink(course, lesson, currentBlock, currentBlock.units[unitIndex - 1]);
  }
}

function getPrevBlockUnitLink(course: Course, lesson: Lesson, currentBlock: LessonBlock): string {
  const blockIndex = lesson.blocks.findIndex(block => block.id === currentBlock.id);
  const isFirstBlock = 0 === blockIndex;

  if (isFirstBlock) {
    return getPrevLessonUnitLink(course, lesson);
  } else {
    return getBlockUnitLink(
      course,
      lesson,
      lesson.blocks[blockIndex - 1],
      last(lesson.blocks[blockIndex - 1].units)
    );
  }
}

function getPrevLessonUnitLink(course: Course, lesson: Lesson): string {
  const lessonIndex = course.lessons.findIndex(l => l.id === lesson.id);
  const isFirstLesson = 0 === lessonIndex;

  if (isFirstLesson) {
    return getDashboardLink(course); // Dashboard Link
  } else {
    const prevLesson = course.lessons[lessonIndex - 1];
    const prevBlock = last(prevLesson.blocks);
    const prevUnit = last(prevBlock.units);
    return getBlockUnitLink(course, prevLesson, prevBlock, prevUnit);
  }
}
export function getNextUnitLink(
  course: Course,
  lesson: Lesson,
  currentBlock: LessonBlock,
  currentUnit: BlockUnit
) {
  const unitIndex = currentBlock.units.findIndex(unit => unit.id === currentUnit.id);
  const isLastUnit = currentBlock.units.length - 1 === unitIndex;

  if (isLastUnit) {
    return getNextBlockUnitLink(course, lesson, currentBlock);
  } else {
    return getBlockUnitLink(course, lesson, currentBlock, currentBlock.units[unitIndex + 1]!);
  }
}

export function getNextBlockUnitLink(
  course: Course,
  lesson: Lesson,
  currentBlock: LessonBlock
): string {
  const blockIndex = lesson.blocks.findIndex(block => block.id === currentBlock.id);
  const isLastBlock = lesson.blocks.length - 1 === blockIndex;

  if (isLastBlock) {
    return getNextLessonUnitLink(course, lesson);
  } else {
    return getBlockUnitLink(
      course,
      lesson,
      lesson.blocks[blockIndex + 1],
      lesson.blocks[blockIndex + 1].units[0]
    );
  }
}
function getNextLessonUnitLink(course: Course, lesson: Lesson): string {
  const lessonIndex = course.lessons.findIndex(l => l.id === lesson.id);

  return getLessonCompleteLink(course, course.lessons[lessonIndex]);
}

// The Next And Previous Lesson buttons to navigate a whole course
export const LessonNavigator: React.FC<{
  lesson: Lesson;
  block: LessonBlock;
  unit: BlockUnit;
}> = ({ lesson, block, unit }) => {
  const { currentUser } = useSessionContext();
  const [course] = useAtom(courseAtom);
  const [lessonNavigatorVisible] = useAtom(lessonNavigatorVisibleAtom);
  const { getModuleById } = useUserData();
  const [nextButtonDisabled, setNextButtonDisabled] = React.useState(false);

  const nextUnitLink = getNextUnitLink(course!, lesson, block, unit);
  const prevUnitLink = getPrevUnitLink(course!, lesson, block, unit);

  const lessonIndex = course?.lessons.findIndex(l => l.id === lesson.id)!;
  const blockIndex = lesson.blocks.findIndex(currentBlock => block.id === currentBlock.id);
  const unitIndex = block.units.findIndex(currentUnit => unit.id === currentUnit.id);

  const inKnowledgeCheckBlock = isKnowledgeCheckBlock(block);
  React.useEffect(() => {
    // if user is navigating on a knowledge check block, we need to make sure it is open
    // otherwise disabled proceeding to the next page
    if (inKnowledgeCheckBlock) {
      const module = getModuleById(course!.module);
      if (module?.assessment.status === "upcoming") {
        setNextButtonDisabled(true);
      }
    }
  }, [course, inKnowledgeCheckBlock, getModuleById]);

  if (!lessonNavigatorVisible) return null;

  return (
    <Box sx={{ borderTop: "1px solid #BFBFBF", width: "min(100%,894px)", mt: "48px" }}>
      <Box sx={{ display: "flex", justifyContent: "space-between", mt: "48px" }}>
        <ButtonOutlineLink
          to={prevUnitLink}
          className="back-btn"
          onClick={() =>
            TrackingService.courseNavigationPreviousButtonClick(
              course!.title,
              lesson.title,
              block.title,
              unit.title
            )
          }
        >
          Back
        </ButtonOutlineLink>

        {nextButtonDisabled ? (
          <Button type="submit" disabled={nextButtonDisabled}>
            Next
          </Button>
        ) : (
          <ButtonLink
            to={nextUnitLink}
            className="next-btn"
            onClick={() => {
              saveBlockUnitCompletion(
                currentUser!.data.cohort_id,
                course!.id,
                lesson.id,
                block.id,
                unit.id,
                lessonIndex,
                blockIndex,
                unitIndex,
                currentUser!
              );
              TrackingService.courseNavigationNextButtonClick(
                course!.title,
                lesson.title,
                block.title,
                unit.title
              );
            }}
          >
            Next
          </ButtonLink>
        )}
      </Box>
    </Box>
  );
};
