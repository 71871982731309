import * as React from "react";
import { Box, SxStyleProp } from "theme-ui";

export const IconDescriptionList: React.FC<{
  sx?: SxStyleProp;
}> = ({ sx = {}, ...rest }) => (
  <Box
    as="dl"
    sx={{
      display: "flex",
      flexWrap: "wrap",
      justifyContent: ["space-between", "space-between", "flex-start"],
      mx: -6,
      my: -4,
      ...sx,
    }}
    {...rest}
  />
);
export const IconDescriptionListItem: React.FC<{
  Icon: React.FC;
  title?: string;
  TitleRender?: React.FC;
  sx?: SxStyleProp;
  titlesx?: SxStyleProp;
  id?: string;
}> = ({ Icon, title, titlesx, TitleRender, children, sx = {}, id, ...rest }) => (
  <Box
    id={id}
    sx={{
      px: 6,
      py: 4,
      // width: ["100%", "50%", "auto"],
    }}
  >
    <Box
      as="dt"
      sx={{
        display: "flex",
        alignItems: "center",
      }}
    >
      <Box
        aria-hidden
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          mr: 3,
          "& > *": {
            width: 24,
            height: 24,
          },
        }}
      >
        <Icon />
      </Box>
      <Box sx={{ whiteSpace: "nowrap", ...titlesx }}>
        {title ? title : null}
        {TitleRender ? <TitleRender /> : null}
      </Box>
    </Box>
    <Box
      as="dd"
      sx={{
        fontSize: 0,
        ml: 8,
        whiteSpace: "nowrap",
      }}
    >
      {children}
    </Box>
  </Box>
);
