import { gql } from "@apollo/client";
import { DirectusSkillWorkshopEnrollmentsResponse } from "@sparkademy/app-common/models/hasura ";
import { SkillWorkshopUserEnrollments } from "@sparkademy/app-common/models/workshops";
import { useHasuraQuery } from "./useHasuraQuery";

export function useWorkshopEnrollmentData(userId: string) {
  const { loading, error, data } = useHasuraQuery(
    gql`
      query ($user_id: uuid!) {
        skill_workshop_session_enrollments(where: { user_id: { _eq: $user_id } }) {
          session_id
          date_created
          workshop_id
        }
      }
    `,

    { user_id: userId }
  );

  return {
    enrollments: data ? parseDirectusResponse(data) : {},
    loading,
    error,
  };
}

function parseDirectusResponse(
  data: DirectusSkillWorkshopEnrollmentsResponse
): SkillWorkshopUserEnrollments {
  const ret: SkillWorkshopUserEnrollments = {};
  data.skill_workshop_session_enrollments.forEach(item => {
    if (Array.isArray(ret[item.workshop_id])) {
      ret[item.workshop_id].push(`${item.session_id}`);
    } else {
      ret[item.workshop_id] = [`${item.session_id}`];
    }
  });

  return ret;
}
