import * as React from "react";
import {
  AlertDialogLabel,
  AlertDialogDescription,
  AlertDialogOverlay,
  AlertDialogContent,
} from "@sparkademy/app-common/elements/Alert";
import { Box, Text } from "theme-ui";
import { ButtonAnchor } from "@sparkademy/app-common/elements/Button";
import { ReactComponent as IllustrationCheck } from "@sparkademy/app-common/materials/illustrations/check.svg";
import { ReactComponent as IllustrationExclamation } from "@sparkademy/app-common/materials/illustrations/exclamation.svg";
import { ButtonGhost } from "@sparkademy/app-common/elements/ButtonGhost";

type WorkshopSession = {
  id: string;
  schedules: {
    startAt: Date;
    endAt: Date;
  }[];
};

const MIN_ENROLLMENTS_FOR_WORKSHOP = 5;

export const WorkshopSignupDialog: React.FC<{
  onClose: () => void;
  onCancel: () => void;
  sessionDetailLink: string;
  session: WorkshopSession;
  enrollmentCount: number;
}> = ({ onClose, onCancel, sessionDetailLink, session, enrollmentCount }) => {
  const cancelRef = React.useRef<HTMLButtonElement>();

  const unconfirmedAttrs = {
    title: "Thank you for signing up!",
    description:
      "This workshop will be confirmed when it has reached the required number of sign ups.",
    icon: <IllustrationExclamation />,
  };
  const confirmedAttrs = {
    title: "Enrollment confirmed!",
    description:
      "Get ready for your upcoming workshop! You will participate in small group, so your attendance is essential to the learning experience.",
    icon: <IllustrationCheck />,
  };
  const confirmed = enrollmentCount >= MIN_ENROLLMENTS_FOR_WORKSHOP;
  const attrs = confirmed ? confirmedAttrs : unconfirmedAttrs;

  return (
    <AlertDialogOverlay
      // @ts-ignore
      leastDestructiveRef={cancelRef}
      id="WorkshopSignupDialog"
    >
      <AlertDialogContent>
        <Box sx={{ maxWidth: "311px" }}>
          <AlertDialogLabel></AlertDialogLabel>
          <AlertDialogDescription>
            <Box
              sx={{
                margin: "auto",
                width: 170,
                height: 170,
                svg: {
                  width: "100%",
                  height: "auto",
                },
              }}
            >
              {attrs.icon}
            </Box>
            <Text as="p" sx={{ textAlign: "center", fontWeight: 700, fontSize: "20px", mb: 4 }}>
              {attrs.title}
            </Text>
            <Text as="p" sx={{ textAlign: "center", fontWeight: 300, fontSize: "16px" }}>
              {attrs.description}
            </Text>
          </AlertDialogDescription>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <ButtonAnchor
            type="submit"
            sx={{ textAlign: "center", mb: 3, mt: 7, fontSize: "20px" }}
            href={sessionDetailLink}
            id="WorkshopOverviewButton"
          >
            See Workshop Overview
          </ButtonAnchor>
          <ButtonGhost
            sx={{ fontSize: "20px" }}
            type="submit"
            onClick={() => {
              onCancel();
              onClose();
            }}
            id="WorkshopSignupDialogCancelButton"
          >
            Cancel Sign Up
          </ButtonGhost>
        </Box>
      </AlertDialogContent>
    </AlertDialogOverlay>
  );
};
