// eslint-disable-next-line
import * as React from "react";
// @ts-ignore
import { mdx } from "mdx.macro";
import { Tool } from "../../models/tool";

// m3 assets
import m36ActsOfTestingImage from "../method-kit/m3/back/6-acts-of-testing.svg";
import m3AbTestingImage from "../method-kit/m3/back/ab-testing.svg";
import m3ClickThroughPrototypeImage from "../method-kit/m3/back/click-through-prototype.svg";
import m3CustomerJourneyImage from "../method-kit/m3/back/customer-journey.svg";
import m3DigitalPrototypesImage from "../method-kit/m3/back/digital-prototypes.svg";
import m3HypothesisImage from "../method-kit/m3/back/hypothesis.svg";
import m3ILikeIWishWhatIfImage from "../method-kit/m3/back/i-like-i-wish-what-if.svg";
import m3IdeaNapkinImage from "../method-kit/m3/back/idea-napkin.svg";
import m3LandingPageApproachImage from "../method-kit/m3/back/landing-page-approach.svg";
import m3LearningAndIteratingImage from "../method-kit/m3/back/learning-and-iterating.svg";
import m3PhysicalObjectImage from "../method-kit/m3/back/physical-object.svg";
import m3PhysicalPrototypesImage from "../method-kit/m3/back/physical-prototypes.svg";
import m3RolePlayImage from "../method-kit/m3/back/role-play.svg";
import m3ServicePrototypesImage from "../method-kit/m3/back/service-prototypes.svg";
import m3SixThinkingHatsImage from "../method-kit/m3/back/six-thinking-hats.svg";
import m3SmokeTestImage from "../method-kit/m3/back/smoke-test.svg";
import m3StoryboardImage from "../method-kit/m3/back/storyboard.svg";
import m3TestPrinciplesImage from "../method-kit/m3/back/test-principles.svg";
import m3TestingTypesImage from "../method-kit/m3/back/testing-types.svg";
import m3TheCoreElementImage from "../method-kit/m3/back/the-core-element.svg";
import m3ValuePropositionImage from "../method-kit/m3/back/value-proposition.svg";
import m3WireframingImage from "../method-kit/m3/back/wireframing.svg";
import m3MockupImage from "../method-kit/m3/back/mock-up.svg";
import m3BusinessModelLeanCanvasImage from "../method-kit/m3/back/business-model-lean-canvas.svg";

import m3PrototypingPrinciplesImage from "../method-kit/m3/back/prototyping-principles.svg";
import m3KeyFunctionImage from "../method-kit/m3/back/key-function.svg";
import m3InnovatorHypothesisImage from "../method-kit/m3/back/innovator-hypothesis.svg";
import m3PrototypesThatExplainImage from "../method-kit/m3/back/prototypes-that-explain.svg";
import m3PrototypesThatInteractImage from "../method-kit/m3/back/prototypes-that-interact.svg";
import m3CaptureFeedbackImage from "../method-kit/m3/back/capture-feedback.svg";
import m3ExperimentCardImage from "../method-kit/m3/back/experiment-card.svg";
import m3EvaluateYourExperimentImage from "../method-kit/m3/back/evaluate-your-experiment.svg";
import m3GainInternalSupportImage from "../method-kit/m3/back/gain-internal-support.svg";

import m3PrototypingPrinciplesThumbImage from "../method-kit/m3/front/prototyping-principles.svg";
import m3KeyFunctionThumbImage from "../method-kit/m3/front/key-function.svg";
import m3InnovatorHypothesisThumbImage from "../method-kit/m3/front/innovator-hypothesis.svg";
import m3PrototypesThatExplainThumbImage from "../method-kit/m3/front/prototypes-that-explain.svg";
import m3PrototypesThatInteractThumbImage from "../method-kit/m3/front/prototypes-that-interact.svg";
import m3CaptureFeedbackThumbImage from "../method-kit/m3/front/capture-feedback.svg";
import m3ExperimentCardThumbImage from "../method-kit/m3/front/experiment-card.svg";
import m3EvaluateYourExperimentThumbImage from "../method-kit/m3/front/evaluate-your-experiment.svg";
import m3GainInternalSupportThumbImage from "../method-kit/m3/front/gain-internal-support.svg";

// @ts-ignore
import m3businessModelLeanCanvasTemplate from "../method-kit/m3/business-model-lean-canvas-template.pdf";
// @ts-ignore
import m3CaptureFeedbackTemplate from "../method-kit/m3/capture-feedback-template.pptx";
// @ts-ignore
import m3LearningIteratingTemplate from "../method-kit/m3/learning-iterating-template.pdf";
// @ts-ignore
import m3SixThinkingHatsTemplate from "../method-kit/m3/six-thinking-hats-template.pdf";
// @ts-ignore
import m3UserTestingTemplate from "../method-kit/m3/user-testing-template.pdf";
// @ts-ignore
import m3ValuePropositionTemplate from "../method-kit/m3/value-proposition-template.pdf";
// @ts-ignore
import m3KeyFunctionTemplate from "../method-kit/m3/key-function-template.pptx";
// @ts-ignore
import m3InnovatorHypothesisTemplate from "../method-kit/m3/innovator-hypothesis-template.pptx";
// @ts-ignore
import m3ExperimentCardTemplate from "../method-kit/m3/experiment-card-template.pptx";
// @ts-ignore
import m3EvaluateYourExperimentTemplate from "../method-kit/m3/evaluate-your-experiment.pptx";

export const tools: Tool[] = [
  {
    phase: "empathize",
    moduleId: "demo",
    name: "Test Principles",
    slug: "demo-test-principles",
    resources: [],
    content: mdx`
**Show, don't tell**<br />
You want to make your idea visual and tangible to spark conversations.

**Invest little time and resources**<br />
Encourage honest feedback, therefore keep your prototype simple and focused.

**Fail early and often**<br />
Explore and move your idea forward by learning from your mistakes.

**Beginners mindset**<br />
Put aside biases and approach a design challenge with a fresh perspective.

**Don't fall in love **<br />
Be flexible and allow the user to co-create your idea.

**Present unfinished work **<br />
It is okay if the project is still in a liquid state and you don't have answers to every question yet.

**Prototype extremes**<br />
Highlight excess features; they can spark debate and accelerated learning.

**Observe and document**<br />
Pay attention to the non-verbal emotional cues and carefully document all your learnings.
    `,
    benefits: [],
    thumbImage: m3TestPrinciplesImage,
    backImage: m3TestPrinciplesImage,
    disabled: true,
  },
  {
    phase: "empathize",
    moduleId: "demo",
    name: "The Core Element",
    slug: "demo-the-core-element",
    resources: ["whiteboard/template", "markers", "post-its"],
    content: mdx`
While building a prototype and testing, you must let the user experience the core of your idea or solution. This feature is what you would like to test and receive feedback on.

But how do you identify the core element is of your idea? You can take three different approaches:

1. Identify where or what is the critical or key function of your idea is.
2. Look at the underlying assumptions of your idea. Where have you made the biggest? Which assumption would completely destroy your idea if it is wrong?
3. Think about your unanswered questions. What are you currently not sure about and want to answer?

With these questions in mind, you are able to build a prototype that focuses on your core idea. And remember, even though the identified feature or part is the core of your idea, it can still look scrappy! Only start prototyping and testing other elements if your user verifies the most critical aspect.
    `,
    benefits: [
      "Encourages you to solely prototype the most important element of your product or service",
      "Sets focus and prioritization",
      "Promotes quick iterations and feedback",
    ],
    thumbImage: m3TheCoreElementImage,
    backImage: m3TheCoreElementImage,
    disabled: true,
  },
  {
    phase: "empathize",
    moduleId: "demo",
    name: "Hypothesis",
    slug: "demo-hypothesis",
    resources: ["whiteboard/template", "markers"],
    content: mdx`
Before you go out and test your prototype, it is useful to be clear on your hypothesis. A hypothesis helps you define:
- What you want to test?
- How and with whom you test to generate and capture feedback?
- Why is it relevant for you?

In short, building a hypothesis means transforming an assumption into a falsifiable statement that you can test. When you formulate a hypothesis, ensure that you stick to the following criteria:

1. The hypothesis is simple and unambiguous.
2. The hypothesis is measurable and falsifiable.
3. The hypothesis relates to some cause and effect. You can use the sentence structure IF ..., THEN ... to help formulate a hypothesis rightly.
    `,
    benefits: [
      "Validates your assumptions",
      "Provides a decision criteria to verify or falsify an assumption",
      "Reveals point of strengths and new opportunities",
    ],
    thumbImage: m3HypothesisImage,
    backImage: m3HypothesisImage,
    disabled: true,
    template: m3UserTestingTemplate, // User testing belongs to the method Hypothesis
  },
  {
    phase: "empathize",
    moduleId: "demo",
    name: "Physical Prototypes",
    slug: "demo-physical-prototypes",
    resources: ["Various 2D/3D prototyping materials"],
    content: mdx`
When prototyping a physical product, consider the following methods useful:

**Physical object**<br />
A direct physical representation of the actual product, usually in the same size/shape but built with low-cost materials like paper or wood.

**Mock-up**<br />
A cardboard/paper-based version of your app or website allows for quick and low-cost testing of the application's core elements and functions.

**Sketches**<br />
A great way to make abstract ideas concrete, structure ideation, and promote shared understanding.

Tip: Think of the critical function and core element. It aims at testing what makes or breaks your idea, not only desirability but also the core technical aspects. In the case of a process or service, this means that you solely prototype the key parts.
`,
    benefits: [
      "Encourages interaction with the customers",
      "Makes abstract ideas concrete",
      "Promotes playfulness",
    ],
    thumbImage: m3PhysicalPrototypesImage,
    backImage: m3PhysicalPrototypesImage,
    disabled: true,
  },
  {
    phase: "empathize",
    moduleId: "demo",
    name: "Digital Prototypes",
    slug: "demo-digital-prototypes",
    resources: [
      "Various prototyping material: blank paper, cardboard, tape, glue, scissors",
      "optional: prototyping software",
    ],
    content: mdx`
If your solution is a digital product, e.g., a new website or app, these prototyping methods are useful:

**Wireframes**<br />
Are used to layout the main content and functionality of a web page or app. Wireframes are used early in the development process, representing the basic structure and flow before visual design and the content are added.

**Click-through prototypes**<br />
Test the user's experience by simulating real user interactions. This method allows you to collect quantitative data about the number of clicks, intuitive behavior, and time spend. Furthermore, you can gather feedback on various usability aspects.

**Wizard of Oz**<br />
This method manually creates the responses of people, devices, apps, or the environment by 'invisible' operators behind the scene. It is a great way to test the user reactions before investing time and effort into more complex prototypes.
    `,
    benefits: [
      "Encourages comprehensiveness by representing the overall navigation",
      "Tests usability from a user's perspective",
      "Allows for more interaction with the product",
      "Promotes playfulness",
    ],
    thumbImage: m3DigitalPrototypesImage,
    backImage: m3DigitalPrototypesImage,
    disabled: true,
  },
  {
    phase: "empathize",
    moduleId: "demo",
    name: "Service Prototypes",
    slug: "demo-service-prototypes",
    resources: ["paper", "makers"],
    content: mdx`
If your solution is a service/process, e.g., the new airport security experience, these prototyping methods are useful:

**Customer Journey map**<br />
Step-by-step visualization of the customer’s experience, including all the relevant steps of the process and the touchpoints you have with the users. Useful to identify problems with the status quo or to develop a new process.

**Storyboard**<br />
Use pen and paper to visually depict the new service/process as a story, e.g., as a comic. It illustrates the interaction of the user or object in a specific context.

**Role Play**<br />
Allows to explain a service or product idea by acting out the scenario of its use. Define some key roles, and prepare rough prototypes that facilitate the performance. While a part of the team acts, the others observe to spot opportunities.

**Scenario**<br />
To make your idea tangible, find a way for the user to experience it first hand. Test it in a “real-world” scenario, e.g., by creating a fake security gateway and play out the process of getting through.
`,
    benefits: [
      "Facilitates discussion about the solution",
      "Translates complex processes into tangible prototypes",
      "Simulates a holistic customer experience",
      "Shows mental leaps in theoretical processe",
    ],
    thumbImage: m3ServicePrototypesImage,
    backImage: m3ServicePrototypesImage,
    disabled: true,
  },
  {
    phase: "empathize",
    moduleId: "demo",
    name: "Physical Object",
    slug: "demo-physical-object",
    resources: [
      "various 2D/3D prototyping material such as: LEGOs, Play-Doh, cardboad/paper, tape/glue, scissors",
    ],
    content: mdx`
This prototyping method is a hands-on tool that entails the actual construction of your product or, in the case of a process or service, the physical reproduction of some parts of it. This process allows ideas or concepts to take a tangible form, making it easier for the final user to interact with the product during the testing phase. Through interaction with the prototype, the user can provide more precise feedback on the idea, or his behavior reveals new insights.

In most cases, the Physical Object should represent your solution's critical function or concept and test what could make or break your idea. But Physical Objects can also be used in A/B testing, as a prompt in user interviews to reveal needs, or to communicate ideas within your team.
    `,
    benefits: [
      "Creates iterative steps in the building process",
      "Promotes playfulness",
      "Encourages interaction with the customers",
      "Makes abstract ideas concrete",
    ],
    thumbImage: m3PhysicalObjectImage,
    backImage: m3PhysicalObjectImage,
    disabled: true,
  },
  {
    phase: "empathize",
    moduleId: "demo",
    name: "Mock-Up",
    slug: "demo-mock-up",
    resources: ["cardboard/paper", "tape/glue/scissors", "pens"],
    content: mdx`
The most common use of Mock-Ups is to create user interfaces that show the end-user what the digital solution will look like, without building the software or the underlying functionality.A Mock-Up is an ‘early-stage prototype’ made of cardboard or similar low-fidelity materials. When creating your Mock-Up, make sure you take on a visitor’s perspective: sketch the visual’s basic functionalities and make sure your user can ‘experience’ the basic digital interactions.

This tool can be used for design implementation and usability testing: by interacting with an early version of the solution, your user can provide valuable feedback already at the preliminary development stage, before you have written a single word of code. At this stage, changes and adjustments to your idea and prototype are still easy and cheap to implement.
    `,
    benefits: [
      "Encourages people to review the visual side of the solution",
      "Allows for more interaction with the product",
      "Promotes playfulness ",
    ],
    thumbImage: m3MockupImage,
    backImage: m3MockupImage,
    disabled: true,
  },
  {
    phase: "empathize",
    moduleId: "demo",
    name: "Wireframing",
    slug: "demo-wireframing",
    resources: ["paper/template", "colored pens", "low resolution programm"],
    content: mdx`
A Wireframe is a low fidelity representation of an application design. It clearly displays a representation of every important aspect of the final solution. Specifically, it should include the main groups of content (what?), the structure of information (where?) and a description and basic visualization of the user–interface interaction (how?). The Wireframe can be used as a tool to assist the technical designer to directly communicate with the user or vice versa. It also helps with determining which content and visuals are most important and should be implemented at a further stage. A simple and fast approach to Wireframing is to hand-draw on screen templates or build on a low-resolution program, such as Power Point.
    `,
    benefits: [
      "Refines the user interface design more rapidly",
      "Encourages comprehensiveness by representing the overall navigation",
      "Tests layout-only usability from a visitor’s perspective",
      "Makes the process more iterative",
    ],
    thumbImage: m3WireframingImage,
    backImage: m3WireframingImage,
    disabled: true,
  },
  {
    phase: "empathize",
    moduleId: "demo",
    name: "Click-through Prototype",
    slug: "demo-click-through-prototype",
    resources: ["different software"],
    content: mdx`
Click-through prototypes often are the next iteration after having created a wireframe. They add the interactive element and connect the different wireframes.

Instead of following a connector, the user is pressing buttons that send him to the next screen, enable him to add information, etc.

A click-through prototype lets the user experience
-	how many times you need to click for a specific action
-	how long it takes to enter the required information
-	whether the arrangement of buttons is intuitive and so on

Several tools help you build click-through prototypes. For rough, low-fidelity ones, you can, for example, use: Powerpoint, Keynote, or Marvel. For more sophisticated high-fidelity prototypes, you can use professional tools, such as Axure or Adobe XD.
    `,
    benefits: [
      "Simulation of real user interactions",
      "Collecting feedback on various usability aspects of the prototype",
      "Fostering further ideation ",
    ],
    thumbImage: m3ClickThroughPrototypeImage,
    backImage: m3ClickThroughPrototypeImage,
    disabled: true,
  },
  {
    phase: "empathize",
    moduleId: "demo",
    name: "Idea Napkin",
    slug: "demo-idea-napkin",
    resources: ["paper", "template", "marker or pen"],
    content: mdx`
The Idea Napkin is an inspiring visual thinking tool that provides a simple, consistent format for summarizing and communicating a new concept. The name originates from the notion that a good idea can be expressed simply, for instance, on the back of a napkin. By starting with sketching your idea and naming it, you make the idea more realistic and conceivable. Hereafter, this tool offers you the possibility to think about the most critical aspects of the idea you are trying to develop at a preliminary stage.

These aspects include:

**User**: who is it for?<br />
**Value proposition**: why is it relevant to your target audience?<br />
**Pains & Challenges**: what are the problems/needs addressed?<br />
**Features, Functions & Resources**: how does it work?<br />

By sketching your idea on paper, the Idea Napkin makes it easier to explain your solution to the audience, collect feedback, and inspire more discussion.
    `,
    benefits: [
      "Helps you balance user and business needs",
      "Structures ideation",
      "Promotes shared understanding",
      "Makes abstract ideas concrete",
    ],
    thumbImage: m3IdeaNapkinImage,
    backImage: m3IdeaNapkinImage,
    disabled: true,
  },
  {
    phase: "empathize",
    moduleId: "demo",
    name: "Customer Journey",
    slug: "demo-customer-journey",
    resources: ["whiteboard", "Post-Its", "markers"],
    content: mdx`
Statistically and demographically driven data collection often fails to capture users’ human experiences and feelings. Customer Journeys are engaging and graphical storylines that provide a vivid but structured visualization of your user’s experience. Customer Journeys help you look at the big picture by identifying the main touchpoints of the process, the relevant stakeholders engaged at each one of its steps, and the key interactions between the users and your company in chronological order.

A customer journey consists of:

- Context (Persona, scenario, overarching goals)
- User experience (Touchpoints, actions, goals, behavior, and thoughts, emotional experience)
- Defining outputs (emerging opportunities, prioritization of design)

Tip: Base the value of the Customer Journey on both the output and the process of co-creating. Avoid the trap of building an easy, assumption-based journey, but use reliable data instead.
    `,
    benefits: [
      "Visualizes reoccurring behavioral patterns",
      "Supports assessing your product’s or service’s overall performance",
      "Reveals an outside-in perspective on design opportunities",
      "Facilitates storytelling",
    ],
    thumbImage: m3CustomerJourneyImage,
    backImage: m3CustomerJourneyImage,
    disabled: true,
  },
  {
    phase: "empathize",
    moduleId: "demo",
    name: "Storyboard",
    slug: "demo-storyboard",
    resources: ["paper", "pens", "markers"],
    content: mdx`
The Storyboard is a visualization technique. You or your team can generate short scenarios as a series of sketches, illustrations, or photo collages to express how potential users will use concepts in proposed situations. This method helps you identify problems that would not have been obvious at the beginning stage of conceiving the idea. For example, imagine a newspaper article in the future that would be featuring your project or an academic paper; what would that look like? The key idea is to place the user or an object she interacts within the specific context so that you can learn about the many different aspects of an encounter and interaction.
You do not need to be a great artist to create a great storyboard. It is not about your drawing capabilities but about capturing a situation in its entirety.
    `,
    benefits: [
      "Facilitates discussion with users",
      "Improves communication",
      "Highlights circumstances and use-cases",
    ],
    thumbImage: m3StoryboardImage,
    backImage: m3StoryboardImage,
    disabled: true,
  },
  {
    phase: "empathize",
    moduleId: "demo",
    name: "Role Play",
    slug: "demo-role-play",
    resources: ["Materials for props actors", "Optional: Camera, video recorder"],
    content: mdx`
Role Play is a technique through which you or your users perform a hypothetical service experience.  You imply that the service already exists and build a potential journey through some of its functionalities.

During Role Play, you can communicate better, and the user can understand the functionalities of your idea. This is especially useful for services/experiences in scenarios where communication barriers exist. Alternatively, you can start to act out the service within the team while other team members try to spot opportunities for improvement.

To get started:
- Define and assign the different roles: the customer, the service provider, etc.
- Create physical artifacts that are used during the interaction.
- Have cameras or mobile phones ready to record the role play.
    `,
    benefits: [
      "Encourage interactions",
      "Reveals relationships and major customer touch-points",
      "Facilitates storytelling",
      "Simulates a holistic customer experience",
    ],
    thumbImage: m3RolePlayImage,
    backImage: m3RolePlayImage,
    disabled: true,
  },
  {
    phase: "empathize",
    moduleId: "demo",
    name: "Testing Types",
    slug: "demo-testing-types",
    resources: ["potential users", "product/service prototypes"],
    content: mdx`
Tests allow you to observe your customers' behaviors. You can directly observe what customers do and how they interact with the prototypes using Learning Prototypes that get quantifiable results. Identify directly what the user wants and find issues with your solution.
Three simple quantifiable testing methods are:

**A/B testing**<br />
This method is all about comparison. In short, you test two different versions of the same solution, e.g., different product designs, prototypes, or core functionalities for the same needs. Do five interviews, adapt the prototype, and continue.

**Landing page approach**<br />
Before you spend time further developing an idea, it is worth testing whether customers will buy it. Therefore, built a landing page to advertise the new offering, add a "buy" button, and the data will speak for itself.

**Smoke test**<br />
For digital applications, this method is the preliminary testing level that ensures that all basic components are functioning properly.
    `,
    benefits: [
      "Supports decision making with actual, verified data through",
      "Direct comparison of several versions of a proposed solution",
    ],
    thumbImage: m3TestingTypesImage,
    backImage: m3TestingTypesImage,
    disabled: true,
  },
  {
    phase: "empathize",
    moduleId: "demo",
    name: "A/B Testing",
    slug: "demo-ab-testing",
    resources: ["template with categories", "potential users", "product/service prototypes"],
    content: mdx`
In A/B testing, two different versions of a product or service are compared directly. The two versions can have completely different core functionalities or only differ in the arrangement of components. Several use cases exist:

1. Users are shown both options and are asked to choose between them. Additionally, it is useful to ask for the reason behind this decision.
2. Participants are divided into two groups (A and B). One group employs one version of the product/service (A); the other group, in contrast, tests the alternative or modified version of the product/service (B). This can also be done digitally, for example, by comparing user interaction with different website designs.

By observing users’ reactions along given categories (both quantitative and qualitative), the users’ preference and the potential of the different versions can be compared directly. This testing method allows the direct validation of competing options, and subsequently, a data-driven decision with which version to move forward.
    `,
    benefits: [
      "Enables to simultaneously gather feedback for two or more version",
      "Supports decision making with actual, verified data through direct comparison of several versions of a proposed solution",
    ],
    thumbImage: m3AbTestingImage,
    backImage: m3AbTestingImage,
    disabled: true,
  },
  {
    phase: "empathize",
    moduleId: "demo",
    name: "Smoke Test",
    slug: "demo-smoke-test",
    resources: ["basic software"],
    content: mdx`
Smoke test helps in exposing integration and major problems early in the cycle. It should be the first method of software testing on both newly created software and enhanced software. The name “smoke test” originally came from testing hardware, in which engineers performed quick initial tests to confirm a basic, safe functioning of a hardware test unit. For digital applications, smoke testing is a preliminary testing level that ensures all essential components are functioning correctly. Smoke testing covers most of the major functions of the software but none of them in depth. The result of this testing is used to decide if a build is stable enough to proceed with further testing. If an application is badly broken, detailed testing might be a waste of time and effort.
    `,
    benefits: [
      "Uncovers problems early",
      "Saves time by focusing on basic components",
      "Works as a gatekeeper to accept or reject a software build",
    ],
    thumbImage: m3SmokeTestImage,
    backImage: m3SmokeTestImage,
    disabled: true,
  },
  {
    phase: "empathize",
    moduleId: "demo",
    name: "Landing Page Approach",
    slug: "demo-landing-page-approach",
    resources: ["potential users", "prototyped landing page"],
    content: mdx`
The Landing Page approach evaluates your product's desirability and the financial viability of the business in a measurable way.

As the name implies, you only build the landing page of a website to advertise this new product or service offering. By adding an option to sign up for the product release, pre-ordering the product, or simply registering for a newsletter, the product's potential becomes measurable. However, the offering does typically not exist yet or has at least not yet been produced. The customer typically gets the message that they need to be patient and are informed when the product is available.
    `,
    benefits: [
      "Gain mesurable insights",
      "Connect with cutomers willing to buy the product",
      "Increase visibility",
    ],
    thumbImage: m3LandingPageApproachImage,
    backImage: m3LandingPageApproachImage,
    disabled: true,
  },
  {
    phase: "empathize",
    moduleId: "demo",
    name: "6 Acts of Testing",
    slug: "demo-6-acts-of-testing",
    resources: [],
    content: mdx`
Similar to interviewing, testing your prototype with a user follows different stages.

**1. Friendly welcome**<br />
Start with small-talk, be natural, informative, and build rapport.

**2. Context questions**<br />
Understand your user. How does your prototype fit his/her life and work?

**3. Prototype introduction**<br />
Ask for permission to test the prototype. Also, explain that it is merely a prototype and thus value all feedback. Last, elaborate on your role and encourage out-loud thinking.

**4. Detailed tasks**<br />
Now it is time to interact with the prototype. But, be cautious, don't explain the entire process. Let the test user interact with it on its own and adhere to the 20:80 rule.

**5. Quick debrief**<br />
Ask a few debriefing questions to help the customer to articulate his reactions. For instance, what did you like about this product/ service?

**6. Thank and wrap-up**<br />
Thank the user for participating and ensure that the feedback was useful. Ask whether he/she is interested in testing the next iterations, and don't forget to stay attentive to any additional comments.
    `,
    benefits: [],
    thumbImage: m36ActsOfTestingImage,
    backImage: m36ActsOfTestingImage,
    disabled: true,
  },
  {
    phase: "empathize",
    moduleId: "demo",
    name: "I Like, I Wish, What If",
    slug: "demo-i-like-i-wish-what-if",
    resources: ["paper", "post-its", "markers"],
    content: mdx`
This feedback method works best in a team setting. This format is a facilitated collective feedback activity where team members engage in a collaborative exercise to provide and receive both positive and constructive feedback. Feedback is classified into three main categories:
**I like**, **I wish**, **What if**.

**I like** - strengths and positive aspects of the work

**I wish** - aspects that could be improved or done differently

**What if** - constructive suggestions on how to proceed and follow up with a solution

In this format, members can constructively provide conciseand direct feedback, allowing you to improve your concept or performance directly. The method can be applied in prototyping and is also useful to give feedback after a workshop or directly to your teammates and their performance.
`,
    benefits: [
      "Encourages constructive and open feedback",
      "Improves team communication",
      "Reduces confusion regarding expectations",
      "Improves performances overtime",
    ],
    thumbImage: m3ILikeIWishWhatIfImage,
    backImage: m3ILikeIWishWhatIfImage,
    disabled: true,
  },
  {
    phase: "empathize",
    moduleId: "demo",
    name: "Six Thinking Hats",
    slug: "demo-six-thinking-hats",
    resources: ["colored hats or colored paper to represent the hats", "at least 6 team members"],
    content: mdx`
The Six Thinking Hats is a tool for group discussion and individual thinking. The human brain thinks in several distinct ways which can be deliberately challenged. By using the six hats, you stimulate the team to think in six distinct directions. The six hats are not natural ways of thinking but rather present extremes in different personalities and thought processes.

**Optimism**: An optimistic response, finding benefits and harmony<br/>
**Information**: Examining the facts and information<br/>
**Emotions**: Intuition, instant reaction, and statement of feeling<br/>
**Discernment**: Logic to be cautious, judgment and evidence<br/>
**Creativity**: Statements that provoke and investigate thoughts<br/>
**Managing**: What is the big picture? What is the subject? Goals?<br/>

Each member gets a hat assigned in a group and gives feedback on an idea based on the specific hat. Hats can be exchanged to stimulate new perspectives.  If no group is available, you can also go through all hats after each other on your own.
`,
    benefits: [
      "Provides new perspectives and reveals opportunities",
      "Inspires and enhances feedback giving",
      "Challenges comfort of your prototype with new insights",
    ],
    thumbImage: m3SixThinkingHatsImage,
    backImage: m3SixThinkingHatsImage,
    disabled: true,
    template: m3SixThinkingHatsTemplate,
  },
  {
    phase: "empathize",
    moduleId: "demo",
    name: "Learning & Iterating",
    slug: "demo-learning-and-iterating",
    resources: ["assumptions or hypothesis which was tested", "results from User Testing"],
    content: mdx`
Integrating the feedback you hear from the people you are designing for is essential for human-centered design. At the end of prototyping and testing, you want to decide whether you and your team continue and refine the idea, iterate, and change the solution or terminate the project completely.

The learning and iterating process consist of 4 steps:

1. Reflect on the initial Hypothesis or Assumption,
2. Note down your observations from the testing and feedback received. Was the success criteria of your hypothesis met?
3. Decide which learnings and insights you take from those observations. Can you validate your hypothesis, or did new assumptions appear?
4. Make concrete decisions and define your next actions.

Tip: Keep on iterating until you have learned enough and result with a satisfactory solution that provides the right answers.
    `,
    benefits: [
      "Refines the initial solution and gets it closer to the ideal one",
      "Helps to make decisions based on hard data, not gut feelings",
    ],
    thumbImage: m3LearningAndIteratingImage,
    backImage: m3LearningAndIteratingImage,
    disabled: true,
    template: m3LearningIteratingTemplate,
  },
  {
    phase: "empathize",
    moduleId: "demo",
    name: "Value Proposition",
    slug: "demo-value-proposition",
    resources: ["results from qualitative user interviews and prototyping tests"],
    content: mdx`
When thinking of launching a new product or service, it is essential to define your ideal target audience and, at the same time, identify and understand the core customer need that your new solution is looking to fulfill or satisfy. The Value Proposition helps you address such concerns by identifying the core elements that distinguish your solution from the existing one(s).

These elements are analyzed from the target user’s perspective and are based on identifying and comparing the strengths and weaknesses of both solutions. By filling each quadrant of the matrix, you detect for whom, why, and how your solution delivers value.
    `,
    benefits: [
      "Improves customer understanding",
      "Identifies differentiation factors and strengths of a new solution",
      "Creates focus and gives directions and clarity",
    ],
    thumbImage: m3ValuePropositionImage,
    backImage: m3ValuePropositionImage,
    disabled: true,
    template: m3ValuePropositionTemplate,
  },
  {
    phase: "empathize",
    moduleId: "demo",
    name: "Business Model & Lean Canvas",
    slug: "demo-business-model-lean-canvas",
    resources: ["templates"],
    content: mdx`
With those prototypes and tests, we looked at different aspects of your idea and validated them one after another.
But to have a running business in the long term, it is essential to also prototype your Business Model. The Business Model Canvas or Lean Canvas are two well-known tools that help you connect the many different aspects of a successful business.

Both Canvases are a strategic management asset to describe, design, and invent business models.  They  provide a visual representation of the main aspects that define how an enterprise creates, delivers and captures value. Those aspects are summarized in 9 different categories. The Business Model Canvas starts with aligning the Value Proposition with the Customer Segments. The Lean Canvas sets a focus on a Problem-Solution fit.

Using those frameworks even in a early prototyping phase of a project, helps to identify more underlying assumptions of your idea.
You shift focus and are able to see if the different elements of your business model are connecting well with each other or if you even considered all of them.This gives you a basic indication whether or not your business idea is able to survive and grow.
    `,
    benefits: [],
    thumbImage: m3BusinessModelLeanCanvasImage,
    backImage: m3BusinessModelLeanCanvasImage,
    disabled: true,
    template: m3businessModelLeanCanvasTemplate,
  },
  {
    moduleId: "demo",
    name: "Prototyping Principles",
    slug: "demo-prototyping-principles",
    resources: [],
    content: mdx`
### Why should you use this method?

- To learn quickly and cheaply
- To create credible data to build conviction
- To avoid expensive failure
- To discover the contradiction between what people say and do
- To avoid wrong conclusion based on biased data creation

### How can you apply this method?

##### 1. Beginner’s mindset

Adapt the mindset of a kindergartener, thus don’t overinvest in a plan, learn by doing and discover the answer.

##### 2. Focus on Desirability

Focus on the desirability question. Does the user want your solution and is he or she willing to pay for it? This should be the focus of your prototyping and testing experiment.

##### 3. Be Cheap

Ask yourself, “what if I had only half the budget available?”. Think of other ways you could design an experiment with less money. Decrease your spending to the smallest amount possible. The more you invest, the harder to throw away.

##### 4. Be Quick

Invest little time and resources and aim to fail early and often. Can this experiment be launched within hours? If not, think of ways to design it faster.

##### 5. Generate Data

You want to learn as much as possible. Thus, you want to gather data respectively learning and insights while designing, creating, and conducting your experiment.

### Next Steps

Having internalized the prototyping principle, you want to explore your [starting point](/method-kit/m3-key-function).
  `,
    benefits: [],
    thumbImage: m3PrototypingPrinciplesThumbImage,
    backImage: m3PrototypingPrinciplesImage,
  },
  {
    moduleId: "demo",
    name: "Key Function",
    slug: "demo-key-function",
    resources: ["Pen", "Paper/Template"],
    content: mdx`
### Why should you use this method?

- Reduce the time it takes to validate a solution idea
- Remove distractions & quickly find out how you can achieve impact

### How can you apply this method?

##### 1. Give your idea a catchy name

Don’t linger on this step for too long. Find a short, descriptive name for each functionality. The goal here is to have a name you can remember them by in your conversations.

##### 2. Describe the intended (game-changing) impact

This is the first most important step. What’s the impact / outcome you want to achieve?

##### 3. Identify the key function to deliver that impact

This is the second most important step. What’s the ONE function my idea must perform to do that?

##### 4. Describe your target customer

##### 5. Observe whether doing the key function delivers the impact

Ask yourself “How can I give myself the ability to observe whether doing this accomplishes that impact?”. Observe it and answer the question “Does performing that key function deliver the impact?”

### What if the idea has many functions?

It’s possible that, at the beginning of a conversation, an idea for a solution is being discussed. That solution can have many parts that add more or less value to the final impact you want to achieve.

For example, Facebook is a solution with many parts: User accounts, login, password reset, newsfeed, friends, photo upload, video upload, etc. Not all these features have the same value in delivering the impact of Facebook as a solution. The same is true for most ideas, which is why you need to prioritize functions of every new idea.

### How to prioritize your functions?

1. In one column, list all the functions that your idea would perform in the life of your customer.

2. In the second column, prioritize the functions ruthlessly by how likely they are to accomplish the impact.

3. Finally, identify the 1-2 critical aspects / functions of your idea, those that are most likely to accomplish the impact.
  `,
    benefits: [],
    template: m3KeyFunctionTemplate,
    thumbImage: m3KeyFunctionThumbImage,
    backImage: m3KeyFunctionImage,
  },
  {
    moduleId: "demo",
    name: "Innovator's Hypothesis",
    slug: "demo-innovator-hypothesis",
    resources: ["Pen", "Paper/Template"],
    content: mdx`
### Working with hypothesis = focus

It is critically important to be very focused in what you’re doing. The broader the scope, the harder it is to make progress. The point of focus may be wrong but focusing is not wrong.

Having a clear hypothesis about who you are designing for (target audience), what their pain is (pain point) and how you plan to solve that pain (key function) is crucial and will help you gain that focus.

### Focus = speed

The more focused you are, the quicker you move, and the quicker you can learn and evolve. The output of such focus is a very fast experiment.

### Experiment

For most ideas, within 2 hours, it is possible to create data to give you confidence that your idea is a great idea investing in.

To test your hypothesis, focus on the desirability of the solution first (instead of its feasibility or business viability). Ask yourself, “Would my target audience desire this option?” and figure out a way to create believable data to support or invalidate your hypothesis fast.

Using the template we provide, design a 2h experiment to test the fit of the different parts of your hypothesis:

1. Target audience

2. Their pain point

3. Your key function

Ask yourself, after the experiment:

- Does this user have this pain point?
- Which user has these pains?
- Does this feature solve these pains?

### Why should you use this method?

- Helps you have focus / experimental hygiene
- Helps you create believable data & test the desirability of your idea
- Helps you to be honest with yourself about whether you succeeded or    not

### How can you apply this method?

##### 1. Write down your hypothesis

Include the target audience, their pain point, the impact you want to have, and the key function that should deliver that impact.

##### 2. Design an experiment to test the hypothesis

The experiment should not take longer than 2h, ideally. You are aiming to (in)validate the hypothesis fast. Remember, it’s about focus & gathering data as quickly as possible, to learn fast & move forward. Use the template to describe the experiment and all its parts.

##### 3. Analyze the data & decide next steps

Decide, based on the data you gathered, whether the target audience desires the key function you designed, and whether it delivers the impact you thought it would. Does this feature solve the pain?
  `,
    benefits: [],
    template: m3InnovatorHypothesisTemplate,
    thumbImage: m3InnovatorHypothesisThumbImage,
    backImage: m3InnovatorHypothesisImage,
  },
  {
    moduleId: "demo",
    name: "Prototypes that Explain",
    slug: "demo-prototypes-that-explain",
    resources: [],
    content: mdx`
These are the prototypes that you need for pitching, presentation, or an introduction but not used for testing.

Here are some of the prototyping techniqes that can be used to explain your idea:

##### 1. Mockups

Mockups are scaled or full-sized models of your product or service. Physical mockups can be created using inexpensive materials, such as Styrofoam, Play-Doh, Legos, or 3D printers.

##### 2. Storyboard

Think of this as a cartoon with five to ten panels, showing the key elements of the solution. This can be created on paper, digitally, or using video. It is a visualization technique. This method helps you identify problems that would not have been obvious at the beginning stage of conceiving the idea.

##### 3. User / customer journey

This is a visual description of a day in the life of a user with your solution. The journey map is a synthetic representation that describes step-by-step how a user interacts with a service. The process is mapped from the user perspective, describing what happens at each stage of the interaction, what touchpoints are involved, what obstacles and barriers they may encounter. The journey map is often integrated as an additional layer representing the level of positive/negative emotions experienced throughout the interaction.

##### 4. Flow charts

A great technique for services is to use a flow chart in which you specify the roles and responsibilities for everyone involved in the delivery of the service, the tasks to be performed by whom, and the order in which they are to be performed.

Flow charts are a great way to both visualize an existing process and prototype a new one.
  `,
    benefits: [],
    thumbImage: m3PrototypesThatExplainThumbImage,
    backImage: m3PrototypesThatExplainImage,
  },
  {
    moduleId: "demo",
    name: "Prototypes that Interact",
    slug: "demo-prototypes-that-interact",
    resources: [],
    content: mdx`
These are the prototypes that enable the user to interact with it and get a sense of how it works. These are used to generate data that will further test the hypothesis.

Here are some of the prototyping techniqes that can be used to help your user interact with your prototype:

##### 1. Wizard of Oz

These prototypes are used for testing functionality and interface ideas within software applications.

You create a paper or digital mockup of the application interface and behavior. A user interacts with the prototype as he or she would with the actual software.

Meanwhile, without speaking, you manipulate the prototype to simulate the reactions of the application in response to the user's action. All of this allows you to observe the user see what his or her interactions with the prototype would look like.

##### 2. Role Plays

Role play is especially helpful if you're operating or designing a complex service environment. Role Play is a technique through which you or your users perform a hypothetical service experience.  You imply that the service already exists and build a potential journey through some of its functionalities.

##### 3. Wireframe

A Wireframe is a low fidelity representation of an application design. It displays a representation of every important aspect of the final solution. Specifically, it should include the main groups of content (what?), the structure of information (where?), and a description and basic visualization of the user–interface interaction (how?).

##### 4. Mechanical Turk

This technique is similar to Wizard of Oz. Manually accomplishing a task that in the future will be done by an algorithm. From the users’ point of view, the experience is as seamless as possible, almost deceptively so. The perception is that they are interacting with “the real thing”. Eg: IBM

##### 5. Imposter Prototype

This technique involves dressing up existing products or services to make them look like your product or service. Eg: Elon Musk

##### 6. Pinocchio

In this prototyping technique, you use the mockup, but pretend that it is the real thing. Create a non-functional version of your product and use your imagination to pretend it's functional to test if, how, and when you would use it. A Pinocchio prototype can help test the physical form factor of a product. As it is in fact a dumb prototype, it works best to convince yourself and your team, not others, that your idea is on the right track. Eg: Palm Pilot

##### 7. Smokescreen

Here, you direct customers to a website, where they see a video of your product. Alongside the video, you ask them to sign up for a beta test or even a preorder. Eg: Dropbox

##### 8. The One Night Stand Technique

This technique is great for services. Here, you offer the services in a pared-down, minimal fashion, on a very limited time basis, to see if there is any interest. Eg: Airbnb

##### 9. Pop-up Store

A pop-up store is a temporary physical point of sale where customers can view and purchase a product for a short period of time. This format is used to test the attractiveness of a product and the willingness to pay before launching it on the market. The most classical example of a prototype, especially for services is the pop-up store. Eg: Lemonade stalls
  `,
    benefits: [],
    thumbImage: m3PrototypesThatInteractThumbImage,
    backImage: m3PrototypesThatInteractImage,
  },
  {
    moduleId: "demo",
    name: "Experiment Card",
    slug: "demo-experiment-card",
    resources: ["Pen", "Paper/Template"],
    content: mdx`
### Why should you use this method?

- Plan and summarize experiments with your team
- Present and share your experimentation plan

### How can you apply this method?

##### 1. Analyze your learning goal

With your team, you analyze the solution or idea you came up with. Start a discussion to [define your key function](/method-kit/m3-key-function) and set your learning goal. The [Innovator's Hypothesis](/method-kit/m3-innovator-hypothesis) is a great template to visually connect the pain point and intended impact of your idea.

##### 2. Define metrics

The next step is to define your metrics. What will you measure with your experiment, what data do you need to support your hypothesis with? You might have to go back and forth between the metrics and your experiment plan. Defining the metrics also includes an invalidation criteria, which means your initial hypothesis is wrong, and an early stop condition to avoid time and money wasted.

##### 3. Set up an experiment plan

What actions are you going to take to test your hypothesis? You can get inspired by the list of [interactive prototypes](/method-kit/m3-prototypes-that-interact), or come up with your own experiment setup. Remember: you are not trying to build your idea but generate data. The experiment you design might not look like your solution at all, but it should generate data to test this solution and the assumptions behind it.

##### 4. Repeat!

To validate and test your idea, you should not only rely on one single experiment. Continue to collect data and plan further experiments!

### Next Steps

Execute and evaluate your experiment! You should start by collecting [insights and feedback](/method-kit/m3-capture-feedback) and [evaluate your experiments](/method-kit/m3-evaluate-your-experiment) to make sense of your data and iterate your solution.

Remember, experiments are always about learning. Even if you did not validate your hypothesis, you collected valuable data to support your future actions and decision making.
`,
    benefits: [],
    thumbImage: m3ExperimentCardThumbImage,
    backImage: m3ExperimentCardImage,
    template: m3ExperimentCardTemplate,
  },
  {
    moduleId: "demo",
    name: "Capture Feedback",
    slug: "demo-capture-feedback",
    resources: ["Markers", "stickers", "paper/template", "camera (optional)"],
    content: mdx`
### Why should you use this method?

- Reveals the strengths and weaknesses of your prototype
- Provides direct and concise feedback
- Challenges assumptions

### How can you apply this method?

##### 0. Schedule a meeting with the user to test the prototype

Schedule a 15-20 mins with your user to interact and test your prototype.

##### 1. Let the user interact with the prototype

While the user is interacting with the prototype, observe the behavior and start taking notes, videos and pictures. It is important to document this process in all forms.

##### 2. Ask the user to narrate as he interacts with the prototype

As the user is narrating his experience with the prototype, you or your field partner should take notes or fill the template. However, don't ask them to give you answers to the questions, but interpret their behavior and comments.

##### 3. Provide post-its to the user

Allow the user to write their thoughts directly on the prototypes using post-its or stickers.

##### 4. Apply the feedback

Try to implement the feedback/observations before you test it with another user.
    `,
    benefits: [],
    thumbImage: m3CaptureFeedbackThumbImage,
    backImage: m3CaptureFeedbackImage,
    template: m3CaptureFeedbackTemplate,
  },
  {
    moduleId: "demo",
    name: "Evaluate your Experiment",
    slug: "demo-evaluate-your-experiment",
    resources: ["Pen", "Paper/Template"],
    content: mdx`
### Why should you use this method?

- To avoid making conclusions of your experiment in a hasty manner
- To collect the right data for the experiment
- To be able to learn from the experiment
- To make the right conclusions on the hypothesis and move forward

### How can you apply this method?

##### Step 0: Conduct the experiment that was designed

##### Step 1: Debrief with the team

After the experiment is concluded, debrief with the team to discuss how the experiment went.

Some of the points to be discussed with the team:

##### 1. Define the pain point that was tested

This is the focal point of the experiment and should be made clear             to the team again.

##### 2. Discuss in detail the execution of the experiment

Go through step by step of how the experiment was executed.
This will help you assess if there were some issues in the execution.

##### 3. Gather the data that was collected from the experiment

Gather all the information and data that each team members collected from the experiment

##### 4. With the data collected, assess if the data confirms any of the 4 outcomes below

1. **Great problem, great solution**: The team should be ready to invest in the idea
2. **Great problem, bad solution**: The team should do another session of brainstorming
3. **Not a problem**: Team should try to identify a new problem
4. **Didn't learn about the problem or the solution**: The experiment did not help you to collect the necessary data to validate or invalidate the hypothesis, and the team should redesign the experiment
  `,
    benefits: [],
    thumbImage: m3EvaluateYourExperimentThumbImage,
    backImage: m3EvaluateYourExperimentImage,
    template: m3EvaluateYourExperimentTemplate,
  },
  {
    moduleId: "demo",
    name: "Gain Internal Support",
    slug: "demo-gain-internal-support",
    resources: [],
    content: mdx`
### Why should you use this method?

- To get your company's support if you have an innovative idea for a     customer problem, but someone in the organization is blocking you
- To fight resistance within your organization
- To increase confidence in the idea and support the case
- To learn more about your hypothesis and adapt the idea

It is important to conduct the experiments in any case. Skipping the experiments is not an option! Don't wait for management to realize how great your idea is but take action to show them. And if your solution fails, you still gather valuable data for your organization. Experimentation is never a waste of time.

### How can you apply this method?

##### 1. Listen to your customers during every interaction

What are people's conversations? What are the reactions to your product/service? What are they doing when they're not using the product/service? Take notes and pay attention to emerging pain points.

##### 2. Sketch out your idea

For example by using tools like the idea napkin, and if possible, connect it to your organization's goals.

##### 3. Present the idea to your manager

Also include the supporting data from your observation.

### How to collect more data if there is continue resistance even after solid observations?

##### 1. Use actual customer data

Conduct experiments that will generate actual data to move innovation from the realm of the imagination to the realm of reality.

##### 2. Prototype the idea

Use prototyping to create the data you need to make the case for your idea.

##### 3. Quick and cheap 2h experiments

By conducting quick and cheap experiments, you get data faster, and hence it is easier to get support.

##### 4. Present your idea

Go back to your management with the collected data and ask for support to continue the project. Be aware of your language "I told you so" might raise resistance, while "I gathered more data" allows for a neutral start of the conversation.
  `,
    benefits: [],
    thumbImage: m3GainInternalSupportThumbImage,
    backImage: m3GainInternalSupportImage,
  },
];
