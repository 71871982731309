export enum AssignmentType {
  PRACTICAL_ASSIGNMENT = "practical_assignment",
  KNOWLEDGE_CHECK = "knowledge_check",
  COURSE_EVALUATION = "course_evaluation",
}

export enum ExerciseStatusType {
  IN_PROGRESS = "in_progress",
  SUBMITTED = "submitted",
  GRADED = "graded",
}

export const AssignmentTypeLabelMapping = {
  [AssignmentType.PRACTICAL_ASSIGNMENT]: "Practical Assignment",
  [AssignmentType.KNOWLEDGE_CHECK]: "Knowledge Check",
  [AssignmentType.COURSE_EVALUATION]: "Course Evaluation",
};

export interface UploadedFile {
  id: number;
  file_url: string;
}

export type Assignment = {
  id: string;
  module_id: string;
  cohort_id: string;
  exercises: Exercise[];
};

export type Exercise = {
  id: string;
  title: string;
  content: string;
  recommended_effort: string;
  method_cards: string[];
  work_style: string;
  setting: string;
  sections: ExerciseSection[];
  type: "standard" | "team";
};

export type ExerciseStatusMap = Map<string, ExerciseStatusType>;

export type GraderRatingStatus = "ungraded" | "pending" | "updating" | "rated";

export type ExerciseStatus = {
  id: number;
  exercise_id: string;
  status: ExerciseStatusType;
  is_retry: boolean;
  grader_rating_status: GraderRatingStatus;
};

export type ExerciseSubmission = {
  id: number;
  user_id: string;
  user_name: string;
  grader_name?: string;
  cohort_id: string;
  module_id: string;
  exercise_id: string;
  is_retry: boolean;
  submitted_at?: Date;
  graded_at?: Date;
  grading_locked_by?: string;
  needs_grading_review?: boolean;
  flag_comment?: string;
  scores: { [item: string]: number };
  max_score: number;
};

export type ExerciseSection = {
  id: string;
  pk: number;
  title?: string;
  content: string;
  questions: Question[];
  grading_criteria_options: GradingCriterion[];
  gpt_query?: string;
};

export type Question = {
  id: string;
  pk: number;
  title: string;
  type: string;
  max_len: number;
  grading_tip?: string;
  points: number;
  grading_criteria_sets: GradingCriteria[];
  grading_initial_points: number;
  gpt_query?: string;
};

export type SavedExercise = {
  id: number;
  assignment_id: string;
  answers: SavedAnswer[];
  scores: Map<string, number>;
  feedback: GradingFeedbackMap;
  criteria_applied: GradingCriteriaAppliedMap;
  submitted_at?: Date;
  submitted_by_id?: string;
  submitted_by_name?: string;
  is_retry: boolean;
  graded_by?: string;
  graded_at?: Date;
  grading_locked_by?: string;
  grading_lock_expires_at?: Date;
  last_saved_at?: number;
  last_saved_by_id?: string;
  last_saved_by_name?: string;
  grader_rating?: number;
};

export type SavedAnswer = {
  id: string;
  question_id: string;
  upload_id: number;
  file_url: string;
  answer: string;
};

// Map with the question id as key and the participant's response as the value
export type AnswersMap = Map<string, string | UploadedFile>;

export type GradingCriteria = {
  title: string;
  type: "single" | "multiple";
  options: GradingCriterion[];
};

export type GradingCriterion = {
  grading_id: string;
  text: string;
  points: number;
};

type CriteriaScore = { [criteriaId: string]: number };

export type GradingFeedbackMap = { [itemId: string]: string[] };
export type GradingCriteriaAppliedMap = { [itemId: string]: CriteriaScore[] };
export type GradingScoresMap = { [itemId: string]: number };

export type ExerciseTeamMap = { [exerciseId: string]: ExerciseTeam };

export type Teammate = {
  id: string;
  email: string;
  first_name: string;
  last_name: string;
  is_leader: boolean;
};

export type ExerciseTeam = {
  id: number;
  module_id: string;
  exercise_id: string;
  members: Teammate[];
};
