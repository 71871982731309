import { BlockUnit, Course, Lesson, LessonBlock } from "@sparkademy/app-common/models/course";

export function getDashboardLink(course: Course) {
  return `/course/${course.id}`;
}

export function getBlockUnitLink(
  course: Course,
  lesson: Lesson,
  block: LessonBlock,
  unit: BlockUnit,
  resume?: boolean
): string {
  return resume
    ? `/course/${course.id}/lesson/${lesson.id}/block/${block.id}/unit/${unit.id}?resume=true`
    : `/course/${course.id}/lesson/${lesson.id}/block/${block.id}/unit/${unit.id}`;
}

export function getBlockCompleteLink(course: Course, lesson: Lesson, block: LessonBlock): string {
  return `/course/${course.id}/lesson/${lesson.id}/block/${block.id}/completed`;
}

export function getLessonCompleteLink(course: Course, lesson: Lesson): string {
  return `/course/${course.id}/lesson/${lesson.id}/completed`;
}

export function isLastUnitInCourse(course: Course, blockUnitId: number): boolean {
  const lastLesson = course.lessons[course.lessons.length - 1];
  const lastBlock = lastLesson.blocks[lastLesson.blocks.length - 1];
  const lastUnit = lastBlock.units[lastBlock.units.length - 1];

  return lastUnit.id === blockUnitId.toString();
}

export function isKnowledgeCheckBlock(block: LessonBlock): boolean {
  return block.title.match(/^knowledge\s+check/i) !== null;
}
