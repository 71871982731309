/** @jsx jsx */
import { jsx, Box, Text, SxStyleProp, SxProps } from "theme-ui";
import * as React from "react";
import { ButtonGhostLink } from "@sparkademy/app-common/elements/ButtonGhost";
import { TrackingService } from "../services/tracking-service";

type BreadcrumbProps = {
  items: {
    name: string;
    url?: string;
  }[];
  sx?: SxProps;
};

export const Breadcrumb: React.FC<BreadcrumbProps> = ({ items, sx }) => {
  const style: SxStyleProp = {
    fontSize: "16px",
    p: 0,
    minHeight: "auto",
    minWidth: "70px",
    lineHeight: 1.3,

    display: "block",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: ["hidden", "visible"],
  };

  return (
    <Box id="breadcrumb" sx={{ display: "flex", mb: "32px", ...sx }}>
      {items.map((item, index) =>
        index < items.length - 1 && item.url ? (
          <React.Fragment key={index}>
            <ButtonGhostLink
              to={item.url}
              sx={style}
              onClick={() => TrackingService.breadcrumbItemClick(item)}
            >
              {item.name}
            </ButtonGhostLink>
            <Text as="span" sx={{ mx: 3, alignSelf: "center" }}>
              &gt;
            </Text>
          </React.Fragment>
        ) : (
          <Text key={index} sx={style}>
            {item.name}
          </Text>
        )
      )}
    </Box>
  );
};
