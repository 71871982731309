import * as React from "react";
import { Heading, Box, Container } from "theme-ui";
import { Narrative } from "@sparkademy/app-common/elements/Narrative";
import { ReactComponent as IllustrationPerson } from "@sparkademy/app-common/materials/illustrations/person.svg";

export enum SparkCheckProfile {
  ChangeMaker = "Change Maker",
  PeoplesPerson = "People's Person",
  FrontRunner = "Front Runner",
  ProblemSolver = "Problem Solver",
}

type Props = {
  profile: SparkCheckProfile;
};

function getShortDefinition(profile: SparkCheckProfile): string {
  const mapping = {
    [SparkCheckProfile.ChangeMaker]: "Knows where to go and with whom",
    [SparkCheckProfile.PeoplesPerson]: "Keeps the team together",
    [SparkCheckProfile.FrontRunner]: "Opens the way",
    [SparkCheckProfile.ProblemSolver]: "Handles existing issues",
  };

  return mapping[profile];
}

function getLongDefinition(profile: SparkCheckProfile): string {
  const mapping = {
    [SparkCheckProfile.ChangeMaker]:
      "Your unique transformation type is most closely aligned to the Change Maker. In particular, you feel very confident about moving towards fast-changing environments. Inherently, you are good at recognizing patterns and at predicting what might happen in the future. Moreover, your empathic nature draws other people along with you. It’s easy for you to engage the team and make others feel heard and appreciated.",
    [SparkCheckProfile.PeoplesPerson]:
      "Your unique transformation type is most closely aligned to the People’s Person. In particular, you prefer to solve existing problems in the here and now and tackle challenges together with your team. By nature, you are good at recognizing and understanding other’s emotions that give you the necessary tool to approach people with empathy. Moreover, it’s easy for you to engage the team and make people around you feel appreciated and supported.",
    [SparkCheckProfile.FrontRunner]:
      "Your unique transformation type is most closely aligned to the Front Runner. In particular, you are not afraid to move towards fast-changing environments and feel comfortable with the exploration of alternatives. By nature, you are good at recognizing patterns and enjoy the search for strategies that can cope the future challenges. You are very goal-focused and prefer well-defined patterns of communication as well as clear distributed roles within your team.",
    [SparkCheckProfile.ProblemSolver]:
      "Your unique transformation type is most closely aligned to the Problem Solver. In particular, you are not afraid to face the root cause of any problem and tackle whatever is blocking your team from going ahead. You prefer to work in the here and now. By nature, you are very goal-focused and feel comfortable with well-defined patterns of communication as well as clear distributed roles within your team.",
  };

  return mapping[profile];
}

function getCardBackgroundColor(profile: SparkCheckProfile): string {
  const mapping = {
    [SparkCheckProfile.ChangeMaker]: "new.advancedGreenLight",
    [SparkCheckProfile.PeoplesPerson]: "new.failingRedLight",
    [SparkCheckProfile.FrontRunner]: "new.turquoiseLight",
    [SparkCheckProfile.ProblemSolver]: "new.coreYellowLight",
  };

  return mapping[profile];
}

export const SparkCheckProfileCard: React.FC<Props> = ({ profile }) => {
  return (
    <React.Fragment>
      <Box
        sx={{
          width: "100%",
          bg: getCardBackgroundColor(profile),
          borderRadius: 20,
          p: [6, 8],
        }}
      >
        <Box
          sx={{
            bg: "new.primary.white",
            borderRadius: 20,
            p: [6, 8],
          }}
        >
          <Container
            sx={{
              textAlign: "center",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <Box
              sx={{
                width: 170,
                height: 170,
                svg: {
                  width: "100%",
                  height: "auto",
                },
              }}
            >
              <IllustrationPerson />
            </Box>

            <Heading
              as="h1"
              sx={{
                fontSize: [2, 3],
                fontWeight: "bold",
                textAlign: "center",
                mb: 10,
              }}
            >
              {profile}
            </Heading>

            <Narrative
              sx={{
                width: "100%",
                textAlign: "left",
                fontSize: 0,
                mb: 8,
              }}
            >
              <b>{profile}:</b> {getShortDefinition(profile)}
            </Narrative>

            <Narrative
              sx={{
                textAlign: "left",
                fontSize: 0,
              }}
            >
              {getLongDefinition(profile)}
            </Narrative>
          </Container>
        </Box>
      </Box>
    </React.Fragment>
  );
};
