import { Box, Link } from "theme-ui";

import React from "react";
import { Layout, LayoutHeader } from "../components/Layout";
import { useSessionContext } from "@sparkademy/app-common/contexts/session-context";
import { Container } from "@sparkademy/app-common/elements/Container";
import { Button } from "@sparkademy/app-common/elements/Button";
import { useQuery } from "@sparkademy/app-common/utils/useQuery";
import { Loader } from "@sparkademy/app-common/components/Loader";
import { oAuthAuthorizeUrl } from "../services/http-api-service";

export const OAuthAuthorize: React.FC = () => {
  const { currentUser } = useSessionContext();

  const query = useQuery();
  const clientId = query.get("client_id") || "";

  const [loading, setLoading] = React.useState<boolean>(false);

  if (loading) {
    return (
      <Layout sx={{ bg: "new.primary.white" }}>
        <Loader />
      </Layout>
    );
  }

  async function onSubmit(event: React.FormEvent<HTMLButtonElement>) {
    setLoading(true);
    window.location.href = oAuthAuthorizeUrl(clientId, currentUser!);
  }

  return (
    <LayoutHeader sx={{ bg: "new.primary.white" }}>
      <Container
        sx={{
          flex: "1 1 auto",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          pt: 13,
        }}
      >
        <Box
          sx={{
            width: 120,
            height: 120,
            fontSize: 50,
            fontWeight: 700,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "#A593EF",
            color: "white",
            borderRadius: "50%",
            mb: 40,
          }}
        >
          {currentUser?.username[0].toUpperCase()}
        </Box>
        <p>
          <b>Circle</b> will receive: your name and email address.
        </p>
        <Box
          sx={{
            display: "flex",
            maxWidth: "90ch",
            textAlign: "center",
            flexDirection: "column",
            pt: 35,
          }}
        >
          <Button onClick={onSubmit}>Continue as {currentUser?.username}</Button>
        </Box>
        <Link sx={{ pt: 24 }} href="https://community.sparkademy.com/users/sign_in">
          Cancel
        </Link>
      </Container>
    </LayoutHeader>
  );
};
