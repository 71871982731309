import * as React from "react";
import { useSessionContext } from "@sparkademy/app-common/contexts/session-context";
import { LayoutSideMenu } from "../components/Layout";
import { ContainerSmall } from "@sparkademy/app-common/elements/Container";
import { Heading, Box, ThemeProvider } from "theme-ui";

import { themeNew } from "@sparkademy/app-common/materials/theme";

import { Breadcrumb } from "../components/Breadcrumb";
import { useParams } from "react-router-dom";
import { HTMLContentWrapper } from "../components/Workshop/HTMLContentWrapper";
import { ReactComponent as GearGroup } from "@sparkademy/app-common/materials/icons/new/gear-group.svg";
import { useWorkshopSessionData } from "../hooks/useBackendData";
import { useWorkshopSessionTeamData } from "../hooks/useWorkshopSessionTeamData";
import { Loader } from "@sparkademy/app-common/components/Loader";
import { CardWorkshopSessionOverview } from "../components/Workshop/CardWorkshopSessionOverview";
import {
  WorkshopTutorialBanner,
  DISMISS_TUTORIAL_TRACKING_KEY,
} from "../components/Workshop/WorkshopTutorialBanner";
import { CircleCheckIcon } from "../components/CustomIcons/CircleCheckIcon";
import { AddToCalendarButton } from "../components/Workshop/AddToCalendarButton";
import { SkillWorkshopSession } from "@sparkademy/app-common/models/workshops";
import { addHours } from "@sparkademy/app-common/utils/date";
import { useAtom } from "jotai";
import { dateStateAtom } from "../stores/current-date";

type Props = {
  name: string;
};

const MIN_ENROLLMENTS_FOR_WORKSHOP = 5;

export const WorkshopSession: React.FC<Props> = () => {
  const { currentUser } = useSessionContext();
  const { workshopId, sessionId } = useParams<{
    workshopId: string;
    sessionId: string;
  }>();
  const { data: session, loading } = useWorkshopSessionData(sessionId!, currentUser!);
  const [currentDate] = useAtom(dateStateAtom);

  const breadcrumbItems = [
    { name: "Dashboard", url: "/dashboard" },
    { name: "Workshops", url: "/workshops" },
    { name: session?.workshop?.title, url: "/workshop/" + workshopId + "/session/" + sessionId },
  ];

  const { team, loading: teamLoading } = useWorkshopSessionTeamData(
    parseInt(sessionId),
    currentUser?.data.id!
  )!;

  if (!currentUser) {
    return null;
  }

  if (loading || teamLoading) {
    return (
      <Box
        sx={{
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          position: "fixed",
          zIndex: 9,
          bg: "background",
        }}
      >
        <Loader />
      </Box>
    );
  }

  const showTutorialBanner = !sessionStorage.getItem(DISMISS_TUTORIAL_TRACKING_KEY);

  // checks whether session starts in 24 hours
  let sessionAboutToStart = false;
  if (session?.schedules && session?.schedules.length > 0) {
    const sessionStartAt = session.schedules[0].startAt;
    const twentyFourHoursFromNow = addHours(currentDate, 24);
    sessionAboutToStart = sessionStartAt && sessionStartAt < twentyFourHoursFromNow;
  }

  return (
    <ThemeProvider theme={themeNew}>
      <LayoutSideMenu
        sx={{
          bg: "new.bg",
        }}
      >
        {/* Workshop Title */}

        <ContainerSmall
          sx={{
            // color: "new.primary.white",
            height: 100,
            mt: 80,
            mb: "32px",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ width: "100%" }}>
            <Breadcrumb items={breadcrumbItems} />
          </Box>
          <Heading
            as="h1"
            sx={{
              fontSize: 4,
              fontWeight: "bold",
            }}
          >
            Workshop Overview
          </Heading>
        </ContainerSmall>
        <ContainerSmall sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
          <Box
            as="article"
            sx={{
              bg: "new.primary.white",
              borderRadius: 10,
              p: 8,
              pb: 0,
              mt: 1,
              width: "100%",
            }}
          >
            <Box
              as="header"
              sx={{
                display: "flex",
                alignItems: "center",
                mb: 8,
              }}
            >
              <GearGroup />
              <Box sx={{ ml: 4 }} />
              <Heading
                as="h2"
                sx={{
                  fontSize: 3,
                  fontWeight: "bold",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                {session?.workshop?.title}
              </Heading>
            </Box>
            <Box
              as="article"
              sx={{
                bg: "new.primary.white",
                borderRadius: 10,
                maxWidth: 820,
                mb: 8,
              }}
            >
              <HTMLContentWrapper html={session?.instructions!} />
            </Box>
          </Box>

          {!sessionAboutToStart &&
            (session?.enrollmentsCount >= MIN_ENROLLMENTS_FOR_WORKSHOP ? (
              <SignupConfirmedCard session={session} />
            ) : (
              <SignupsTrackerCard enrollmentsCount={session?.enrollmentsCount} />
            ))}

          <Box
            id="prep-card"
            sx={{
              bg: "new.primary.white",
              borderRadius: 10,
              p: 8,
              pb: 5,
              mt: 8,
              width: "90%",
            }}
          >
            <Box
              as="header"
              sx={{
                display: "flex",
                alignItems: "center",
                mb: 8,
              }}
            >
              <Heading
                as="h2"
                sx={{
                  fontSize: 2,
                  fontWeight: "bold",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                Preparation
              </Heading>
            </Box>
            <Box
              as="article"
              sx={{
                bg: "new.primary.white",
                borderRadius: 10,
                maxWidth: 820,
                // p: 8,
              }}
            >
              <HTMLContentWrapper html={session?.preparationSummary!}></HTMLContentWrapper>
            </Box>
          </Box>
          {/** Sessions cards **/}
          {session?.schedules!.map((schedule, index) => {
            return (
              <CardWorkshopSessionOverview
                session={session}
                schedule={schedule}
                index={index}
                isMultiSession={session.schedules.length > 1}
                resources={team?.resources}
                key={index}
              />
            );
          })}
          <Box
            as="hr"
            sx={{
              border: 0,
              height: "1px",
              borderBottom: theme => `2px solid ${theme.colors.new.secondary.violet}`,
              width: "80%",
              marginLeft: "auto",
              marginRight: "auto",
              my: 8,
            }}
          />
        </ContainerSmall>
        {showTutorialBanner && <WorkshopTutorialBanner session={session} />}
      </LayoutSideMenu>
    </ThemeProvider>
  );
};

const SignupsTrackerCard: React.FC<{ enrollmentsCount: number }> = ({ enrollmentsCount }) => {
  return (
    <React.Fragment>
      <Box
        id="signups-card"
        sx={{
          bg: "new.primary.white",
          borderRadius: 10,
          p: 8,
          mt: 8,
          width: "80%",
        }}
      >
        <Box
          as="article"
          sx={{
            textAlign: "center",
          }}
        >
          This workshop has{" "}
          <b>
            {enrollmentsCount} out of {MIN_ENROLLMENTS_FOR_WORKSHOP}
          </b>{" "}
          minimum sign ups
        </Box>
        <Box
          sx={{
            margin: "auto",
            my: "30px",
            display: "flex",
            justifyContent: "space-between",
            width: "350px",
          }}
        >
          {Array.from({ length: MIN_ENROLLMENTS_FOR_WORKSHOP }).map((_, index) => (
            <CircleCheckIcon key={index} checked={index + 1 <= enrollmentsCount} />
          ))}
        </Box>
        <Box
          as="article"
          sx={{
            textAlign: "center",
            lineHeight: "1.4",
          }}
        >
          We want to ensure the best peer-to-peer learning experience.
          <br />
          Your enrollment will be finalized when the workshop reaches {
            MIN_ENROLLMENTS_FOR_WORKSHOP
          }{" "}
          sign-ups.
          <br />
          You will be informed of the workshop status via email at least one week in advance.
        </Box>
      </Box>
      <Box
        as="hr"
        sx={{
          border: 0,
          height: "1px",
          borderBottom: theme => `2px solid ${theme.colors.new.secondary.violet}`,
          width: "80%",
          marginLeft: "auto",
          marginRight: "auto",
          mt: 8,
        }}
      />
    </React.Fragment>
  );
};

const SignupConfirmedCard: React.FC<{ session: SkillWorkshopSession }> = ({ session }) => {
  const sessionDetailLink = `/workshop/${session.workshop.id}/session/${session.id}`;

  return (
    <React.Fragment>
      <Box
        id="signup-confirmed-card"
        sx={{
          bg: "new.primary.white",
          borderRadius: 10,
          p: 8,
          mt: 8,
          width: "80%",
        }}
      >
        <Box
          as="article"
          sx={{
            textAlign: "center",
          }}
        >
          Enrollment confirmed!
        </Box>

        <Box sx={{ my: 7, display: "flex", justifyContent: "center" }}>
          <AddToCalendarButton
            isFilled={true}
            title={"Sparkademy Workshop"}
            events={session.schedules}
            link={sessionDetailLink}
          />
        </Box>
        <Box
          as="article"
          sx={{
            fontSize: "0.9rem",
            textAlign: "center",
            lineHeight: "1.4",
          }}
        >
          Get ready for your upcoming workshop! You will participate in a small group so your
          attendance is essential to the learning experience.
          <br />
          Email team@sparkademy.com if you can no longer attend.
        </Box>
      </Box>
      <Box
        as="hr"
        sx={{
          border: 0,
          height: "1px",
          borderBottom: theme => `2px solid ${theme.colors.new.secondary.violet}`,
          width: "80%",
          marginLeft: "auto",
          marginRight: "auto",
          mt: 8,
        }}
      />
    </React.Fragment>
  );
};
