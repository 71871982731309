// eslint-disable-next-line
import * as React from "react";
// @ts-ignore
import { mdx } from "mdx.macro";
import { Tool } from "../../models/tool";

// m4 assets
import m4ConstructiveFeedbackFrontImage from "../method-kit/m4/front/constructive-feedback.svg";
import m4ConstructiveFeedbackBackImage from "../method-kit/m4/back/constructive-feedback.svg";

export const tools: Tool[] = [
  {
    phase: "empathize",
    moduleId: "sth_embax",
    name: "Constructive Feedback",
    slug: "sth-providing-constructive-feedback",
    resources: [],
    content: mdx`
Feedback is crucial for learning, but giving and receiving feedback is not easy.

##### The 7:1 Feedback Principle

According to research, high-performing teams share positive feedback nearly six times more often than average teams. Low-performing teams, on the other hand, share nearly twice as much negative feedback as average teams. Negative feedback hurts. In a team, it can trigger negative energy, which hampers collaboration. But if counterbalanced with positive feedback, it can be constructive and help teammates play better together.

##### What to give feedback on

It is more difficult to give and receive negative feedback. Before giving it, you need to realize that some aspects of behaviour are more difficult to change than others.

When giving feedback and looking for a solution to solve a conflict, keep this spectrum in mind and aim for easy aspects. Work practices are much easier to change than a personality. Imagine the different personalities in your team cause conflict because someone is always working close to a deadline and needs time pressure, and someone else would like to have everything planned with a time buffer. Solving this conflict should not aim to change how those people feel and prefer working because, most likely, they just can’t change by telling them.

Instead, look at the spectrum and think about an easier solution. For example, you could implement a work practice of setting two deadlines instead of one, so you have the time pressure to get done, plus a designated time to control everything and make large changes.
Or talk about why everyone is acting the way they do and try to change their attitude towards each other. So if person A works until the deadline, try not to view this as sloppy and unresponsible work. And if person B is asking for the current status of the project every day, don’t see this as annoying and controlling, but communicate and give them confidence that everything will be ready at the deadline.

##### Giving feedback on skills

Skills are relatively easy to change. Is your teammate a poor presenter? Give him some tips and tricks about how to make presentations more interactive, shorter, and informative.

##### Giving feedback on personality

Personality, at the other extreme, is inherently difficult to change. Does your teammate get anxious quickly, working himself up over deadlines and deliverables? There is no use telling your teammate to stop worrying – it is very likely that he can’t. When criticized for their personality attributes, most people get defensive, which makes them unreceptive to feedback. Try to frame personality problems in changeable terms. Instead of telling your colleague to stop worrying, ask how you can create work practices that help him cope with stress. Or ask if you can provide any type of training that increases his confidence in getting the work done.

##### Giving feedback on work practices

An aspect that is relatively easy to change is work practices, and that’s an area where people tend to give the most feedback. Before you provide feedback to someone working in a different way than yours, however, pause for a moment to ask yourself:

1. Is this a real problem? Or is it just that person doing his work in a different work style?
2. Perhaps there are even good things about his way of working that you missed?
3. Perhaps waiting until the deadline gives room for him to help other people in the team contribute?
4. Perhaps he simply benefits from the added pressure of the deadline?

Feedback should be adequate and reflect real problems, not just personal preferences.

##### Giving constructive feedback

Both positive and negative feedback need to be (1) specific and (2) actionable.

##### Specific feedback

Unspecific feedback examples:

1. “Good job!” > Good job doing what exactly?
2. “Be a better team player next time!” > How? What did I do wrong?

Specific feedback example

“You did such a great job yesterday. I was really impressed about how you enabled us all to see new aspects of this product that we are working on. Please let us know what helped you do such a great job!”

##### Actionable feedback

A good way to make your feedback specific and workable is to develop an action plan according to the feedback. Define workable next step and attach goals to them. Include rewards if the goals are reached and consequences if they aren’t. Specify a time for follow-up. And make sure that the person who gives the feedback and the one who receives it share the same understanding about moving forward.

When giving feedback, the most important point is helping the recipient receive feedback with an open mind. Stay calm and constructive. Frame problems in terms of skills and work management rather than in terms of personality or personal habits. And keep in mind the 7:1 principle. High performing teams do not avoid negative feedback – they just make sure to balance it out with a surplus of positivity.
    `,
    benefits: [],
    thumbImage: m4ConstructiveFeedbackFrontImage,
    backImage: m4ConstructiveFeedbackBackImage,
  },
];
