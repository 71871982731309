import * as React from "react";
import { Box } from "theme-ui";
// import { UploadAssignmentsModal } from "../components/UploadAssignmentsModal";
import { ButtonGhostAnchor } from "@sparkademy/app-common/elements/ButtonGhost";
import { ButtonAnchor } from "@sparkademy/app-common/elements/Button";
import { CalendarOptions, ICalendar } from "datebook";
import { TrackingService } from "../../services/tracking-service";

type Events = {
  startAt: Date;
  endAt: Date;
}[];

export const AddToCalendarButton: React.FC<{
  events: Events;
  link: string;
  isFilled?: boolean;
  title: string;
}> = ({ events, link, isFilled, title }) => {
  const options: CalendarOptions = {
    title,
    location: "Online",
    start: events[0].startAt,
    end: events[0].endAt,
    description: "See details at: sparkademy.com" + link,
  };

  const calendar = new ICalendar(options);

  if (events.length > 1) {
    const option = {
      title,
      location: "Online",
      start: events[1].startAt,
      end: events[1].endAt,
      description: "See details at: sparkademy.com" + link,
    };
    calendar.addEvent(new ICalendar(option));
  }

  return (
    <Box>
      {/* {showDialog && <AddToCalendarDialog events={events} onClose={() => setShowDialog(false)} />} */}
      {isFilled ? (
        <ButtonAnchor
          onClick={() => {
            // setShowDialog(true);
            TrackingService.AddToCalendarClicked();
            calendar.download();
          }}
          // href={ICalendar.render()}
          // target="_blank"
          id="AddToCalendarButton"
        >
          Add to Calendar
        </ButtonAnchor>
      ) : (
        <ButtonGhostAnchor
          onClick={() => {
            // setShowDialog(true);
            TrackingService.AddToCalendarClicked();
            calendar.download();
          }}
          // href={ICalendar.render()}
          // target="_blank"
          id="AddToCalendarButton"
        >
          Add to Calendar
        </ButtonGhostAnchor>
      )}
    </Box>
  );
};
