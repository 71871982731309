import * as React from "react";
import { Box, Text, Heading } from "theme-ui";
import { Container } from "@sparkademy/app-common/elements/Container";
import { Button, ButtonAnchor } from "@sparkademy/app-common/elements/Button";
import { ButtonGhost } from "@sparkademy/app-common/elements/ButtonGhost";
import { ReactComponent as IllustrationPerson } from "@sparkademy/app-common/materials/illustrations/person.svg";
import { FourSquares } from "../../models/four-squares";
import { Narrative } from "@sparkademy/app-common/elements/Narrative";
import useEventListener from "@use-it/event-listener";

export const GameFourSquaresFinished: React.FC<{
  gameState: FourSquares;
  updateGame: (choice: number | null) => void;
}> = ({ gameState, updateGame }) => {
  useEventListener("keydown", (event: KeyboardEvent) => {
    // @ts-ignore
    if (event.keyCode === 13) {
      updateGame(null);
    }
  });

  if (gameState.type !== "finished") {
    return null;
  }

  const testPart2Url = `${window.location.protocol}//${window.location.host}/assessment-part2${window.location.search}`;

  return (
    <React.Fragment>
      {gameState.step === 1 && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            textAlign: "center",
          }}
        >
          <Heading
            as="h1"
            sx={{
              fontSize: [2, 3],
              fontWeight: "bold",
              textAlign: "center",
              my: 6,
            }}
          >
            Final Round Completed
          </Heading>
          <Text
            sx={{
              fontSize: [25, 50],
              fontWeight: "bold",
              color: "brand.blue",
              lineHeight: 1,
              mb: 7,
            }}
          >
            {`Well done! Your result is ${gameState.total || 0} points.`}
          </Text>

          <Narrative>
            <p>You have completed the first part of the Spark Check! Did you find the pattern?</p>
            <p>
              Participant results will only be used to identify the people with the most ideal
              profile to participate in the Innovation Catalyst Program. They do not reflect your
              motivation or experience related to innovation or creativity.
            </p>
            <p>Your points results will not be communicated to your company.</p>
          </Narrative>

          <Button sx={{ mt: 11 }} onClick={() => updateGame(null)}>
            What's Next?
          </Button>
        </Box>
      )}

      {gameState.step === 2 && (
        <Container
          sx={{
            textAlign: "center",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <Box
            sx={{
              mt: [0, 14],
            }}
          />
          <Box
            sx={{
              width: 170,
              height: 170,
              svg: {
                width: "100%",
                height: "auto",
              },
            }}
          >
            <IllustrationPerson />
          </Box>

          <Heading
            as="h1"
            sx={{
              fontSize: [2, 3],
              fontWeight: "bold",
              textAlign: "center",
              mb: 10,
            }}
          >
            What’s Next?
          </Heading>
          <Narrative>
            <p>
              Congratulations! You are getting closer to discovering your Innovator Profile.
              <br />
              Your next step is to take the <b>second part</b> of the Spark Check.
            </p>
            <p>You may begin the second part right now or continue later.</p>
          </Narrative>
          <ButtonAnchor
            sx={{ mt: 11 }}
            href={`/assessment-part2${window.location.search}`}
            target="_blank"
          >
            Begin second part
          </ButtonAnchor>
          <ButtonGhost onClick={() => updateGame(null)}>Continue Later</ButtonGhost>
        </Container>
      )}

      {gameState.step === 3 && (
        <Container
          sx={{
            textAlign: "center",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <Box
            sx={{
              mt: 14,
            }}
          />
          <Box
            sx={{
              width: 170,
              height: 170,
              svg: {
                width: "100%",
                height: "auto",
              },
            }}
          >
            <IllustrationPerson />
          </Box>

          <Heading
            as="h1"
            sx={{
              fontSize: [2, 3],
              fontWeight: "bold",
              textAlign: "center",
              mb: 10,
            }}
          >
            See You Soon!
          </Heading>
          <Narrative sx={{ px: 5 }}>
            <p>
              Thank you! Have some rest and don’t forget to take the <b>second part</b> at
            </p>
            <Box sx={{ mt: 3, fontSize: [0, 1] }}>
              <a href={testPart2Url} target="_blank" rel="noopener noreferrer">
                {testPart2Url}
              </a>
            </Box>
          </Narrative>
        </Container>
      )}
    </React.Fragment>
  );
};
