import * as React from "react";
import { Box, Heading, Text, Textarea, Select, Input } from "theme-ui";
import { TextareaInput } from "@sparkademy/app-common/elements/Textarea";

export const FormInputField: React.FC<{
  text: string;
  value: string;
  hasError: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}> = ({ text, value, hasError, onChange }) => (
  <Box
    sx={{
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
    }}
  >
    <Text sx={{ mb: 3, maxWidth: [330, 500] }}>{text}</Text>
    <Input
      value={value}
      onChange={onChange}
      sx={{
        width: [330, 500],
        mb: [6, 8],
        border: "2px solid",
        borderColor: hasError ? "new.failingRed" : "auto",
        fontSize: "16px",
        lineHeight: "22px",
        py: "16px",
        px: "16px",
        ":focus-visible": {
          outline: "none",
        },
        ":active,:focus": {
          borderColor: "primary",
        },
      }}
    />
  </Box>
);

export const FormTextField: React.FC<{
  id: string;
  text: string;
  value: string;
  hasError: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}> = ({ id, text, value, hasError, onChange }) => (
  <Box
    sx={{
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
    }}
  >
    <Text sx={{ mb: 3, maxWidth: [330, 500] }}>{text}</Text>
    <TextareaInput
      id={id}
      value={value}
      onChange={onChange}
      sx={{
        borderColor: hasError ? "new.failingRed" : "auto",
        width: [330, 500],
        mb: [6, 8],
        fontSize: "16px",
        lineHeight: "22px",
        py: "16px",
        px: "16px",
        ":active,:focus": {
          borderColor: "primary",
        },
      }}
    />
  </Box>
);

export const FormSelectField: React.FC<{
  id: string;
  text: string;
  selected: string;
  options: { value: string; label: string }[];
  hasError: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  note?: string;
}> = ({ id, text, selected, options, hasError, onChange, note }) => (
  <Box
    sx={{
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      mb: [6, 8],
    }}
  >
    <Text sx={{ mb: 2, maxWidth: [330, 500] }}>{text}</Text>
    {note && <Text sx={{ fontSize: "0.8em", mb: 3, maxWidth: [330, 500] }}>{note}</Text>}
    <Select
      id={id}
      sx={{
        border: "2px solid",
        borderColor: hasError ? "new.failingRed" : "auto",
        py: "16px",
        px: "16px",
        outline: "none",
        width: [330, 500],
        ":active,:focus": {
          borderColor: "primary",
        },
      }}
      onChange={onChange}
      value={selected}
    >
      {options.map((option, idx) => (
        <option key={idx} value={option.value}>
          {option.label}
        </option>
      ))}
    </Select>
  </Box>
);

export const validateFields = (
  form: { [attr: string]: any },
  attributeToLabelMap: { [attr: string]: string }
) => {
  const errors: { [field: string]: string } = {};
  Object.entries(form).forEach(([field, value]) => {
    if (!value) {
      errors[field] = `${attributeToLabelMap[field]} is required`;
    }
  });
  return errors;
};

export const validateFields_v2 = (
  fields: string[],
  form: { [attr: string]: any },
  attributeToLabelMap: { [attr: string]: string }
) => {
  const errors: { [field: string]: string } = {};

  for (const field of fields) {
    if (!form[field]) {
      errors[field] = `${attributeToLabelMap[field]} is required`;
    }
  }

  return errors;
};
