import * as React from "react";
import ky from "ky";
import {
  AlertDialogLabel,
  AlertDialogDescription,
  AlertDialogOverlay,
  AlertDialogContent,
} from "@sparkademy/app-common/elements/Alert";
import { Box, Text, Button as BaseButton } from "theme-ui";
import { Button } from "@sparkademy/app-common/elements/Button";
import { ReactComponent as IllustrationTeam } from "@sparkademy/app-common/materials/illustrations/team-faces.svg";
import { User } from "@sparkademy/app-common/models/user";
import { ReactComponent as Close } from "@sparkademy/app-common/materials/icons/close.svg";
import VisuallyHidden from "@reach/visually-hidden";
import { ButtonGhost } from "@sparkademy/app-common/elements/ButtonGhost";
import { Teammate } from "@sparkademy/app-common/models/assignment";
import { AssignmentService } from "@sparkademy/app-common/services/assignment-service";
import { LoaderInline } from "@sparkademy/app-common/components/Loader";
import { ScrollableSelectList } from "@sparkademy/app-common/components/ScrollableSelectList";
import { useUserData } from "../../hooks/useUserData";

export const TeamFormationDialog: React.FC<{
  assignmentId: string;
  moduleId: string;
  exerciseId: string;
  user: User;
  onClose: () => void;
  onContinueWithoutTeam: () => void;
}> = ({ assignmentId, moduleId, exerciseId, user, onClose, onContinueWithoutTeam }) => {
  const cancelRef = React.useRef<HTMLButtonElement>();
  const [currentStep, setCurrentStep] = React.useState("welcome");
  const [availableTeammates, setAvailableTeammates] = React.useState<Teammate[]>([]);
  const [error, setError] = React.useState<string>("");

  const [selectedTeammates, setSelectedTeammates] = React.useState<string[]>([]);
  const selectTeammate = (id: string) => {
    if (selectedTeammates.includes(id)) {
      setSelectedTeammates(selectedTeammates.filter(stm => stm !== id));
    } else {
      setSelectedTeammates(selectedTeammates.concat(id));
    }
  };

  const [loading, setLoading] = React.useState(false);

  const fetchAvailableTeammates = React.useCallback(async () => {
    setLoading(true);
    AssignmentService.getAvailableTeammates(moduleId, exerciseId, user)
      .then(teammates => {
        setAvailableTeammates(teammates);
        setLoading(false);
      })
      .catch(err => {
        setLoading(false);
        console.error(err);
      });
  }, [moduleId, exerciseId, user]);

  const submitTeam = async () => {
    setLoading(true);
    try {
      await AssignmentService.createTeam(
        assignmentId,
        moduleId,
        exerciseId,
        selectedTeammates,
        user
      );
      window.location.reload();
      setLoading(false);
      onClose();
    } catch (error: any) {
      if (error instanceof ky.HTTPError) {
        // check whether error.response is a JSON object
        const headers = error.response.headers;
        const isJsonResponse = headers.get("content-type")?.includes("application/json");
        if (!isJsonResponse) {
          setError("An error ocurred, try again later.");
          return;
        }

        const errorResp = await error.response.json();
        if (errorResp.error && errorResp.users) {
          setError(
            `These people are unavailable: ${errorResp.users.join(
              ", "
            )}. Please, go back and change your selection.`
          );
        }
      } else {
        setError("An error ocurred, try again later.");
      }
    }
  };

  React.useEffect(() => {
    fetchAvailableTeammates();
  }, [moduleId, exerciseId, user, fetchAvailableTeammates]);

  return (
    <AlertDialogOverlay
      // @ts-ignore
      leastDestructiveRef={cancelRef}
    >
      <AlertDialogContent>
        <AlertDialogLabel
          style={{ height: "32px", width: "100%", position: "sticky", top: "32px" }}
        >
          <BaseButton
            sx={{
              all: "unset",
              fontFamily: "inherit",
              float: "right",
              color: "primary",
              cursor: "pointer",
              bg: "transparent",
            }}
            onClick={onClose}
          >
            <VisuallyHidden>Close</VisuallyHidden>
            <Close width={32} height={32} aria-hidden="true" focusable="false" />
          </BaseButton>
        </AlertDialogLabel>

        {currentStep === "welcome" && <Welcome user={user} setCurrentStep={setCurrentStep} />}
        {currentStep === "teamSelect" && (
          <TeamSelect
            moduleId={moduleId}
            teamMates={availableTeammates}
            setCurrentStep={setCurrentStep}
            selectedTeammates={selectedTeammates}
            selectTeammate={selectTeammate}
            onContinueWithoutTeam={onContinueWithoutTeam}
          />
        )}
        {currentStep === "teamConfirmation" && (
          <TeamConfirmation
            loading={loading}
            error={error}
            setError={setError}
            fetchAvailableTeammates={fetchAvailableTeammates}
            availableTeammates={availableTeammates}
            selectedTeammates={selectedTeammates}
            setCurrentStep={setCurrentStep}
            submitTeam={submitTeam}
            currentUser={user}
          />
        )}
      </AlertDialogContent>
    </AlertDialogOverlay>
  );
};

const Welcome: React.FC<{
  user: User;
  setCurrentStep: (step: string) => void;
}> = ({ user, setCurrentStep }) => {
  return (
    <React.Fragment>
      <Box sx={{ maxWidth: "605px" }}>
        <AlertDialogDescription>
          <Illustration />
          <Text as="p" sx={{ textAlign: "center", fontWeight: 700, fontSize: "20px" }}>
            Welcome, {user.data.firstName}
          </Text>
          <Text as="p" sx={{ textAlign: "center", fontSize: "16px", pt: "12px", width: "405px" }}>
            As a Team Leader, you are about to select your team members for this exercise and submit
            at the end. The answers and evaluation received will be the same for everyone.
          </Text>
        </AlertDialogDescription>
      </Box>
      <Bullets activeIndex={0} />
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          flexDirection: "row-reverse",
          mb: "16px", //
        }}
      >
        <Button
          type="submit"
          onClick={() => setCurrentStep("teamSelect")}
          sx={{ width: "261px", justifyContent: "center" }}
        >
          Select members
        </Button>
      </Box>
    </React.Fragment>
  );
};

const TeamMateSelectionList = ScrollableSelectList<Teammate>();

const TeamSelect: React.FC<{
  moduleId: string;
  teamMates: Teammate[];
  setCurrentStep: (step: string) => void;
  selectedTeammates: string[];
  selectTeammate: (id: string) => void;
  onContinueWithoutTeam: () => void;
}> = ({
  moduleId,
  teamMates,
  setCurrentStep,
  selectedTeammates,
  selectTeammate,
  onContinueWithoutTeam,
}) => {
  const { getModuleById } = useUserData();
  const module = getModuleById(moduleId);

  return (
    <React.Fragment>
      <Box sx={{ maxWidth: "605px" }}>
        <AlertDialogDescription>
          <Illustration />
          <Text as="p" sx={{ textAlign: "center", fontWeight: 700, fontSize: "20px" }}>
            Please select your team members
          </Text>
          <Text as="p" sx={{ textAlign: "center", fontSize: "16px", py: "12px", width: "405px" }}>
            Scroll down using the selector below to find available teammates.
          </Text>
          <TeamMateSelectionList
            items={teamMates}
            getIsSelected={function (tm: Teammate): boolean {
              return selectedTeammates.includes(tm.id);
            }}
            onSelectItem={tm => selectTeammate(tm.id)}
            itemRenderer={function (tm: Teammate): string {
              return `${tm.first_name} ${tm.last_name}`;
            }}
            isMultiple
          />
        </AlertDialogDescription>
      </Box>
      <Bullets activeIndex={1} />
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          mb: "16px",
        }}
      >
        <Button
          type="submit"
          onClick={() => setCurrentStep("teamConfirmation")}
          sx={{ width: "261px", justifyContent: "center" }}
          disabled={selectedTeammates.length === 0}
        >
          Next
        </Button>

        {module?.retry && (
          <ButtonGhost
            sx={{ textDecoration: "underline", mt: "10px" }}
            onClick={onContinueWithoutTeam}
          >
            Continue without a team
          </ButtonGhost>
        )}
      </Box>
    </React.Fragment>
  );
};

const TeamConfirmation: React.FC<{
  loading: boolean;
  error: string;
  fetchAvailableTeammates: () => void;
  setError: (val: string) => void;
  availableTeammates: Teammate[];
  selectedTeammates: string[];
  setCurrentStep: (step: string) => void;
  submitTeam: () => void;
  currentUser: User;
}> = ({
  loading,
  error,
  fetchAvailableTeammates,
  setError,
  availableTeammates,
  selectedTeammates,
  setCurrentStep,
  submitTeam,
  currentUser,
}) => {
  const teammatesById = availableTeammates.reduce((acc, cur) => {
    acc[cur.id] = `${cur.first_name} ${cur.last_name}`;
    return acc;
  }, {} as { [id: string]: string });

  return (
    <React.Fragment>
      <Box sx={{ maxWidth: "605px" }}>
        <AlertDialogDescription>
          <Illustration />
          <Text as="p" sx={{ textAlign: "center", fontWeight: 700, fontSize: "20px" }}>
            Your team has been selected
          </Text>
          <Text as="p" sx={{ textAlign: "center", fontSize: "16px", pt: "12px", width: "405px" }}>
            Great! All members will receive the same evaluation and feedback. Please ensure
            information is correct before continuing.
          </Text>
        </AlertDialogDescription>
      </Box>
      <TeamDisplayBox
        label="Team Leader"
        text={`${currentUser.data.firstName} ${currentUser.data.lastName} (You)`}
      />
      <TeamDisplayBox
        label="Team Members"
        text={selectedTeammates.map(st => teammatesById[st]).join(", ")}
      />
      <Bullets activeIndex={2} />
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          mb: "16px",
        }}
      >
        {error !== "" ? (
          <Text sx={{ maxWidth: "500px" }}>{error}</Text>
        ) : loading ? (
          <LoaderInline />
        ) : (
          <Button
            type="submit"
            onClick={submitTeam}
            sx={{ width: "261px", justifyContent: "center" }}
          >
            Confirm team
          </Button>
        )}
        <ButtonGhost
          onClick={() => {
            fetchAvailableTeammates();
            setCurrentStep("teamSelect");
            setError("");
          }}
          sx={{ width: "261px", justifyContent: "center" }}
        >
          Back
        </ButtonGhost>
      </Box>
    </React.Fragment>
  );
};

const Illustration = () => (
  <Box
    sx={{
      margin: "auto",
      width: 140,
      height: 140,
      mb: "20px",
      svg: {
        width: "100%",
        height: "auto",
      },
    }}
  >
    <IllustrationTeam />
  </Box>
);

const Bullets: React.FC<{ activeIndex: number }> = ({ activeIndex }) => (
  <Box
    sx={{
      mt: "32px",
      mb: "24px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    }}
  >
    {new Array(3).fill(0).map((e, i) => (
      <Box
        key={`box-${i}`}
        sx={{
          width: "10px",
          height: "10px",
          borderRadius: "50%",
          bg: i === activeIndex ? "new.primary.purple" : "new.secondary.grey",
          mx: 2,
        }}
      />
    ))}
  </Box>
);

const TeamDisplayBox: React.FC<{ label: string; text: string }> = ({ label, text }) => (
  <Box
    sx={{
      mt: label === "Team Leader" ? "24px" : "12px",
      display: "flex",
      p: "16px",
      border: "0.5px solid #BFBFBF",
      flexDirection: "column",
      alignItems: "center",
      width: "395px",
      borderRadius: "5px",
      textAlign: "center",
    }}
  >
    <Text sx={{ fontWeight: 700, mb: "8px" }}>{label}</Text>
    <Text>{text}</Text>
  </Box>
);
