/** @jsx jsx */
import { jsx, Box, Text, Heading } from "theme-ui";
import React from "react";
import { LayoutHeader } from "../components/Layout";
import { Container } from "@sparkademy/app-common/elements/Container";
import { ReactComponent as IllustrationPersonSad } from "@sparkademy/app-common/materials/illustrations/person-sad.svg";
import { uiCopy } from "../data/ui-copy";
import { useQuery } from "@sparkademy/app-common/utils/useQuery";
import { ButtonOutline } from "@sparkademy/app-common/elements/ButtonOutline";

export const GenericErrorPage: React.FC = () => {
  const query = useQuery();
  const reason = query.get("reason");
  const openTimestamp = parseInt(query.get("open") || "0");
  const closeTimestamp = parseInt(query.get("close") || "0");

  let headlineCopy;
  let errorCopy;
  let helpCopy = uiCopy.GENERIC_HELP_MESSAGE();

  switch (reason) {
    case "login":
      headlineCopy = uiCopy.LOGIN_ERROR_HEADER();
      errorCopy = uiCopy.LOGIN_ERROR_MESSAGE();
      break;
    case "not-authed":
      headlineCopy = uiCopy.NOT_AUTHED_ERROR_HEADER();
      errorCopy = uiCopy.NOT_AUTHED_ERROR_MESSAGE();
      break;
    case "session-expired":
      headlineCopy = uiCopy.SESSION_EXPIRED_ERROR_HEADER();
      errorCopy = uiCopy.SESSION_EXPIRED_ERROR_MESSAGE();
      break;
    case "module-failed":
      headlineCopy = uiCopy.MODULE_FAILED_ERROR_HEADER();
      errorCopy = uiCopy.MODULE_FAILED_ERROR_MESSAGE();
      break;
    case "assessment-upcoming":
      headlineCopy = uiCopy.ASSESSMENT_UPCOMING_ERROR_HEADER();

      if (openTimestamp && closeTimestamp) {
        errorCopy = uiCopy.ASSESSMENT_UPCOMING_ERROR_MESSAGE(
          new Date(openTimestamp * 1000),
          new Date(closeTimestamp * 1000)
        );
      } else {
        errorCopy = uiCopy.GENERIC_ERROR_MESSAGE();
      }
      break;
    case "assessment-closed":
      headlineCopy = uiCopy.ASSESSMENT_CLOSED_ERROR_HEADER();
      errorCopy = uiCopy.ASSESSMENT_CLOSED_ERROR_MESSAGE();
      break;
    case "assessment-locked":
      headlineCopy = uiCopy.ASSESSMENT_LOCkED_ERROR_HEADER();
      errorCopy = uiCopy.ASSESSMENT_LOCkED_ERROR_MESSAGE();
      break;
    case "assessment-pending-evaluation":
      headlineCopy = uiCopy.ASSESSMENT_PENDING_EVALUATION_ERROR_HEADER();
      errorCopy = uiCopy.ASSESSMENT_PENDING_EVALUATION_ERROR_MESSAGE();
      break;
    case "homework-upcoming":
      headlineCopy = uiCopy.HOMEWORk_UPCOMING_ERROR_HEADER();

      if (openTimestamp && closeTimestamp) {
        errorCopy = uiCopy.HOMEWORk_UPCOMING_ERROR_MESSAGE(
          new Date(openTimestamp * 1000),
          new Date(closeTimestamp * 1000)
        );
      } else {
        errorCopy = uiCopy.GENERIC_ERROR_MESSAGE();
      }
      break;
    case "homework-closed":
      headlineCopy = uiCopy.HOMEWORk_CLOSED_ERROR_HEADER();
      errorCopy = uiCopy.HOMEWORk_CLOSED_ERROR_MESSAGE();
      break;
    case "homework-locked":
      headlineCopy = uiCopy.HOMEWORk_LOCkED_ERROR_HEADER();
      errorCopy = uiCopy.HOMEWORk_LOCkED_ERROR_MESSAGE();
      break;
    case "homework-pending-evaluation":
      headlineCopy = uiCopy.HOMEWORk_PENDING_EVALUATION_ERROR_HEADER();
      errorCopy = uiCopy.HOMEWORk_PENDING_EVALUATION_ERROR_MESSAGE();
      break;
    case "feedback-open":
      headlineCopy = uiCopy.FEEDBACk_OPEN_ERROR_HEADER();
      errorCopy = uiCopy.FEEDBACk_OPEN_ERROR_MESSAGE();
      break;
    case "spark-check-taken":
      headlineCopy = uiCopy.SPARK_CHECK_TAKEN_ERROR_HEADER();
      errorCopy = uiCopy.SPARK_CHECK_TAKEN_ERROR_MESSAGE();
      helpCopy = uiCopy.SPARK_CHECK_TAKEN_ERROR_HELP();
      break;
    case "cohort-changed":
      headlineCopy = "Cohort Changed";
      errorCopy = (
        <p>Your cohort has changed and you need to sign in again to refresh your session data.</p>
      );
      break;
    case "cms-missing-content":
      headlineCopy = "Missing Content";
      errorCopy = <p>It looks like there's missing content in the CMS to serve this request.</p>;
      break;
    case "invalid-cohort":
      headlineCopy = "Invalid Cohort";
      errorCopy = <p>It looks like the cohort is invalid for this request.</p>;
      break;
    default:
      headlineCopy = uiCopy.GENERIC_ERROR_HEADER();
      errorCopy = uiCopy.GENERIC_ERROR_MESSAGE();
  }

  return (
    <LayoutHeader>
      <Container
        sx={{
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            mt: 14,
          }}
        />
        <Box
          sx={{
            width: 170,
            height: 170,
            svg: {
              width: "100%",
              height: "auto",
            },
          }}
        >
          <IllustrationPersonSad />
        </Box>

        <Heading
          as="h1"
          sx={{
            fontSize: [2, 3],
            fontWeight: "bold",
            textAlign: "center",
            mb: [5, 10],
          }}
        >
          {headlineCopy}
        </Heading>

        <Text>{errorCopy}</Text>

        <Box>
          <ButtonOutline id="back-btn" sx={{ my: "16px" }} onClick={() => window.history.back()}>
            Back
          </ButtonOutline>
        </Box>

        <Text sx={{ mb: "16px" }}>{helpCopy}</Text>
      </Container>
    </LayoutHeader>
  );
};
